import i18n from './i18n';
import React, { Component } from 'react';

export const proFeatures = () => [
  {
    image: '/images/landing/features_tracking.png',
    title: i18n.t(`home.pro.features.feats.feat1.title`),
    description: i18n.t(`home.pro.features.feats.feat1.description`),
    featureKey: 'tracking'
  },
  {
    image: '/images/landing/features_reports.png',
    title: i18n.t(`home.pro.features.feats.feat2.title`),
    description: i18n.t(`home.pro.features.feats.feat2.description`),
    featureKey: 'reports'
  },
  {
    image: '/images/landing/features_beacons.png',
    title: i18n.t(`home.pro.features.feats.feat3.title`),
    description: i18n.t(`home.pro.features.feats.feat3.description`),
    featureKey: 'beacon'
  },
  {
    image: '/images/landing/features_accuracy.png',
    title: i18n.t(`home.pro.features.feats.feat4.title`),
    description: i18n.t(`home.pro.features.feats.feat4.description`),
    featureKey: 'accuracy'
  },
  {
    image: '/images/landing/features_fraud_prevention.png',
    title: i18n.t(`home.pro.features.feats.feat5.title`),
    description: i18n.t(`home.pro.features.feats.feat5.description`),
    featureKey: 'fraud'
  },
  {
    image: '/images/landing/features_secure_storage.png',
    title: i18n.t(`home.pro.features.feats.feat6.title`),
    description: i18n.t(`home.pro.features.feats.feat6.description`),
    featureKey: 'storage'
  }
];

export const enterpriseFeatures = () => [
  {
    image: '/images/landing/features_team_dashboard.png',
    title: i18n.t(`home.enterprise.features.feats.feat1.title`),
    description: i18n.t(`home.enterprise.features.feats.feat1.description`),
    featureKey: 'dashboard'
  },
  {
    image: '/images/landing/features_multi_teams.png',
    title: i18n.t(`home.enterprise.features.feats.feat2.title`),
    description: i18n.t(`home.enterprise.features.feats.feat2.description`),
    featureKey: 'multi_team'
  },
  {
    image: '/images/landing/features_reports.png',
    title: i18n.t(`home.enterprise.features.feats.feat3.title`),
    description: i18n.t(`home.enterprise.features.feats.feat3.description`),
    featureKey: 'report_workflow'
  },
  {
    image: '/images/landing/features_fraud_prevention.png',
    title: i18n.t(`home.enterprise.features.feats.feat4.title`),
    description: i18n.t(`home.enterprise.features.feats.feat4.description`),
    featureKey: 'fraud_prevention'
  },
  {
    image: '/images/landing/features_secure_storage.png',
    title: i18n.t(`home.enterprise.features.feats.feat5.title`),
    description: i18n.t(`home.enterprise.features.feats.feat5.description`),
    featureKey: 'secure_storage'
  },
  {
    image: '/images/landing/features_integrations.png',
    title: i18n.t(`home.enterprise.features.feats.feat6.title`),
    description: i18n.t(`home.enterprise.features.feats.feat6.description`),
    featureKey: 'integrations'
  }
];

export const enterpriseHowItWorks = () => [
  {
    title: i18n.t(`home.enterprise.howItWorks.track.title`),
    description: i18n.t(`home.enterprise.howItWorks.track.description`),
    stepKey: 'track'
  },
  {
    title: i18n.t(`home.enterprise.howItWorks.report.title`),
    description: i18n.t(`home.enterprise.howItWorks.report.description`),
    stepKey: 'report'
  },
  {
    title: i18n.t(`home.enterprise.howItWorks.submit.title`),
    description: i18n.t(`home.enterprise.howItWorks.submit.description`),
    stepKey: 'submit'
  },
  {
    title: i18n.t(`home.enterprise.howItWorks.approve.title`),
    description: i18n.t(`home.enterprise.howItWorks.approve.description`),
    stepKey: 'approve'
  }
];

export const landingPlansFreeFeatures = () => {
  return (
    `
    <div>` +
    i18n.t('home.plans.plans.free.item1') +
    `</div>
    <div>` +
    i18n.t('home.plans.plans.free.item2') +
    `</div>
    <div>` +
    i18n.t('home.plans.plans.free.item3') +
    `</div>
  `
  );
};
export const landingPlansProFeatures = () => {
  return (
    `
    <div>` +
    i18n.t('home.plans.plans.pro.item1') +
    `</div>
    <div>` +
    i18n.t('home.plans.plans.pro.item2') +
    `</div>
    <div>` +
    i18n.t('home.plans.plans.pro.item3') +
    `</div>
  `
  );
};
export const landingPlansEnterpriseFeatures = () => {
  return (
    `
    <div>` +
    i18n.t('home.plans.plans.enterprise.item1') +
    `</div>
    <div>` +
    i18n.t('home.plans.plans.enterprise.item2') +
    `</div>
    <div>` +
    i18n.t('home.plans.plans.enterprise.item3') +
    `</div>
    <div>` +
    i18n.t('home.plans.plans.enterprise.item4') +
    `</div>
  `
  );
};

export const langOptions = [
  { key: 'en', value: 'en', text: 'EN' },
  { key: 'fr', value: 'fr', text: 'FR' },
  { key: 'de', value: 'de', text: 'DE' },
  { key: 'it', value: 'it', text: 'IT' }
];
