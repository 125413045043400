import React from 'react';
import { createSelector } from 'reselect';

import { isEmpty, getUserFullName } from '../utils';
import { getDateFormatter, getUserLocale } from './locale';
import { getSelectedItems } from './list';

import i18n from '../utils/i18n';
import countries from '../utils/countries';
import { columnNames, REPORT_STATUS } from '../utils/constants';
import { isUndefined } from 'lodash';

export const getSelectedTeamId = state => {
  return state.teamUserInfo && state.teamUserInfo.selectedTeam;
};
export const getHasTeams = state => {
  return state.teamUserInfo && state.teamUserInfo.hasTeams;
};
export const getHasTeamSubscription = state => {
  return state.teamUserInfo && state.teamUserInfo.hasTeamSubscription;
};
export const getRolesInSelectedTeam = state => {
  return state.teamUserInfo && state.teamUserInfo.rolesInSelectedTeam;
};
export const getPermissionsInSelectedTeam = state => {
  return state.teamUserInfo && state.teamUserInfo.permissionsInSelectedTeam;
};
export const getTeamUserInfo = state => {
  return state.teamUserInfo || {};
};
export const getHasFreeCompany = createSelector(
  [getHasTeams, getHasTeamSubscription],
  (hasTeams, hasTeamSubscription) => {
    return hasTeams && !hasTeamSubscription;
  }
);
export const getIsAdmin = state => {
  const roles = getRolesInSelectedTeam(state) || [];
  return roles.includes('admin');
};
export const getIsManager = state => {
  const roles = getRolesInSelectedTeam(state) || [];
  return roles.includes('admin') || roles.includes('manager');
};
export const getIsEmployee = state => {
  const roles = getRolesInSelectedTeam(state) || [];
  return roles.includes('employee');
};
export const getIsEmployeeAndManager = state => {
  const roles = getRolesInSelectedTeam(state) || [];
  return (
    roles.includes('employee') &&
    (roles.includes('admin') || roles.includes('manager'))
  );
};
export const getCompanyId = state => {
  return state.company.id;
};
export const getTeamsList = state => {
  return state.teams.list;
};
export const getTeamMembersList = state => {
  return state.teamMembers.list;
};
export const getTeamMembersModal = state => {
  return state.teamMembers.modal;
};

export const getTeamMembersListForReport = state => {
  if (isUndefined(state.teamMembers) || isEmpty(state.teamMembers.list)) {
    return null;
  }
  return state.teamMembers.list
    .filter(
      el =>
        el.status === 'active' &&
        el.user_roles.map(e => e.role.name).includes('Employee')
    )
    .sort((a, b) => {
      if (a.user.last_name === b.user.last_name) {
        return a.user.first_name > b.user.first_name ? 1 : -1;
      }
      return a.user.last_name > b.user.last_name ? 1 : -1;
    })
    .map(el => {
      return {
        id: el.user.id,
        fullName: getUserFullName(el.user)
      };
    });
};

export const getSelectedTeamListItem = state => {
  const teamsList = getTeamsList(state);
  const selectedItem = teamsList.length && teamsList.find(el => el.isSelected);
  return selectedItem ? selectedItem.team : {};
};

export const getTeamsShortObjects = state => {
  const teamsList = getTeamsList(state);
  if (teamsList.length) {
    return teamsList.map(team => ({
      name: team.name,
      id: team.id,
      stats: team.stats
    }));
  }
  return [];
};

export const getTeamIdFromTeamMembers = state => {
  return state.teamMembers.teamId;
};

export const getSelectedTeamDetails = createSelector(
  [getTeamsList, getTeamIdFromTeamMembers],
  (teamsList, teamId) => teamsList.find(team => team.id === teamId) || {}
);

export const getTeamName = (teams, teamId) => {
  if (isUndefined(teams) || isUndefined(teamId)) {
    return null;
  }
  const team = teams.find(team => team.id === teamId);
  if (team) {
    return team.name;
  } else {
    return '';
  }
};

export const getTeamCurrency = state => {
  const selectedTeamId = getSelectedTeamId(state);
  if (selectedTeamId) {
    const teams = getTeamsList(state);
    const team = teams.find(team => team.id === selectedTeamId);
    if (team) {
      return team.currency;
    }
  }
  return undefined;
};

export const getTeamDateFormat = (teams, teamId) => {
  if (isUndefined(teams) || isUndefined(teamId)) {
    return null;
  }
  const team = teams.find(team => team.id === teamId);
  if (team) {
    return team.dateFormat || team.date_format;
  } else {
    return undefined;
  }
};

export const getSelectedListItems = (state, type) => {
  return state[type].list.reduce((acc, next) => {
    if (next.isSelected) {
      acc.push(next);
    }
    return acc;
  }, []);
};

export const getSelectedItemIndex = (list, itemId) => {
  return list.findIndex(item => item.id === itemId);
};

export const hasManagingPermissionForAtLeastOneTeam = state => {
  return getTeamsList(state).some(team =>
    team.user_roles.some(role => role.manage_roles)
  );
};
export const hasPermissionToManageVehiclesForAtleastOneTeam = state => {
  return getTeamsList(state).some(team =>
    team.user_roles.some(role => role.manage_vehicles)
  );
};

export const canManageMileageRates = state => {
  return getTeamsList(state).some(team =>
    team.user_roles.some(role => role.manage_rates)
  );
};

export const getCanCreateTeam = state => {
  const isAdmin = getIsAdmin(state);
  return isAdmin;
};

export const getCanInviteUsersToTeam = createSelector(
  [getPermissionsInSelectedTeam],
  permissionsInSelectedTeam => permissionsInSelectedTeam.manageRoles
);

export const getCanInviteAdmin = createSelector(
  [getIsAdmin],
  isAdmin => isAdmin
);
export const getCanInviteManager = createSelector(
  [getIsAdmin],
  isAdmin => isAdmin
);
export const getCanInviteEmployee = createSelector(
  [getHasFreeCompany, getCanInviteUsersToTeam],
  (hasFreeCompany, canInviteUsers) => !hasFreeCompany && canInviteUsers
);

export const aggregatedRolesInAllTeams = state => {
  const roles = state.teams.list.reduce((acc, next) => {
    return acc.concat(
      next.user_roles.map(role => role.role.name.toLowerCase())
    );
  }, []);

  return roles.filter((value, index, self) => self.indexOf(value) === index);
};

export const getSelectedMembersInfo = state => {
  const selectedMembersInfo = state.teamMembers.list.reduce((acc, next) => {
    if (next.isSelected) {
      if (!acc.roles) {
        acc.roles = [];
      }
      acc.roles = acc.roles.concat(
        next.user_roles.map(role => role.role.name.toLowerCase())
      );

      if (!acc.names) {
        acc.names = [];
      }
      if (next.user && next.user.first_name) {
        acc.names.push(getUserFullName(next.user));
      } else if (next.invites && next.invites[0]) {
        acc.names.push(getUserFullName(next.invites[0]));
      }

      if (next.user && next.user.email === state.user.details.email) {
        acc.haveSelectedMyself = true;
      }
    }

    return acc;
  }, {});

  if (!isEmpty(selectedMembersInfo)) {
    selectedMembersInfo.roles = selectedMembersInfo.roles.filter(
      (value, index, self) => self.indexOf(value) === index
    );
  }
  return selectedMembersInfo;
};

export const getSelectedTeamReportsInfo = state => {
  const {
    user: { id: loggedInUserId }
  } = state;
  const selectedReports = Object.values(getSelectedItems(state));

  const selectedReportsInfo = {
    haveSelectedOwnItems: false,
    status: {
      new: 0,
      submitted: 0,
      flagged: 0,
      resolved: 0,
      approved: 0
    }
  };
  if (selectedReports.length) {
    selectedReports.reduce((acc, next) => {
      if (next.owner.id === loggedInUserId) {
        acc.haveSelectedOwnItems = true;
      }

      switch (next.approval_status) {
        case REPORT_STATUS.NEW:
          acc.status.new += 1;
          break;

        case REPORT_STATUS.FLAGGED:
          acc.status.flagged += 1;
          break;

        case REPORT_STATUS.SUBMITTED:
          acc.status.submitted += 1;
          break;

        case REPORT_STATUS.RESOLVED:
          acc.status.resolved += 1;
          break;

        case REPORT_STATUS.APPROVED:
          acc.status.approved += 1;
          break;

        default:
          break;
      }

      return acc;
    }, selectedReportsInfo);
  }

  return selectedReportsInfo;
};

export const getSelectedTeamStats = createSelector(
  [getSelectedTeamId, getTeamsList],
  (selectedTeamId, allTeams) => {
    const teamDetails = allTeams.find(item => item.id === selectedTeamId);
    return teamDetails ? teamDetails.stats : {};
  }
);
export const getReportsBadgeCount = state => {
  const stats = getSelectedTeamStats(state);
  return (stats.new_own || 0) + (stats.flagged_own || 0);
};
export const getTeamReportsBadgeCount = state => {
  const stats = getSelectedTeamStats(state);
  const new_own = stats.new_own || 0;
  return stats.new - new_own + stats.submitted + stats.resolved;
};

export const getShowAccountInvoices = state => {
  const hasTeamSubscription = getHasTeamSubscription(state);
  const isManager = getIsManager(state);
  return !hasTeamSubscription || isManager;
};

const hasEmployeeRoleInCompany = state => {
  return getTeamsList(state).some(team =>
    team.user_roles.some(role => role.role.id === 2)
  );
};

export const getCanDeleteTeamAccount = state => {
  return (
    (state.user.details && state.user.details.is_company_owner) ||
    !hasEmployeeRoleInCompany(state)
  );
};

export const getTeamsListColumns = createSelector(
  [getUserLocale],
  userLocale => {
    return {
      [columnNames.ID]: {
        label: 'list.id',
        conversion: item => item.id,
        expandable: false,
        width: 120,
        classes: 'team-id-column',
        sortKey: 'id',
        sortable: false,
        actualKeys: ['id']
      },
      [columnNames.TEAM_NAME]: {
        label: 'team.name',
        conversion: team => <span className="span">{team.name}</span>,
        expandable: true,
        width: 160,
        classes: 'left team-name-column'
      },
      [columnNames.TEAM_MEMBERS]: {
        label: 'team.members',
        conversion: team => <span className="span">{team.member_count}</span>,
        expandable: true,
        width: 60,
        classes: 'left team-members-column'
      },
      [columnNames.TEAM_COUNTRY]: {
        label: 'team.country',
        conversion: team => {
          const countryObj = countries.find(
            el => el.cca2 === team.country.toUpperCase()
          );
          const userLanguage = userLocale.split('-')[0];
          const country =
            countryObj.translations[userLanguage.toLowerCase()] ||
            countryObj.translations.en;
          return <span className="span">{country.common}</span>;
        },
        expandable: true,
        width: 140,
        classes: 'left team-country-column'
      },
      [columnNames.TEAM_DATE_FORMAT]: {
        label: 'team.dateFormat',
        conversion: team => {
          const dateFormat = team.date_format
            .replace('DD', i18n.t(`settings.timeFormat.DD`))
            .replace('MM', i18n.t(`settings.timeFormat.MM`))
            .replace('YYYY', i18n.t(`settings.timeFormat.YYYY`));
          return <span className="span">{dateFormat}</span>;
        },
        expandable: true,
        width: 140,
        classes: 'left team-date-format-column'
      },
      [columnNames.TEAM_CURRENCY]: {
        label: 'team.currency',
        conversion: team => <span className="span">{team.currency}</span>,
        expandable: true,
        width: 70,
        classes: 'left team-currency-column'
      }
    };
  }
);

export const getTeamMembersListColumns = createSelector(
  [getDateFormatter],
  dateFormatter => {
    return {
      [columnNames.ID]: {
        label: 'list.id',
        conversion: item => item.id,
        expandable: false,
        width: 60,
        classes: 'id-column',
        sortKey: 'id',
        sortable: false,
        actualKeys: ['id']
      },
      [columnNames.MEMBER_NAME]: {
        label: 'member.name',
        conversion: member => {
          let name = '';
          if (member.user) {
            name = getUserFullName(member.user);
          }
          if (!name && member.invites && member.invites[0]) {
            name = getUserFullName(member.invites[0]);
          }

          return <span className="span">{name}</span>;
        },
        expandable: true,
        width: 170,
        classes: 'left member-name-column'
      },
      [columnNames.MEMBER_EMAIL]: {
        label: 'member.email',
        conversion: member => {
          const classList = ['span', 'email'];
          const lastInvite = member.invites[0];
          if (lastInvite && lastInvite.send_status !== 'accepted') {
            classList.push('dimmed');
          } else {
            classList.push('highlighted');
          }
          let userEmail;
          if (member.user && member.user.email) {
            userEmail = member.user.email;
          } else if (lastInvite) {
            userEmail = lastInvite.email;
          }
          return <span className={classList.join(' ')}>{userEmail}</span>;
        },
        expandable: true,
        width: 240,
        classes: 'left member-email-column'
      },
      [columnNames.MEMBER_ROLES]: {
        label: 'member.roles',
        conversion: member => {
          return (
            member.user_roles && (
              <div className="tileContainer">
                {member.user_roles.map(role => (
                  <div
                    className={`roleTile ${role.role.name.toLowerCase()}`}
                    key={role.role.name}
                  >
                    <span>
                      {i18n.t(`member.${role.role.name.toLowerCase()}`)}
                    </span>
                  </div>
                ))}
              </div>
            )
          );
        },
        expandable: true,
        width: 180,
        classes: 'left member-roles-column'
      },
      [columnNames.MEMBER_INVITE_STATUS]: {
        label: 'member.inviteStatus',
        conversion: member => member,
        expandable: true,
        width: 255,
        classes: 'left member-invite-status-column'
      },
      [columnNames.MEMBER_SINCE]: {
        label: 'member.since',
        conversion: member => {
          const lastInvite = member.invites[0];
          if (lastInvite && lastInvite.send_status !== 'accepted') {
            return null;
          }
          return (
            <span className="span">
              {dateFormatter(member.date_joined * 1000)}
            </span>
          );
        },
        expandable: true,
        width: 100,
        classes: 'left member-since-column'
      }
    };
  }
);
