import React from 'react';
import { createSelector } from 'reselect';

import FilterText from '../../components/FilterText';
import { Distance, Currency } from '../../components/Units';

import { getKeyByValue, isEmpty, getUserFullName } from '../../utils';
import { columnNames, REPORT_STATUS } from '../../utils/constants';
import i18n from '../../utils/i18n';

import { getDateRangeFormatter, getCountry } from '../locale';
import { getUserId } from '../user';
import {
  getTeamUserInfo,
  getIsEmployeeAndManager,
  getRolesInSelectedTeam,
  getHasTeams
} from '../teams';

const columnsForManager = [
  columnNames.CHECK,
  columnNames.ID,
  columnNames.TEAM_REPORT_REPORTED_BY,
  columnNames.TITLE,
  columnNames.REPORT_DATE_RANGE,
  columnNames.FILTERS,
  columnNames.TEAM_REPORT_STATUS,
  columnNames.TRIPS,
  columnNames.REPORT_DISTANCE,
  columnNames.REPORT_EXPENSES
];
const columnsForEmployee = [
  columnNames.CHECK,
  columnNames.ID,
  columnNames.TITLE,
  columnNames.REPORT_DATE_RANGE,
  columnNames.FILTERS,
  columnNames.TEAM_REPORT_STATUS,
  columnNames.TRIPS,
  columnNames.REPORT_DISTANCE,
  columnNames.REPORT_EXPENSES
];
const columnsForIndividualUser = [
  columnNames.CHECK,
  columnNames.ID,
  columnNames.TITLE,
  columnNames.REPORT_DATE_RANGE,
  columnNames.FILTERS,
  columnNames.TRIPS,
  columnNames.REPORT_DISTANCE,
  columnNames.REPORT_EXPENSES
];

const getAllColumns = createSelector(
  [getDateRangeFormatter, getCountry, getUserId],
  (dateRangeFormatter, userCountry, loggedInUserId) => {
    return {
      [columnNames.CHECK]: {
        label: 'checkbox',
        conversion: check => check,
        expandable: false,
        width: 78,
        classes: 'checkItem checkboxColumn',
        sortKey: '',
        sortable: false,
        actualKeys: ['']
      },
      [columnNames.ID]: {
        label: 'list.id',
        conversion: item => item.id,
        expandable: false,
        width: 60,
        classes: 'id-column',
        sortKey: 'id',
        sortable: false,
        actualKeys: ['id']
      },
      [columnNames.TITLE]: {
        label: 'common.title',
        conversion: report => {
          return report.name;
        },
        expandable: true,
        width: 200,
        classes: 'left title-column'
      },
      [columnNames.REPORT_DATE_RANGE]: {
        label: 'list.dateRange',
        conversion: report => {
          if (!isEmpty(report.criteria) && report.criteria.dates) {
            const dateRange = report.criteria.dates;
            if (dateRange.start && dateRange.end) {
              const dateRangeToDisplay = dateRangeFormatter(
                dateRange.start * 1000,
                dateRange.end * 1000
              );
              return (
                <div className="dateRangeContainer">
                  <div>{dateRangeToDisplay.start}</div>
                  <div>{dateRangeToDisplay.end}</div>
                </div>
              );
            }
          }
          return '—';
        },
        expandable: false,
        width: 120,
        classes: 'left date-column'
      },
      [columnNames.FILTERS]: {
        label: 'common.filters',
        conversion: report => {
          return (
            report.criteria && (
              <FilterText criteria={report.criteria} expanded />
            )
          );
        },
        expandable: true,
        width: 200,
        classes: 'left filters-column'
      },
      [columnNames.TRIPS]: {
        label: 'dashboard.trips',
        conversion: report => {
          return report.trips;
        },
        expandable: false,
        width: 80,
        classes: 'right trips-column'
      },
      [columnNames.REPORT_DISTANCE]: {
        label:
          ['us', 'gb', 'lr', 'mm'].indexOf(userCountry.toLowerCase()) > -1
            ? 'list.distanceMi'
            : 'list.distanceKm',
        conversion: report => {
          return report.distance ? (
            <Distance value={report.distance} showUnit={false} />
          ) : (
            '—'
          );
        },
        expandable: false,
        width: 100,
        classes: 'right distance-column'
      },
      [columnNames.REPORT_EXPENSES]: {
        label: 'common.expenses',
        conversion: report => {
          return report.expenses && report.expenses.amount !== undefined ? (
            <Currency
              value={report.expenses.amount}
              iso={report.expenses.currency}
            />
          ) : (
            '—'
          );
        },
        expandable: false,
        width: 140,
        classes: 'right expenses-column'
      },
      [columnNames.TEAM_REPORT_REPORTED_BY]: {
        label: 'list.reportedBy',
        conversion: report => {
          const { id } = report.owner;
          const isYou = id === loggedInUserId;
          const fullName = getUserFullName(report.owner);
          return (
            <span className={isYou ? 'you' : ''}>
              {`${fullName} ${isYou ? `(${i18n.t('common.you')})` : ``}`}
            </span>
          );
        },
        expandable: true,
        width: 160,
        classes: 'left team-reported-by-column'
      },
      [columnNames.TEAM_REPORT_STATUS]: {
        label: 'common.status',
        conversion: report => {
          const status = getKeyByValue(REPORT_STATUS, report.approval_status);
          return (
            <span className={`${status.toLowerCase()}`}>
              {i18n.t(`team.approvals.${status.toLowerCase()}`)}
            </span>
          );
        },
        expandable: true,
        width: 100,
        classes: 'left team-report-status-column',
        sortable: true,
        sortKey: 'approval_status'
      }
    };
  }
);

export const getColumns = createSelector(
  [getAllColumns, getHasTeams, getRolesInSelectedTeam],
  (allColumns, hasTeams, rolesInSelectedTeam) => {
    let columns = columnsForIndividualUser;
    if (hasTeams) {
      columns = columnsForEmployee;
      if (
        rolesInSelectedTeam.includes('admin') ||
        rolesInSelectedTeam.includes('manager')
      ) {
        columns = columnsForManager;
      }
    }

    return columns.reduce((acc, column) => {
      acc[column] = allColumns[column];
      return acc;
    }, {});
  }
);

export const getReportsBadgeText = state => {
  const { rolesInSelectedTeam } = getTeamUserInfo(state);
  const {
    reports: { stats = {} } = {},
    teamReports: { stats: teamStats = {} } = {}
  } = state.listNew;
  const { new: newR, flagged } = stats;
  const { submitted, resolved } = teamStats;
  const text = [];
  const countText = [];
  const teamText = [];
  const teamCountText = [];
  if (
    rolesInSelectedTeam.includes('admin') ||
    rolesInSelectedTeam.includes('manager')
  ) {
    if (submitted) {
      teamText.push(`${i18n.t(`team.approvals.submitted`)}`);
      teamCountText.push(`${submitted} ${i18n.t(`team.approvals.submitted`)}`);
    }
    if (resolved) {
      teamText.push(`${i18n.t(`team.approvals.resolved`)}`);
      teamCountText.push(`${resolved} ${i18n.t(`team.approvals.resolved`)}`);
    }
  }
  if (rolesInSelectedTeam.includes('employee')) {
    if (newR) {
      text.push(`${i18n.t(`team.approvals.new`)}`);
      countText.push(`${newR} ${i18n.t(`team.approvals.new`)}`);
    }
    if (flagged) {
      text.push(`${i18n.t(`team.approvals.flagged`)}`);
      countText.push(`${flagged} ${i18n.t(`team.approvals.flagged`)}`);
    }
  }

  const totalText = text.concat(teamText);
  const totalCount = countText.concat(teamCountText);

  return {
    approvalsText: text.join(`, `),
    approvalsCount: countText.join(' + '),
    teamApprovalsText: teamText.join(`, `),
    teamApprovalsCount: teamCountText.join(' + '),
    totalApprovalsText: totalText.join(`, `),
    totalApprovalsCount: totalCount.join(' + ')
  };
};

export const getReportsHeaderTitle = state => {
  const managerAndEmployee = getIsEmployeeAndManager(state);
  const hasTeams = getHasTeams(state);
  const rolesInSelectedTeam = getRolesInSelectedTeam(state);
  if (hasTeams) {
    if (managerAndEmployee) {
      return listType =>
        listType === 'teamReports'
          ? i18n.t(`dashboard.teamReports`)
          : i18n.t(`dashboard.myReports`);
    } else if (rolesInSelectedTeam.includes('employee')) {
      return () => i18n.t(`dashboard.myReports`);
    }
    return () => i18n.t(`dashboard.teamReports`);
  }
  return () => i18n.t(`dashboard.reports`);
};

export const getReportsListGroupByReportId = state => {
  const {
    reportTrips: { openedReportId } = {},
    reports: { openedItem: openedItemInReportsList } = {},
    teamReports: { openedItem: openedItemInTeamReportsList } = {}
  } = state.listNew;
  if (openedReportId === openedItemInReportsList) {
    return state.listNew.reports;
  } else if (openedReportId === openedItemInTeamReportsList) {
    return state.listNew.teamReports;
  }
  return {};
};
export const getReportsListTypeByReportId = state => {
  const {
    reportTrips: { openedReportId } = {},
    reports: { openedItem: openedItemInReportsList } = {},
    teamReports: { openedItem: openedItemInTeamReportsList } = {}
  } = state.listNew;
  if (openedReportId === openedItemInReportsList) {
    return `reports`;
  } else if (openedReportId === openedItemInTeamReportsList) {
    return `teamReports`;
  }
  return '';
};

export const getReportsListFilterText = (listType, teamStats) => {
  const approvals = [];
  if (listType === 'reports') {
    teamStats.new && approvals.push(i18n.t(`team.approvals.new`));
    teamStats.flagged && approvals.push(i18n.t(`team.approvals.flagged`));
  }
  if (listType === 'teamReports') {
    teamStats.resolved && approvals.push(i18n.t(`team.approvals.resolved`));
    teamStats.submitted && approvals.push(i18n.t(`team.approvals.submitted`));
    teamStats.new && approvals.push(i18n.t(`team.approvals.new`));
  }
  return approvals.join(', ');
};
