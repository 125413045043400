import { SET_ERROR, DELETE_ERROR } from '../actions/errors';

export const errors = (state = [], action) => {
  const currentErrors = [...state];
  switch (action.type) {
    case SET_ERROR:
      return [...currentErrors, action.error];

    case DELETE_ERROR:
      return currentErrors.filter(error => error.id !== action.errorId);

    default:
      return state;
  }
};
