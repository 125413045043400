const recentPlacesResponseTransformer = places => {
  if (!places || !places.length) {
    return [];
  }

  return places.map(({ id, address, place_id, location, name }) => ({
    id,
    name,
    address,
    place_id,
    location
  }));
};

export { recentPlacesResponseTransformer };
