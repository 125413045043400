import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Image, Modal } from 'semantic-ui-react';
import ReactPlayer from 'react-player/lazy';

import Header from '../containers/PublicRouteHeader';
import Footer from '../containers/PublicRouteFooter';
import LandingIntro from '../components/Landing/LandingIntroSection';
import HowItWorksSection from '../components/Landing/Enterprise/HowItWorksSection';
import FeaturesSection from '../components/Landing/Pro/FeaturesSection';
import CustomersSection from '../components/Landing/Enterprise/CustomersSection';
import PlansSection from '../components/Landing/Enterprise/PlansSection';
import PlansSectionMobile from '../components/Landing/Enterprise/PlansSectionMobile';

import { retreivePlans } from '../actions/billing';

import {
  isUserLoggedIn,
  getActiveUserSubscription,
  getIsMobile,
  getIsTablet
} from '../selectors/user';
import { getAllPlans } from '../selectors/billing';
import { getBillingCountry } from '../selectors/managePlan';
import { getHasTeamSubscription } from '../selectors/teams';

import { getDashboardLinkFor } from '../utils';
import { getEnterprisePlansForCountry } from '../utils/plans';
import { enterpriseFeatures } from '../utils/landingPro';
import {
  trackLoginFromGetStarted,
  trackMoreInfo,
  trackSeeItInActionVideo
} from '../utils/analytics';

import '../css/landing.css';
import '../css/landing_intro.css';
import '../css/landing_howItWorks.css';
import '../css/landing_features.css';
import '../css/landing_plans.css';
import '../css/landingPro.css';

class LandingEnterprisePage extends Component {
  state = {
    showVideo: false
  };

  componentWillMount() {
    this.props.dispatch(retreivePlans());
  }
  componentDidMount() {
    const page = document.getElementById('landingPage');
    if (page && !window.location.href.includes('#')) {
      page.scrollTop = 0;
    }
  }

  getIntroActions = () => {
    const { t } = this.props;
    const actions = [];
    actions.push(
      <div key="first">
        <a
          href={getDashboardLinkFor('managePlan')}
          className="action upgradeBtn"
          onClick={this.trackLogin}
        >
          {t(`home.header.goToDashboard`)}
        </a>
      </div>
    );
    actions.push(
      <div key="second" className="showVideoAction" onClick={this.showVideo}>
        <Image src="/images/icoPlay.png" />
        <span>{t('home.enterprise.howItWorks.seeItInAction')}</span>
      </div>
    );

    return actions;
  };

  showVideo = () => {
    this.setState({ showVideo: true });
    trackSeeItInActionVideo();
  };
  stopVideo = () => {
    this.setState({ showVideo: false });
  };

  trackLogin = () => {
    if (!this.props.userLoggedIn) {
      trackLoginFromGetStarted('enterprise');
    }
  };
  trackEventForCustomPlan = () => {
    trackMoreInfo('enterprise', 'customPlan');
  };

  render() {
    const { t, isMobile, isTablet } = this.props;
    const { showVideo } = this.state;
    return (
      <div id="landingPage" className="enterprise">
        <Header />

        <LandingIntro
          appName="Psngr Enterprise"
          title={t(`home.enterprise.intro.title`)}
          subTitle={t(`home.enterprise.intro.subtitle`)}
          actions={this.getIntroActions()}
        />
        <HowItWorksSection isMobile={isMobile} isTablet={isTablet} />
        <FeaturesSection featuresList={enterpriseFeatures()} />
        <CustomersSection />

        {isMobile ? (
          <PlansSectionMobile
            plans={this.props.enterprisePlans}
            t={t}
            trackEvent={this.trackLogin}
            trackEventForCustomPlan={this.trackEventForCustomPlan}
          />
        ) : (
          <PlansSection
            {...this.props}
            trackEvent={this.trackLogin}
            trackEventForCustomPlan={this.trackEventForCustomPlan}
          />
        )}

        <Footer />

        <Modal
          id="seeInActionModal"
          dimmer="blurring"
          centered
          open={showVideo}
          onClose={this.stopVideo}
        >
          <Modal.Content>
            <ReactPlayer
              className="videoPlayer"
              url="https://s3.eu-west-3.amazonaws.com/co.psngr.videos/enterprise/web-psngr-enterprise.mp4"
              playing={showVideo}
              // controls
              width="100%"
              height="100%"
              onEnded={this.stopVideo}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

LandingEnterprisePage.propTypes = {
  userLoggedIn: PropTypes.bool,
  currentSubscription: PropTypes.object,
  enterprisePlans: PropTypes.array,
  t: PropTypes.func,
  dispatch: PropTypes.func,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    userLoggedIn: isUserLoggedIn(state),
    currentSubscription: getActiveUserSubscription(state),
    enterprisePlans: getEnterprisePlansForCountry(
      getAllPlans(state),
      getBillingCountry(state)
    ),
    isMobile: getIsMobile(state),
    isTablet: getIsTablet(state),
    hasTeamSubscription: getHasTeamSubscription(state)
  };
};
const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default translate()(
  connect(mapStateToProps, mapDispatchToProps)(LandingEnterprisePage)
);
