import React from 'react';
import { createSelector } from 'reselect';
import { Icon, Popup } from 'semantic-ui-react';

import { isEmpty } from '../utils';
import i18n from '../utils/i18n';
import { columnNames } from '../utils/constants';
import {
  getAllowedOwnershipTypes,
  getVehicleOwnershipType,
  vehicleFullName
} from '../utils/vehicles';
import { Distance } from '../components/Units';
import { getUserId } from './user';
import {
  getIsAdmin,
  getIsManager,
  getPermissionsInSelectedTeam
} from './teams';

// vehicles START

export const getVehicleClasses = state => {
  return state.vehicles.classes || [];
};
export const getVehicleClassOptions = createSelector(
  [getVehicleClasses],
  vehicleClasses => {
    const classesOptions = vehicleClasses.map(el => ({
      key: el.id,
      text: el.name,
      value: el.name
    }));
    return classesOptions;
  }
);

export const getVehicleYears = state => {
  return state.vehicles.yearOptions || [];
};
export const getVehicleYearsOptions = createSelector(
  [getVehicleYears],
  vehicleYears => {
    const yearsOptions = vehicleYears.map(el => ({
      key: el.toString(),
      text: el.toString(),
      value: el.toString()
    }));
    return yearsOptions;
  }
);

export const getVehicleMakes = state => {
  return state.vehicles.makeOptions || [];
};
export const getVehicleMakesOptions = createSelector(
  [getVehicleMakes],
  vehicleMakes => {
    const makesOptions = vehicleMakes.map(el => ({
      key: el,
      text: el,
      value: el
    }));
    return makesOptions;
  }
);

export const getVehicleModels = state => {
  return state.vehicles.modelOptions || [];
};
export const getVehicleModelsOptions = createSelector(
  [getVehicleModels],
  vehicleModels => {
    const modelsOptions = vehicleModels.map(el => ({
      key: el,
      text: el,
      value: el
    }));
    return modelsOptions;
  }
);

export const getVehicleOwnershipOptions = createSelector(
  [getAllowedOwnershipTypes],
  ownershipTypes => {
    const types = ownershipTypes.map(el => ({
      key: el,
      text: el,
      value: el
    }));
    return types;
  }
);

export const getGenericVehicleOptions = state => {
  return state.vehicles.vehicleOptions || [];
};

export const getVehicle = state => {
  return state.vehicles.vehicle || {};
};

// vehicles END
// user vehicles START

export const getUserVehicles = state => state.vehicles.userVehicles;

export const getVehiclesMap = state => state.vehicles.vehiclesMap || {};

export const getVehicleOptions = createSelector([getUserVehicles], vehicles => {
  return (
    vehicles &&
    vehicles.map(vehicle => ({
      text: vehicleFullName(vehicle),
      value: vehicle.id,
      key: vehicle.id
    }))
  );
});

export const getDrivingVehicleOptions = createSelector(
  [getUserVehicles],
  vehicles => {
    const options = [];
    for (const i in vehicles) {
      const vehicle = vehicles[i];
      if (!vehicle.hidden && vehicle.travel_mode === 'DRIVING') {
        options.push({
          text: vehicleFullName(vehicle),
          value: vehicle.id,
          key: vehicle.id
        });
      }
    }
    return options;
  }
);

/**
 *
 * @param {*} vclass
 * @returns vclass name to be used instead of travel_mode for vehicle icon
 */
export const getVehicleClassIcon = vclass => {
  if (vclass.id == 4) {
    return 'scooter';
  }
  return null;
};

export const genGetVehicleName = createSelector([getUserVehicles], vehicles => {
  return vehicleId => {
    return vehicleFullName(
      vehicles &&
        vehicles.find(vehicle => {
          return vehicle.id === vehicleId;
        })
    );
  };
});

export const getMileageRateMatcher = createSelector(
  [getUserVehicles],
  vehicles => {
    return (vehicleId, tags) => {
      const vehicle = vehicles && vehicles.find(v => v.id === vehicleId);
      if (!vehicle) return null;
      let mileageRate = null;
      for (let i = 0; i < tags.length; i++) {
        mileageRate = vehicle.mileage_rates.find(mr => mr.tag === tags[i]);
        if (mileageRate) return mileageRate;
      }
    };
  }
);

export const getOpenedVehicleDetails = (vehicleId, state) => {
  return getUserVehicles(state).find(vehicle => vehicle.id === vehicleId) || {};
};

export const canEditUserVehicle = (vehicleId, state) => {
  const ownerId = getOpenedVehicleDetails(vehicleId, state).owner.id;
  const userId = getUserId(state);
  if (userId === ownerId || getIsAdmin(state)) {
    return true;
  } else if (
    getIsManager(state) &&
    getPermissionsInSelectedTeam(state).manageVehicles
  ) {
    return true;
  }
  return false;
};

export const getVehicleDrivers = (vehicleId, state) => {
  return getOpenedVehicleDetails(vehicleId, state).drivers || [];
};

export const getColumnsForVehiclesList = createSelector([], () => {
  return {
    [columnNames.ID]: {
      label: 'list.id',
      conversion: item => item.id,
      expandable: false,
      width: 60,
      classes: 'id-column'
    },
    [columnNames.LICENSE]: {
      label: 'vehicles.list.license',
      conversion: item => {
        return vehicleFullName(item);
      },
      expandable: true,
      width: 30,
      classes: 'license-column'
    },
    [columnNames.MAKE]: {
      label: 'vehicles.list.makemodel',
      conversion: item =>
        !isEmpty(item.vehicle)
          ? item.vehicle.make +
            ' ' +
            item.vehicle.model +
            ' ' +
            item.vehicle.year
          : '-',
      expandable: true,
      width: 60,
      classes: 'make-column'
    },
    [columnNames.OWNERSHIP]: {
      label: 'vehicles.list.ownership',
      conversion: item => getVehicleOwnershipType(item),
      expandable: true,
      width: 60,
      classes: 'model-column'
    },
    // [columnNames.YEAR]: {
    //   label: 'vehicles.list.year',
    //   conversion: item => (!isEmpty(item.vehicle) ? item.vehicle.year : '-'),
    //   expandable: true,
    //   width: 60,
    //   classes: 'year-column'
    // },
    [columnNames.BEACON]: {
      label: 'vehicles.list.beacon',
      conversion: item => {
        return (
          <div className={`beaconStatus ${item.beacon ? 'connected' : ''}`}>
            <Icon name="bluetooth alternative" />
            <span className="text">
              {item.beacon ? (
                i18n.t('vehicles.status.connected')
              ) : (
                <Popup
                  trigger={<span>{i18n.t('vehicles.status.connect')}</span>}
                  content={i18n.t('vehicles.status.connectingBeacon')}
                  position="bottom center"
                />
              )}
            </span>
          </div>
        );
      },
      expandable: true,
      width: 60,
      classes: 'beacon-column'
    },
    [columnNames.CURRENTODOMETER]: {
      label: 'vehicles.list.current-odometer',
      conversion: item => {
        return <Distance value={item.odometer.current} showUnit />;
      },
      expandable: true,
      width: 60,
      classes: 'right odometer-column'
    }
  };
});

// user vehicles END
