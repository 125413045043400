import serverRequest from '../utils/Api';
import { URLs } from '../utils/urls';
import { getBeaconOrders } from '../selectors/beacons';
import { setError } from './errors';
import { createErrorObject } from '../utils/dataStore';

export const RECEIVE_USER_BEACONS = 'RECEIVE_USER_BEACONS';
export const RECEIVE_BEACON_ORDER_LIST = 'RECEIVE_BEACON_ORDER_LIST';
export const RECEIVE_BEACON_ORDER_DETAILS = 'RECEIVE_BEACON_ORDER_DETAILS';
export const UPDATE_BEACON_ORDER_LIST = 'UPDATE_BEACON_ORDER_LIST';
export const UPDATE_BEACON_ORDER = 'UPDATE_BEACON_ORDER';
export const SET_FETCHING_LISTS = 'SET_FETCHING_LISTS';
export const SET_FETCHING_ORDER_BEACON_LIST = 'SET_FETCHING_ORDER_BEACON_LIST';

const receiveUserBeacons = info => ({
  type: RECEIVE_USER_BEACONS,
  info
});
const receiveBeaconOrderList = info => ({
  type: RECEIVE_BEACON_ORDER_LIST,
  info
});
export const receiveBeaconOrderDetails = details => ({
  type: RECEIVE_BEACON_ORDER_DETAILS,
  details
});
const updateBeaconOrderList = info => ({
  type: UPDATE_BEACON_ORDER_LIST,
  info
});
const updateBeaconOrder = (list, count) => ({
  type: UPDATE_BEACON_ORDER,
  list,
  count
});
const setFetchingLists = (isFetching = true) => ({
  type: SET_FETCHING_LISTS,
  isFetching
});
const setFetchingOrderBeaconList = (isFetching = true) => ({
  type: SET_FETCHING_ORDER_BEACON_LIST,
  isFetching
});

export const fetchUserBeaconDetails = uuid => (dispatch, getState) => {
  const { url, method } = URLs.beacon.getBeaconDetails(uuid);
  serverRequest(method, url, getState()).then(json => {
    console.log('BEACON DETAILS', json);
  });
};

const fetchBeaconOrders = state => {
  return new Promise(resolve => {
    const { url, method } = URLs.beacon.getBeaconOrderList();
    serverRequest(method, url, state).then(json => {
      const { results, ...resp } = json;
      resp.list = results;
      resolve(resp);
    });
  });
};
const fetchBeacons = state => {
  return new Promise(resolve => {
    const { url, method } = URLs.beacon.getBeaconsList();
    serverRequest(method, url, state).then(json => {
      const { results, ...resp } = json;
      resp.list = results;
      resolve(resp);
    });
  });
};
export const getBeaconLists = () => (dispatch, getState) => {
  dispatch(setFetchingLists());
  const state = getState();

  Promise.all([fetchBeaconOrders(state), fetchBeacons(state)]).then(
    response => {
      dispatch(receiveBeaconOrderList(response[0]));
      dispatch(receiveUserBeacons(response[1]));
      dispatch(setFetchingLists(false));
    }
  );
};

export const fetchBeaconOrderDetails = beaconOrderId => (
  dispatch,
  getState
) => {
  const { url, method } = URLs.beacon.getBeaconOrderDetails(beaconOrderId);
  serverRequest(method, url, getState())
    .then(json => {
      const { list = [], count = 0 } = getBeaconOrders(getState());
      const index = list.findIndex(el => el.id === json.id);
      let newList = [...list];
      let newCount = count;
      if (index > -1) {
        newList.splice(index, 1, json);
      } else {
        newList = [json].concat(list);
        newCount = count + 1;
        list.length &&
          list.length % 10 === 0 &&
          list.length !== count &&
          newList.pop();
      }
      dispatch(updateBeaconOrder(newList, newCount));
    })
    .catch(() => {
      dispatch(setError(createErrorObject('', 'order_details_fetch_failed')));
    });
};

export const fetchNextPageBeaconOrder = next => (dispatch, getState) => {
  dispatch(setFetchingOrderBeaconList());
  let url = next;
  if (url.indexOf(process.env.REACT_APP_api_url) === 0) {
    url = url.split(process.env.REACT_APP_api_url)[1];
  }
  serverRequest('get', url, getState()).then(json => {
    dispatch(updateBeaconOrderList(json));
    dispatch(setFetchingOrderBeaconList(false));
  });
};
