import React, { Fragment } from 'react';
import { createSelector } from 'reselect';
import { Popup } from 'semantic-ui-react';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _intersection from 'lodash/intersection';
import moment from 'moment';

import i18n from '../utils/i18n';

import { Distance, Currency, Weight } from '../components/Units';
import Time from '../components/Time';
import FilterText from '../components/FilterText';

import { getUserId } from './user';
import {
  getDateFormatter,
  getDateRangeFormatter,
  getWeightCalculator,
  getCountry,
  getLocal
} from './locale';
import { getMileageRateMatcher } from './vehicles';
import {
  getTeamUserInfo,
  getIsEmployeeAndManager,
  getIsManager,
  getHasTeams,
  getIsEmployee
} from './teams';
import {
  getTripRevisionTitle,
  getKeyByValue,
  getUserFullName,
  isEmpty
} from '../utils';
import {
  getInitialColumns,
  getDefaultReportRulesColumns,
  getDefaultReportsColumns,
  getDefaultTripColumns,
  getDefaultTripColumnsForManager,
  getDefaultVehiclesColumns,
  getRate,
  getModeVehicleConversion,
  getDefaultReportsColumnsManager,
  getDefaultReportsColumnsEmployee,
  getDefaultTripColumnsForEmployee
} from '../utils/listUtils';
import { columnNames, REPORT_STATUS } from '../utils/constants';
import { getFrequencyText } from '../utils/frequency';

import { getReportsListGroupByReportId } from './list/reports';

export const getActiveList = state => {
  return state.listNew && state.listNew.activeList;
};
export const getActiveListGroup = state => {
  return (state && state.listNew[state.listNew.activeList]) || {};
};
export const getItemIndex = (id, list) => {
  return list.findIndex(e => id === e.id);
};
export const getOpenedItemIndex = state => {
  const listGroup = getActiveListGroup(state);
  return _findIndex(
    listGroup.list,
    item => item.id === parseInt(listGroup.openedItem, 10)
  );
};
export const getOpenedItemId = state => {
  const listGroup = getActiveListGroup(state);
  return listGroup.openedItem;
};
export const getOpenedItem = state => {
  const listGroup = getActiveListGroup(state);
  return listGroup.openedItems
    ? listGroup.openedItems[listGroup.openedItem]
    : {};
};
export const getOpenedReport = (state, reportsListType) => {
  const listGroup = state.listNew[reportsListType];
  if (!listGroup) return {};
  return listGroup.openedItem && listGroup.openedItems
    ? listGroup.openedItems[listGroup.openedItem]
    : {};
};
export const getListFromReportId = (state, reportId) => {
  const { teamReports = {} } = state.listNew;
  if (teamReports.openedItem === reportId) {
    return 'teamReports';
  }
  return 'reports';
};
export const getSelectedItemsIds = state => {
  const listGroup = getActiveListGroup(state);
  return Object.values(listGroup.selectedItems);
};
export const getSelectedItems = state => {
  const listGroup = getActiveListGroup(state);
  const selectedItems = listGroup.selectedItems || {};
  return Object.keys(selectedItems).reduce((acc, key) => {
    acc[key] = listGroup.list[key];
    return acc;
  }, {});
};
const getListType = state => {
  return state.listNew.activeList;
};
export const getUnclassifiedTripsCount = state => {
  const { trips: { unclassifiedTripsCount = 0 } = {} } = state.listNew;
  return unclassifiedTripsCount;
};
const getReportTripsDisplayFields = state => {
  if (state.listNew.activeList === 'reportTrips') {
    const listGroup = getReportsListGroupByReportId(state);
    const { criteria } = listGroup.openedItems[listGroup.openedItem];
    const defaultTripColumns = getDefaultTripColumnsForEmployee();
    if (criteria) {
      const allHeaders = getHeaders(state);
      const displayFieldsToShow = defaultTripColumns.reduce((acc, column) => {
        if (
          !allHeaders[column].actualKeys ||
          !allHeaders[column].actualKeys.length
        ) {
          // e.g. "check" and "more" columns, which are not regular "displayFields"
          acc.push(column);
        } else if (
          _intersection(allHeaders[column].actualKeys, criteria.display_fields)
            .length
        ) {
          acc.push(column);
        }
        return acc;
      }, []);
      return displayFieldsToShow;
    }

    return defaultTripColumns;
  }
  return [];
};
const getSelectedAllItems = state => {
  const listGroup = getActiveListGroup(state);
  return listGroup.selectedAllItems;
};

export const getSelectionStats = createSelector(
  [getListType, getActiveListGroup, getSelectedAllItems],
  (listType, listGroup, selectedAllItems) => {
    const { selectedItems = {} } = listGroup;
    const selected = Object.keys(selectedItems).length;
    const total = listGroup.stats
      ? listGroup.stats.trips_count
      : listGroup.list && listGroup.list.length;
    const allSelected = selectedAllItems || selected === total;

    let distance = null;
    let expenses = null;
    let currencyIso = null;
    let duration = 0;
    let item = null;

    Object.entries(selectedItems).forEach(([key]) => {
      item = listGroup.list[key];
      if (listType === 'reports') {
        distance += item.distance;
        expenses += item.expenses.amount;
        currencyIso = item.expenses.currency;
      } else if (item.stats) {
        distance += item.stats.distance;
        if (listType === 'vehicles') {
          expenses += item.stats.mileage_expenses.value;
          currencyIso = item.stats.mileage_expenses.currency;
        } else {
          expenses += item.mileage_expenses.value;
          currencyIso = item.mileage_expenses.currency;
          duration += item.stats.elapsed;
        }
      }
    });

    return {
      allSelected,
      currencyIso,
      distance,
      expenses,
      selected,
      duration,
      total
    };
  }
);

export const getAvailableColumns = createSelector(
  [
    getListType,
    getReportTripsDisplayFields,
    getInitialColumns,
    getTeamUserInfo,
    getIsEmployee,
    getIsEmployeeAndManager,
    getIsManager
  ],
  (
    activeList,
    reportTripsFields,
    initialColumns,
    teamUserInfo,
    isEmployee,
    isEmployeeAndManager,
    isManager
  ) => {
    let displayFields = [];
    let fields = [];

    const { hasTeams } = teamUserInfo;

    switch (activeList) {
      case 'trips':
        fields = getLocal('visibleColumns');
        if (hasTeams) {
          if (isManager && !isEmployee) {
            displayFields = getDefaultTripColumnsForManager();
          } else {
            displayFields = getDefaultTripColumnsForEmployee();
          }
        } else {
          displayFields = getDefaultTripColumns();
        }
        break;
      case 'teamTrips':
        displayFields = getDefaultTripColumnsForManager();
        break;
      case 'reports':
        displayFields = !hasTeams
          ? getDefaultReportsColumns()
          : getDefaultReportsColumnsEmployee();
        break;
      case 'teamReports':
        displayFields = !hasTeams
          ? getDefaultReportsColumns()
          : isManager
          ? getDefaultReportsColumnsManager()
          : getDefaultReportsColumnsEmployee();
        break;
      case 'reportTrips':
        fields = reportTripsFields || [];
        displayFields = getDefaultTripColumnsForEmployee();
        break;
      case 'automaticReports':
        displayFields = getDefaultReportRulesColumns();
        break;
      case 'vehicles':
        displayFields = getDefaultVehiclesColumns();
        break;
      default:
        return {};
    }
    const initialFields = _isEmpty(fields) ? initialColumns : fields;
    const result = displayFields.reduce((acc, field) => {
      acc[field] = initialFields.includes(field);
      return acc;
    }, {});
    return result;
  }
);

export const getHeaders = createSelector(
  [
    getDateFormatter,
    getWeightCalculator,
    getMileageRateMatcher,
    getCountry,
    getDateRangeFormatter,
    getUserId,
    getHasTeams
  ],
  (
    dateFormatter,
    weightCalculator,
    mileageRateMatcher,
    countryName,
    dateRangeFormatter,
    userId,
    hasTeams
  ) => {
    return {
      [columnNames.CHECK]: {
        label: 'checkbox',
        conversion: check => check,
        expandable: false,
        width: 78,
        classes: 'checkItem checkboxColumn',
        sortKey: '',
        sortable: false,
        actualKeys: []
      },
      [columnNames.ID]: {
        label: 'list.id',
        conversion: item => item.id,
        expandable: false,
        width: 63,
        classes: 'id-column',
        sortKey: 'id',
        sortable: false,
        actualKeys: ['id']
      },
      // trips
      [columnNames.DATE]: {
        label: 'list.dateTime',
        conversion: (trip, displayFields) => {
          if (displayFields && displayFields.length) {
            const showStartTime = displayFields.includes('starttime');
            const showEndTime = displayFields.includes('endtime');
            return (
              <Fragment>
                {displayFields.includes('startdate') ? (
                  <span>{dateFormatter(trip.departure_time * 1000)}</span>
                ) : null}
                {showStartTime || showEndTime ? (
                  <div className="timesContainer">
                    {showStartTime ? (
                      <Time value={trip.departure_time * 1000} />
                    ) : (
                      <span style={{ opacity: '0' }}>
                        <Time value={trip.departure_time * 1000} />
                      </span>
                    )}
                    <span className="timeDash"> - </span>
                    {showEndTime ? (
                      <Time value={trip.arrival_time * 1000} />
                    ) : (
                      <span style={{ opacity: '0' }}>
                        <Time value={trip.arrival_time * 1000} />
                      </span>
                    )}
                  </div>
                ) : null}
              </Fragment>
            );
          }

          return (
            <Fragment>
              <span>{dateFormatter(trip.departure_time * 1000)}</span>
              <div className="timesContainer">
                <Time value={trip.departure_time * 1000} />
                <span className="timeDash"> - </span>
                <Time value={trip.arrival_time * 1000} />
              </div>
            </Fragment>
          );
        },
        width: 135,
        expandable: false,
        classes: 'left date-time-column',
        sortKey: 'departure_time',
        sortable: false,
        // sortable: true,
        actualKeys: ['startdate', 'starttime', 'endtime']
      },
      [columnNames.TEAM_TRIP_EMPLOYEE]: {
        label: 'list.teamTripEmployee',
        conversion: trip => {
          const { id } = trip.owner;
          const isYou = id === userId;
          let fullName = '';
          if (trip.owner.full_name) {
            fullName = trip.owner.full_name;
          } else {
            fullName = getUserFullName(trip.owner);
          }
          return (
            <span className={isYou ? 'you' : ''}>
              {`${fullName} ${isYou ? `(${i18n.t('common.you')})` : ``}`}
            </span>
          );
        },
        width: 115,
        expandable: false,
        classes: 'left employee-name-column',
        sortKey: 'owner.name',
        sortable: false,
        // sortable: true,
        actualKeys: ['employee_name']
      },
      [columnNames.DURATION]: {
        label: 'list.duration',
        conversion: trip => (
          <Time value={trip.stats && trip.stats.elapsed * 1000} duration />
        ),
        expandable: false,
        width: 70,
        classes: 'duration-column',
        sortKey: 'stats.elapsed',
        sortable: false,
        actualKeys: ['duration']
      },
      [columnNames.MODE_VEHICLE]: {
        label: 'list.modeVehicle',
        conversion: trip => getModeVehicleConversion(trip),
        width: 152,
        expandable: false,
        classes: 'mode-vehicle-column',
        sortKey: 'travel_mode',
        sortable: false,
        actualKeys: ['travelmode', 'vehicle']
      },
      [columnNames.ADDRESS_FROM_TO]: {
        label: 'list.fromTo',
        conversion: trip => {
          return (
            <Fragment>
              <div>
                {trip.flight
                  ? trip.flight.departureAirport.name
                  : (trip.origin && trip.origin.name) || '-'}
              </div>
              <div>
                {trip.flight
                  ? trip.flight.arrivalAirport.name
                  : (trip.destination && trip.destination.name) || '-'}
              </div>
            </Fragment>
          );
        },
        expandable: true,
        width: 180,
        classes: 'address-column',
        sortKey: 'origin.name',
        sortable: false,
        actualKeys: ['addressfrom', 'addressto']
      },
      [columnNames.CITY_FROM_TO]: {
        label: 'list.cityFromTo',
        conversion: trip => {
          return (
            <Fragment>
              <div className="city">{trip.origin && trip.origin.city}</div>
              <div className="city">
                {trip.destination && trip.destination.city}
              </div>
            </Fragment>
          );
        },
        width: 100,
        expandable: false,
        classes: 'city-column',
        sortKey: 'origin.city',
        sortable: false,
        actualKeys: ['cityfrom', 'cityto']
      },
      [columnNames.TAGS_NOTES]: {
        label: 'list.tagsNotes',
        conversion: trip => {
          const classList = [];
          const tagsToShow = [];
          let notesToDisplay = trip.notes || '';
          if (!trip.tags || !trip.tags.length) {
            tagsToShow.push(i18n.t('list.untagged'));
            classList.push('unclassified');
          } else {
            trip.tags &&
              trip.tags.forEach(tag => {
                const tagName = `#${tag.name}`;
                notesToDisplay = notesToDisplay.replace(`${tagName}`, '');
                tagsToShow.push(tagName);
              });
          }
          return (
            <Fragment>
              <div className={classList.join(' ')}>{tagsToShow.join(' ')}</div>
              {notesToDisplay.length > 20 ? (
                <Popup
                  trigger={<div className="notesContent">{notesToDisplay}</div>}
                  on="hover"
                  position="bottom left"
                  className="revisionTooltip small"
                >
                  {trip.notes}
                </Popup>
              ) : (
                <div className="notesContent">{notesToDisplay}</div>
              )}
            </Fragment>
          );
        },
        width: 130,
        expandable: true,
        classes: 'tags-column',
        sortable: false,
        actualKeys: ['tags', 'notes']
      },
      [columnNames.EMISSIONS]: {
        label: {
          key: 'list.emissionsUnit',
          parameters: { unit: weightCalculator().unitShort }
        },
        conversion: trip => {
          return (
            trip.stats && <Weight value={trip.stats.emissions} format="*v" />
          );
        },
        expandable: false,
        width: 100,
        classes: 'right emissions-column',
        sortKey: 'stats.emissions',
        sortable: false,
        // sortable: true,
        actualKeys: ['emissions']
      },
      [columnNames.DISTANCE]: {
        label:
          ['us', 'gb', 'lr', 'mm'].indexOf(countryName.toLowerCase()) > -1
            ? 'list.distanceMi'
            : 'list.distanceKm',
        conversion: (trip, displayFields) => {
          const revisionTitle = getTripRevisionTitle(trip.revised);
          return trip.stats ? (
            <div className="distanceContainer">
              <Distance value={trip.stats.distance} showUnit={false} />
              {displayFields[columnNames.REVISION] && revisionTitle ? (
                <span className="info">
                  <Popup
                    trigger={
                      <img
                        alt="revision"
                        src="/images/trip_revision_icon.png"
                        className="icon"
                      />
                    }
                    on="hover"
                    position="bottom center"
                    className="revisionTooltip small"
                  >
                    {revisionTitle}
                  </Popup>
                </span>
              ) : null}
              {displayFields[columnNames.REVISION] && trip.auto_tagged ? (
                <span className="info">
                  <Popup
                    trigger={
                      <img
                        id="auto-classify-icon"
                        alt="auto-classified"
                        src="/images/icn_auto_classify-lightgrey-small.png"
                        className="icon"
                      />
                    }
                    on="hover"
                    position="bottom center"
                    className="revisionTooltip small"
                  >
                    {i18n.t(`list.auto-classified`)}
                  </Popup>
                </span>
              ) : null}
            </div>
          ) : (
            '—'
          );
        },
        expandable: false,
        width: 80,
        classes: 'right distance-column',
        sortKey: 'stats.distance',
        sortable: false,
        // sortable: true,
        actualKeys: ['distance']
      },
      [columnNames.START_ODOMETER]: {
        label: 'automaticReports.dataFields.start_odometer',
        conversion: trip => (
          <Distance value={trip.start_odometer} showUnit={false} />
        ),
        classes: 'right odometer-column',
        expandable: false,
        width: 80,
        actualKeys: ['start_odometer']
      },
      [columnNames.END_ODOMETER]: {
        label: 'automaticReports.dataFields.end_odometer',
        conversion: trip => (
          <Distance value={trip.end_odometer} showUnit={false} />
        ),
        classes: 'right odometer-column',
        expandable: false,
        width: 80,
        actualKeys: ['end_odometer']
      },
      [columnNames.EXPENSES_RATE]: {
        label: 'list.expensesRate',
        conversion: (trip, displayFields) => {
          const status = getKeyByValue(
            REPORT_STATUS,
            trip.new_approval_status || trip.approval_status
          ).toLowerCase();

          const rate = getRate(trip, mileageRateMatcher);
          return (
            <Fragment>
              {trip.mileage_expenses && trip.mileage_expenses.value ? (
                rate === '-' ? (
                  <div>
                    <Currency
                      value={trip.mileage_expenses.value}
                      iso={trip.mileage_expenses.currency}
                      round={3}
                    />
                  </div>
                ) : (
                  <Popup
                    trigger={
                      <div>
                        <Currency
                          value={trip.mileage_expenses.value}
                          iso={trip.mileage_expenses.currency}
                          round={3}
                        />
                      </div>
                    }
                    on="hover"
                    position="bottom right"
                    className="revisionTooltip small"
                  >
                    <Fragment>
                      <span>
                        {`${i18n.t(`tooltip.expenseRate.mileageRate`)} `}
                      </span>
                      {rate}
                    </Fragment>
                  </Popup>
                )
              ) : (
                '—'
              )}
              {hasTeams && displayFields[columnNames.APPROVAL_STATUS] && (
                <div className="rateContainer">
                  <span className={`reportStatus ${status}`}>
                    {i18n.t(`team.approvals.${status}`)}
                  </span>
                </div>
              )}
            </Fragment>
          );
        },
        expandable: false,
        width: 114,
        classes: 'right expenses-rate-column',
        sortKey: 'mileage_expenses.value',
        sortable: false,
        // sortable: true,
        actualKeys: ['expenses', 'mileage_rate']
      },
      [columnNames.APPROVAL_STATUS]: {
        // this column never shows up in the list,
        // it is used in the ColumnPicker to hide/show approval_status in the list
        label: i18n.t('automaticReports.dataFields.approval_status'),
        actualKeys: ['approval_status']
      },
      [columnNames.REVISION]: {
        // this column never shows up in the list,
        // it is used in the ColumnPicker to hide/show revision in the list
        label: i18n.t('automaticReports.dataFields.revisions'),
        actualKeys: ['revisions']
      },
      [columnNames.MORE]: {
        label: 'more',
        conversion: more => more,
        expandable: false,
        width: 44,
        classes: 'moreColumnPicker',
        sortKey: 'empty',
        sortable: false,
        actualKeys: []
      },

      // reports
      [columnNames.USERNAME]: {
        label: 'list.reportedBy',
        conversion: report => {
          return report.owner.id;
        },
        expandable: true,
        // width: 85,
        classes: 'left name-column'
      },
      [columnNames.TITLE]: {
        label: 'common.title',
        conversion: report => {
          return report.name;
        },
        expandable: true,
        width: 200,
        classes: 'left title-column'
      },
      [columnNames.REPORT_DATE_RANGE]: {
        label: 'list.dateRange',
        conversion: report => {
          if (!_isEmpty(report.criteria) && report.criteria.dates) {
            const dateRange = report.criteria.dates;
            if (dateRange.start && dateRange.end) {
              const dateRangeToDisplay = dateRangeFormatter(
                dateRange.start * 1000,
                dateRange.end * 1000
              );
              return (
                <div className="dateRangeContainer">
                  <div>{dateRangeToDisplay.start}</div>
                  <div>{dateRangeToDisplay.end}</div>
                </div>
              );
            }
          }
          return '—';
        },
        expandable: false,
        width: 120,
        classes: 'left date-column'
      },
      [columnNames.FILTERS]: {
        label: 'common.filters',
        conversion: report => {
          return (
            report.criteria && (
              <FilterText criteria={report.criteria} expanded />
            )
          );
        },
        expandable: true,
        width: 200,
        classes: 'left filters-column'
      },
      [columnNames.TRIPS]: {
        label: 'dashboard.trips',
        conversion: report => {
          return report.trips;
        },
        expandable: false,
        width: 80,
        classes: 'right trips-column'
      },
      [columnNames.REPORT_DISTANCE]: {
        label:
          ['us', 'gb', 'lr', 'mm'].indexOf(countryName.toLowerCase()) > -1
            ? 'list.distanceMi'
            : 'list.distanceKm',
        conversion: report => {
          return report.distance ? (
            <Distance value={report.distance} showUnit={false} />
          ) : (
            '—'
          );
        },
        expandable: false,
        width: 100,
        classes: 'right distance-column'
      },
      [columnNames.REPORT_EXPENSES]: {
        label: 'common.expenses',
        conversion: report => {
          return report.expenses && report.expenses.amount !== undefined ? (
            <Currency
              value={report.expenses.amount}
              iso={report.expenses.currency}
            />
          ) : (
            '—'
          );
        },
        expandable: false,
        width: 140,
        classes: 'right expenses-column'
      },
      // report teams
      [columnNames.TEAM_REPORT_REPORTED_BY]: {
        label: 'list.reportedBy',
        conversion: report => {
          if (report.aggregate) {
            return i18n.t('list.team');
          } else {
            const { id } = report.owner;
            const isYou = id === userId;
            const fullName = getUserFullName(report.owner);
            return (
              <span className={isYou ? 'you' : ''}>
                {`${fullName} ${isYou ? `(you)` : ``}`}
              </span>
            );
          }
        },
        expandable: true,
        width: 160,
        classes: 'left team-reported-by-column'
      },
      [columnNames.TEAM_REPORT_STATUS]: {
        label: 'common.status',
        conversion: report => {
          const status = getKeyByValue(REPORT_STATUS, report.approval_status);
          return (
            <span className={`${status.toLowerCase()}`}>
              {i18n.t(`team.approvals.${status.toLowerCase()}`)}
            </span>
          );
        },
        expandable: true,
        width: 100,
        classes: 'left team-report-status-column'
      },

      // automaticReports
      [columnNames.TITLE]: {
        label: 'common.title',
        conversion: automaticReport => {
          return automaticReport.name;
        },
        expandable: true,
        width: 240,
        classes: 'left name-column'
      },
      [columnNames.FREQUENCY]: {
        label: 'automaticReports.frequency',
        conversion: automaticReport => {
          return !_isEmpty(automaticReport.frequency)
            ? getFrequencyText(automaticReport.frequency)
            : '—';
        },
        expandable: true,
        width: 240,
        classes: 'left frequency-column'
      },
      [columnNames.ENABLED]: {
        label: 'automaticReports.status',
        conversion: automaticReport => automaticReport,
        expandable: false,
        width: 181,
        classes: 'left enabled-column'
      },

      // teams list
      [columnNames.TEAM_NAME]: {
        label: 'team.name',
        conversion: team => <span className="span">{team.name}</span>,
        expandable: true,
        width: 160,
        classes: 'left team-name-column'
      },
      [columnNames.TEAM_MEMBERS]: {
        label: 'team.members',
        conversion: team => <span className="span">{team.members}</span>,
        expandable: true,
        width: 60,
        classes: 'left team-members-column'
      },
      [columnNames.TEAM_COUNTRY]: {
        label: 'team.country',
        conversion: team => <span className="span">{team.country}</span>,
        expandable: true,
        width: 140,
        classes: 'left team-country-column'
      },
      [columnNames.TEAM_DATE_FORMAT]: {
        label: 'team.dateFormat',
        conversion: team => <span className="span">{team.dateFormat}</span>,
        expandable: true,
        width: 140,
        classes: 'left team-date-format-column'
      },
      [columnNames.TEAM_CURRENCY]: {
        label: 'team.currency',
        conversion: team => <span className="span">{team.currency}</span>,
        expandable: true,
        width: 70,
        classes: 'left team-currency-column'
      },

      // team members list
      [columnNames.MEMBER_NAME]: {
        label: 'member.name',
        conversion: member => (
          <span className="span">{`${getUserFullName(member.user)}`}</span>
        ),
        expandable: true,
        width: 170,
        classes: 'left member-name-column'
      },
      [columnNames.MEMBER_EMAIL]: {
        label: 'member.email',
        conversion: member => <span className="span">{member.user.email}</span>,
        expandable: true,
        width: 240,
        classes: 'left member-email-column'
      },
      [columnNames.MEMBER_ROLES]: {
        label: 'member.roles',
        conversion: member =>
          member.user_roles &&
          member.user_roles.map(role => (
            <div className={`roleTile ${role.name.toLowerCase()}`}>
              <span>{role.name}</span>
            </div>
          )),
        expandable: true,
        width: 180,
        classes: 'left member-roles-column'
      },
      [columnNames.MEMBER_INVITE_STATUS]: {
        label: 'member.inviteStatus',
        conversion: member => {
          const lastInvite = member.invites[member.invites.length - 1];
          return lastInvite.send_status === 'accepted' ? (
            ''
          ) : (
            <div>
              <span className="span">{lastInvite.created}</span>
              <span className="span blue">{i18n.t(`actions.resend`)}</span>
              <span className="span red">{i18n.t(`actions.revoke`)}</span>
            </div>
          );
        },
        expandable: true,
        width: 255,
        classes: 'left member-invite-status-column'
      },
      [columnNames.SINCE]: {
        label: 'member.since',
        conversion: member => {
          const lastInvite = member.invites[member.invites.length - 1];
          return lastInvite.send_status === 'accepted' ? (
            <span className="span">{lastInvite.created}</span>
          ) : (
            ''
          );
        },
        expandable: true,
        width: 100,
        classes: 'left member-since-column'
      }
    };
  }
);

export const getActiveHeading = state => {
  const {
    listNew: { activeList, reportTrips },
    teamUserInfo
  } = state;
  const hasTeams = getHasTeams(state);
  switch (activeList) {
    case 'trips':
    case 'teamTrips':
      return {
        getHeading: trip => {
          const time = moment(trip.departure_time * 1000);
          const formattedTime = time.format('MMMM YYYY');
          const [month, year] = formattedTime.split(' ');
          return `${month.toLowerCase()} ${year}`;
        }
      };

    case 'reports':
    case 'teamReports':
      return {
        getHeading: report => {
          if (
            hasTeams &&
            (teamUserInfo.rolesInSelectedTeam.includes('admin') ||
              teamUserInfo.rolesInSelectedTeam.includes('manager'))
          ) {
            const heading = getKeyByValue(
              REPORT_STATUS,
              report.approval_status
            );
            return i18n.t(`team.approvals.${heading.toLowerCase()}`);
            // return heading;
          }

          const time = moment(
            (report.criteria ? report.criteria.dates.end : report.created) *
              1000
          ).utc();
          const formattedTime = time.format('MMMM YYYY');
          const [month, year] = formattedTime.split(' ');
          return `${month.toLowerCase()} ${year}`;
        }
      };

    case 'reportTrips':
      return {
        getHeading: trip => {
          if (hasTeams) {
            const listGroup = getReportsListGroupByReportId(state);
            const openedReport =
              listGroup.openedItems[reportTrips.openedReportId];
            if (
              openedReport.approval_status === REPORT_STATUS.FLAGGED ||
              openedReport.approval_status === REPORT_STATUS.RESOLVED
            ) {
              const heading = getKeyByValue(
                REPORT_STATUS,
                trip.approval_status
              );
              return heading;
            }
          }

          const time = moment(trip.departure_time * 1000);
          const formattedTime = time.format('MMMM YYYY');
          const [month, year] = formattedTime.split(' ');
          return `${month.toLowerCase()} ${year}`;
        }
      };

    case 'automaticReports':
      return {
        getHeading: report => {
          const time = moment(report.modified * 1000);
          return time.format('MMMM YYYY');
        }
      };

    default:
      return {
        getHeading: item => {
          const time = moment(item.departure_time * 1000);
          return time.format('MMMM YYYY');
        }
      };
  }
};

export const getSelectedTagsCount = state => {
  const listSelection = getSelectedItems(state);
  const selectedTagsCount = {};
  Object.values(listSelection).forEach(item => {
    item.tags &&
      item.tags.forEach(tag => {
        !selectedTagsCount[tag.name]
          ? (selectedTagsCount[tag.name] = 1)
          : selectedTagsCount[tag.name]++;
      });
  });
  return selectedTagsCount;
};

export const getSelectedTripsOwnerIds = state => {
  const listSelection = getSelectedItems(state);
  return Object.values(listSelection).reduce((acc, trip) => {
    acc.push(trip.owner.id);
    return acc;
  }, []);
};

export const getTripsTags = state => {
  const { trips: { list = [] } = {} } = state.listNew;
  const tagsList = [];
  list.forEach(({ tags }) => {
    if (tags) {
      tags.forEach(
        ({ name }) => !tagsList.includes(name) && tagsList.push(name)
      );
    }
  });
  return tagsList;
};

export const checkForNonNewTrips = state => {
  const { list = [], selectedItems } = state.listNew[state.listNew.activeList];
  if (!selectedItems) return false;
  return Object.keys(selectedItems).some(
    index => list[index].approval_status !== REPORT_STATUS.NEW
  );
};

export const listHasFilters = state => {
  const { filters, filterSearchText } = getActiveListGroup(state);
  return Boolean(
    (!isEmpty(filters) && (filters.start_date || filters.end_date)) ||
      filterSearchText
  );
};

export const getListFilters = state => {
  const { filters } = getActiveListGroup(state);
  return filters;
};

export const getFilterSearchText = (state, listType) => {
  const list = state.listNew[listType];
  if (list) {
    return list.filterSearchText;
  }
};

export const checkIfFiltersAreEmpty = state => {
  return listType => {
    const { status, ...rest } = state.listNew[listType].filters || {};
    return _isEmpty(rest);
  };
};
