const sendEvent = (category, action, label) => {
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label
    });
  }
};

export const trackAppDownload = (eventCategory, eventLabel) => {
  sendEvent(eventCategory, 'download', eventLabel);
};
export const trackLoginFromGetStarted = eventCategory => {
  sendEvent(eventCategory, 'login', 'getStarted');
};
export const trackLoginFromGoToDashboard = eventCategory => {
  sendEvent(eventCategory, 'login', 'goToDashboard');
};
export const trackLogin = (eventCategory, eventLabel) => {
  sendEvent(eventCategory, 'login', eventLabel);
};
export const trackMoreInfo = (eventCategory, eventLabel) => {
  sendEvent(eventCategory, 'moreInfo', eventLabel);
};

export const trackSignIn = eventLabel => {
  sendEvent('login', 'signIn', eventLabel);
};
export const trackSignUp = eventLabel => {
  sendEvent('login', 'signUp', eventLabel);
};
export const trackSeeItInActionVideo = () => {
  sendEvent('enterprise', 'seeItInAction', 'videoPlayed');
};
