export const getHelpAnswersLists = state => {
  return state.helpPage.list || [];
};
export const getQuestionIdToLookup = state => {
  return state.helpPage.questionIdToLookup;
};
export const getSearchText = state => {
  return state.helpPage.searchText;
};
export const getHelpListLanguage = state => {
  return state.helpPage.listLanguage;
};
