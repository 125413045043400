import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Image } from 'semantic-ui-react';

import SectionTitle from './SectionTitle';

const WorkPartWrapper = ({ img, title, subTitle }) => (
  <div className="workPartWrapper">
    <div className="header">
      <Image src={img} size="small" />
      <div className="title">{title}</div>
    </div>
    <div className="subTitle">{subTitle}</div>
  </div>
);

WorkPartWrapper.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string
};

const HowItWorksSection = ({ t }) => {
  return (
    <div className="howItWorksSection" id="howItWorks">
      <div className="section">
        <div className="titleContainer">
          <SectionTitle secondLine={t(`home.howItWorks.title`)} />

          <div className="sectionSubTitle">{t(`home.howItWorks.subtitle`)}</div>
        </div>

        <div className="worksContainer">
          <WorkPartWrapper
            img="/images/landing/hiw_log.png"
            title={t(`home.howItWorks.log.title`)}
            subTitle={t(`home.howItWorks.log.subtitle`)}
          />
          <div className="separator" />
          <WorkPartWrapper
            img="/images/landing/hiw_calc.png"
            title={t(`home.howItWorks.calculate.title`)}
            subTitle={t(`home.howItWorks.calculate.subtitle`)}
          />
          <div className="separator" />
          <WorkPartWrapper
            img="/images/landing/hiw_generate.png"
            title={t(`home.howItWorks.generate.title`)}
            subTitle={t(`home.howItWorks.generate.subtitle`)}
          />
        </div>
      </div>
    </div>
  );
};

HowItWorksSection.propTypes = {
  t: PropTypes.func.isRequired
};

export default translate()(HowItWorksSection);
