import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LandingIntroSection extends Component {
  render() {
    const { appName, title, subTitle, actions, onPlay } = this.props;
    return (
      <div className="introSection" id="intro">
        <div className="left">
          <div className="details">
            <div className="appName">{appName}</div>
            <div className="title">{title}</div>
            <div className="subTitle">{subTitle}</div>
            <div className="actions">{actions}</div>
          </div>
        </div>

        <div className="right">
          <div className="dashboardScreen">
            {appName == 'Psngr Pro' && (
              <div className="playButton" onClick={onPlay} />
            )}
          </div>
          <div className="appScreen" />
        </div>
      </div>
    );
  }
}

LandingIntroSection.propTypes = {
  appName: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  actions: PropTypes.array,
  onPlay: PropTypes.bool
};

export default LandingIntroSection;
