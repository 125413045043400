import {
  SELECT_MEMBERS_LIST_ITEM,
  DESELECT_MEMBERS_LIST_ITEM,
  SELECT_ALL_MEMBERS_LIST_ITEMS,
  DESELECT_ALL_MEMBERS_LIST_ITEMS,
  CLOSE_TEAM_MEMBERS_LIST,
  SET_OPENED_TEAM_ID,
  SET_TEAM_MEMBERS_LIST,
  FETCHING_TEAM_MEMBERS_LIST,
  UPDATE_TEAM_MEMBERS_LIST,
  UPDATE_TEAM_MEMBERS_MODAL
} from '../actions/teamMembers';

const initialState = {
  stats: {
    count: 0
  },
  pagination: {
    next: null
  },
  teamId: null,
  list: [],
  isFetching: false
};

export const teamMembers = (state = initialState, action) => {
  const updatedList = [...state.list];
  const listItem = updatedList[action.itemIndex];
  switch (action.type) {
    case SELECT_MEMBERS_LIST_ITEM:
      updatedList[action.itemIndex] = Object.assign({}, listItem, {
        isSelected: true
      });
      return Object.assign({}, state, {
        list: updatedList
      });

    case DESELECT_MEMBERS_LIST_ITEM:
      updatedList[action.itemIndex] = Object.assign({}, listItem, {
        isSelected: false
      });
      return Object.assign({}, state, {
        list: updatedList
      });

    case SELECT_ALL_MEMBERS_LIST_ITEMS:
      return Object.assign({}, state, {
        list: updatedList.reduce((acc, next) => {
          acc.push(
            Object.assign({}, next, {
              isSelected: true
            })
          );
          return acc;
        }, [])
      });

    case DESELECT_ALL_MEMBERS_LIST_ITEMS:
      return Object.assign({}, state, {
        list: updatedList.reduce((acc, next) => {
          acc.push(
            Object.assign({}, next, {
              isSelected: false
            })
          );
          return acc;
        }, [])
      });

    case SET_OPENED_TEAM_ID:
      return Object.assign({}, state, {
        teamId: action.teamId
      });

    case SET_TEAM_MEMBERS_LIST:
      return Object.assign({}, state, {
        stats: action.stats,
        pagination: action.pagination,
        list: action.list,
        isFetching: false
      });

    case CLOSE_TEAM_MEMBERS_LIST:
      return initialState;

    case FETCHING_TEAM_MEMBERS_LIST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case UPDATE_TEAM_MEMBERS_LIST:
      return Object.assign({}, state, { list: action.list });
    case UPDATE_TEAM_MEMBERS_MODAL:
      return Object.assign({}, state, { modal: action.modal });
    default:
      return state;
  }
};
