import { createSelector } from 'reselect';

import { getUserDetailsInfo } from './billing';
import { getCurrencyFromCountry, getShippingZoneFromCountry } from './locale';
import { getCustomer } from './customer';
import { isEmpty } from '../utils';

export const getBeaconsProducts = state => {
  return !isEmpty(state.products.beacons) && state.products.beacons.items;
};

export const getShippingProducts = state => {
  return state.products.beacons.shipping;
};

export const getCustomerCurrency = state => {
  const {
    billing: {
      payment: { billingInfo }
    },
    user: { details }
  } = state;
  const customer = getCustomer(state);
  let country = '';
  if (billingInfo.country) country = billingInfo.country;
  else if (!isEmpty(customer)) {
    country =
      customer.billing_address && customer.billing_address.country_code_alpha2;
  } else {
    country = details.country;
  }
  return getCurrencyFromCountry(country);
};

export const getCustomerBeaconProduct = createSelector(
  [getCustomerCurrency, getBeaconsProducts],
  (currency, beacons) => beacons && beacons.find(el => el.currency === currency)
);

export const getShippingZone = state => {
  const {
    billingInfo,
    shippingInfo,
    sameAddressBillingShipping
  } = state.billing.payment;
  const { country: userCountry } = getUserDetailsInfo(state);
  let country;
  country = sameAddressBillingShipping
    ? billingInfo.country
    : shippingInfo.country;
  if (country === undefined) country = userCountry;
  return country && getShippingZoneFromCountry(country);
};

export const getCustomerShippingProducts = createSelector(
  [getCustomerCurrency, getShippingProducts, getShippingZone],
  (currency, shipping, zone) =>
    shipping &&
    shipping
      .filter(
        el =>
          el.currency === currency &&
          (el.product_type.indexOf(zone) > -1 ||
            el.product_type.indexOf('free') > -1)
      )
      .sort((a, b) => a.price > b.price)
);

export const getFreeShippingMethodId = createSelector(
  [getCustomerShippingProducts],
  shippingProducts =>
    shippingProducts && shippingProducts.find(el => el.price === 0).id
);

export const deriveBeaconsProductData = createSelector(
  [getBeaconsProducts, getCustomerBeaconProduct],
  (products, selectedBeacon) => {
    return {
      products,
      ready: !isEmpty(products) && true,
      currency: selectedBeacon && selectedBeacon.currency
    };
  }
);
// export const deriveBeaconsProductData = createSelector(
//   [getBeaconsProducts, getServices, getCustomerBeaconProduct],
//   (products, services, selectedBeacon) => {
//     return {
//       products,
//       ready: !isEmpty(products) && services.taxamo,
//       currency: selectedBeacon && selectedBeacon.currency
//     };
//   }
// );
