import React from 'react';
import PropTypes from 'prop-types';

const SectionTitle = ({ firstLine, secondLine, white }) => (
  <React.Fragment>
    <div className={`sectionTitle ${white ? `white` : ''}`}>
      {firstLine && <div className="firstLine">{firstLine}</div>}

      {secondLine && (
        <div className="secondLine">
          <div className="dash" />
          <div>{secondLine}</div>
        </div>
      )}
    </div>
    <div className={`sectionTitle mobile tablet ${white ? 'white' : ''}`}>
      <div className="dash" />
      <div className="title">
        {firstLine && secondLine
          ? `${firstLine} ${secondLine}`
          : firstLine && !secondLine
            ? `${firstLine}`
            : !firstLine && secondLine
              ? `${secondLine}`
              : ''}
      </div>
    </div>
  </React.Fragment>
);

SectionTitle.propTypes = {
  firstLine: PropTypes.string,
  secondLine: PropTypes.string,
  white: PropTypes.bool
};

export default SectionTitle;
