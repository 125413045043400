import qs from 'qs';

import { getLocal } from '../selectors/locale';
import { vehicleFullName } from './vehicles';

import {
  REPORT_STATUS,
  SHOW_MANAGE_FOR_ENTERPRISE,
  EUCountries
} from './constants';
import i18n from './i18n';
import moment from 'moment';
import { add, isUndefined } from 'lodash';

export const isEmpty = element => {
  return typeof element !== 'undefined' && element !== null
    ? !Object.keys(element).length
    : true;
};
export const isNotUndefined = element => typeof element !== 'undefined';
export const checkUndefined = element =>
  typeof element !== 'undefined' && element;

export const flatten = arr => {
  return Array.isArray(arr) ? [].concat(...arr.map(flatten)) : arr;
};

export const containStr = (str, strToCheck) => {
  return str.toString().includes(strToCheck);
};

export const handleRequiredError = item => {
  return item === false;
};

export function validateEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const getUserFullName = user => {
  let fullName = '';
  if (user.first_name && user.last_name) {
    fullName = `${user.first_name} ${user.last_name}`;
  } else if (!user.first_name && user.last_name) {
    fullName = `${user.last_name}`;
  } else if (user.first_name && !user.last_name) {
    fullName = `${user.first_name}`;
  }

  return fullName;
};

export const toDataURL = url => {
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );
};

export const filterDuplicates = array => {
  const uniqueNames = [];
  const uniqueArray = [];
  array.forEach(el => {
    if (uniqueNames.indexOf(el.name) === -1) {
      uniqueNames.push(el.name);
      uniqueArray.push(el);
    }
  });
  return uniqueArray;
};

export const getBeaconDiscountPerItem = (quantity, discounts) => {
  if (isEmpty(discounts)) return 0;
  const sortedDiscounts = discounts.sort((a, b) => {
    if (a.quantity > b.quantity) {
      return 1;
    } else if (a.quantity < b.quantity) {
      return -1;
    }

    return 0;
  });
  return sortedDiscounts.reduce((acc, next) => {
    if (quantity >= next.quantity) {
      acc = next.discount_per_item;
    }
    return acc;
  }, 0);
};

export const getAllRoleOptions = () => {
  return [
    { text: i18n.t(`member.admin`), value: 'admin' },
    { text: i18n.t(`member.manager`), value: 'manager' },
    { text: i18n.t(`member.employee`), value: 'employee' }
  ];
};

export const calculateBeaconPrice = (pricePerItem, quantity, discounts) => {
  if (isEmpty(discounts)) return pricePerItem * quantity;
  const discountPerItem = getBeaconDiscountPerItem(quantity, discounts);
  return (pricePerItem - discountPerItem) * quantity;
};

export const getTopDiscount = (discounts, price, quantity, t) => {
  var discount = discounts
    .sort((a, b) => a.discount_per_item - b.discount_per_item)
    .find(a => a.quantity > quantity);
  if (isUndefined(discount) && discounts.length > 0) {
    discount = discounts.slice(-1)[0];
  }
  return t('beacon.discountAtHighestQuantity', {
    quantity: discount.quantity,
    percent: Math.round((discount.discount_per_item / price) * 100)
  });
};

export const getVehiclesMap = allUserVehicles => {
  return allUserVehicles.reduce((acc, vehicle) => {
    acc[vehicle.id] = {
      vehicleName: vehicleFullName(vehicle),
      travelMode: vehicle.travel_mode,
      hasBeaconConnected: !!vehicle.beacon || false
    };
    return acc;
  }, {});
};

export const getTripRevisionTitle = revision => {
  let revisionTitle = '';
  if (revision) {
    if (revision & 0x20) {
      revisionTitle = i18n.t(`list.loggedManually`);
    } else if (revision & 0x10) {
      revisionTitle = i18n.t('list.merged');
    } else {
      var changes = [];
      if (revision & 0x1) {
        changes.push(i18n.t(`list.revisionDetails.location_short`));
      }
      if (revision & 0x2) {
        changes.push(i18n.t(`list.revisionDetails.vehicle_short`));
      }
      if (revision & 0x4) {
        changes.push(i18n.t(`list.revisionDetails.travelMode_short`));
      }
      if (revision & 0x8) {
        changes.push(i18n.t(`list.revisionDetails.deletedSteps_short`));
      }
      if (changes.length > 0) {
        revisionTitle = `${i18n.t(`list.revised`)}: ${changes.join(', ')}`;
      } else if (revision > 1) {
        revisionTitle = i18n.t(`list.revised`);
      }
    }
  }

  return revisionTitle;
};

const getDateFormatForLocale = locale => {
  const day = 31;
  const month = 11;
  const thisYear = new Date().getFullYear();
  const date = new Date(thisYear, month, day).toLocaleDateString(
    locale || undefined
  );

  let delimiter = '/';
  if (!date.includes(delimiter)) {
    delimiter = '-';
    if (!date.includes(delimiter)) {
      delimiter = '.';
    }
  }
  const dateParts = date.split(delimiter);

  // find where is the year part
  const indexForDayPart = dateParts.indexOf(day.toString());

  const options = {};
  const dayText = 'DD';
  const monthText = 'MM';
  const yearText = 'YYYY';
  const dayTrans = i18n.t(`settings.timeFormat.${dayText}`);
  const monthTrans = i18n.t(`settings.timeFormat.${monthText}`);
  const yearTrans = i18n.t(`settings.timeFormat.${yearText}`);

  const option1 = {
    text: [dayTrans, monthTrans, yearTrans].join(delimiter),
    value: [dayText, monthText, yearText].join(delimiter)
  };
  const option2 = {
    text: [monthTrans, dayTrans, yearTrans].join(delimiter),
    value: [monthText, dayText, yearText].join(delimiter)
  };
  const option3 = {
    text: [yearTrans, monthTrans, dayTrans].join(delimiter),
    value: [yearText, monthText, dayText].join(delimiter)
  };

  if (indexForDayPart === 0) {
    options.default = option1;
    options.two = option2;
    options.three = option3;
  } else if (indexForDayPart === 1) {
    options.default = option2;
    options.two = option1;
    options.three = option3;
  } else {
    options.default = option3;
    options.two = option1;
    options.three = option2;
  }
  return options;
};
export const getTimeFormatForLocale = locale => {
  const date = new Date();
  date.setHours(23);
  try {
    const localeTimeString = date.toLocaleTimeString(locale);
    if (parseInt(localeTimeString.substr(0, 2), 10) === 23) {
      return '24h';
    }
    return '12h';
  } catch (err) {
    return '12h';
  }
};
export const getDateFormatOptions = (country, language) => {
  const date = getDateFormatForLocale(`${language}-${country}`);
  return [
    { value: date.default.value, text: date.default.text },
    { value: date.two.value, text: date.two.text },
    { value: date.three.value, text: date.three.text }
  ];
};
export const getDateFormatOptionsFromPreference = preference => {
  let delimiter = '/';
  if (!preference.includes(delimiter)) {
    delimiter = '-';
    if (!preference.includes(delimiter)) {
      delimiter = '.';
    }
  }
  const dateParts = preference.split(delimiter);

  // find where is the year part
  const indexForDayPart = dateParts.indexOf('DD');

  const dayText = 'DD';
  const monthText = 'MM';
  const yearText = 'YYYY';
  const dayTrans = i18n.t(`settings.timeFormat.${dayText}`);
  const monthTrans = i18n.t(`settings.timeFormat.${monthText}`);
  const yearTrans = i18n.t(`settings.timeFormat.${yearText}`);

  let options = [];
  const option1 = {
    text: [dayTrans, monthTrans, yearTrans].join(delimiter),
    value: [dayText, monthText, yearText].join(delimiter)
  };
  const option2 = {
    text: [monthTrans, dayTrans, yearTrans].join(delimiter),
    value: [monthText, dayText, yearText].join(delimiter)
  };
  const option3 = {
    text: [yearTrans, monthTrans, dayTrans].join(delimiter),
    value: [yearText, monthText, dayText].join(delimiter)
  };

  if (indexForDayPart === 0) {
    options = [option1, option2, option3];
  } else if (indexForDayPart === 1) {
    options = [option2, option1, option3];
  } else {
    options = [option3, option1, option2];
  }
  return options;
};

export const parseSearchQuery = searchQuery => {
  const parsedQuery = {
    text: searchQuery,
    approvalStatus: []
  };
  let parsedText = searchQuery.toLowerCase().replace('#', '');
  const approvalNew = i18n.t('team.approvals.new').toLowerCase();
  const submitted = i18n.t('team.approvals.submitted').toLowerCase();
  const resolved = i18n.t('team.approvals.resolved').toLowerCase();
  const flagged = i18n.t('team.approvals.flagged').toLowerCase();
  const approved = i18n.t('team.approvals.approved').toLowerCase();
  if (parsedText.includes(i18n.t('list.untagged'))) {
    parsedText = parsedText.replace(i18n.t('list.untagged'), '');
    parsedQuery.tagged = 'false';
  }
  if (parsedText.includes(approvalNew)) {
    parsedText = parsedText.replace(approvalNew, '');
    parsedQuery.approvalStatus.push(REPORT_STATUS.NEW);
  }
  if (parsedText.includes(submitted)) {
    parsedText = parsedText.replace(submitted, '');
    parsedQuery.approvalStatus.push(REPORT_STATUS.SUBMITTED);
  }
  if (parsedText.includes(flagged)) {
    parsedText = parsedText.replace(flagged, '');
    parsedQuery.approvalStatus.push(REPORT_STATUS.FLAGGED);
  }
  if (parsedText.includes(resolved)) {
    parsedText = parsedText.replace(resolved, '');
    parsedQuery.approvalStatus.push(REPORT_STATUS.RESOLVED);
  }
  if (parsedText.includes(approved)) {
    parsedText = parsedText.replace(approved, '');
    parsedQuery.approvalStatus.push(REPORT_STATUS.APPROVED);
  }
  parsedQuery.text = parsedText.trim();
  return parsedQuery;
};

export const getDateRangeToFilterBy = ({ start_date, end_date }) => {
  const startDate = start_date.setUTCHours(0);
  let endDate = new Date(end_date);
  endDate.setUTCHours(23);
  endDate.setUTCMinutes(59);
  endDate = endDate.setUTCSeconds(59);

  return {
    startDate: startDate / 1000,
    endDate: endDate / 1000
  };
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const getFiltersFromText = (
  filterSearchText,
  tagOptions,
  vehiclesMap
) => {
  const searchText = filterSearchText;

  const words = searchText.split(/[,\s]+/);
  const filters = words.reduce(
    (acc, word) => {
      let tagWord = word.indexOf('#') === 0 ? word.replace('#', '') : word;
      tagWord = tagWord.trim().toLowerCase();
      const cleanWord = word.trim().toLowerCase();
      let addWordToArray = true;

      if (!cleanWord) return acc;

      if (tagWord === i18n.t('list.untagged')) {
        acc.tagged = false;
        addWordToArray = false;
      }

      tagOptions.forEach(({ name }) => {
        if (name.toLowerCase() === tagWord) {
          acc.tags.push(name);
        }
      });

      Object.keys(vehiclesMap).forEach(vehicleId => {
        vehiclesMap[vehicleId].forEach(prop => {
          if (prop && prop.toLowerCase().startsWith(cleanWord)) {
            acc.vehicles.push(vehicleId);
          }
        });
      });

      Object.keys(REPORT_STATUS).forEach(status => {
        if (
          i18n.t(`team.approvals.${status.toLowerCase()}`).toLowerCase() ===
          cleanWord
        ) {
          acc.approvalStatus.push(REPORT_STATUS[status]);
          addWordToArray = false;
        }
      });

      if (addWordToArray) {
        acc.textArray.push(cleanWord);
      }

      return acc;
    },
    {
      tags: [],
      vehicles: [],
      tagged: true,
      approvalStatus: [],
      textArray: []
    }
  );
  filters.text = filters.textArray.join(',');
  return filters;
};

export const getFiltersFromTextForReportSearch = filterSearchText => {
  const words = filterSearchText.split(/[,\s]+/);
  const filters = words.reduce(
    (acc, word) => {
      const cleanWord = word.trim().toLowerCase();
      let wordIsStatus = false;
      Object.keys(REPORT_STATUS).forEach(status => {
        if (
          i18n.t(`team.approvals.${status.toLowerCase()}`).toLowerCase() ===
          cleanWord
        ) {
          acc.approvalStatus.push(REPORT_STATUS[status]);
          wordIsStatus = true;
        }
      });
      if (!wordIsStatus) {
        acc.textArray.push(word.trim());
      }
      return acc;
    },
    {
      approvalStatus: [],
      textArray: []
    }
  );
  return {
    approvalStatus: filters.approvalStatus,
    text: filters.textArray.join(',')
  };
};

export const getSubscriptionTypeName = type => {
  switch (type) {
    case 'braintree':
      return 'Psngr Pro';
    case 'team':
      return 'Psngr Enterprise';
    case 'apple_iap':
      return 'Psngr IAP';
    case 'free':
      return 'Psngr Free';
    default:
      return '';
  }
};

export const makeSubcriptionDescription = subscription => {
  const { type } = subscription;
  return `${i18n.t(`subscription.current`, {
    type: getSubscriptionTypeName(type)
  })}${type === 'apple_iap' ? i18n.t(`subscription.iapDescription`) : ``}`;
};

export const getLanguageFromLocal = () => {
  return getLocal('appLanguage');
};

export const getCancelInfoURL = userLanguage => {
  switch (userLanguage) {
    case 'en':
      return 'https://support.apple.com/en-us/HT202039';
    case 'es':
      return 'https://support.apple.com/es-es/HT202039';
    case 'fr':
      return 'https://support.apple.com/fr-fr/HT202039';
    case 'de':
      return 'https://support.apple.com/de-de/HT202039';
    case 'it':
      return 'https://support.apple.com/it-it/HT202039';
    default:
      return '';
  }
};

export const getDashboardLinkFor = (forPage, params = {}) => {
  let link = '/dashboard';
  switch (forPage) {
    case 'account':
      link = '/dashboard/account';
      break;
    case 'trips':
      link = '/dashboard/trips';
      if (params.for && params.for === 'teamTrips') {
        link += `?for=team`;
      }
      break;
    case 'tripDetails':
      link = `/dashboard/trips/${params.id}`;
      break;
    case 'reports':
      link = '/dashboard/reports';
      if (params.for && params.for === 'teamReports') {
        link += `?for=team`;
      }
      break;
    case 'reportTrips':
      link = `/dashboard/reports/${params.id}/trips`;
      break;
    case 'reportTripId':
      link = `/dashboard/reports/${params.reportId}/trips/${params.tripId}`;
      break;
    case 'reportRules':
      link = '/dashboard/report-rules';
      break;
    case 'billing':
      link = '/dashboard/billing';
      break;
    case 'billingOverview':
      link = '/dashboard/billing/overview';
      break;
    case 'billingPay':
      link = `/dashboard/billing/pay?plan=${params.plan}`;
      if (params.discount) {
        link += `&discount=${params.discount}`;
      }
      break;
    case 'billingChanges':
      link = `/dashboard/billing/pay?action=approve`;
      break;
    case 'managePlan':
      link = '/dashboard/manage';
      break;
    case 'help':
      link = '/dashboard/help';
      const queryString = {};
      if (params.id) {
        queryString.id = params.id;
      }
      if (params.lang) {
        queryString.lang = params.lang;
      }
      if (params.search) {
        queryString.search = params.search;
      }

      if (!isEmpty(queryString)) {
        link += `?${qs.stringify(queryString)}`;
      }
      break;
    case 'teams':
      link = '/dashboard/teams';
      break;
    case 'teamMembers':
      link = `/dashboard/teams/${params.id}/members`;
      break;
    case 'vehicles':
      link = '/dashboard/vehicles';
      break;
    case 'vehicleDetails':
      link = `/dashboard/vehicles/${params.id}`;
      break;
    case 'beaconsOrder':
      link = '/dashboard/beacons/order';
      break;
    case 'beaconsIntro':
      link = '/dashboard/beacons';
      break;
    case 'beaconOrderDetails':
      link = `/dashboard/beacons/${params.id}`;
      break;
    default:
      break;
  }

  return link;
};
export const getPublicLinkFor = (forPage, params = {}) => {
  let link = '/';
  switch (forPage) {
    case 'login':
      link = '/login';
      if (params['invitation-code']) {
        link += `?invitation-code=${params['invitation-code']}`;
      }
      break;
    case 'solutionsPro':
      link = '/pro';
      break;
    case 'solutionsEnterprise':
      link = '/enterprise';
      break;
    case 'help':
      link = '/help';
      const queryString = {};
      if (params.hid) {
        queryString.hid = params.hid;
      }
      if (params.lang) {
        queryString.lang = params.lang;
      }
      if (params.search) {
        queryString.search = params.search;
      }

      if (!isEmpty(queryString)) {
        link += `?${qs.stringify(queryString)}`;
      }
      break;
    default:
      break;
  }

  return link;
};

export const getProfileMenuItems = (
  isMobile,
  isManager,
  isAdmin,
  userInfo,
  accountPendingActions,
  hasTeams,
  permissionsInTeam
) => {
  let menuItems = [
    {
      key: 'account',
      text: i18n.t('dashboard.account'),
      hasBadge: true,
      link: getDashboardLinkFor('account'),
      showBadge: accountPendingActions > 0,
      badgeCount: accountPendingActions
    }
  ];

  // if (isMobile) {
  //   menuItems = [
  //     {
  //       key: 'trips',
  //       text: i18n.t('dashboard.trips'),
  //       hasBadge: true,
  //       link: getDashboardLinkFor('trips')
  //     },
  //     {
  //       key: 'reports',
  //       text: i18n.t('dashboard.reports'),
  //       hasBadge: true,
  //       link: getDashboardLinkFor('reports')
  //     },
  //     {
  //       key: 'report-rules',
  //       text: i18n.t('dashboard.report-rules'),
  //       hasBadge: false,
  //       link: getDashboardLinkFor('reportRules')
  //     }
  //   ].concat(menuItems);
  // }

  if (!isMobile && isManager && hasTeams) {
    menuItems.push({
      key: 'teams',
      text: i18n.t('dashboard.teams'),
      hasBadge: false,
      link: getDashboardLinkFor('teams')
    });
  }

  if (!hasTeams || (SHOW_MANAGE_FOR_ENTERPRISE && isAdmin)) {
    menuItems.push({
      key: 'manage-plan',
      text: i18n.t('dashboard.managePlan'),
      hasBadge: false,
      link: getDashboardLinkFor('managePlan')
    });
  }

  if ((hasTeams && isAdmin) || (!hasTeams && userInfo.hasCustomer)) {
    menuItems.push({
      key: 'billing',
      text: i18n.t('dashboard.billing'),
      hasBadge: false,
      link: getDashboardLinkFor('billing')
    });
  }

  if (!hasTeams || (hasTeams && permissionsInTeam.manageVehicles)) {
    menuItems.push({
      key: 'beacons',
      text: i18n.t('dashboard.orderBeacons'),
      hasBadge: false,
      link:
        userInfo.beaconCount > 0
          ? getDashboardLinkFor('beaconsOrder')
          : getDashboardLinkFor('beaconsIntro')
    });
  }

  menuItems.push({
    key: 'help',
    text: i18n.t('dashboard.help'),
    hasBadge: false,
    link: getDashboardLinkFor('help')
  });

  return menuItems;
};

export const getFullAddress = (addrObj, countryOptions) => {
  let addr = '';
  if (addrObj.company) {
    addr += `${addrObj.company}, `;
  }
  if (addrObj.first_name) {
    addr += `${addrObj.first_name} ${addrObj.last_name}<br/>`;
  }
  if (addrObj.street_address) {
    addr += `${addrObj.street_address}`;
  }
  if (addrObj.extended_address) {
    addr += `, ${addrObj.extended_address}`;
  }
  if (addrObj.postal_code) {
    addr += `, ${addrObj.postal_code}`;
  }
  if (addrObj.locality) {
    addr += ` ${addrObj.locality}`;
  }
  if (addrObj.region) {
    addr += ` ${addrObj.region}`;
  }
  if (addrObj.country_code_alpha2) {
    const { text: country } = countryOptions.find(
      obj => obj.key === addrObj.country_code_alpha2
    );
    addr += `, ${country}`;
  }

  return addr;
};
export const getTaxRegionFromTaxCountry = country => {
  if (EUCountries.includes(country.toUpperCase())) {
    return 'EU';
  }

  return country;
};

export const getSameDayTripsOccurrences = (list = [], selectedTripIds = []) => {
  const datesMap = {};
  const selectedTrips = list.filter(({ id }) => selectedTripIds.includes(id));

  if (!selectedTrips.length) {
    return [];
  }

  for (let trip of selectedTrips) {
    const date = moment.unix(trip.departure_time).format('YYYY/MM/DD');

    if (!datesMap[date]) {
      if (Object.keys(datesMap).length > 0) {
        return [];
      }
      datesMap[date] = [trip.id];
    } else {
      datesMap[date].push(trip.id);
    }
  }

  return Object.entries(datesMap).filter(([, ids]) => ids.length > 1);
};
