import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import SectionTitle from '../SectionTitle';
import FeatureCard from '../FeatureCard';

const FeaturesSection = ({ sectionSubTitle, featuresList, t }) => {
  return (
    <div className="featuresSection" id="features">
      <div className="section">
        <div className="titleContainer">
          <SectionTitle secondLine={t(`home.pro.features.title`)} />
          {sectionSubTitle && (
            <div className="sectionSubTitle">{sectionSubTitle}</div>
          )}
        </div>
        <div className="featuresList">
          {featuresList.map(feature => (
            <FeatureCard key={feature.featureKey} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
};

FeaturesSection.propTypes = {
  sectionSubTitle: PropTypes.string,
  t: PropTypes.func.isRequired,
  featuresList: PropTypes.array
};

export default translate()(FeaturesSection);
