import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../SectionTitle';
import UpgradeCard from './UpgradeCard';

const UpgradeProSection = ({
  t,
  plans,
  userLoggedIn,
  currentSubscription,
  trackEvent
}) => {
  return (
    <div className="upgradeProSection" id="pricing">
      <div className="section">
        <div className="left">
          <SectionTitle secondLine={t('home.plans.titlePartTwo')} white />
          <div className="perksContainer">
            <div className="perksInfo">{t(`home.pro.upgrade.description`)}</div>
            <ul
              className="perksList"
              dangerouslySetInnerHTML={{
                __html: t('home.pro.upgrade.list.items')
              }}
            />
          </div>
        </div>

        <div className="right">
          <UpgradeCard
            t={t}
            plans={plans}
            userLoggedIn={userLoggedIn}
            currentSubscription={currentSubscription}
            trackEvent={trackEvent}
          />
        </div>
      </div>
    </div>
  );
};

UpgradeProSection.propTypes = {
  plans: PropTypes.array,
  userLoggedIn: PropTypes.bool,
  currentSubscription: PropTypes.object,
  trackEvent: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default UpgradeProSection;
