import { LIST_LIMITS } from './constants';
import { newTripRequestPayloadTransformer } from './transformers/requestTransformer';
import { recentPlacesResponseTransformer } from './transformers/responseTransformer';

export const URLs = {
  common: {
    getAppStats: (start_ts, end_ts) => ({
      method: 'GET',
      url: 'stats' + `?start_date=${start_ts},${end_ts}`
    }),
    getCurrencyForCountry: countryCode => ({
      method: 'GET',
      url: `currency-list?country=${countryCode}`
    }),
    getStatesForCountry: countryCode => ({
      method: 'GET',
      url: `state?country-code=${countryCode}`
    })
  },
  reportRules: {
    getReportRules: (forTeam, teamId) => {
      let url = forTeam
        ? `teams/${teamId}/report-rules`
        : `users/<user_id>/periodic_reports`;
      url += `?limit=${LIST_LIMITS.TWENTY_FIVE}`;

      return {
        method: 'GET',
        url
      };
    },
    getReportRule: id => ({
      method: 'GET',
      url: `users/<user_id>/periodic_reports/${id}`
    }),
    create: (forTeam, teamId) => ({
      method: 'POST',
      url: forTeam
        ? `teams/${teamId}/report-rules`
        : `users/<user_id>/periodic_reports`
    }),
    update: (ruleId, forTeam, teamId) => ({
      method: 'PATCH',
      url: forTeam
        ? `teams/${teamId}/report-rules/${ruleId}`
        : `users/<user_id>/periodic_reports/${ruleId}`
    }),
    delete: (ruleId, forTeam, teamId) => ({
      method: 'DELETE',
      url: forTeam
        ? `teams/${teamId}/report-rules/${ruleId}`
        : `users/<user_id>/periodic_reports/${ruleId}`
    })
  },
  reports: {
    getReports: (forTeam, teamId, queryString) => {
      let url = forTeam ? `teams/${teamId}/reports` : 'reports';
      url += `?${queryString}`;

      return {
        method: 'GET',
        url
      };
    },
    getDetails: id => ({
      method: 'GET',
      url: `reports/${id}`
    }),
    create: teamId => ({
      method: 'POST',
      url: teamId ? `teams/${teamId}/reports` : `reports`
    }),
    update: reportId => ({
      method: 'PUT',
      url: `reports/${reportId}`
    }),
    delete: reportId => ({
      method: 'DELETE',
      url: `reports/${reportId}`
    }),
    deleteMultiple: (reportIds, teamId) => ({
      method: 'DELETE',
      url: teamId
        ? `teams/${teamId}/reports?ids=[${reportIds}]`
        : `users/<user_id>/reports?ids=[${reportIds}]`
    }),
    export: (reportId, queryString) => {
      return {
        method: 'GET',
        url: `reports/${reportId}/export?${queryString}`
      };
    },
    getTrips: (reportId, limit) => ({
      method: 'GET',
      url: `reports/${reportId}/trips?limit=${limit}`
    })
  },
  trips: {
    getTrips: (forTeam, teamId, queryString) => {
      let url = forTeam ? `teams/${teamId}/trips` : 'trips';
      url += `?${queryString}`;

      return {
        method: 'GET',
        url
      };
    },
    getDetails: tripId => ({
      method: 'GET',
      url: `trips/${tripId}`
    }),
    getMultipleTripsDetails: tripIds => ({
      method: 'GET',
      url: `trips/details?id__in=${tripIds.join(',')}`
    }),
    abortTrip: tripId => ({
      method: 'POST',
      url: `trips/${tripId}/abort`
    }),
    abortTrips: () => ({
      method: 'POST',
      url: `trips/abort`
    }),
    abortTeamTrips: teamId => ({
      method: 'PUT',
      url: `teams/${teamId}/trips/abort`
    }),
    restoreTrip: tripId => ({
      method: 'POST',
      url: `trips/restore?id=${tripId}`
    }),
    restoreTrips: tripIds => ({
      method: 'POST',
      url: `trips/restore?id=${tripIds}`
    }),
    restoreTeamTrips: teamId => ({
      method: 'PUT',
      url: `teams/${teamId}/trips/restore`
    }),
    exportList: (forTeam, teamId, queryString) => {
      let url = forTeam ? `teams/${teamId}/trips/export` : `trips/export`;
      url += `?${queryString}`;

      return {
        method: 'GET',
        url
      };
    },
    updateStep: (tripId, stepId) => ({
      method: 'POST',
      url: `trips/${tripId}/steps/${stepId}/update`
    }),
    createNewTrip: () => ({
      method: 'POST',
      url: `trips/completed`,
      requestPayloadTransformer: newTripRequestPayloadTransformer
    }),
    getRecentPlaces: ({ userId, searchTerm, limit = 3 }) => ({
      method: 'GET',
      url: `destinations/view?search=${searchTerm}&limit=${limit}`,
      responseTransformer: recentPlacesResponseTransformer
    }),
    mergeTrips: () => ({
      method: 'POST',
      url: `trips/merge`
    })
  },
  beacon: {
    getBeaconOrderList: () => ({
      method: 'GET',
      url: `premium/orders?fields=id,products,total,invoice,order_datetime&limit=100`
    }),
    getBeaconsList: () => ({
      method: 'GET',
      url: `users/<user_id>/beacons`
    }),
    getBeaconOrderDetails: orderId => ({
      method: 'GET',
      url: `premium/orders/${orderId}`
    }),
    getBeaconDetails: uuid => ({
      method: 'GET',
      url: `users/<user_id>/beacons/${uuid}`
    }),
    getBeaconProducts: () => ({
      method: 'GET',
      url: 'premium/products'
    }),
    placeOrder: () => ({
      method: 'POST',
      url: 'premium/orders'
    }),
    deletePendingOrder: pid => ({
      method: 'DELETE',
      url: `premium/orders?pid=${pid}`
    })
  },
  customer: {
    createCustomer: () => ({
      method: 'POST',
      url: `premium/customer`
    }),
    getCustomer: () => ({
      method: 'GET',
      url: `premium/customer`
    }),
    updateCustomer: () => ({
      method: 'PUT',
      url: `premium/customer`
    }),
    getCustomerAddressList: () => ({
      method: 'GET',
      url: `premium/customer/address`
    }),
    updateCustomerAddress: addressId => ({
      method: 'PUT',
      url: `premium/customer/address/${addressId}`
    }),
    deleteCustomerAddress: addressId => ({
      method: 'DELETE',
      url: `premium/customer/address/${addressId}`
    }),
    addPaymentMethod: () => ({
      method: 'POST',
      url: 'premium/customer/payment-methods'
    }),
    deletePaymentMethod: token => ({
      method: 'DELETE',
      url: `premium/customer/payment-methods/${token}`
    }),
    setPrimaryPaymentMethod: token => ({
      method: 'PATCH',
      url: `premium/customer/payment-methods/${token}`
    }),
    cancelSubscription: subscriptionId => ({
      method: 'PUT',
      url: `users/<user_id>/subscription/${subscriptionId}`
    }),
    getBillingHistory: () => ({
      method: 'GET',
      url: 'users/<user_id>/billing'
    }),
    getBraintreeToken: currency => ({
      method: 'GET',
      url: `premium/customer/token?currency_code=${currency}`
    }),
    getVaultedPaymentMethodNonce: token => ({
      method: 'GET',
      url: `premium/customer/payment-methods/${token}/nonce`
    })
  },
  vehicles: {
    getUserVehicles: (hidden = false) => ({
      method: 'GET',
      url: `users/<user_id>/vehicles?hidden=${hidden}`
    }),
    createUserVehicle: () => ({
      method: 'POST',
      url: 'users/<user_id>/vehicles/add'
    }),
    updateUserVehicle: vehicleId => ({
      method: 'POST',
      url: `users/<user_id>/vehicles/${vehicleId}/update`
    }),
    getVehicleClasses: () => ({
      method: 'GET',
      url: 'vehicles/class'
    }),
    getYears: () => ({
      method: 'GET',
      url: 'vehicles/year'
    }),
    getMakes: year => ({
      method: 'GET',
      url: `vehicles/make${year ? `?year=${year}` : ''}`
    }),
    getModels: (make, year) => {
      let url = `vehicles/`;
      if (year && make) {
        url += `model?make=${make}&year=${year}`;
      } else if (!year && make) {
        url += `model?make=${make}`;
      }

      return {
        method: 'GET',
        url
      };
    },
    getVehicles: (year, make, model) => {
      let url = `vehicles`;
      if (year && make && model) {
        url += `?year=${year}&make=${make}&model=${model}`;
      }
      return {
        method: 'GET',
        url
      };
    }
  },
  odometer: {
    addOdometerValue: vehicleId => ({
      method: 'POST',
      url: `vehicles/${vehicleId}/odometer`
    }),
    deleteOdometerValue: (vehicleId, odometerId) => ({
      method: 'DELETE',
      url: `vehicles/${vehicleId}/odometer/${odometerId}`
    })
  },
  mileageRates: {
    getUserMileageRates: () => ({
      method: 'GET',
      url: `users/<user_id>/mileagerates`
    }),
    addToVehicle: () => ({
      method: 'POST',
      url: 'users/<user_id>/mileagerates'
    }),
    updateMileageRate: mileageRateId => ({
      method: 'PUT',
      url: `users/<user_id>/mileagerates/${mileageRateId}`
    })
  },
  user: {
    updateUser: () => ({
      method: 'POST',
      url: `users/update`
    }),
    deleteUser: () => ({
      method: 'DELETE',
      url: `users/delete`
    }),
    transferUserData: () => ({
      method: 'POST',
      url: `users/transfer`
    }),
    getUserDetails: () => ({
      method: 'GET',
      url: `users/<user_id>/details?no-activity=true`
    }),
    resetPassword: () => ({
      method: 'POST',
      url: `users/reset_password`
    }),
    sendConfirmationEmail: () => ({
      method: 'POST',
      url: `users/<user_id>/send_confirmation_email`
    }),
    updateProfileImage: () => ({
      method: 'POST',
      url: `users/image`
    }),
    deleteProfileImage: () => ({
      method: 'POST',
      url: `users/image/delete`
    }),
    login: () => ({
      method: 'POST',
      url: 'users/login'
    }),
    createUser: () => ({
      method: 'POST',
      url: 'users/create'
    }),
    authentitateOTPForUser: () => ({
      method: 'PUT',
      url: 'users/authenticate'
    }),
    checkEmail: email => ({
      method: 'GET',
      url: `users/check?email=${email}`
    }),
    getInviteCodeDetails: inviteCode => ({
      method: 'GET',
      url: `team-invitation/${inviteCode}`
    }),
    getSettings: () => ({
      method: 'GET',
      url: 'users/settings/view'
    }),
    updateSettings: () => ({
      method: 'POST',
      url: 'users/settings/update'
    })
  },
  company: {
    create: () => ({
      method: 'POST',
      url: 'company'
    }),
    getDetails: fields => ({
      method: 'GET',
      url: fields ? `company?fields=${fields.join(',')}` : 'company'
    }),
    getCompanyAddresses: () => ({
      method: 'GET',
      url: 'company/address'
    }),
    updateDetails: () => ({
      method: 'PATCH',
      url: 'company'
    }),
    addAddress: () => ({
      method: 'POST',
      url: 'company/address'
    }),
    updateAddress: addressId => ({
      method: 'PUT',
      url: `company/address/${addressId}`
    }),
    deleteAddress: addressId => ({
      method: 'DELETE',
      url: `company/address/${addressId}`
    }),
    updateCompanyLogo: () => ({
      method: 'PUT',
      url: 'company/logo'
    })
  },
  tags: {
    getTagsList: () => ({
      method: 'GET',
      url: `trips/tags/view`
    }),
    addTagsToTrip: () => ({
      method: 'POST',
      url: `trips/tags/add`
    }),
    removeTagsFromTrip: () => ({
      method: 'POST',
      url: `trips/tags/remove`
    }),
    setNotesAndTagsOfTrip: tripId => ({
      method: 'PATCH',
      url: `trips/${tripId}`
    })
  },
  help: {
    getHelpPageData: () => ({
      method: 'GET',
      url: `web/faq`
    })
  },
  plans: {
    getAllPlans: (fetchAll = true) => ({
      method: 'GET',
      url: `premium/plans?fetch-all=${fetchAll}`
    }),
    getSpecificPlan: planId => ({
      method: 'GET',
      url: `premium/plans?fetch-all=true&plan_id=${planId}`
    }),
    verifyPromoCodeForPlan: (planId, code) => ({
      method: 'GET',
      url: `premium/plans?fetch-all=true&plan_id=${planId}&discount=${code}`
    }),
    buySubscription: forEnterprise => ({
      method: 'POST',
      url: forEnterprise ? 'premium/company' : 'users/<user_id>/pay/braintree'
    }),
    updateSubscriptionPrice: subscription_id => ({
      method: 'POST',
      url: `premium/subscription/${subscription_id}/update`
    }),
    getCompanySubscription: () => ({
      method: 'GET',
      url: 'premium/company'
    }),
    updateCompanySubscription: () => ({
      method: 'PATCH',
      url: 'premium/company'
    })
  },
  teams: {
    getList: companyId => ({
      method: 'GET',
      url: `company/${companyId}/teams?ordering=name`
    }),
    create: companyId => ({
      method: 'POST',
      url: `company/${companyId}/teams`
    }),
    update: (companyId, teamId) => ({
      method: 'PUT',
      url: `company/${companyId}/teams/${teamId}`
    }),
    delete: (companyId, teamId) => ({
      method: 'DELETE',
      url: `company/${companyId}/teams/${teamId}`
    })
  },
  teamMembers: {
    getList: teamId => ({
      method: 'GET',
      url: `teams/${teamId}/users?limit=20`
    }),
    delete: (teamId, memberId) => ({
      method: 'DELETE',
      url: `teams/${teamId}/users/${memberId}`
    }),
    invite: teamId => ({
      method: 'POST',
      url: `teams/${teamId}/users`
    }),
    resendInvitation: (teamId, memberId) => ({
      method: 'POST',
      url: `teams/${teamId}/users/${memberId}/invites`
    }),
    updateRoles: (teamId, memberId) => ({
      method: 'PUT',
      url: `teams/${teamId}/users/${memberId}/roles/bulk`
    })
  },
  promotions: {
    getPromotions: () => ({
      method: 'GET',
      url: 'promotions'
    })
  }
};
