import {
  ACTION_TYPE_TRIP_SAVE,
  ACTION_TRIPS_MERGE_MODAL_OPEN,
  ACTION_GET_TRIPS_TO_MERGE,
  ACTION_TYPE_TRIPS_MERGE,
  ACTION_TYPE_UPDATE_TRIPS_TO_MERGE
} from '../actions/trips';

const initialState = {};

export const ui = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPE_TRIP_SAVE.process:
      return {
        ...state,
        trip: { ...(state.trip || {}), isSaveLoading: payload }
      };
    case ACTION_TYPE_TRIP_SAVE.error:
      return { ...state, trip: { ...(state.trip || {}), saveError: payload } };
    case ACTION_TRIPS_MERGE_MODAL_OPEN.success:
      return {
        ...state,
        isTripsMergeModalOpen: payload,
        tripsMergeForm: payload ? state.tripsMergeForm : {}
      };
    case ACTION_GET_TRIPS_TO_MERGE.process:
      return {
        ...state,
        tripsMergeForm: {
          ...state.tripsMergeForm,
          isGetTripsToMergeLoading: payload
        }
      };
    case ACTION_GET_TRIPS_TO_MERGE.success:
      return {
        ...state,
        tripsMergeForm: {
          ...state.tripsMergeForm,
          isGetTripsToMergeLoading: false,
          ...payload
        }
      };
    case ACTION_GET_TRIPS_TO_MERGE.error:
      return {
        ...state,
        tripsMergeForm: {
          ...state.tripsMergeForm,
          isGetTripsToMergeLoading: false
        }
      };
    case ACTION_TYPE_TRIPS_MERGE.process:
      return {
        ...state,
        tripsMergeForm: {
          ...state.tripsMergeForm,
          isMergeTripsLoading: payload
        }
      };
    case ACTION_TYPE_TRIPS_MERGE.error:
      return {
        ...state,
        tripsMergeForm: {
          ...state.tripsMergeForm,
          isMergeTripsLoading: false,
          error: payload
        }
      };
    case ACTION_TYPE_TRIPS_MERGE.success:
      return {
        ...state,
        tripsMergeForm: {
          ...state.tripsMergeForm,
          isMergeTripsLoading: false
        }
      };
    case ACTION_TYPE_UPDATE_TRIPS_TO_MERGE.success:
      return {
        ...state,
        tripsMergeForm: {
          ...state.tripsMergeForm,
          trips: payload
        }
      };
    default:
      return state;
  }
};
