import React from 'react';
import PropTypes from 'prop-types';
import getSymbolFromCurrency from 'currency-symbol-map';

import { Currency } from '../../Units';
import SectionTitle from '../SectionTitle';

import { isEmpty, getDashboardLinkFor } from '../../../utils';
import { MAIL_TO_LINKS } from '../../../utils/constants';

const PlansSectionMobile = ({
  plans,
  trackEvent,
  trackEventForCustomPlan,
  t
}) => {
  const plansToShow = plans.reduce(
    (acc, plan) => {
      if (plan.default_employee_count === 2) {
        acc.silver = plan;
      } else if (plan.default_employee_count === 5) {
        acc.gold = plan;
      }
      return acc;
    },
    {
      silver: {},
      gold: {}
    }
  );

  return (
    <div className="plansSection mobile" id="pricing">
      <div className="section">
        <div className="titleContainer">
          <SectionTitle secondLine={t(`home.enterprise.plans.titlePartTwo`)} />
          <div className="sectionSubTitle">
            {t(`home.enterprise.plans.subtitle`)}
          </div>
        </div>

        <div className="plansContainer">
          <a
            href={getDashboardLinkFor('managePlan')}
            className="planContainer"
            onClick={trackEvent}
          >
            <div className="basicInfo">
              <div className="details">
                <div className="planName">{plansToShow.silver.name}</div>
                <div className="employeeCount">
                  <span className="bold">
                    {plansToShow.silver.default_employee_count}
                  </span>
                  <span>{t('home.enterprise.plans.employees')}</span>
                </div>
              </div>
              <div className="priceWrapper">
                <div className="price">
                  <span className="currency">
                    {getSymbolFromCurrency(plansToShow.silver.currency)}
                  </span>
                  <span className="value">
                    {Math.round(plansToShow.silver.base_price)}
                  </span>
                </div>
                <div className="frequency">
                  {t('home.plans.plans.free.perMonth')}
                </div>
              </div>
            </div>
            <div className="addOnInfo">
              {!isEmpty(plansToShow.silver) ? (
                <React.Fragment>
                  <span className="inline">+</span>
                  <Currency
                    iso={plansToShow.silver.currency}
                    value={
                      plansToShow.silver.add_ons[0].amount /
                      Math.max(plansToShow.silver.add_ons[0].quantity, 1)
                    }
                    round={2}
                  />
                  <span>{t(`home.enterprise.plans.perExtraEmployee`)}</span>
                </React.Fragment>
              ) : (
                ''
              )}
            </div>
          </a>

          <a
            href={getDashboardLinkFor('managePlan')}
            className="planContainer"
            onClick={trackEvent}
          >
            <div className="basicInfo">
              <div className="details">
                <div className="planName">{plansToShow.gold.name}</div>
                <div className="employeeCount">
                  <span className="bold">
                    {plansToShow.gold.default_employee_count}
                  </span>
                  <span>{t('home.enterprise.plans.employees')}</span>
                </div>
              </div>
              <div className="priceWrapper">
                <div className="price">
                  <span className="currency">
                    {getSymbolFromCurrency(plansToShow.gold.currency)}
                  </span>
                  <span className="value">
                    {Math.round(plansToShow.gold.base_price)}
                  </span>
                </div>
                <div className="frequency">
                  {t('home.plans.plans.free.perMonth')}
                </div>
              </div>
            </div>
            <div className="addOnInfo">
              {!isEmpty(plansToShow.gold) ? (
                <React.Fragment>
                  <span className="inline">+</span>
                  <Currency
                    iso={plansToShow.gold.currency}
                    value={
                      plansToShow.gold.add_ons[0].amount /
                      Math.max(1, plansToShow.gold.add_ons[0].quantity)
                    }
                    round={2}
                  />
                  <span>{t(`home.enterprise.plans.perExtraEmployee`)}</span>
                </React.Fragment>
              ) : (
                ''
              )}
            </div>
          </a>

          <a
            href={MAIL_TO_LINKS.CONTACT_FOR_CUSTOM_PLANS}
            className="planContainer"
            onClick={trackEventForCustomPlan}
          >
            <div className="basicInfo">
              <div className="details">
                <div className="planName">Platinum</div>
                <div className="employeeCount">
                  <span className="bold">50+</span>
                  <span>{t('home.enterprise.plans.employees')}</span>
                </div>
              </div>
              <div className="priceWrapper">
                <div className="customPrice">
                  {t(`home.enterprise.plans.customPricing`)}
                </div>
              </div>
            </div>
            <div className="addOnInfo">
              <span>{t(`home.enterprise.plans.contactUs`)}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

PlansSectionMobile.propTypes = {
  plans: PropTypes.array,
  trackEvent: PropTypes.func,
  trackEventForCustomPlan: PropTypes.func,
  t: PropTypes.func
};

export default PlansSectionMobile;
