import {
  SET_USER_SETTINGS,
  UPDATE_USER_SETTING,
  HAS_TRANSFER_REQUEST
} from '../actions/userSettings';

const initialState = {};

export const userSettings = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_SETTINGS:
      return action.settings;

    case UPDATE_USER_SETTING:
      return Object.assign({}, state, action.settingToBeUpdated);

    case HAS_TRANSFER_REQUEST:
      return Object.assign({}, state, {
        ...state,
        transferRequestEmail: action.transferRequestEmail
      });

    default:
      return state;
  }
};
