import React from 'react';
import PropTypes from 'prop-types';

const StoreButtons = ({
  showAndroidBtn,
  showIBtn,
  language,
  trackEventForDownload
}) => (
  <React.Fragment>
    {showIBtn && (
      <a
        href="https://itunes.apple.com/us/app/psngr-mileage-tracker/id871087584"
        target="_blank"
        rel="noopener noreferrer"
        className="action appStoreLink storeLinkBtn"
        onClick={() => trackEventForDownload('ios')}
      >
        <img
          alt="Get it on App Store"
          src={`/images/landing/AppStoreBadge${language.toUpperCase()}.svg`}
        />
      </a>
    )}
    {showAndroidBtn && (
      <a
        href="https://play.google.com/store/apps/details?id=nl.hgrams.passenger&utm_source=psngrco&utm_campaign=landing&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        target="_blank"
        rel="noopener noreferrer"
        className="action playStoreLink storeLinkBtn"
        onClick={() => trackEventForDownload('android')}
      >
        <img
          alt="Get it on Google Play"
          src={`https://play.google.com/intl/en_us/badges/images/generic/${language}_badge_web_generic.png`}
        />
      </a>
    )}
  </React.Fragment>
);

StoreButtons.propTypes = {
  showAndroidBtn: PropTypes.bool,
  showIBtn: PropTypes.bool,
  language: PropTypes.string,
  trackEventForDownload: PropTypes.func
};

StoreButtons.defaultProps = {
  showAndroidBtn: true,
  showIBtn: true,
  language: 'en',
  trackEventForDownload: () => {}
};

export default StoreButtons;
