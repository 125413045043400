import { createSelector } from 'reselect';
import _remove from 'lodash/remove';

import { isEmpty } from '../utils';

export const getCustomer = state => state.customer;

export const getCustomerAddressList = createSelector(
  [getCustomer],
  customer => customer.addressList
);

export const getUserAddresses = createSelector([getCustomer], customer => {
  const addressList = [];
  if (customer.addressList && customer.addressList.length) {
    customer.addressList.reduce((acc, addr) => {
      acc.push(
        Object.assign({}, addr, {
          firstName: addr.first_name,
          lastName: addr.last_name,
          address: addr.street_address,
          postalCode: addr.postal_code,
          country: addr.country_code_alpha2,
          isBillingAddress: addr.is_billing,
          isShippingAddress: addr.is_shipping
        })
      );

      return acc;
    }, addressList);
  }

  const billingAddr = _remove(
    addressList,
    addr => addr.isBillingAddress === true
  );
  const shippingAddr = _remove(
    addressList,
    addr => addr.isShippingAddress === true
  );

  return [...billingAddr, ...shippingAddr, ...addressList];
});

export const getCustomerBillingAddress = createSelector(
  [getCustomer],
  customer => {
    let { billing_address: billingAddr } = customer;
    if (!isEmpty(billingAddr)) {
      billingAddr = {
        firstName: billingAddr.first_name,
        lastName: billingAddr.last_name,
        address: billingAddr.street_address,
        postalCode: billingAddr.postal_code,
        country: billingAddr.country_code_alpha2,
        company: billingAddr.company,
        region: billingAddr.region,
        locality: billingAddr.locality
      };
    }

    return billingAddr;
  }
);

export const getCustomerShippingAddress = createSelector(
  [getCustomer],
  customer => {
    let { shipping_address: shippingAddr } = customer;
    if (!isEmpty(shippingAddr)) {
      shippingAddr = {
        firstName: shippingAddr.first_name,
        lastName: shippingAddr.last_name,
        address: shippingAddr.street_address,
        postalCode: shippingAddr.postal_code,
        country: shippingAddr.country_code_alpha2,
        company: shippingAddr.company,
        region: shippingAddr.region,
        locality: shippingAddr.locality
      };
    }

    return shippingAddr;
  }
);

export const listPaymentMethods = createSelector([getCustomer], customer => {
  const {
    payment_methods: {
      credit_cards: creditCards = [],
      paypal_accounts: paypals = []
    } = {}
  } = customer;
  return creditCards
    .map(method => ({
      type: 'card',
      cardType: method.card_type,
      primary: method.default,
      value: `•••• •••• •••• ${method.last_4}`,
      token: method.token,
      created: method.created,
      image: method.image_url,
      accountOwnerName: method.cardholder_name,
      expiry: `${method.expiration_month}.${method.expiration_year}`
    }))
    .concat(
      paypals.map(method => ({
        type: 'paypal',
        primary: method.default,
        value: method.email,
        token: method.token,
        created: method.created
      }))
    )
    .sort((a, b) => b.primary - a.primary || a.created - b.created);
});

export const getCustomerBillingCountry = state => {
  const {
    billing_address: { country_code_alpha2: country } = {}
  } = state.customer;
  return country;
};
