import i18n from '../utils/i18n';

import Excel from '../images/icons/excel-icon.png';
import Csv from '../images/icons/csv-icon.png';
import Pdf from '../images/icons/pdf-icon.png';
// local payment method icons
import iDeal from '../images/icons/ideal-logo.svg';
import giropay from '../images/icons/giropay.svg';
import eps from '../images/icons/eps-logo.svg';
import MyBank from '../images/icons/mybank.svg';
import Bancontact from '../images/icons/bancontact.svg';

export const API_VERSION = 3;
export const languageOptions = [
  { key: 'de', value: 'de', text: 'Deutsch', flag: 'de' },
  { key: 'en', value: 'en', text: 'English', flag: 'gb' },
  { key: 'es', value: 'es', text: 'Español', flag: 'es' },
  { key: 'fr', value: 'fr', text: 'Français', flag: 'fr' },
  { key: 'it', value: 'it', text: 'Italiano', flag: 'it' },
  { key: 'nl', value: 'nl', text: 'Nederlands', flag: 'nl' }
];
export const days = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
];
export const months = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER'
];
export const calendarMonths = () => [
  i18n.t(`months.january`),
  i18n.t(`months.february`),
  i18n.t(`months.march`),
  i18n.t(`months.april`),
  i18n.t(`months.may`),
  i18n.t(`months.june`),
  i18n.t(`months.july`),
  i18n.t(`months.august`),
  i18n.t(`months.september`),
  i18n.t(`months.october`),
  i18n.t(`months.november`),
  i18n.t(`months.december`)
];
export const weekDays = () => [
  i18n.t(`days.sunday`),
  i18n.t(`days.monday`),
  i18n.t(`days.tuesday`),
  i18n.t(`days.wednesday`),
  i18n.t(`days.thursday`),
  i18n.t(`days.friday`),
  i18n.t(`days.saturday`)
];
export const shortWeekDays = () => [
  i18n.t(`days.sunday`).slice(0, 2),
  i18n.t(`days.monday`).slice(0, 2),
  i18n.t(`days.tuesday`).slice(0, 2),
  i18n.t(`days.wednesday`).slice(0, 2),
  i18n.t(`days.thursday`).slice(0, 2),
  i18n.t(`days.friday`).slice(0, 2),
  i18n.t(`days.saturday`).slice(0, 2)
];
export const travelModes = [
  'DRIVING',
  'BICYCLING',
  'TRANSIT',
  'WALKING',
  'FLYING'
];
export const aggregateReportOptions = () => [
  { text: i18n.t(`list.allTeamMembers`), value: 1 },
  { text: i18n.t(`list.singleEmployee`), value: 0 }
];

export const columnNames = {
  CHECK: 'check',
  ID: 'id',

  // trips
  DATE: 'date',
  ADDRESS_FROM_TO: 'from_to',
  MODE_VEHICLE: 'mode_vehicle',
  TAGS_NOTES: 'tags_notes',
  TRAVELMODE: 'travelmode',
  CITY_FROM_TO: 'city_from_to',
  VEHICLE: 'vehicle',
  EXPENSES_RATE: 'expenses_rate',
  DISTANCE: 'distance',
  DURATION: 'duration',
  RATE: 'rate',
  EMISSIONS: 'emissions',
  MORE: 'more',
  START_ODOMETER: 'start_odometer',
  END_ODOMETER: 'end_odometer',
  // trips (teams)
  TEAM_TRIP_EMPLOYEE: 'employee_name',
  APPROVAL_STATUS: 'approval_status',
  REVISION: 'revision',

  // reports
  NAME: 'name',
  AGGREGATE: 'aggregate',
  REPORT_DATE_RANGE: 'report_date_range',
  FILTERS: 'filters',
  TRIPS: 'trips',
  REPORT_DISTANCE: 'report_distance',
  REPORT_EXPENSES: 'report_expenses',

  REPORTCREATEDATE: 'report_create_date',
  REPORTID: 'reportid',
  TITLE: 'title',
  // reports (teams)
  TEAM_REPORT_REPORTED_BY: 'team_report_reported_by',
  TEAM_REPORT_STATUS: 'team_report_status',

  // report rules
  FREQUENCY: 'frequency',
  AUTO_SUBMIT: 'auto_submit',
  ENABLED: 'enabled',

  // vehicles
  LICENSE: 'license',
  MAKE: 'make',
  MODEL: 'model',
  YEAR: 'year',
  BEACON: 'beacon',
  CURRENTODOMETER: 'current-odometer',
  LASTODOMETER: 'last-odometer',
  MILEAGERATE: 'mileage-rate',

  // teams
  TEAM_NAME: 'teamName',
  TEAM_MEMBERS: 'teamMembers',
  TEAM_COUNTRY: 'teamCountry',
  TEAM_LANGUAGE: 'teamLanguage',
  TEAM_DATE_FORMAT: 'teamDateFormat',
  TEAM_CURRENCY: 'TEAM_CURRENCY',

  // team members
  MEMBER_NAME: 'memberName',
  MEMBER_EMAIL: 'memberEmail',
  MEMBER_ROLES: 'memberRoles',
  MEMBER_INVITE_STATUS: 'memberInviteStatus',
  MEMBER_SINCE: 'memberSince'
};

export const TIME_FORMATS = {
  '24': '24h',
  '12': '12h'
};

export const timeFormatOptions = () => {
  return [
    { text: i18n.t('settings.timeFormat.24Hours'), value: TIME_FORMATS['24'] },
    { text: i18n.t('settings.timeFormat.12Hours'), value: TIME_FORMATS['12'] }
  ];
};

export const amountOptions = (() => {
  const options = [];
  for (let i = 0; i < 10; i++) {
    options.push({ key: `${i + 1}`, value: `${i + 1}`, text: `${i + 1}` });
  }
  return options;
})();

export const REPORT_STATUS = {
  FLAGGED: 1,
  RESOLVED: 2,
  SUBMITTED: 3,
  NEW: 4,
  APPROVED: 5
};

export const LIST_LIMITS = {
  ONE: 1,
  TWENTY: 20,
  TWENTY_FIVE: 25
};

export const getDownloadMenuItems = () => {
  const items = [];
  items.push({
    key: 'xlsx',
    value: 'xlsx',
    text: 'Excel',
    image: Excel
  });
  items.push({
    key: 'csv',
    value: 'csv',
    text: 'CSV',
    image: Csv
  });
  items.push({
    key: 'pdf',
    value: 'pdf',
    text: 'PDF',
    image: Pdf
  });

  return items;
};

export const localPaymentMethods = {
  AT: { type: 'eps', img: eps },
  BE: { type: 'bancontact', img: Bancontact },
  DE: { type: 'giropay', img: giropay },
  IT: { type: 'mybank', img: MyBank },
  NL: { type: 'iDeal', img: iDeal }
};

export const getFixedColumnsForPicker = listType => {
  let fixedColumns = [];
  switch (listType) {
    case 'trips':
      fixedColumns = ['date', 'distance', 'expenses_rate'];
      break;
    default:
      break;
  }

  return fixedColumns;
};

// minimum flight distance in meters
export const MIN_FLIGHT_DISTANCE = 100000;
// minimum average speed for flight in meters per second
export const MIN_FLIGHT_SPEED = 140;

export const EUCountries = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB'
];

export const TAXAMO_SUPPORTED_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
  'NL'
];

export const COUNTRIES_WITH_KNOWN_FORMATTED_VAT = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'EL',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'CHE',
  'GB'
];

export const TAX_ID_SELF_DECLARATION_COUNTRIES = ['AU', 'CA', 'US'];

export const MAX_EXPORT_TRIPS_LIMIT = 200;

export const MAIL_TO_LINKS = {
  CONTACT_FOR_CUSTOM_PLANS: `mailto:sales@psngr.co?subject=${encodeURIComponent(
    'Psngr Enterprise - Platinum'
  )}&body=`,
  SUBSCRIPTION_PAST_DUE: `mailto:sales@psngr.co?subject=${encodeURIComponent(
    'Subscription Past-Due'
  )}&body=`,
  GENERAL_CONTACT: 'mailto:support@psngr.co',
  MFA_FAIL: `mailto:support@psngr.co?subject=${encodeURIComponent(
    'MFA email'
  )}`,
  PAYMENT_ERROR: `mailto:support@psngr.co?subject=${encodeURIComponent(
    'Payment error'
  )}&body=`,
  PAGE_NOT_LOADING: `mailto:support@psngr.co?subject=${encodeURIComponent(
    'Page Not Loading'
  )}&body=Hi, I can't load this page: ${window.location.href}`,
  REACTIVATE_ACCOUNT: `mailto:support@psngr.co?subject=${encodeURIComponent(
    'Reactivate account '
  )}`
};

export const BILLING_HISTORY_HEADERS = [
  'time.date',
  'billing.description',
  'billing.amount',
  'billing.invoice'
];
export const BEACON_ORDER_HISTORY_HEADERS = [
  'time.date',
  'billing.description',
  'beacon.quantity',
  'billing.amount',
  'billing.invoice'
];

export const HISTORY_TABLE_PER_PAGE = 10;

export const PLANS = {
  PRO_MONTH: 'co_psngr_monthly_eur',
  PRO_YEAR: 'co_psngr_yearly_eur',
  SILVER: 'monthly_team_eur_5',
  GOLD: 'monthly_team_eur_50'
};

export const SHOW_VEHICLES_TAB = true;
export const SHOW_MANAGE_FOR_ENTERPRISE = true;
export const SHOW_FREE_COMPANY_CREATE = true;
export const SHOW_BOP_FLAG = true;

export const GOOGLE_API_KEY = 'AIzaSyB8q_ZkvNXh0Y7oY0sxwEXgAZb2hY4hLAg';
export const DEFAULT_TIME_FORMAT = 'h:mm A';
export const NEW_TRIP_DATETIME_FORMAT = `MMMM Do YYYY, ${DEFAULT_TIME_FORMAT}`;
export const TRAVEl_MODES = {
  DRIVING: 'DRIVING',
  BICYCLING: 'BICYCLING',
  TRANSIT: 'TRANSIT',
  WALKING: 'WALKING',
  FLYING: 'FLYING'
};
export const TRANSIT_TYPES = {
  RAIL: 'RAIL',
  HEAVY_RAIL: 'HEAVY_RAIL',
  BUS: 'BUS',
  TRAIN: 'TRAIN',
  TRAM: 'TRAM',
  SUBWAY: 'SUBWAY'
};
export const TRAVEl_MODE_ICON_COLORS = {
  default: 'default',
  black: 'black',
  gray: 'gray'
};
