import {
  SET_SELECTED_TEAM,
  USER_HAS_TEAM_SUBSCRIPTION,
  USER_HAS_TEAMS,
  USER_HAS_NO_TEAMS
} from '../actions/teamUserInfo';

const initialState = {
  hasTeamSubscription: false,
  hasTeams: false,
  rolesInSelectedTeam: [],
  permissionsInSelectedTeam: {}
};

export const teamUserInfo = (state = initialState, action) => {
  switch (action.type) {
    case USER_HAS_TEAM_SUBSCRIPTION:
      return Object.assign({}, state, {
        hasTeamSubscription: true
      });

    case USER_HAS_TEAMS:
      return Object.assign({}, state, {
        hasTeams: true
      });

    case USER_HAS_NO_TEAMS:
      return Object.assign({}, state, {
        hasTeams: false
      });

    case SET_SELECTED_TEAM:
      return Object.assign({}, state, {
        selectedTeam: action.teamId,
        rolesInSelectedTeam: action.roles,
        permissionsInSelectedTeam: action.permissions
      });

    default:
      return state;
  }
};
