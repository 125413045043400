import i18n from '../utils/i18n';
import { checkUndefined } from '../utils';
import { isUndefined } from 'lodash';

export const getVclassId = vclassName => {
  switch (vclassName) {
    case 'Midsize car':
      return 1;
    case 'Large car':
      return 2;
    case 'Compact car':
      return 3;
    case 'Scooter':
      return 4;
    case 'Motorcycle':
      return 5;
    case 'SUV':
      return 6;
    case 'Van':
      return 7;
    case 'Pickup truck':
      return 8;
    case 'Truck':
      return 9;
    case 'Bicycle':
      return 10;
    default:
      return 1;
  }
};

export const getNameOfVclass = vclass => {
  if (vclass) {
    switch (vclass.id) {
      case 1:
        return i18n.t('vehicle.midsize');
      case 2:
        return i18n.t('vehicle.large');
      case 3:
        return i18n.t('vehicle.compact');
      case 4:
        return i18n.t('vehicle.scooter');
      case 5:
        return i18n.t('vehicle.motorcycle');
      case 6:
        return i18n.t('vehicle.suv');
      case 7:
        return i18n.t('vehicle.van');
      case 8:
        return i18n.t('vehicle.pickup');
      case 9:
        return i18n.t('vehicle.truck');
      case 10:
        return i18n.t('vehicle.bicycle');
      case 11:
        return i18n.t('vehicle.airplane');
      default:
        return i18n.t('vehicle.midsize');
    }
  }
  return i18n.t('vehicle.midsize');
};

export const vehicleFullName = userVehicle => {
  let name = '';
  if (userVehicle) {
    if (userVehicle.vehicle) {
      name = `${userVehicle.vehicle.make} ${userVehicle.vehicle.model}`;
      if (userVehicle.license) {
        name = userVehicle.license;
      }
    } else {
      name = getNameOfVclass(userVehicle.vclass);
    }
  }
  return name;
};

export const VehicleOwnership = {
  PRIVATE: 1,
  COMPANY: 2,
  TRANSIT: 3,
  TAXI: 4,
  RENTAL: 5,
  SHARE: 6
};

export const getAllowedOwnershipTypes = () => {
  return [
    i18n.t('vehicle.ownership.private'),
    i18n.t('vehicle.ownership.company'),
    i18n.t('vehicle.ownership.rental')
  ];
};

export const getOwnershipTypes = () => {
  return [
    i18n.t('vehicle.ownership.private'),
    i18n.t('vehicle.ownership.company'),
    i18n.t('vehicle.ownership.transit'),
    i18n.t('vehicle.ownership.taxi'),
    i18n.t('vehicle.ownership.rental'),
    i18n.t('vehicle.ownership.share')
  ];
};

export const getVehicleOwnershipType = userVehicle => {
  var ownership = i18n.t('vehicle.ownership.private');
  if (userVehicle) {
    if (userVehicle.designation) {
      return getOwnershipType(userVehicle.designation);
    }
  }
  return ownership;
};

export const getOwnershipType = designation => {
  if (isUndefined(designation) || designation === 0) {
    return i18n.t('vehicle.ownership.private');
  }
  return getOwnershipTypes()[designation - 1];
};

export const getDesignationForOwnershipType = ownership => {
  if (isUndefined(ownership)) {
    return VehicleOwnership.PRIVATE;
  }
  let index = getOwnershipTypes().indexOf(ownership);
  if (index >= 0) {
    return index + 1;
  }
  return VehicleOwnership.PRIVATE;
};

// trips vehicles START

export const getStepTransitLine = step => {
  const { steps } = step.route;
  const stepTest = steps;
  const transitDetails =
    stepTest &&
    stepTest
      .map(stepE => checkUndefined(stepE.transit_details || stepE.transit))
      .filter(Boolean);
  const line =
    transitDetails && transitDetails.length && transitDetails[0].line;
  return line ? `${line.vehicle.name} ${line.short_name}` : step.travel_mode;
};
// trips vehicles END

export const checkTwoDatesSameDay = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const setHourOfDate = (date, hour) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour);
};

export const getVehicleClassOptions = vehicleClasses => {
  const vehicleClassesToShow = [];

  if (vehicleClasses.length) {
    const vehicleClassesMap = vehicleClasses.reduce((acc, next) => {
      acc[
        next.name
          .split(' ')
          .join('_')
          .toLowerCase()
      ] = next;
      return acc;
    }, {});

    vehicleClassesToShow.push(vehicleClassesMap.scooter);
    vehicleClassesToShow.push(vehicleClassesMap.motorcycle);
    vehicleClassesToShow.push(vehicleClassesMap.compact_car);
    vehicleClassesToShow.push(vehicleClassesMap.midsize_car);
    vehicleClassesToShow.push(vehicleClassesMap.large_car);
    vehicleClassesToShow.push(vehicleClassesMap.suv);
    vehicleClassesToShow.push(vehicleClassesMap.van);
    vehicleClassesToShow.push(vehicleClassesMap.pickup_truck);
    vehicleClassesToShow.push(vehicleClassesMap.truck);
  }
  return vehicleClassesToShow;
};

const findVClassIndex = (vehicleClasses, className) => {
  return vehicleClasses.findIndex(
    vClass => vClass.name.toLowerCase() === className.toLowerCase()
  );
};

export const getGenericVehicleClassIndex = (genericVehicle, vehicleClasses) => {
  const vehicleClassIndex = findVClassIndex(
    vehicleClasses,
    genericVehicle.class
  );
  if (vehicleClassIndex === -1) {
    switch (genericVehicle.class) {
      case 'Two Seaters':
        return findVClassIndex(vehicleClasses, 'Midsize Car');
      case 'compact Cars':
        return findVClassIndex(vehicleClasses, 'Compact Car');
      case 'Sport Utility Vehicle':
        return findVClassIndex(vehicleClasses, 'SUV');
      case 'Station Wagons':
        return findVClassIndex(vehicleClasses, 'Large car');
      case 'Vans':
      case 'Minivan':
      case 'Special Purpose Vehicle':
      case 'Leisure activity':
      case 'MPV':
        return findVClassIndex(vehicleClasses, 'van');
      default:
        return 0;
    }
  }

  return vehicleClassIndex;
};
