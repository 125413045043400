import { SET_STATE_LIST } from '../actions/stateList';

const initialState = [];

export const stateList = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATE_LIST:
      return action.stateList;

    default:
      return state;
  }
};
