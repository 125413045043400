import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { translate } from 'react-i18next';

import 'semantic-ui-css/semantic.min.css';

import DefaultLandingPage from './pages/LandingPage';
import ProSolution from './pages/LandingProPage';
import EnterpriseSolution from './pages/LandingEnterprisePage';
import PublicHelpPage from './pages/PublicHelpPage';

import NotificationCenter from './containers/NotificationCenter';
import PrivateRoute from './components/PrivateRoute';

import { loadApp } from './actions';
import { setKindOfDeviceUsingWindowWidth } from './actions/login';

import { isUserLoggedIn } from './selectors/user';

import './css/dashboard.css';
import './index.css';
import './css/mobileMenu.css';

import asyncComponent from './components/AsyncComponent';
import { Helmet } from 'react-helmet';

const AsyncLoginPage = asyncComponent(() => import('./pages/LoginPage'));
const AsyncDashboardPage = asyncComponent(() =>
  import('./containers/Dashboard')
);

class App extends Component {
  static propTypes = {
    t: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    userLoggedIn: PropTypes.bool,
    location: PropTypes.object
  };

  componentWillMount() {
    const animatedCSSLink = document.createElement('link');
    animatedCSSLink.rel = 'stylesheet';
    animatedCSSLink.href =
      'https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css';
    animatedCSSLink.type = 'text/css';
    const firstLinkTag = document.getElementsByTagName('link')[0];
    firstLinkTag.parentNode.insertBefore(animatedCSSLink, firstLinkTag);

    this.props.dispatch(loadApp());
    window.addEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate(prevProps) {
    const { pathname, search } = this.props.location;
    if (
      pathname !== prevProps.location.pathname ||
      search !== prevProps.location.search
    ) {
      window.gtag('event', 'page_view', { page_path: pathname + search });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize = () => {
    this.props.dispatch(setKindOfDeviceUsingWindowWidth());
  };

  render() {
    const { t, userLoggedIn } = this.props;

    return (
      <div id="psngrWebApp">
        <Helmet>
          <title>Psngr Dashboard</title>
        </Helmet>
        <Switch>
          <Route exact path="/" component={DefaultLandingPage} />
          <Redirect exact from="/en" to="/" />
          <Redirect exact from="/de" to="/" />
          <Redirect exact from="/es" to="/" />
          <Redirect exact from="/fr" to="/" />
          <Redirect exact from="/it" to="/" />
          <Route path="/pro" component={ProSolution} />
          <Route path="/enterprise" component={EnterpriseSolution} />
          <Route path="/help" t={t} component={PublicHelpPage} />
          <Route path="/login" component={AsyncLoginPage} />
          <PrivateRoute
            path="/:lang/dashboard"
            isLoggedIn={userLoggedIn}
            component={AsyncDashboardPage}
          />
          <PrivateRoute
            path="/dashboard"
            isLoggedIn={userLoggedIn}
            component={AsyncDashboardPage}
          />
        </Switch>
        <NotificationCenter />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    user: { detailsPresent: userDetailsPresent }
  } = state;

  return {
    userLoggedIn: isUserLoggedIn(state),
    userDetailsPresent
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default translate()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
);
