import {
  SELECT_DATE_FROM,
  SELECT_DATE_TO,
  SET_NEXT_PAGE_TO_BE,
  SET_COUNTRY_BY_IP,
  SET_APP_LANGUAGE,
  SET_APP_STATS,
  SET_IS_APP,
  TOGGLE_IS_SMOOOCH_INITIALIZED,
  SET_USER_GEO_BY_IP
} from '../actions';
import { RECEIVE_TAGS } from '../actions/tags';
import { CHANGE_TIME_FORMAT } from '../actions/user';
import { USER_SIGNOUT } from '../actions/login';

import { TIME_FORMATS } from '../utils/constants';
import history from '../utils/history';
import { setMomentLocale } from '../utils/date';

export const loadedTags = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_TAGS:
      return action.tags;
    default:
      return state;
  }
};

export const selectedDateRange = (state = { from: '', to: '' }, action) => {
  switch (action.type) {
    case SELECT_DATE_FROM:
      return {
        ...state,
        from: action.from
      };
    case SELECT_DATE_TO:
      return {
        ...state,
        to: action.to
      };
    default:
      return state;
  }
};

export const timeFormatPreference = (state = TIME_FORMATS['24'], action) => {
  switch (action.type) {
    case CHANGE_TIME_FORMAT:
      return action.format;
    default:
      return state;
  }
};

export const nextPageToBe = (
  state = `${history.location.pathname}${history.location.search}`,
  action
) => {
  switch (action.type) {
    case SET_NEXT_PAGE_TO_BE:
      return action.nextPage;
    case USER_SIGNOUT:
      return '/';

    default:
      return state;
  }
};

export const countryByIp = (state = null, action) => {
  switch (action.type) {
    case SET_COUNTRY_BY_IP:
    case USER_SIGNOUT:
      return action.countryByIp;

    default:
      return state;
  }
};

export const appLanguage = (state = 'en', action) => {
  const { type, appLanguage } = action;

  if (appLanguage) {
    setMomentLocale(appLanguage === 'en' ? 'en-gb' : appLanguage);
  }

  switch (type) {
    case SET_APP_LANGUAGE:
    case USER_SIGNOUT:
      return appLanguage;
    default:
      return state;
  }
};

export const appStats = (state = {}, action) => {
  switch (action.type) {
    case SET_APP_STATS:
      return action.stats || state;
    default:
      return state;
  }
};

export const isApp = (state = false, action) => {
  switch (action.type) {
    case SET_IS_APP:
      return true;
    default:
      return state;
  }
};

export const isSmoochInitialized = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_IS_SMOOOCH_INITIALIZED:
      return action.isInitialized;
    default:
      return state;
  }
};

export const locationByIp = (state = null, action) => {
  switch (action.type) {
    case SET_USER_GEO_BY_IP:
      return action.payload;
    default:
      return state;
  }
};
