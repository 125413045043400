import { createSelector } from 'reselect';

import { getCountryFromIp } from './app';
import { getCustomerBillingCountry } from './customer';
import { getUserInfo } from './user';

import { isEmpty } from '../utils';

export const getBillingCountry = createSelector(
  [getCustomerBillingCountry, getCountryFromIp, getUserInfo],
  (customerBillingCountry, countryFromIP, userDetails) => {
    if (customerBillingCountry) {
      return customerBillingCountry;
    }
    if (!isEmpty(userDetails)) {
      return userDetails.country;
    }
    if (countryFromIP) {
      return countryFromIP;
    }
    return 'NL';
  }
);
