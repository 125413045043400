export const loadCookieconsent = () => {
  const cookieScript = document.createElement('script');
  cookieScript.src = 'https://consent.cookiebot.com/uc.js';
  cookieScript.setAttribute('id', 'Cookiebot');
  cookieScript.setAttribute(
    'data-cbid',
    'd25b0bf6-1e7d-4496-8f39-59d79e60a0b6'
  );
  cookieScript.setAttribute('type', 'text/javascript');
  cookieScript.async = true;
  document.body.appendChild(cookieScript);
};
