export const SET_ERROR = 'SET_ERROR';
export const DELETE_ERROR = 'DELETE_ERROR';

export const setError = error => {
  return {
    type: SET_ERROR,
    error
  };
};

export const deleteError = errorId => {
  return {
    type: DELETE_ERROR,
    errorId
  };
};

export const deleteErrorsOfBillingForm = (context = '') => (
  dispatch,
  getState
) => {
  let errorsToDelete = [
    'not_allowed_op',
    'invalid_subscription_payment',
    'form_validations_error'
  ];
  if (context === 'beacon') {
    errorsToDelete = [
      'not_allowed_op',
      'invalid_beacon_payment',
      'form_validations_error'
    ];
  }

  const { errors } = getState();

  errorsToDelete.forEach(errorType => {
    const error = errors.find(el => el.type === errorType);
    if (error) {
      dispatch(deleteError(error.id));
    }
  });
};

export const deleteMileageRateErrors = () => (dispatch, getState) => {
  const { errors } = getState();
  let errorsToDelete = ['mileage_rate_error'];

  errorsToDelete.forEach(errorType => {
    const error = errors.find(el => el.type === errorType);
    if (error) {
      dispatch(deleteError(error.id));
    }
  });
};
