/*{
	"cca2":"AW",
	"translations":{
		"en":{"common":"Aruba"},
		"de":{"official":"Aruba","common":"Aruba"},
		"fr":{"official":"Aruba","common":"Aruba"},
		"it":{"official":"Aruba","common":"Aruba"},
		"es":{"official":"Aruba","common":"Aruba"}
	}
}*/
const countries = [
  {
    cca2: 'AW',
    translations: {
      en: { common: 'Aruba' },
      nl: { common: 'Aruba' },
      de: { official: 'Aruba', common: 'Aruba' },
      fr: { official: 'Aruba', common: 'Aruba' },
      it: { official: 'Aruba', common: 'Aruba' },
      es: { official: 'Aruba', common: 'Aruba' }
    }
  },
  {
    cca2: 'AF',
    translations: {
      en: { common: 'Afghanistan' },
      nl: { common: 'Afghanistan' },
      de: {
        official: 'Islamische Republik Afghanistan',
        common: 'Afghanistan'
      },
      fr: {
        official: "R\u00e9publique islamique d'Afghanistan",
        common: 'Afghanistan'
      },
      it: {
        official: "Repubblica islamica dell'Afghanistan",
        common: 'Afghanistan'
      },
      es: {
        official: 'Rep\u00fablica Isl\u00e1mica de Afganist\u00e1n',
        common: 'Afganist\u00e1n'
      }
    }
  },
  {
    cca2: 'AO',
    translations: {
      en: { common: 'Angola' },
      nl: { common: 'Angola' },
      de: { official: 'Republik Angola', common: 'Angola' },
      fr: { official: "R\u00e9publique d'Angola", common: 'Angola' },
      it: { official: "Repubblica dell'Angola", common: 'Angola' },
      es: { official: 'Rep\u00fablica de Angola', common: 'Angola' }
    }
  },
  {
    cca2: 'AI',
    translations: {
      en: { common: 'Anguilla' },
      nl: { common: 'Anguilla' },
      de: { official: 'Anguilla', common: 'Anguilla' },
      fr: { official: 'Anguilla', common: 'Anguilla' },
      it: { official: 'Anguilla', common: 'Anguilla' },
      es: { official: 'Anguila', common: 'Anguilla' }
    }
  },
  {
    cca2: 'AX',
    translations: {
      en: { common: '\u00c5land Islands' },
      nl: { common: '\u00c5land-eilanden' },
      de: { official: '\u00c5land-Inseln', common: '\u00c5land' },
      fr: { official: 'Ahvenanmaa', common: 'Ahvenanmaa' },
      it: { official: 'Isole \u00c5land', common: 'Isole Aland' },
      es: { official: 'Islas \u00c5land', common: 'Alandia' }
    }
  },
  {
    cca2: 'AL',
    translations: {
      en: { common: 'Albania' },
      nl: { common: 'Albanië' },
      de: { official: 'Republik Albanien', common: 'Albanien' },
      fr: { official: "R\u00e9publique d'Albanie", common: 'Albanie' },
      it: { official: "Repubblica d'Albania", common: 'Albania' },
      es: { official: 'Rep\u00fablica de Albania', common: 'Albania' }
    }
  },
  {
    cca2: 'AD',
    translations: {
      en: { common: 'Andorra' },
      nl: { common: 'Andorra' },
      de: { official: 'F\u00fcrstentum Andorra', common: 'Andorra' },
      fr: { official: "Principaut\u00e9 d'Andorre", common: 'Andorre' },
      it: { official: 'Principato di Andorra', common: 'Andorra' },
      es: { official: 'Principado de Andorra', common: 'Andorra' }
    }
  },
  {
    cca2: 'AE',
    translations: {
      en: { common: 'United Arab Emirates' },
      nl: { common: 'Verenigde Arabische Emiraten' },
      de: {
        official: 'Vereinigte Arabische Emirate',
        common: 'Vereinigte Arabische Emirate'
      },
      fr: {
        official: '\u00c9mirats arabes unis',
        common: '\u00c9mirats arabes unis'
      },
      it: { official: 'Emirati Arabi Uniti', common: 'Emirati Arabi Uniti' },
      es: {
        official: 'Emiratos \u00c1rabes Unidos',
        common: 'Emiratos \u00c1rabes Unidos'
      }
    }
  },
  {
    cca2: 'AR',
    translations: {
      en: { common: 'Argentina' },
      nl: { common: 'Argentinië' },
      de: { official: 'Argentinische Republik', common: 'Argentinien' },
      fr: { official: 'R\u00e9publique argentine', common: 'Argentine' },
      it: { official: 'Repubblica Argentina', common: 'Argentina' },
      es: { official: 'Rep\u00fablica Argentina', common: 'Argentina' }
    }
  },
  {
    cca2: 'AM',
    translations: {
      en: { common: 'Armenia' },
      nl: { common: 'Armenië' },
      de: { official: 'Republik Armenien', common: 'Armenien' },
      fr: {
        official: "R\u00e9publique d'Arm\u00e9nie",
        common: 'Arm\u00e9nie'
      },
      it: { official: 'Repubblica di Armenia', common: 'Armenia' },
      es: { official: 'Rep\u00fablica de Armenia', common: 'Armenia' }
    }
  },
  {
    cca2: 'AS',
    translations: {
      en: { common: 'American Samoa' },
      nl: { common: 'Amerikaans Samoa' },
      de: { official: 'Amerikanisch-Samoa', common: 'Amerikanisch-Samoa' },
      fr: {
        official: 'Samoa am\u00e9ricaines',
        common: 'Samoa am\u00e9ricaines'
      },
      it: { official: 'Samoa americane', common: 'Samoa Americane' },
      es: { official: 'Samoa Americana', common: 'Samoa Americana' }
    }
  },
  {
    cca2: 'AQ',
    translations: {
      en: { common: 'Antarctica' },
      nl: { common: 'Antarctica' },
      de: { official: 'Antarktika', common: 'Antarktis' },
      fr: { official: 'Antarctique', common: 'Antarctique' },
      it: { official: 'Antartide', common: 'Antartide' },
      es: { official: 'Ant\u00e1rtida', common: 'Ant\u00e1rtida' }
    }
  },
  {
    cca2: 'TF',
    translations: {
      en: { common: 'French Southern and Antarctic Lands' },
      nl: { common: 'Franse Zuidelijke en Antarctische gebieden' },
      de: {
        official: 'Gebiet der Franz\u00f6sisch S\u00fcd-und Antarktisgebiete',
        common: 'Franz\u00f6sische S\u00fcd-und Antarktisgebiete'
      },
      fr: {
        official:
          'Territoire des Terres australes et antarctiques fran\u00e7aises',
        common: 'Terres australes et antarctiques fran\u00e7aises'
      },
      it: {
        official: 'Territorio della australi e antartiche francesi Terre',
        common: 'Territori Francesi del Sud'
      },
      es: {
        official:
          'Territorio del Franc\u00e9s Tierras australes y ant\u00e1rticas',
        common: 'Tierras Australes y Ant\u00e1rticas Francesas'
      }
    }
  },
  {
    cca2: 'AG',
    translations: {
      en: { common: 'Antigua and Barbuda' },
      nl: { common: 'Antigua en Barbuda' },
      de: { official: 'Antigua und Barbuda', common: 'Antigua und Barbuda' },
      fr: { official: 'Antigua -et-Barbuda', common: 'Antigua-et-Barbuda' },
      it: { official: 'Antigua e Barbuda', common: 'Antigua e Barbuda' },
      es: { official: 'Antigua y Barbuda', common: 'Antigua y Barbuda' }
    }
  },
  {
    cca2: 'AU',
    translations: {
      en: { common: 'Australia' },
      nl: { common: 'Australië' },
      de: { official: 'Commonwealth Australien', common: 'Australien' },
      fr: { official: 'Australie', common: 'Australie' },
      it: { official: "Commonwealth dell'Australia", common: 'Australia' },
      es: { official: 'Mancomunidad de Australia', common: 'Australia' }
    }
  },
  {
    cca2: 'AT',
    translations: {
      en: { common: 'Austria' },
      nl: { common: 'Oostenrijk' },
      de: { official: 'Republik \u00d6sterreich', common: '\u00d6sterreich' },
      fr: { official: "R\u00e9publique d'Autriche", common: 'Autriche' },
      it: { official: "Repubblica d'Austria", common: 'Austria' },
      es: { official: 'Rep\u00fablica de Austria', common: 'Austria' }
    }
  },
  {
    cca2: 'AZ',
    translations: {
      en: { common: 'Azerbaijan' },
      nl: { common: 'Azerbeidzjan' },
      de: { official: 'Republik Aserbaidschan', common: 'Aserbaidschan' },
      fr: {
        official: "R\u00e9publique d'Azerba\u00efdjan",
        common: 'Azerba\u00efdjan'
      },
      it: { official: "Repubblica dell'Azerbaigian", common: 'Azerbaijan' },
      es: {
        official: 'Rep\u00fablica de Azerbaiy\u00e1n',
        common: 'Azerbaiy\u00e1n'
      }
    }
  },
  {
    cca2: 'BI',
    translations: {
      en: { common: 'Burundi' },
      nl: { common: 'Boeroendi' },
      de: { official: 'Republik Burundi', common: 'Burundi' },
      fr: { official: 'R\u00e9publique du Burundi', common: 'Burundi' },
      it: { official: 'Repubblica del Burundi', common: 'Burundi' },
      es: { official: 'Rep\u00fablica de Burundi', common: 'Burundi' }
    }
  },
  {
    cca2: 'BE',
    translations: {
      en: { common: 'Belgium' },
      nl: { common: 'België' },
      de: { official: 'K\u00f6nigreich Belgien', common: 'Belgien' },
      fr: { official: 'Royaume de Belgique', common: 'Belgique' },
      it: { official: 'Regno del Belgio', common: 'Belgio' },
      es: { official: 'Reino de B\u00e9lgica', common: 'B\u00e9lgica' }
    }
  },
  {
    cca2: 'BJ',
    translations: {
      en: { common: 'Benin' },
      nl: { common: 'Benin' },
      de: { official: 'Republik Benin', common: 'Benin' },
      fr: { official: 'R\u00e9publique du B\u00e9nin', common: 'B\u00e9nin' },
      it: { official: 'Repubblica del Benin', common: 'Benin' },
      es: { official: 'Rep\u00fablica de Benin', common: 'Ben\u00edn' }
    }
  },
  {
    cca2: 'BF',
    translations: {
      en: { common: 'Burkina Faso' },
      nl: { common: 'Burkina Faso' },
      de: { official: 'Burkina Faso', common: 'Burkina Faso' },
      fr: { official: 'R\u00e9publique du Burkina', common: 'Burkina Faso' },
      it: { official: 'Burkina Faso', common: 'Burkina Faso' },
      es: { official: 'Burkina Faso', common: 'Burkina Faso' }
    }
  },
  {
    cca2: 'BD',
    translations: {
      en: { common: 'Bangladesh' },
      nl: { common: 'Bangladesh' },
      de: { official: 'Volksrepublik Bangladesch', common: 'Bangladesch' },
      fr: {
        official: 'La R\u00e9publique populaire du Bangladesh',
        common: 'Bangladesh'
      },
      it: {
        official: 'Repubblica popolare del Bangladesh',
        common: 'Bangladesh'
      },
      es: {
        official: 'Rep\u00fablica Popular de Bangladesh',
        common: 'Bangladesh'
      }
    }
  },
  {
    cca2: 'BG',
    translations: {
      en: { common: 'Bulgaria' },
      nl: { common: 'Bulgarije' },
      de: { official: 'Republik Bulgarien', common: 'Bulgarien' },
      fr: { official: 'R\u00e9publique de Bulgarie', common: 'Bulgarie' },
      it: { official: 'Repubblica di Bulgaria', common: 'Bulgaria' },
      es: { official: 'Rep\u00fablica de Bulgaria', common: 'Bulgaria' }
    }
  },
  {
    cca2: 'BH',
    translations: {
      en: { common: 'Bahrain' },
      nl: { common: 'Bahrein' },
      de: { official: 'K\u00f6nigreich Bahrain', common: 'Bahrain' },
      fr: { official: 'Royaume de Bahre\u00efn', common: 'Bahre\u00efn' },
      it: { official: 'Regno del Bahrain', common: 'Bahrein' },
      es: { official: 'Reino de Bahrein', common: 'Bahrein' }
    }
  },
  {
    cca2: 'BS',
    translations: {
      en: { common: 'Bahamas' },
      nl: { common: 'Bahamas' },
      de: { official: 'Commonwealth der Bahamas', common: 'Bahamas' },
      fr: { official: 'Commonwealth des Bahamas', common: 'Bahamas' },
      it: { official: 'Commonwealth delle Bahamas', common: 'Bahamas' },
      es: { official: 'Commonwealth de las Bahamas', common: 'Bahamas' }
    }
  },
  {
    cca2: 'BA',
    translations: {
      en: { common: 'Bosnia and Herzegovina' },
      nl: { common: 'Bosnië-Herzegovina' },
      de: {
        official: 'Bosnien und Herzegowina',
        common: 'Bosnien und Herzegowina'
      },
      fr: {
        official: 'Bosnie-et-Herz\u00e9govine',
        common: 'Bosnie-Herz\u00e9govine'
      },
      it: { official: 'Bosnia-Erzegovina', common: 'Bosnia ed Erzegovina' },
      es: { official: 'Bosnia y Herzegovina', common: 'Bosnia y Herzegovina' }
    }
  },
  {
    cca2: 'BL',
    translations: {
      en: { common: 'Saint Barth\u00e9lemy' },
      nl: { common: 'Saint Barth\u00e9lemy' },
      de: {
        official: 'Gebietsk\u00f6rperschaft Saint -Barth\u00e9lemy',
        common: 'Saint-Barth\u00e9lemy'
      },
      fr: {
        official: 'Collectivit\u00e9 de Saint-Barth\u00e9lemy',
        common: 'Saint-Barth\u00e9lemy'
      },
      it: {
        official: 'Collettivit\u00e0 di Saint Barth\u00e9lemy',
        common: 'Antille Francesi'
      },
      es: {
        official: 'Colectividad de San Barth\u00e9lemy',
        common: 'San Bartolom\u00e9'
      }
    }
  },
  {
    cca2: 'BY',
    translations: {
      en: { common: 'Belarus' },
      nl: { common: 'Wit-Rusland' },
      de: { official: 'Republik Belarus', common: 'Wei\u00dfrussland' },
      fr: {
        official: 'R\u00e9publique de Bi\u00e9lorussie',
        common: 'Bi\u00e9lorussie'
      },
      it: { official: 'Repubblica di Belarus', common: 'Bielorussia' },
      es: { official: 'Rep\u00fablica de Belar\u00fas', common: 'Bielorrusia' }
    }
  },
  {
    cca2: 'BZ',
    translations: {
      en: { common: 'Belize' },
      nl: { common: 'Belize' },
      de: { official: 'Belize', common: 'Belize' },
      fr: { official: 'Belize', common: 'Belize' },
      it: { official: 'Belize', common: 'Belize' },
      es: { official: 'Belice', common: 'Belice' }
    }
  },
  {
    cca2: 'BM',
    translations: {
      en: { common: 'Bermuda' },
      nl: { common: 'Bermuda' },
      de: { official: 'Bermuda', common: 'Bermuda' },
      fr: { official: 'Bermudes', common: 'Bermudes' },
      it: { official: 'Bermuda', common: 'Bermuda' },
      es: { official: 'Bermuda', common: 'Bermudas' }
    }
  },
  {
    cca2: 'BO',
    translations: {
      en: { common: 'Bolivia' },
      nl: { common: 'Bolivia' },
      de: {
        official: 'Multinationaler Staat von Bolivien',
        common: 'Bolivien'
      },
      fr: { official: '\u00c9tat plurinational de Bolivie', common: 'Bolivie' },
      it: { official: 'Stato Plurinazionale della Bolivia', common: 'Bolivia' },
      es: { official: 'Estado Plurinacional de Bolivia', common: 'Bolivia' }
    }
  },
  {
    cca2: 'BR',
    translations: {
      en: { common: 'Brazil' },
      nl: { common: 'Brazilië' },
      de: {
        official: 'F\u00f6derative Republik Brasilien',
        common: 'Brasilien'
      },
      fr: {
        official: 'R\u00e9publique f\u00e9d\u00e9rative du Br\u00e9sil',
        common: 'Br\u00e9sil'
      },
      it: { official: 'Repubblica federativa del Brasile', common: 'Brasile' },
      es: { official: 'Rep\u00fablica Federativa del Brasil', common: 'Brasil' }
    }
  },
  {
    cca2: 'BB',
    translations: {
      en: { common: 'Barbados' },
      nl: { common: 'Barbados' },
      de: { official: 'Barbados', common: 'Barbados' },
      fr: { official: 'Barbade', common: 'Barbade' },
      it: { official: 'Barbados', common: 'Barbados' },
      es: { official: 'Barbados', common: 'Barbados' }
    }
  },
  {
    cca2: 'BN',
    translations: {
      en: { common: 'Brunei' },
      nl: { common: 'Brunei' },
      de: {
        official: 'Nation von Brunei, Wohnung des Friedens',
        common: 'Brunei'
      },
      fr: { official: '\u00c9tat de Brunei Darussalam', common: 'Brunei' },
      it: {
        official: 'Nazione di Brunei, Dimora della Pace',
        common: 'Brunei'
      },
      es: {
        official: 'Naci\u00f3n de Brunei, Morada de la Paz',
        common: 'Brunei'
      }
    }
  },
  {
    cca2: 'BT',
    translations: {
      en: { common: 'Bhutan' },
      nl: { common: 'Bhutan' },
      de: { official: 'K\u00f6nigreich Bhutan', common: 'Bhutan' },
      fr: { official: 'Royaume du Bhoutan', common: 'Bhoutan' },
      it: { official: 'Regno del Bhutan', common: 'Bhutan' },
      es: { official: 'Reino de But\u00e1n', common: 'But\u00e1n' }
    }
  },
  {
    cca2: 'BV',
    translations: {
      en: { common: 'Bouvet Island' },
      nl: { common: 'Bouvet Island' },
      de: { official: 'Bouvet-Insel', common: 'Bouvetinsel' },
      fr: { official: '\u00cele Bouvet', common: '\u00cele Bouvet' },
      it: { official: 'Isola Bouvet', common: 'Isola Bouvet' },
      es: { official: 'Isla Bouvet', common: 'Isla Bouvet' }
    }
  },
  {
    cca2: 'BW',
    translations: {
      en: { common: 'Botswana' },
      nl: { common: 'Botswana' },
      de: { official: 'Republik Botsuana', common: 'Botswana' },
      fr: { official: 'R\u00e9publique du Botswana', common: 'Botswana' },
      it: { official: 'Repubblica del Botswana', common: 'Botswana' },
      es: { official: 'Rep\u00fablica de Botswana', common: 'Botswana' }
    }
  },
  {
    cca2: 'CF',
    translations: {
      en: { common: 'Central African Republic' },
      nl: { common: 'Centraal Afrikaanse Republiek' },
      de: {
        official: 'Zentralafrikanische Republik',
        common: 'Zentralafrikanische Republik'
      },
      fr: {
        official: 'R\u00e9publique centrafricaine',
        common: 'R\u00e9publique centrafricaine'
      },
      it: {
        official: 'Repubblica Centrafricana',
        common: 'Repubblica Centrafricana'
      },
      es: {
        official: 'Rep\u00fablica Centroafricana',
        common: 'Rep\u00fablica Centroafricana'
      }
    }
  },
  {
    cca2: 'CA',
    translations: {
      en: { common: 'Canada' },
      nl: { common: 'Canada' },
      de: { official: 'Kanada', common: 'Kanada' },
      fr: { official: 'Canada', common: 'Canada' },
      it: { official: 'Canada', common: 'Canada' },
      es: { official: 'Canad\u00e1', common: 'Canad\u00e1' }
    }
  },
  {
    cca2: 'CC',
    translations: {
      en: { common: 'Cocos (Keeling) Islands' },
      nl: { common: 'Cocos (Keeling) eilanden' },
      de: {
        official: 'Gebiet der Cocos (Keeling) Islands',
        common: 'Kokosinseln'
      },
      fr: {
        official: 'Territoire des \u00eeles Cocos (Keeling)',
        common: '\u00celes Cocos'
      },
      it: {
        official: 'Territorio della (Keeling) Isole Cocos',
        common: 'Isole Cocos e Keeling'
      },
      es: {
        official: 'Territorio de los (Keeling) Islas Cocos',
        common: 'Islas Cocos o Islas Keeling'
      }
    }
  },
  {
    cca2: 'CH',
    translations: {
      en: { common: 'Switzerland' },
      nl: { common: 'Zwitserland' },
      de: { official: 'Schweizerische Eidgenossenschaft', common: 'Schweiz' },
      fr: { official: 'Conf\u00e9d\u00e9ration suisse', common: 'Suisse' },
      it: { official: 'Confederazione svizzera', common: 'Svizzera' },
      es: { official: 'Confederaci\u00f3n Suiza', common: 'Suiza' }
    }
  },
  {
    cca2: 'CL',
    translations: {
      en: { common: 'Chile' },
      nl: { common: 'Chili' },
      de: { official: 'Republik Chile', common: 'Chile' },
      fr: { official: 'R\u00e9publique du Chili', common: 'Chili' },
      it: { official: 'Repubblica del Cile', common: 'Cile' },
      es: { official: 'Rep\u00fablica de Chile', common: 'Chile' }
    }
  },
  {
    cca2: 'CN',
    translations: {
      en: { common: 'China' },
      nl: { common: 'China' },
      de: { official: 'Volksrepublik China', common: 'China' },
      fr: { official: 'R\u00e9publique populaire de Chine', common: 'Chine' },
      it: { official: 'Repubblica popolare cinese', common: 'Cina' },
      es: { official: 'Rep\u00fablica Popular de China', common: 'China' }
    }
  },
  {
    cca2: 'CI',
    translations: {
      en: { common: 'Ivory Coast' },
      nl: { common: 'Ivoorkust' },
      de: {
        official: "Republik C\u00f4te d'Ivoire",
        common: 'Elfenbeink\u00fcste'
      },
      fr: {
        official: "R\u00e9publique de C\u00f4te d' Ivoire",
        common: "C\u00f4te d'Ivoire"
      },
      it: {
        official: "Repubblica della Costa d'Avorio",
        common: "Costa d'Avorio"
      },
      es: {
        official: "Rep\u00fablica de C\u00f4te d'Ivoire",
        common: 'Costa de Marfil'
      }
    }
  },
  {
    cca2: 'CM',
    translations: {
      en: { common: 'Cameroon' },
      nl: { common: 'Kameroen' },
      de: { official: 'Republik Kamerun', common: 'Kamerun' },
      fr: { official: 'R\u00e9publique du Cameroun', common: 'Cameroun' },
      it: { official: 'Repubblica del Camerun', common: 'Camerun' },
      es: { official: 'Rep\u00fablica de Camer\u00fan', common: 'Camer\u00fan' }
    }
  },
  {
    cca2: 'CD',
    translations: {
      en: { common: 'DR Congo' },
      nl: { common: 'DR Congo' },
      de: {
        official: 'Demokratische Republik Kongo',
        common: 'Kongo (Dem. Rep.)'
      },
      fr: {
        official: 'R\u00e9publique d\u00e9mocratique du Congo',
        common: 'Congo (R\u00e9p. d\u00e9m.)'
      },
      it: {
        official: 'Repubblica Democratica del Congo',
        common: 'Congo (Rep. Dem.)'
      },
      es: {
        official: 'Rep\u00fablica Democr\u00e1tica del Congo',
        common: 'Congo (Rep. Dem.)'
      }
    }
  },
  {
    cca2: 'CG',
    translations: {
      en: { common: 'Republic of the Congo' },
      nl: { common: 'Republiek Congo' },
      de: { official: 'Republik Kongo', common: 'Kongo' },
      fr: { official: 'R\u00e9publique du Congo', common: 'Congo' },
      it: { official: 'Repubblica del Congo', common: 'Congo' },
      es: { official: 'Rep\u00fablica del Congo', common: 'Congo' }
    }
  },
  {
    cca2: 'CK',
    translations: {
      en: { common: 'Cook Islands' },
      nl: { common: 'Cook eilanden' },
      de: { official: 'Cook-Inseln', common: 'Cookinseln' },
      fr: { official: '\u00celes Cook', common: '\u00celes Cook' },
      it: { official: 'Isole Cook', common: 'Isole Cook' },
      es: { official: 'Islas Cook', common: 'Islas Cook' }
    }
  },
  {
    cca2: 'CO',
    translations: {
      en: { common: 'Colombia' },
      nl: { common: 'Colombia' },
      de: { official: 'Republik Kolumbien', common: 'Kolumbien' },
      fr: { official: 'R\u00e9publique de Colombie', common: 'Colombie' },
      it: { official: 'Repubblica di Colombia', common: 'Colombia' },
      es: { official: 'Rep\u00fablica de Colombia', common: 'Colombia' }
    }
  },
  {
    cca2: 'KM',
    translations: {
      en: { common: 'Comoros' },
      nl: { common: 'Comoren' },
      de: { official: 'Union der Komoren', common: 'Union der Komoren' },
      fr: { official: 'Union des Comores', common: 'Comores' },
      it: { official: 'Unione delle Comore', common: 'Comore' },
      es: { official: 'Uni\u00f3n de las Comoras', common: 'Comoras' }
    }
  },
  {
    cca2: 'CV',
    translations: {
      en: { common: 'Cape Verde' },
      nl: { common: 'Kaapverdië' },
      de: { official: 'Republik Cabo Verde', common: 'Kap Verde' },
      fr: {
        official: 'R\u00e9publique du Cap-Vert',
        common: '\u00celes du Cap-Vert'
      },
      it: { official: 'Repubblica di Capo Verde', common: 'Capo Verde' },
      es: { official: 'Rep\u00fablica de Cabo Verde', common: 'Cabo Verde' }
    }
  },
  {
    cca2: 'CR',
    translations: {
      en: { common: 'Costa Rica' },
      nl: { common: 'Costa Rica' },
      de: { official: 'Republik Costa Rica', common: 'Costa Rica' },
      fr: { official: 'R\u00e9publique du Costa Rica', common: 'Costa Rica' },
      it: { official: 'Repubblica di Costa Rica', common: 'Costa Rica' },
      es: { official: 'Rep\u00fablica de Costa Rica', common: 'Costa Rica' }
    }
  },
  {
    cca2: 'CU',
    translations: {
      en: { common: 'Cuba' },
      nl: { common: 'Cuba' },
      de: { official: 'Republik Kuba', common: 'Kuba' },
      fr: { official: 'R\u00e9publique de Cuba', common: 'Cuba' },
      it: { official: 'Repubblica di Cuba', common: 'Cuba' },
      es: { official: 'Rep\u00fablica de Cuba', common: 'Cuba' }
    }
  },
  {
    cca2: 'CW',
    translations: {
      en: { common: 'Cura\u00e7ao' },
      nl: { common: 'Curacao' },
      de: { common: 'Curacao' },
      fr: { official: 'Cura\u00e7ao', common: 'Cura\u00e7ao' },
      it: { common: 'Cura\u00e7ao' },
      es: { official: 'Pa\u00eds de Curazao', common: 'Curazao' }
    }
  },
  {
    cca2: 'CX',
    translations: {
      en: { common: 'Christmas Island' },
      nl: { common: 'Kersteiland' },
      de: { official: 'Gebiet der Weihnachtsinsel', common: 'Weihnachtsinsel' },
      fr: {
        official: "Territoire de l'\u00eele Christmas",
        common: '\u00cele Christmas'
      },
      it: {
        official: 'Territorio di Christmas Island',
        common: 'Isola di Natale'
      },
      es: {
        official: 'Territorio de la Isla de Navidad',
        common: 'Isla de Navidad'
      }
    }
  },
  {
    cca2: 'KY',
    translations: {
      en: { common: 'Cayman Islands' },
      nl: { common: 'Kaaiman Eilanden' },
      de: { official: 'Cayman-Inseln', common: 'Kaimaninseln' },
      fr: {
        official: '\u00celes Ca\u00efmans',
        common: '\u00celes Ca\u00efmans'
      },
      it: { official: 'Isole Cayman', common: 'Isole Cayman' },
      es: { official: 'Islas Caim\u00e1n', common: 'Islas Caim\u00e1n' }
    }
  },
  {
    cca2: 'CY',
    translations: {
      en: { common: 'Cyprus' },
      nl: { common: 'Cyprus' },
      de: { official: 'Republik Zypern', common: 'Zypern' },
      fr: { official: 'R\u00e9publique de Chypre', common: 'Chypre' },
      it: { official: 'Repubblica di Cipro', common: 'Cipro' },
      es: { official: 'Rep\u00fablica de Chipre', common: 'Chipre' }
    }
  },
  {
    cca2: 'CZ',
    translations: {
      en: { common: 'Czech Republic' },
      nl: { common: 'Tsjechische Republiek' },
      de: {
        official: 'Tschechische Republik',
        common: 'Tschechische Republik'
      },
      fr: {
        official: 'R\u00e9publique tch\u00e8que',
        common: 'R\u00e9publique tch\u00e8que'
      },
      it: { official: 'Repubblica Ceca', common: 'Repubblica Ceca' },
      es: { official: 'Rep\u00fablica Checa', common: 'Rep\u00fablica Checa' }
    }
  },
  {
    cca2: 'DE',
    translations: {
      en: { common: 'Germany' },
      nl: { common: 'Duitsland' },
      de: { official: 'Bundesrepublik Deutschland', common: 'Deutschland' },
      fr: {
        official: "R\u00e9publique f\u00e9d\u00e9rale d'Allemagne",
        common: 'Allemagne'
      },
      it: { official: 'Repubblica federale di Germania', common: 'Germania' },
      es: { official: 'Rep\u00fablica Federal de Alemania', common: 'Alemania' }
    }
  },
  {
    cca2: 'DJ',
    translations: {
      en: { common: 'Djibouti' },
      nl: { common: 'Djibouti' },
      de: { official: 'Republik Dschibuti', common: 'Dschibuti' },
      fr: { official: 'R\u00e9publique de Djibouti', common: 'Djibouti' },
      it: { official: 'Repubblica di Gibuti', common: 'Gibuti' },
      es: { official: 'Rep\u00fablica de Djibouti', common: 'Djibouti' }
    }
  },
  {
    cca2: 'DM',
    translations: {
      en: { common: 'Dominica' },
      nl: { common: 'Dominica' },
      de: { official: 'Commonwealth von Dominica', common: 'Dominica' },
      fr: { official: 'Commonwealth de la Dominique', common: 'Dominique' },
      it: { official: 'Commonwealth di Dominica', common: 'Dominica' },
      es: { official: 'Mancomunidad de Dominica', common: 'Dominica' }
    }
  },
  {
    cca2: 'DK',
    translations: {
      en: { common: 'Denmark' },
      nl: { common: 'Denemarken' },
      de: {
        official: 'K\u00f6nigreich D\u00e4nemark',
        common: 'D\u00e4nemark'
      },
      fr: { official: 'Royaume du Danemark', common: 'Danemark' },
      it: { official: 'Regno di Danimarca', common: 'Danimarca' },
      es: { official: 'Reino de Dinamarca', common: 'Dinamarca' }
    }
  },
  {
    cca2: 'DO',
    translations: {
      en: { common: 'Dominican Republic' },
      nl: { common: 'Dominicaanse Republiek' },
      de: {
        official: 'Dominikanische Republik',
        common: 'Dominikanische Republik'
      },
      fr: {
        official: 'R\u00e9publique Dominicaine',
        common: 'R\u00e9publique dominicaine'
      },
      it: {
        official: 'Repubblica Dominicana',
        common: 'Repubblica Dominicana'
      },
      es: {
        official: 'Rep\u00fablica Dominicana',
        common: 'Rep\u00fablica Dominicana'
      }
    }
  },
  {
    cca2: 'DZ',
    translations: {
      en: { common: 'Algeria' },
      nl: { common: 'Algerije' },
      de: {
        official: 'Demokratische Volksrepublik Algerien',
        common: 'Algerien'
      },
      fr: {
        official:
          "R\u00e9publique d\u00e9mocratique et populaire d'Alg\u00e9rie",
        common: 'Alg\u00e9rie'
      },
      it: {
        official: 'Repubblica popolare democratica di Algeria',
        common: 'Algeria'
      },
      es: {
        official: 'Rep\u00fablica Argelina Democr\u00e1tica y Popular',
        common: 'Argelia'
      }
    }
  },
  {
    cca2: 'EC',
    translations: {
      en: { common: 'Ecuador' },
      nl: { common: 'Ecuador' },
      de: { official: 'Republik Ecuador', common: 'Ecuador' },
      fr: {
        official: "R\u00e9publique de l'\u00c9quateur",
        common: '\u00c9quateur'
      },
      it: { official: "Repubblica dell'Ecuador", common: 'Ecuador' },
      es: { official: 'Rep\u00fablica del Ecuador', common: 'Ecuador' }
    }
  },
  {
    cca2: 'EG',
    translations: {
      en: { common: 'Egypt' },
      nl: { common: 'Egypte' },
      de: {
        official: 'Arabische Republik \u00c4gypten',
        common: '\u00c4gypten'
      },
      fr: {
        official: "R\u00e9publique arabe d'\u00c9gypte",
        common: '\u00c9gypte'
      },
      it: { official: "Repubblica araba d'Egitto", common: 'Egitto' },
      es: { official: 'Rep\u00fablica \u00c1rabe de Egipto', common: 'Egipto' }
    }
  },
  {
    cca2: 'ER',
    translations: {
      en: { common: 'Eritrea' },
      nl: { common: 'Eritrea' },
      de: { official: 'Staat Eritrea', common: 'Eritrea' },
      fr: {
        official: "\u00c9tat d'\u00c9rythr\u00e9e",
        common: '\u00c9rythr\u00e9e'
      },
      it: { official: 'Stato di Eritrea', common: 'Eritrea' },
      es: { official: 'Estado de Eritrea', common: 'Eritrea' }
    }
  },
  {
    cca2: 'EH',
    translations: {
      en: { common: 'Western Sahara' },
      nl: { common: 'Westelijke Sahara' },
      de: {
        official: 'Demokratische Arabische Republik Sahara',
        common: 'Westsahara'
      },
      fr: {
        official: 'R\u00e9publique arabe sahraouie d\u00e9mocratique',
        common: 'Sahara Occidental'
      },
      it: {
        official: 'Repubblica Araba Saharawi Democratica',
        common: 'Sahara Occidentale'
      },
      es: {
        official: 'Rep\u00fablica \u00c1rabe Saharaui Democr\u00e1tica',
        common: 'Sahara Occidental'
      }
    }
  },
  {
    cca2: 'ES',
    translations: {
      en: { common: 'Spain' },
      nl: { common: 'Spanje' },
      de: { official: 'K\u00f6nigreich Spanien', common: 'Spanien' },
      fr: { official: "Royaume d'Espagne", common: 'Espagne' },
      it: { official: 'Regno di Spagna', common: 'Spagna' },
      es: { official: 'Reino de Espa\u00f1a', common: 'Espa\u00f1a' }
    }
  },
  {
    cca2: 'EE',
    translations: {
      en: { common: 'Estonia' },
      nl: { common: 'Estland' },
      de: { official: 'Republik Estland', common: 'Estland' },
      fr: { official: "R\u00e9publique d'Estonie", common: 'Estonie' },
      it: { official: 'Repubblica di Estonia', common: 'Estonia' },
      es: { official: 'Rep\u00fablica de Estonia', common: 'Estonia' }
    }
  },
  {
    cca2: 'ET',
    translations: {
      en: { common: 'Ethiopia' },
      nl: { common: 'Ethiopië' },
      de: {
        official: 'Demokratische Bundesrepublik \u00c4thiopien',
        common: '\u00c4thiopien'
      },
      fr: {
        official:
          "R\u00e9publique f\u00e9d\u00e9rale d\u00e9mocratique d'\u00c9thiopie",
        common: '\u00c9thiopie'
      },
      it: {
        official: 'Repubblica federale democratica di Etiopia',
        common: 'Etiopia'
      },
      es: {
        official: 'Rep\u00fablica Democr\u00e1tica Federal de Etiop\u00eda',
        common: 'Etiop\u00eda'
      }
    }
  },
  {
    cca2: 'FI',
    translations: {
      en: { common: 'Finland' },
      nl: { common: 'Finland' },
      de: { official: 'Republik Finnland', common: 'Finnland' },
      fr: { official: 'R\u00e9publique de Finlande', common: 'Finlande' },
      it: { official: 'Repubblica di Finlandia', common: 'Finlandia' },
      es: { official: 'Rep\u00fablica de Finlandia', common: 'Finlandia' }
    }
  },
  {
    cca2: 'FJ',
    translations: {
      en: { common: 'Fiji' },
      nl: { common: 'Fiji' },
      de: { official: 'Republik Fidschi', common: 'Fidschi' },
      fr: { official: 'R\u00e9publique des Fidji', common: 'Fidji' },
      it: { official: 'Repubblica di Figi', common: 'Figi' },
      es: { official: 'Rep\u00fablica de Fiji', common: 'Fiyi' }
    }
  },
  {
    cca2: 'FK',
    translations: {
      en: { common: 'Falkland Islands' },
      nl: { common: 'Falkland Eilanden' },
      de: { official: 'Falkland-Inseln', common: 'Falklandinseln' },
      fr: { official: '\u00celes Malouines', common: '\u00celes Malouines' },
      it: {
        official: 'Isole Falkland',
        common: 'Isole Falkland o Isole Malvine'
      },
      es: { official: 'islas Malvinas', common: 'Islas Malvinas' }
    }
  },
  {
    cca2: 'FR',
    translations: {
      en: { common: 'France' },
      nl: { common: 'Frankrijk' },
      de: { official: 'Franz\u00f6sische Republik', common: 'Frankreich' },
      fr: { official: 'R\u00e9publique fran\u00e7aise', common: 'France' },
      it: { official: 'Repubblica francese', common: 'Francia' },
      es: { official: 'Rep\u00fablica franc\u00e9s', common: 'Francia' }
    }
  },
  {
    cca2: 'FO',
    translations: {
      en: { common: 'Faroe Islands' },
      nl: { common: 'Faeröer' },
      de: { official: 'F\u00e4r\u00f6er', common: 'F\u00e4r\u00f6er-Inseln' },
      fr: {
        official: '\u00celes F\u00e9ro\u00e9',
        common: '\u00celes F\u00e9ro\u00e9'
      },
      it: { official: 'Isole Faroe', common: 'Isole Far Oer' },
      es: { official: 'Islas Feroe', common: 'Islas Faroe' }
    }
  },
  {
    cca2: 'FM',
    translations: {
      en: { common: 'Micronesia' },
      nl: { common: 'Micronesië' },
      de: {
        official: 'F\u00f6derierte Staaten von Mikronesien',
        common: 'Mikronesien'
      },
      fr: {
        official: '\u00c9tats f\u00e9d\u00e9r\u00e9s de Micron\u00e9sie',
        common: 'Micron\u00e9sie'
      },
      it: { official: 'Stati federati di Micronesia', common: 'Micronesia' },
      es: { official: 'Estados Federados de Micronesia', common: 'Micronesia' }
    }
  },
  {
    cca2: 'GA',
    translations: {
      en: { common: 'Gabon' },
      nl: { common: 'Gabon' },
      de: { official: 'Gabunische Republik', common: 'Gabun' },
      fr: { official: 'R\u00e9publique gabonaise', common: 'Gabon' },
      it: { official: 'Repubblica gabonese', common: 'Gabon' },
      es: { official: 'Rep\u00fablica de Gab\u00f3n', common: 'Gab\u00f3n' }
    }
  },
  {
    cca2: 'GB',
    translations: {
      en: { common: 'United Kingdom' },
      nl: { common: 'Verenigd Koninkrijk' },
      de: {
        official:
          'Vereinigtes K\u00f6nigreich Gro\u00dfbritannien und Nordirland',
        common: 'Vereinigtes K\u00f6nigreich'
      },
      fr: {
        official: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
        common: 'Royaume-Uni'
      },
      it: {
        official: 'Regno Unito di Gran Bretagna e Irlanda del Nord',
        common: 'Regno Unito'
      },
      es: {
        official: 'Reino Unido de Gran Breta\u00f1a e Irlanda del Norte',
        common: 'Reino Unido'
      }
    }
  },
  {
    cca2: 'GE',
    translations: {
      en: { common: 'Georgia' },
      nl: { common: 'Georgië' },
      de: { official: 'Georgia', common: 'Georgien' },
      fr: {
        official: 'R\u00e9publique de G\u00e9orgie',
        common: 'G\u00e9orgie'
      },
      it: { official: 'Georgia', common: 'Georgia' },
      es: { official: 'Georgia', common: 'Georgia' }
    }
  },
  {
    cca2: 'GG',
    translations: {
      en: { common: 'Guernsey' },
      nl: { common: 'Guernsey' },
      de: { official: 'Guernsey', common: 'Guernsey' },
      fr: { official: 'Bailliage de Guernesey', common: 'Guernesey' },
      it: { official: 'Baliato di Guernsey', common: 'Guernsey' },
      es: { official: 'Bail\u00eda de Guernsey', common: 'Guernsey' }
    }
  },
  {
    cca2: 'GH',
    translations: {
      en: { common: 'Ghana' },
      nl: { common: 'Ghana' },
      de: { official: 'Republik Ghana', common: 'Ghana' },
      fr: { official: 'R\u00e9publique du Ghana', common: 'Ghana' },
      it: { official: 'Repubblica del Ghana', common: 'Ghana' },
      es: { official: 'Rep\u00fablica de Ghana', common: 'Ghana' }
    }
  },
  {
    cca2: 'GI',
    translations: {
      en: { common: 'Gibraltar' },
      nl: { common: 'Gibraltar' },
      de: { official: 'Gibraltar', common: 'Gibraltar' },
      fr: { official: 'Gibraltar', common: 'Gibraltar' },
      it: { official: 'Gibilterra', common: 'Gibilterra' },
      es: { official: 'Gibraltar', common: 'Gibraltar' }
    }
  },
  {
    cca2: 'GN',
    translations: {
      en: { common: 'Guinea' },
      nl: { common: 'Guinea' },
      de: { official: 'Republik Guinea', common: 'Guinea' },
      fr: { official: 'R\u00e9publique de Guin\u00e9e', common: 'Guin\u00e9e' },
      it: { official: 'Repubblica di Guinea', common: 'Guinea' },
      es: { official: 'Rep\u00fablica de Guinea', common: 'Guinea' }
    }
  },
  {
    cca2: 'GP',
    translations: {
      en: { common: 'Guadeloupe' },
      nl: { common: 'Guadeloupe' },
      de: { official: 'Guadeloupe', common: 'Guadeloupe' },
      fr: { official: 'Guadeloupe', common: 'Guadeloupe' },
      it: { official: 'Guadeloupe', common: 'Guadeloupa' },
      es: { official: 'Guadalupe', common: 'Guadalupe' }
    }
  },
  {
    cca2: 'GM',
    translations: {
      en: { common: 'Gambia' },
      nl: { common: 'Gambia' },
      de: { official: 'Republik Gambia', common: 'Gambia' },
      fr: { official: 'R\u00e9publique de Gambie', common: 'Gambie' },
      it: { official: 'Repubblica del Gambia', common: 'Gambia' },
      es: { official: 'Rep\u00fablica de Gambia', common: 'Gambia' }
    }
  },
  {
    cca2: 'GW',
    translations: {
      en: { common: 'Guinea-Bissau' },
      nl: { common: 'Guinee-Bissau' },
      de: { official: 'Republik Guinea-Bissau', common: 'Guinea-Bissau' },
      fr: {
        official: 'R\u00e9publique de Guin\u00e9e-Bissau',
        common: 'Guin\u00e9e-Bissau'
      },
      it: { official: 'Repubblica di Guinea-Bissau', common: 'Guinea-Bissau' },
      es: {
        official: 'Rep\u00fablica de Guinea-Bissau',
        common: 'Guinea-Bis\u00e1u'
      }
    }
  },
  {
    cca2: 'GQ',
    translations: {
      en: { common: 'Equatorial Guinea' },
      nl: { common: 'Equatoriaal-Guinea' },
      de: {
        official: 'Republik \u00c4quatorialguinea',
        common: '\u00c4quatorialguinea'
      },
      fr: {
        official: 'R\u00e9publique de Guin\u00e9e \u00e9quatoriale',
        common: 'Guin\u00e9e \u00e9quatoriale'
      },
      it: {
        official: 'Repubblica della Guinea Equatoriale',
        common: 'Guinea Equatoriale'
      },
      es: {
        official: 'Rep\u00fablica de Guinea Ecuatorial',
        common: 'Guinea Ecuatorial'
      }
    }
  },
  {
    cca2: 'GR',
    translations: {
      en: { common: 'Greece' },
      nl: { common: 'Griekenland' },
      de: { official: 'Hellenische Republik', common: 'Griechenland' },
      fr: { official: 'R\u00e9publique hell\u00e9nique', common: 'Gr\u00e8ce' },
      it: { official: 'Repubblica ellenica', common: 'Grecia' },
      es: { official: 'Rep\u00fablica Hel\u00e9nica', common: 'Grecia' }
    }
  },
  {
    cca2: 'GD',
    translations: {
      en: { common: 'Grenada' },
      nl: { common: 'Grenada' },
      de: { official: 'Grenada', common: 'Grenada' },
      fr: { official: 'Grenade', common: 'Grenade' },
      it: { official: 'Grenada', common: 'Grenada' },
      es: { official: 'Granada', common: 'Grenada' }
    }
  },
  {
    cca2: 'GL',
    translations: {
      en: { common: 'Greenland' },
      nl: { common: 'Groenland' },
      de: { official: 'Gr\u00f6nland', common: 'Gr\u00f6nland' },
      fr: { official: 'Groenland', common: 'Groenland' },
      it: { official: 'Groenlandia', common: 'Groenlandia' },
      es: { official: 'Groenlandia', common: 'Groenlandia' }
    }
  },
  {
    cca2: 'GT',
    translations: {
      en: { common: 'Guatemala' },
      nl: { common: 'Guatemala' },
      de: { official: 'Republik Guatemala', common: 'Guatemala' },
      fr: { official: 'R\u00e9publique du Guatemala', common: 'Guatemala' },
      it: { official: 'Repubblica del Guatemala', common: 'Guatemala' },
      es: { official: 'Rep\u00fablica de Guatemala', common: 'Guatemala' }
    }
  },
  {
    cca2: 'GF',
    translations: {
      en: { common: 'French Guiana' },
      nl: { common: 'Frans-Guyana' },
      de: { official: 'Guayana', common: 'Franz\u00f6sisch Guyana' },
      fr: { official: 'Guyane', common: 'Guyane' },
      it: { official: 'Guiana', common: 'Guyana francese' },
      es: { official: 'Guayana', common: 'Guayana Francesa' }
    }
  },
  {
    cca2: 'GU',
    translations: {
      en: { common: 'Guam' },
      nl: { common: 'Guam' },
      de: { official: 'Guam', common: 'Guam' },
      fr: { official: 'Guam', common: 'Guam' },
      it: { official: 'Guam', common: 'Guam' },
      es: { official: 'Guam', common: 'Guam' }
    }
  },
  {
    cca2: 'GY',
    translations: {
      en: { common: 'Guyana' },
      nl: { common: 'Guyana' },
      de: { official: 'Kooperative Republik Guyana', common: 'Guyana' },
      fr: {
        official: 'R\u00e9publique coop\u00e9rative de Guyana',
        common: 'Guyana'
      },
      it: { official: 'Co -operative Republic of Guyana', common: 'Guyana' },
      es: { official: 'Rep\u00fablica Cooperativa de Guyana', common: 'Guyana' }
    }
  },
  {
    cca2: 'HK',
    translations: {
      en: { common: 'Hong Kong' },
      nl: { common: 'Hong Kong' },
      de: {
        official: 'Sonderverwaltungszone der Volksrepublik China',
        common: 'Hongkong'
      },
      fr: {
        official:
          'R\u00e9gion administrative sp\u00e9ciale de Hong Kong de la R\u00e9publique populaire de Chine',
        common: 'Hong Kong'
      },
      it: {
        official:
          'Hong Kong Regione amministrativa speciale della Repubblica Popolare Cinese',
        common: 'Hong Kong'
      },
      es: {
        official:
          'Hong Kong Regi\u00f3n Administrativa Especial de la Rep\u00fablica Popular China',
        common: 'Hong Kong'
      }
    }
  },
  {
    cca2: 'HM',
    translations: {
      en: { common: 'Heard Island and McDonald Islands' },
      nl: { common: 'Heard-eiland en McDonaldeilanden' },
      de: {
        official: 'Heard und McDonaldinseln',
        common: 'Heard und die McDonaldinseln'
      },
      fr: {
        official: 'Des \u00eeles Heard et McDonald',
        common: '\u00celes Heard-et-MacDonald'
      },
      it: {
        official: 'Isole Heard e McDonald',
        common: 'Isole Heard e McDonald'
      },
      es: {
        official: 'Islas Heard y McDonald',
        common: 'Islas Heard y McDonald'
      }
    }
  },
  {
    cca2: 'HN',
    translations: {
      en: { common: 'Honduras' },
      nl: { common: 'Honduras' },
      de: { official: 'Republik Honduras', common: 'Honduras' },
      fr: { official: 'R\u00e9publique du Honduras', common: 'Honduras' },
      it: { official: 'Repubblica di Honduras', common: 'Honduras' },
      es: { official: 'Rep\u00fablica de Honduras', common: 'Honduras' }
    }
  },
  {
    cca2: 'HR',
    translations: {
      en: { common: 'Croatia' },
      nl: { common: 'Kroatië' },
      de: { official: 'Republik Kroatien', common: 'Kroatien' },
      fr: { official: 'R\u00e9publique de Croatie', common: 'Croatie' },
      it: { official: 'Repubblica di Croazia', common: 'Croazia' },
      es: { official: 'Rep\u00fablica de Croacia', common: 'Croacia' }
    }
  },
  {
    cca2: 'HT',
    translations: {
      en: { common: 'Haiti' },
      nl: { common: 'Haïti' },
      de: { official: 'Republik Haiti', common: 'Haiti' },
      fr: { official: "R\u00e9publique d'Ha\u00efti", common: 'Ha\u00efti' },
      it: { official: 'Repubblica di Haiti', common: 'Haiti' },
      es: { official: 'Rep\u00fablica de Hait\u00ed', common: 'Haiti' }
    }
  },
  {
    cca2: 'HU',
    translations: {
      en: { common: 'Hungary' },
      nl: { common: 'Hongarije' },
      de: { official: 'Ungarn', common: 'Ungarn' },
      fr: { official: 'Hongrie', common: 'Hongrie' },
      it: { official: 'Ungheria', common: 'Ungheria' },
      es: { official: 'Hungr\u00eda', common: 'Hungr\u00eda' }
    }
  },
  {
    cca2: 'ID',
    translations: {
      en: { common: 'Indonesia' },
      nl: { common: 'Indonesië' },
      de: { official: 'Republik Indonesien', common: 'Indonesien' },
      fr: {
        official: "R\u00e9publique d'Indon\u00e9sie",
        common: 'Indon\u00e9sie'
      },
      it: { official: 'Repubblica di Indonesia', common: 'Indonesia' },
      es: { official: 'Rep\u00fablica de Indonesia', common: 'Indonesia' }
    }
  },
  {
    cca2: 'IM',
    translations: {
      en: { common: 'Isle of Man' },
      nl: { common: 'Isle of Man' },
      de: { official: 'Isle of Man', common: 'Insel Man' },
      fr: { official: 'Isle of Man', common: '\u00cele de Man' },
      it: { official: 'Isola di Man', common: 'Isola di Man' },
      es: { official: 'Isla de Man', common: 'Isla de Man' }
    }
  },
  {
    cca2: 'IN',
    translations: {
      en: { common: 'India' },
      nl: { common: 'Indië' },
      de: { official: 'Republik Indien', common: 'Indien' },
      fr: { official: "R\u00e9publique de l'Inde", common: 'Inde' },
      it: { official: "Repubblica dell'India", common: 'India' },
      es: { official: 'Rep\u00fablica de la India', common: 'India' }
    }
  },
  {
    cca2: 'IO',
    translations: {
      en: { common: 'British Indian Ocean Territory' },
      nl: { common: 'Brits-Indisch oceaan gebied' },
      de: {
        official: 'Britisch-Indischer Ozean',
        common: 'Britisches Territorium im Indischen Ozean'
      },
      fr: {
        official: "Territoire britannique de l' oc\u00e9an Indien",
        common: "Territoire britannique de l'oc\u00e9an Indien"
      },
      it: {
        official: "Territorio britannico dell'Oceano Indiano",
        common: "Territorio britannico dell'oceano indiano"
      },
      es: {
        official: 'Territorio Brit\u00e1nico del Oc\u00e9ano \u00cdndico',
        common: 'Territorio Brit\u00e1nico del Oc\u00e9ano \u00cdndico'
      }
    }
  },
  {
    cca2: 'IE',
    translations: {
      en: { common: 'Ireland' },
      nl: { common: 'Ierland' },
      de: { official: 'Republik Irland', common: 'Irland' },
      fr: { official: "R\u00e9publique d'Irlande", common: 'Irlande' },
      it: { official: "Repubblica d'Irlanda", common: 'Irlanda' },
      es: { official: 'Rep\u00fablica de Irlanda', common: 'Irlanda' }
    }
  },
  {
    cca2: 'IR',
    translations: {
      en: { common: 'Iran' },
      nl: { common: 'Iran' },
      de: { official: 'Islamische Republik Iran', common: 'Iran' },
      fr: { official: "R\u00e9publique islamique d'Iran", common: 'Iran' },
      it: { common: 'Iran' },
      es: {
        official: 'Rep\u00fablica Isl\u00e1mica de Ir\u00e1n',
        common: 'Iran'
      }
    }
  },
  {
    cca2: 'IQ',
    translations: {
      en: { common: 'Iraq' },
      nl: { common: 'Irak' },
      de: { official: 'Republik Irak', common: 'Irak' },
      fr: { official: "R\u00e9publique d'Irak", common: 'Irak' },
      it: { official: "Repubblica dell'Iraq", common: 'Iraq' },
      es: { official: 'Rep\u00fablica de Irak', common: 'Irak' }
    }
  },
  {
    cca2: 'IS',
    translations: {
      en: { common: 'Iceland' },
      nl: { common: 'IJsland' },
      de: { official: 'Island', common: 'Island' },
      fr: { official: "R\u00e9publique d'Islande", common: 'Islande' },
      it: { official: 'Islanda', common: 'Islanda' },
      es: { official: 'Islandia', common: 'Islandia' }
    }
  },
  {
    cca2: 'IL',
    translations: {
      en: { common: 'Israel' },
      nl: { common: 'Israël' },
      de: { official: 'Staat Israel', common: 'Israel' },
      fr: { official: "\u00c9tat d'Isra\u00ebl", common: 'Isra\u00ebl' },
      it: { official: 'Stato di Israele', common: 'Israele' },
      es: { official: 'Estado de Israel', common: 'Israel' }
    }
  },
  {
    cca2: 'IT',
    translations: {
      en: { common: 'Italy' },
      nl: { common: 'Italië' },
      de: { official: 'Italienische Republik', common: 'Italien' },
      fr: { official: 'R\u00e9publique italienne', common: 'Italie' },
      it: { official: 'Repubblica italiana', common: 'Italia' },
      es: { official: 'Rep\u00fablica Italiana', common: 'Italia' }
    }
  },
  {
    cca2: 'JM',
    translations: {
      en: { common: 'Jamaica' },
      nl: { common: 'Jamaica' },
      de: { official: 'Jamaika', common: 'Jamaika' },
      fr: { official: 'Jama\u00efque', common: 'Jama\u00efque' },
      it: { official: 'Giamaica', common: 'Giamaica' },
      es: { official: 'Jamaica', common: 'Jamaica' }
    }
  },
  {
    cca2: 'JE',
    translations: {
      en: { common: 'Jersey' },
      nl: { common: 'Jersey' },
      de: { official: 'Vogtei Jersey', common: 'Jersey' },
      fr: { official: 'Bailliage de Jersey', common: 'Jersey' },
      it: { official: 'Baliato di Jersey', common: 'Isola di Jersey' },
      es: { official: 'Bail\u00eda de Jersey', common: 'Jersey' }
    }
  },
  {
    cca2: 'JO',
    translations: {
      en: { common: 'Jordan' },
      nl: { common: 'Jordanië' },
      de: {
        official: 'Haschemitisches K\u00f6nigreich Jordanien',
        common: 'Jordanien'
      },
      fr: {
        official: 'Royaume hach\u00e9mite de Jordanie',
        common: 'Jordanie'
      },
      it: { official: 'Regno hascemita di Giordania', common: 'Giordania' },
      es: { official: 'Reino Hachemita de Jordania', common: 'Jordania' }
    }
  },
  {
    cca2: 'JP',
    translations: {
      en: { common: 'Japan' },
      nl: { common: 'Japan' },
      de: { official: 'Japan', common: 'Japan' },
      fr: { official: 'Japon', common: 'Japon' },
      it: { official: 'Giappone', common: 'Giappone' },
      es: { official: 'Jap\u00f3n', common: 'Jap\u00f3n' }
    }
  },
  {
    cca2: 'KZ',
    translations: {
      en: { common: 'Kazakhstan' },
      nl: { common: 'Kazachstan' },
      de: { official: 'Republik Kasachstan', common: 'Kasachstan' },
      fr: { official: 'R\u00e9publique du Kazakhstan', common: 'Kazakhstan' },
      it: { official: 'Repubblica del Kazakhstan', common: 'Kazakistan' },
      es: {
        official: 'Rep\u00fablica de Kazajst\u00e1n',
        common: 'Kazajist\u00e1n'
      }
    }
  },
  {
    cca2: 'KE',
    translations: {
      en: { common: 'Kenya' },
      nl: { common: 'Kenia' },
      de: { official: 'Republik Kenia', common: 'Kenia' },
      fr: { official: 'R\u00e9publique du Kenya', common: 'Kenya' },
      it: { official: 'Repubblica del Kenya', common: 'Kenya' },
      es: { official: 'Rep\u00fablica de Kenya', common: 'Kenia' }
    }
  },
  {
    cca2: 'KG',
    translations: {
      en: { common: 'Kyrgyzstan' },
      nl: { common: 'Kirgizië' },
      de: { official: 'Kirgisische Republik', common: 'Kirgisistan' },
      fr: { official: 'R\u00e9publique kirghize', common: 'Kirghizistan' },
      it: { official: 'Kirghizistan', common: 'Kirghizistan' },
      es: { official: 'Rep\u00fablica Kirguisa', common: 'Kirguizist\u00e1n' }
    }
  },
  {
    cca2: 'KH',
    translations: {
      en: { common: 'Cambodia' },
      nl: { common: 'Cambodja' },
      de: { official: 'K\u00f6nigreich Kambodscha', common: 'Kambodscha' },
      fr: { official: 'Royaume du Cambodge', common: 'Cambodge' },
      it: { official: 'Regno di Cambogia', common: 'Cambogia' },
      es: { official: 'Reino de Camboya', common: 'Camboya' }
    }
  },
  {
    cca2: 'KI',
    translations: {
      en: { common: 'Kiribati' },
      nl: { common: 'Kiribati' },
      de: {
        official: 'Unabh\u00e4ngige und souver\u00e4ne Republik Kiribati',
        common: 'Kiribati'
      },
      fr: { official: 'R\u00e9publique de Kiribati', common: 'Kiribati' },
      it: {
        official: 'Repubblica indipendente e sovrano di Kiribati',
        common: 'Kiribati'
      },
      es: {
        official: 'Rep\u00fablica Independiente y Soberano de Kiribati',
        common: 'Kiribati'
      }
    }
  },
  {
    cca2: 'KN',
    translations: {
      en: { common: 'Saint Kitts and Nevis' },
      nl: { common: 'Saint Kitts en Nevis' },
      de: {
        official: 'F\u00f6deration von Saint Kitts und Nevisa',
        common: 'Saint Christopher und Nevis'
      },
      fr: {
        official: 'F\u00e9d\u00e9ration de Saint -Christophe-et Nevisa',
        common: 'Saint-Christophe-et-Ni\u00e9v\u00e8s'
      },
      it: {
        official: 'Federazione di Saint Christopher e Nevisa',
        common: 'Saint Kitts e Nevis'
      },
      es: {
        official: 'Federaci\u00f3n de San Crist\u00f3bal y Nevisa',
        common: 'San Crist\u00f3bal y Nieves'
      }
    }
  },
  {
    cca2: 'KR',
    translations: {
      en: { common: 'South Korea' },
      nl: { common: 'Zuid-Korea' },
      de: { official: 'Republik Korea', common: 'S\u00fcdkorea' },
      fr: {
        official: 'R\u00e9publique de Cor\u00e9e',
        common: 'Cor\u00e9e du Sud'
      },
      it: { official: 'Repubblica di Corea', common: 'Corea del Sud' },
      es: { official: 'Rep\u00fablica de Corea', common: 'Corea del Sur' }
    }
  },
  {
    cca2: 'XK',
    translations: {
      en: { common: 'Kosovo' },
      nl: { common: 'Kosovo' },
      de: { common: 'Kosovo' },
      fr: { official: 'R\u00E9publique du Kosovo', common: 'Kosovo' },
      it: { common: 'Kosovo' },
      es: { official: 'Rep\u00fablica de Kosovo', common: 'Kosovo' }
    }
  },
  {
    cca2: 'KW',
    translations: {
      en: { common: 'Kuwait' },
      nl: { common: 'Koeweit' },
      de: { official: 'Staat Kuwait', common: 'Kuwait' },
      fr: { official: '\u00c9tat du Kowe\u00eft', common: 'Kowe\u00eft' },
      it: { official: 'Stato del Kuwait', common: 'Kuwait' },
      es: { official: 'Estado de Kuwait', common: 'Kuwait' }
    }
  },
  {
    cca2: 'LA',
    translations: {
      en: { common: 'Laos' },
      nl: { common: 'Laos' },
      de: { official: 'Laos, Demokratische Volksrepublik', common: 'Laos' },
      fr: {
        official: 'R\u00e9publique d\u00e9mocratique populaire lao',
        common: 'Laos'
      },
      it: {
        official: 'Repubblica democratica popolare del Laos',
        common: 'Laos'
      },
      es: {
        official: 'Rep\u00fablica Democr\u00e1tica Popular Lao',
        common: 'Laos'
      }
    }
  },
  {
    cca2: 'LB',
    translations: {
      en: { common: 'Lebanon' },
      nl: { common: 'Libanon' },
      de: { official: 'Libanesische Republik', common: 'Libanon' },
      fr: { official: 'R\u00e9publique libanaise', common: 'Liban' },
      it: { official: 'Repubblica libanese', common: 'Libano' },
      es: { official: 'Rep\u00fablica Libanesa', common: 'L\u00edbano' }
    }
  },
  {
    cca2: 'LR',
    translations: {
      en: { common: 'Liberia' },
      nl: { common: 'Liberia' },
      de: { official: 'Republik Liberia', common: 'Liberia' },
      fr: { official: 'R\u00e9publique du Lib\u00e9ria', common: 'Liberia' },
      it: { official: 'Repubblica di Liberia', common: 'Liberia' },
      es: { official: 'Rep\u00fablica de Liberia', common: 'Liberia' }
    }
  },
  {
    cca2: 'LY',
    translations: {
      en: { common: 'Libya' },
      nl: { common: 'Libië' },
      de: { official: 'Staat Libyen', common: 'Libyen' },
      fr: {
        official:
          'Grande R\u00e9publique arabe libyenne populaire et socialiste',
        common: 'Libye'
      },
      it: { official: 'Stato della Libia', common: 'Libia' },
      es: { official: 'Estado de Libia', common: 'Libia' }
    }
  },
  {
    cca2: 'LC',
    translations: {
      en: { common: 'Saint Lucia' },
      nl: { common: 'Saint Lucia' },
      de: { official: 'St. Lucia', common: 'Saint Lucia' },
      fr: { official: 'Sainte-Lucie', common: 'Sainte-Lucie' },
      it: { official: 'Santa Lucia', common: 'Santa Lucia' },
      es: { official: 'Santa Luc\u00eda', common: 'Santa Luc\u00eda' }
    }
  },
  {
    cca2: 'LI',
    translations: {
      en: { common: 'Liechtenstein' },
      nl: { common: 'Liechtenstein' },
      de: {
        official: 'F\u00fcrstentum Liechtenstein',
        common: 'Liechtenstein'
      },
      fr: {
        official: 'Principaut\u00e9 du Liechtenstein',
        common: 'Liechtenstein'
      },
      it: { official: 'Principato del Liechtenstein', common: 'Liechtenstein' },
      es: { official: 'Principado de Liechtenstein', common: 'Liechtenstein' }
    }
  },
  {
    cca2: 'LK',
    translations: {
      en: { common: 'Sri Lanka' },
      nl: { common: 'Sri Lanka' },
      de: {
        official: 'Demokratische Sozialistische Republik Sri Lanka',
        common: 'Sri Lanka'
      },
      fr: {
        official: 'R\u00e9publique d\u00e9mocratique socialiste du Sri Lanka',
        common: 'Sri Lanka'
      },
      it: {
        official: 'Repubblica democratica socialista dello Sri Lanka',
        common: 'Sri Lanka'
      },
      es: {
        official: 'Rep\u00fablica Democr\u00e1tica Socialista de Sri Lanka',
        common: 'Sri Lanka'
      }
    }
  },
  {
    cca2: 'LS',
    translations: {
      en: { common: 'Lesotho' },
      nl: { common: 'Lesotho' },
      de: { official: 'K\u00f6nigreich Lesotho', common: 'Lesotho' },
      fr: { official: 'Royaume du Lesotho', common: 'Lesotho' },
      it: { official: 'Regno del Lesotho', common: 'Lesotho' },
      es: { official: 'Reino de Lesotho', common: 'Lesotho' }
    }
  },
  {
    cca2: 'LT',
    translations: {
      en: { common: 'Lithuania' },
      nl: { common: 'Litouwen' },
      de: { official: 'Republik Litauen', common: 'Litauen' },
      fr: { official: 'R\u00e9publique de Lituanie', common: 'Lituanie' },
      it: { official: 'Repubblica di Lituania', common: 'Lituania' },
      es: { official: 'Rep\u00fablica de Lituania', common: 'Lituania' }
    }
  },
  {
    cca2: 'LU',
    translations: {
      en: { common: 'Luxembourg' },
      nl: { common: 'Luxemburg' },
      de: { official: 'Gro\u00dfherzogtum Luxemburg,', common: 'Luxemburg' },
      fr: { official: 'Grand-Duch\u00e9 de Luxembourg', common: 'Luxembourg' },
      it: { official: 'Granducato di Lussemburgo', common: 'Lussemburgo' },
      es: { official: 'Gran Ducado de Luxemburgo', common: 'Luxemburgo' }
    }
  },
  {
    cca2: 'LV',
    translations: {
      en: { common: 'Latvia' },
      nl: { common: 'Letland' },
      de: { official: 'Republik Lettland', common: 'Lettland' },
      fr: { official: 'R\u00e9publique de Lettonie', common: 'Lettonie' },
      it: { official: 'Repubblica di Lettonia', common: 'Lettonia' },
      es: { official: 'Rep\u00fablica de Letonia', common: 'Letonia' }
    }
  },
  {
    cca2: 'MO',
    translations: {
      en: { common: 'Macau' },
      nl: { common: 'Macau' },
      de: {
        official: 'Sonderverwaltungsregion Macau der Volksrepublik China',
        common: 'Macao'
      },
      fr: {
        official:
          'R\u00e9gion administrative sp\u00e9ciale de Macao de la R\u00e9publique populaire de Chine',
        common: 'Macao'
      },
      it: {
        official:
          'Macao Regione amministrativa speciale della Repubblica Popolare Cinese',
        common: 'Macao'
      },
      es: {
        official:
          'Macao, Regi\u00f3n Administrativa Especial de la Rep\u00fablica Popular China',
        common: 'Macao'
      }
    }
  },
  {
    cca2: 'MF',
    translations: {
      en: { common: 'Saint Martin' },
      nl: { common: 'Sint-Maarten' },
      de: { official: 'St. Martin', common: 'Saint Martin' },
      fr: { official: 'Saint-Martin', common: 'Saint-Martin' },
      it: { official: 'saint Martin', common: 'Saint Martin' },
      es: { official: 'Saint Martin', common: 'Saint Martin' }
    }
  },
  {
    cca2: 'MA',
    translations: {
      en: { common: 'Morocco' },
      nl: { common: 'Marokko' },
      de: { official: 'K\u00f6nigreich Marokko', common: 'Marokko' },
      fr: { official: 'Royaume du Maroc', common: 'Maroc' },
      it: { official: 'Regno del Marocco', common: 'Marocco' },
      es: { official: 'Reino de Marruecos', common: 'Marruecos' }
    }
  },
  {
    cca2: 'MC',
    translations: {
      en: { common: 'Monaco' },
      nl: { common: 'Monaco' },
      de: { official: 'F\u00fcrstentum Monaco', common: 'Monaco' },
      fr: { official: 'Principaut\u00e9 de Monaco', common: 'Monaco' },
      it: { official: 'Principato di Monaco', common: 'Principato di Monaco' },
      es: { official: 'Principado de M\u00f3naco', common: 'M\u00f3naco' }
    }
  },
  {
    cca2: 'MD',
    translations: {
      en: { common: 'Moldova' },
      nl: { common: 'Moldavië' },
      de: { official: 'Republik Moldau', common: 'Moldawie' },
      fr: { official: 'R\u00e9publique de Moldavie', common: 'Moldavie' },
      it: { official: 'Repubblica di Moldova', common: 'Moldavia' },
      es: { official: 'Rep\u00fablica de Moldova', common: 'Moldavia' }
    }
  },
  {
    cca2: 'MG',
    translations: {
      en: { common: 'Madagascar' },
      nl: { common: 'Madagascar' },
      de: { official: 'Republik Madagaskar', common: 'Madagaskar' },
      fr: { official: 'R\u00e9publique de Madagascar', common: 'Madagascar' },
      it: { official: 'Repubblica del Madagascar', common: 'Madagascar' },
      es: { official: 'Rep\u00fablica de Madagascar', common: 'Madagascar' }
    }
  },
  {
    cca2: 'MV',
    translations: {
      en: { common: 'Maldives' },
      nl: { common: 'Maldiven' },
      de: { official: 'Republik Malediven', common: 'Malediven' },
      fr: { official: 'R\u00e9publique des Maldives', common: 'Maldives' },
      it: { official: 'Repubblica delle Maldive', common: 'Maldive' },
      es: { official: 'Rep\u00fablica de las Maldivas', common: 'Maldivas' }
    }
  },
  {
    cca2: 'MX',
    translations: {
      en: { common: 'Mexico' },
      nl: { common: 'Mexico' },
      de: { official: 'Vereinigte Mexikanische Staaten', common: 'Mexiko' },
      fr: { official: '\u00c9tats-Unis du Mexique', common: 'Mexique' },
      it: { official: 'Stati Uniti del Messico', common: 'Messico' },
      es: { official: 'Estados Unidos Mexicanos', common: 'M\u00e9xico' }
    }
  },
  {
    cca2: 'MH',
    translations: {
      en: { common: 'Marshall Islands' },
      nl: { common: 'Marshall eilanden' },
      de: {
        official: 'Republik der Marshall-Inseln',
        common: 'Marshallinseln'
      },
      fr: {
        official: 'R\u00e9publique des \u00celes Marshall',
        common: '\u00celes Marshall'
      },
      it: {
        official: 'Repubblica delle Isole Marshall',
        common: 'Isole Marshall'
      },
      es: {
        official: 'Rep\u00fablica de las Islas Marshall',
        common: 'Islas Marshall'
      }
    }
  },
  {
    cca2: 'MK',
    translations: {
      en: { common: 'Macedonia' },
      nl: { common: 'Macedonië' },
      de: { official: 'Republik Mazedonien', common: 'Mazedonien' },
      fr: {
        official: 'R\u00e9publique de Mac\u00e9doine',
        common: 'Mac\u00e9doine'
      },
      it: { official: 'Repubblica di Macedonia', common: 'Macedonia' },
      es: { official: 'Rep\u00fablica de Macedonia', common: 'Macedonia' }
    }
  },
  {
    cca2: 'ML',
    translations: {
      en: { common: 'Mali' },
      nl: { common: 'Mali' },
      de: { official: 'Republik Mali', common: 'Mali' },
      fr: { official: 'R\u00e9publique du Mali', common: 'Mali' },
      it: { official: 'Repubblica del Mali', common: 'Mali' },
      es: { official: 'Rep\u00fablica de Mal\u00ed', common: 'Mali' }
    }
  },
  {
    cca2: 'MT',
    translations: {
      en: { common: 'Malta' },
      nl: { common: 'Malta' },
      de: { official: 'Republik Malta', common: 'Malta' },
      fr: { official: 'R\u00e9publique de Malte', common: 'Malte' },
      it: { official: 'Repubblica di Malta', common: 'Malta' },
      es: { official: 'Rep\u00fablica de Malta', common: 'Malta' }
    }
  },
  {
    cca2: 'MM',
    translations: {
      en: { common: 'Myanmar' },
      nl: { common: 'Myanmar' },
      de: { official: 'Republik der Union von Myanmar', common: 'Myanmar' },
      fr: {
        official: "R\u00e9publique de l'Union du Myanmar",
        common: 'Birmanie'
      },
      it: { official: "Repubblica dell'Unione di Myanmar", common: 'Birmania' },
      es: {
        official: 'Rep\u00fablica de la Uni\u00f3n de Myanmar',
        common: 'Myanmar'
      }
    }
  },
  {
    cca2: 'ME',
    translations: {
      en: { common: 'Montenegro' },
      nl: { common: 'Montenegro' },
      de: { official: 'Montenegro', common: 'Montenegro' },
      fr: { official: 'Mont\u00e9n\u00e9gro', common: 'Mont\u00e9n\u00e9gro' },
      it: { official: 'Montenegro', common: 'Montenegro' },
      es: { official: 'Montenegro', common: 'Montenegro' }
    }
  },
  {
    cca2: 'MN',
    translations: {
      en: { common: 'Mongolia' },
      nl: { common: 'Mongolië' },
      de: { official: 'Mongolei', common: 'Mongolei' },
      fr: { official: 'Mongolie', common: 'Mongolie' },
      it: { official: 'Mongolia', common: 'Mongolia' },
      es: { official: 'Mongolia', common: 'Mongolia' }
    }
  },
  {
    cca2: 'MP',
    translations: {
      en: { common: 'Northern Mariana Islands' },
      nl: { common: 'noordelijke Mariana eilanden' },
      de: {
        official: 'Commonwealth der N\u00f6rdlichen Marianen',
        common: 'N\u00f6rdliche Marianen'
      },
      fr: {
        official: 'Commonwealth des \u00eeles Mariannes du Nord',
        common: '\u00celes Mariannes du Nord'
      },
      it: {
        official: 'Commonwealth delle Isole Marianne Settentrionali',
        common: 'Isole Marianne Settentrionali'
      },
      es: {
        official: 'Mancomunidad de las Islas Marianas del Norte',
        common: 'Islas Marianas del Norte'
      }
    }
  },
  {
    cca2: 'MZ',
    translations: {
      en: { common: 'Mozambique' },
      nl: { common: 'Mozambique' },
      de: { official: 'Republik Mosambik', common: 'Mosambik' },
      fr: { official: 'R\u00e9publique du Mozambique', common: 'Mozambique' },
      it: { official: 'Repubblica del Mozambico', common: 'Mozambico' },
      es: { official: 'Rep\u00fablica de Mozambique', common: 'Mozambique' }
    }
  },
  {
    cca2: 'MR',
    translations: {
      en: { common: 'Mauritania' },
      nl: { common: 'Mauritanië' },
      de: {
        official: 'Islamische Republik Mauretanien',
        common: 'Mauretanien'
      },
      fr: {
        official: 'R\u00e9publique islamique de Mauritanie',
        common: 'Mauritanie'
      },
      it: {
        official: 'Repubblica islamica di Mauritania',
        common: 'Mauritania'
      },
      es: {
        official: 'Rep\u00fablica Isl\u00e1mica de Mauritania',
        common: 'Mauritania'
      }
    }
  },
  {
    cca2: 'MS',
    translations: {
      en: { common: 'Montserrat' },
      nl: { common: 'Montserrat' },
      de: { official: 'Montserrat', common: 'Montserrat' },
      fr: { official: 'Montserrat', common: 'Montserrat' },
      it: { official: 'Montserrat', common: 'Montserrat' },
      es: { official: 'Montserrat', common: 'Montserrat' }
    }
  },
  {
    cca2: 'MQ',
    translations: {
      en: { common: 'Martinique' },
      nl: { common: 'Martinique' },
      de: { official: 'Martinique', common: 'Martinique' },
      fr: { official: 'Martinique', common: 'Martinique' },
      it: { official: 'Martinique', common: 'Martinica' },
      es: { official: 'Martinica', common: 'Martinica' }
    }
  },
  {
    cca2: 'MU',
    translations: {
      en: { common: 'Mauritius' },
      nl: { common: 'Mauritius' },
      de: { official: 'Republik Mauritius', common: 'Mauritius' },
      fr: {
        official: 'R\u00e9publique de Maurice',
        common: '\u00cele Maurice'
      },
      it: { official: 'Repubblica di Mauritius', common: 'Mauritius' },
      es: { official: 'Rep\u00fablica de Mauricio', common: 'Mauricio' }
    }
  },
  {
    cca2: 'MW',
    translations: {
      en: { common: 'Malawi' },
      nl: { common: 'Malawi' },
      de: { official: 'Republik Malawi', common: 'Malawi' },
      fr: { official: 'R\u00e9publique du Malawi', common: 'Malawi' },
      it: { official: 'Repubblica del Malawi', common: 'Malawi' },
      es: { official: 'Rep\u00fablica de Malawi', common: 'Malawi' }
    }
  },
  {
    cca2: 'MY',
    translations: {
      en: { common: 'Malaysia' },
      nl: { common: 'Maleisië' },
      de: { official: 'Malaysia', common: 'Malaysia' },
      fr: { official: 'F\u00e9d\u00e9ration de Malaisie', common: 'Malaisie' },
      it: { official: 'Malaysia', common: 'Malesia' },
      es: { official: 'Malasia', common: 'Malasia' }
    }
  },
  {
    cca2: 'YT',
    translations: {
      en: { common: 'Mayotte' },
      nl: { common: 'Mayotte' },
      de: {
        official: '\u00dcbersee-D\u00e9partement Mayotte',
        common: 'Mayotte'
      },
      fr: { official: 'D\u00e9partement de Mayotte', common: 'Mayotte' },
      it: { official: 'Dipartimento di Mayotte', common: 'Mayotte' },
      es: { official: 'Departamento de Mayotte', common: 'Mayotte' }
    }
  },
  {
    cca2: 'NA',
    translations: {
      en: { common: 'Namibia' },
      nl: { common: 'Namibië' },
      de: { official: 'Republik Namibia', common: 'Namibia' },
      fr: { official: 'R\u00e9publique de Namibie', common: 'Namibie' },
      it: { official: 'Repubblica di Namibia', common: 'Namibia' },
      es: { official: 'Rep\u00fablica de Namibia', common: 'Namibia' }
    }
  },
  {
    cca2: 'NC',
    translations: {
      en: { common: 'New Caledonia' },
      nl: { common: 'Nieuw-Caledonië' },
      de: { official: 'Neukaledonien', common: 'Neukaledonien' },
      fr: {
        official: 'Nouvelle-Cal\u00e9donie',
        common: 'Nouvelle-Cal\u00e9donie'
      },
      it: { official: 'Nuova Caledonia', common: 'Nuova Caledonia' },
      es: { official: 'nueva Caledonia', common: 'Nueva Caledonia' }
    }
  },
  {
    cca2: 'NE',
    translations: {
      en: { common: 'Niger' },
      nl: { common: 'Niger' },
      de: { official: 'Republik Niger', common: 'Niger' },
      fr: { official: 'R\u00e9publique du Niger', common: 'Niger' },
      it: { official: 'Repubblica del Niger', common: 'Niger' },
      es: { official: 'Rep\u00fablica de N\u00edger', common: 'N\u00edger' }
    }
  },
  {
    cca2: 'NF',
    translations: {
      en: { common: 'Norfolk Island' },
      nl: { common: 'Norfolk Island' },
      de: { official: 'Gebiet der Norfolk-Insel', common: 'Norfolkinsel' },
      fr: {
        official: "Territoire de l'\u00eele Norfolk",
        common: '\u00cele Norfolk'
      },
      it: { official: 'Territorio di Norfolk Island', common: 'Isola Norfolk' },
      es: {
        official: 'Territorio de la Isla Norfolk',
        common: 'Isla de Norfolk'
      }
    }
  },
  {
    cca2: 'NG',
    translations: {
      en: { common: 'Nigeria' },
      nl: { common: 'Nigeria' },
      de: { official: 'Bundesrepublik Nigeria', common: 'Nigeria' },
      fr: {
        official: 'R\u00e9publique f\u00e9d\u00e9rale du Nigeria',
        common: 'Nig\u00e9ria'
      },
      it: { official: 'Repubblica federale di Nigeria', common: 'Nigeria' },
      es: { official: 'Rep\u00fablica Federal de Nigeria', common: 'Nigeria' }
    }
  },
  {
    cca2: 'NI',
    translations: {
      en: { common: 'Nicaragua' },
      nl: { common: 'Nicaragua' },
      de: { official: 'Republik Nicaragua', common: 'Nicaragua' },
      fr: { official: 'R\u00e9publique du Nicaragua', common: 'Nicaragua' },
      it: { official: 'Repubblica del Nicaragua', common: 'Nicaragua' },
      es: { official: 'Rep\u00fablica de Nicaragua', common: 'Nicaragua' }
    }
  },
  {
    cca2: 'NU',
    translations: {
      en: { common: 'Niue' },
      nl: { common: 'Niue' },
      de: { official: 'Niue', common: 'Niue' },
      fr: { official: 'Niue', common: 'Niue' },
      it: { official: 'Niue', common: 'Niue' },
      es: { official: 'Niue', common: 'Niue' }
    }
  },
  {
    cca2: 'NL',
    translations: {
      en: { common: 'Netherlands' },
      nl: { common: 'Nederland' },
      de: { official: 'Niederlande', common: 'Niederlande' },
      fr: { official: 'Pays-Bas', common: 'Pays-Bas' },
      it: { official: 'Paesi Bassi', common: 'Paesi Bassi' },
      es: { official: 'Pa\u00edses Bajos', common: 'Pa\u00edses Bajos' }
    }
  },
  {
    cca2: 'NO',
    translations: {
      en: { common: 'Norway' },
      nl: { common: 'Noorwegen' },
      de: { official: 'K\u00f6nigreich Norwegen', common: 'Norwegen' },
      fr: { official: 'Royaume de Norv\u00e8ge', common: 'Norv\u00e8ge' },
      it: { official: 'Regno di Norvegia', common: 'Norvegia' },
      es: { official: 'Reino de Noruega', common: 'Noruega' }
    }
  },
  {
    cca2: 'NP',
    translations: {
      en: { common: 'Nepal' },
      nl: { common: 'Nepal' },
      de: {
        official: 'Demokratischen Bundesrepublik Nepal',
        common: 'N\u00e9pal'
      },
      fr: { official: 'R\u00e9publique du N\u00e9pal', common: 'N\u00e9pal' },
      it: {
        official: 'Repubblica federale democratica del Nepal',
        common: 'Nepal'
      },
      es: {
        official: 'Rep\u00fablica Democr\u00e1tica Federal de Nepal',
        common: 'Nepal'
      }
    }
  },
  {
    cca2: 'NR',
    translations: {
      en: { common: 'Nauru' },
      nl: { common: 'Nauru' },
      de: { official: 'Republik Nauru', common: 'Nauru' },
      fr: { official: 'R\u00e9publique de Nauru', common: 'Nauru' },
      it: { official: 'Repubblica di Nauru', common: 'Nauru' },
      es: { official: 'Rep\u00fablica de Nauru', common: 'Nauru' }
    }
  },
  {
    cca2: 'NZ',
    translations: {
      en: { common: 'New Zealand' },
      nl: { common: 'Nieuw Zeeland' },
      de: { official: 'Neuseeland', common: 'Neuseeland' },
      fr: {
        official: 'Nouvelle-Z\u00e9lande',
        common: 'Nouvelle-Z\u00e9lande'
      },
      it: { official: 'Nuova Zelanda', common: 'Nuova Zelanda' },
      es: { official: 'nueva Zelanda', common: 'Nueva Zelanda' }
    }
  },
  {
    cca2: 'OM',
    translations: {
      en: { common: 'Oman' },
      nl: { common: 'Oman' },
      de: { official: 'Sultanat Oman', common: 'Oman' },
      fr: { official: "Sultanat d'Oman", common: 'Oman' },
      it: { official: "Sultanato dell'Oman", common: 'oman' },
      es: { official: 'Sultanato de Om\u00e1n', common: 'Om\u00e1n' }
    }
  },
  {
    cca2: 'PK',
    translations: {
      en: { common: 'Pakistan' },
      nl: { common: 'Pakistan' },
      de: { official: 'Islamische Republik Pakistan', common: 'Pakistan' },
      fr: {
        official: 'R\u00e9publique islamique du Pakistan',
        common: 'Pakistan'
      },
      it: { official: 'Repubblica islamica del Pakistan', common: 'Pakistan' },
      es: {
        official: 'Rep\u00fablica Isl\u00e1mica de Pakist\u00e1n',
        common: 'Pakist\u00e1n'
      }
    }
  },
  {
    cca2: 'PA',
    translations: {
      en: { common: 'Panama' },
      nl: { common: 'Panama' },
      de: { official: 'Republik Panama', common: 'Panama' },
      fr: { official: 'R\u00e9publique du Panama', common: 'Panama' },
      it: { official: 'Repubblica di Panama', common: 'Panama' },
      es: { official: 'Rep\u00fablica de Panam\u00e1', common: 'Panam\u00e1' }
    }
  },
  {
    cca2: 'PN',
    translations: {
      en: { common: 'Pitcairn Islands' },
      nl: { common: 'Pitcairneilanden' },
      de: { official: 'Pitcairn Inselgruppe', common: 'Pitcairn' },
      fr: {
        official: "Groupe d'\u00eeles Pitcairn",
        common: '\u00celes Pitcairn'
      },
      it: { official: 'Pitcairn gruppo di isole', common: 'Isole Pitcairn' },
      es: { official: 'Grupo de Islas Pitcairn', common: 'Islas Pitcairn' }
    }
  },
  {
    cca2: 'PE',
    translations: {
      en: { common: 'Peru' },
      nl: { common: 'Peru' },
      de: { official: 'Republik Peru', common: 'Peru' },
      fr: { official: 'R\u00e9publique du P\u00e9rou', common: 'P\u00e9rou' },
      it: { official: 'Repubblica del Per\u00f9', common: 'Per\u00f9' },
      es: { official: 'Rep\u00fablica de Per\u00fa', common: 'Per\u00fa' }
    }
  },
  {
    cca2: 'PH',
    translations: {
      en: { common: 'Philippines' },
      nl: { common: 'Filippijnen' },
      de: { official: 'Republik der Philippinen', common: 'Philippinen' },
      fr: {
        official: 'R\u00e9publique des Philippines',
        common: 'Philippines'
      },
      it: { official: 'Repubblica delle Filippine', common: 'Filippine' },
      es: { official: 'Rep\u00fablica de las Filipinas', common: 'Filipinas' }
    }
  },
  {
    cca2: 'PW',
    translations: {
      en: { common: 'Palau' },
      nl: { common: 'Palau' },
      de: { official: 'Palau', common: 'Palau' },
      fr: {
        official: 'R\u00e9publique des Palaos (Palau)',
        common: 'Palaos (Palau)'
      },
      it: { official: 'Repubblica di Palau', common: 'Palau' },
      es: { official: 'Rep\u00fablica de Palau', common: 'Palau' }
    }
  },
  {
    cca2: 'PG',
    translations: {
      en: { common: 'Papua New Guinea' },
      nl: { common: 'Papoea-Nieuw-Guinea' },
      de: {
        official: 'Unabh\u00e4ngige Staat Papua-Neuguinea',
        common: 'Papua-Neuguinea'
      },
      fr: {
        official:
          '\u00c9tat ind\u00e9pendant de Papouasie-Nouvelle-Guin\u00e9e',
        common: 'Papouasie-Nouvelle-Guin\u00e9e'
      },
      it: {
        official: 'Stato indipendente di Papua Nuova Guinea',
        common: 'Papua Nuova Guinea'
      },
      es: {
        official: 'Estado Independiente de Pap\u00faa Nueva Guinea',
        common: 'Pap\u00faa Nueva Guinea'
      }
    }
  },
  {
    cca2: 'PL',
    translations: {
      en: { common: 'Poland' },
      nl: { common: 'Polen' },
      de: { official: 'Republik Polen', common: 'Polen' },
      fr: { official: 'R\u00e9publique de Pologne', common: 'Pologne' },
      it: { official: 'Repubblica di Polonia', common: 'Polonia' },
      es: { official: 'Rep\u00fablica de Polonia', common: 'Polonia' }
    }
  },
  {
    cca2: 'PR',
    translations: {
      en: { common: 'Puerto Rico' },
      nl: { common: 'Puerto Rico' },
      de: { official: 'Commonwealth von Puerto Rico', common: 'Puerto Rico' },
      fr: { official: 'Porto Rico', common: 'Porto Rico' },
      it: { official: 'Commonwealth di Porto Rico', common: 'Porto Rico' },
      es: { official: 'Asociado de Puerto Rico', common: 'Puerto Rico' }
    }
  },
  {
    cca2: 'KP',
    translations: {
      en: { common: 'North Korea' },
      nl: { common: 'Noord Korea' },
      de: {
        official: 'Demokratische Volksrepublik Korea',
        common: 'Nordkorea'
      },
      fr: {
        official: 'R\u00e9publique populaire d\u00e9mocratique de Cor\u00e9e',
        common: 'Cor\u00e9e du Nord'
      },
      it: {
        official: 'Repubblica democratica popolare di Corea',
        common: 'Corea del Nord'
      },
      es: {
        official: 'Rep\u00fablica Popular Democr\u00e1tica de Corea',
        common: 'Corea del Norte'
      }
    }
  },
  {
    cca2: 'PT',
    translations: {
      en: { common: 'Portugal' },
      nl: { common: 'Portugal' },
      de: { official: 'Portugiesische Republik', common: 'Portugal' },
      fr: { official: 'R\u00e9publique portugaise', common: 'Portugal' },
      it: { official: 'Repubblica portoghese', common: 'Portogallo' },
      es: { official: 'Rep\u00fablica Portuguesa', common: 'Portugal' }
    }
  },
  {
    cca2: 'PY',
    translations: {
      en: { common: 'Paraguay' },
      nl: { common: 'Paraguay' },
      de: { official: 'Republik Paraguay', common: 'Paraguay' },
      fr: { official: 'R\u00e9publique du Paraguay', common: 'Paraguay' },
      it: { official: 'Repubblica del Paraguay', common: 'Paraguay' },
      es: { official: 'Rep\u00fablica de Paraguay', common: 'Paraguay' }
    }
  },
  {
    cca2: 'PS',
    translations: {
      en: { common: 'Palestine' },
      nl: { common: 'Palestina' },
      de: { official: 'Staat Pal\u00e4stina', common: 'Pal\u00e4stina' },
      fr: { official: '\u00c9tat de Palestine', common: 'Palestine' },
      it: { official: 'Stato di Palestina', common: 'Palestina' },
      es: { official: 'Estado de Palestina', common: 'Palestina' }
    }
  },
  {
    cca2: 'PF',
    translations: {
      en: { common: 'French Polynesia' },
      nl: { common: 'Frans-Polynesië' },
      de: {
        official: 'Franz\u00f6sisch-Polynesien',
        common: 'Franz\u00f6sisch-Polynesien'
      },
      fr: {
        official: 'Polyn\u00e9sie fran\u00e7aise',
        common: 'Polyn\u00e9sie fran\u00e7aise'
      },
      it: { official: 'Polinesia Francese', common: 'Polinesia Francese' },
      es: { official: 'Polinesia franc\u00e9s', common: 'Polinesia Francesa' }
    }
  },
  {
    cca2: 'QA',
    translations: {
      en: { common: 'Qatar' },
      nl: { common: 'Katar' },
      de: { official: 'Staat Katar', common: 'Katar' },
      fr: { official: '\u00c9tat du Qatar', common: 'Qatar' },
      it: { official: 'Stato del Qatar', common: 'Qatar' },
      es: { official: 'Estado de Qatar', common: 'Catar' }
    }
  },
  {
    cca2: 'RE',
    translations: {
      en: { common: 'R\u00e9union' },
      nl: { common: 'R\u00e9union' },
      de: { official: 'R\u00e9union', common: 'R\u00e9union' },
      fr: { official: 'Ile de la R\u00e9union', common: 'R\u00e9union' },
      it: { official: 'R\u00e9union', common: 'Riunione' },
      es: { official: 'Isla de la Reuni\u00f3n', common: 'Reuni\u00f3n' }
    }
  },
  {
    cca2: 'RO',
    translations: {
      en: { common: 'Romania' },
      nl: { common: 'Roemenië' },
      de: { official: 'Rum\u00e4nien', common: 'Rum\u00e4nien' },
      fr: { official: 'Roumanie', common: 'Roumanie' },
      it: { official: 'Romania', common: 'Romania' },
      es: { official: 'Rumania', common: 'Rumania' }
    }
  },
  {
    cca2: 'RU',
    translations: {
      en: { common: 'Russia' },
      nl: { common: 'Rusland' },
      de: { official: 'Russische F\u00f6deration', common: 'Russland' },
      fr: { official: 'F\u00e9d\u00e9ration de Russie', common: 'Russie' },
      it: { official: 'Federazione russa', common: 'Russia' },
      es: { official: 'Federaci\u00f3n de Rusia', common: 'Rusia' }
    }
  },
  {
    cca2: 'RW',
    translations: {
      en: { common: 'Rwanda' },
      nl: { common: 'Rwanda' },
      de: { official: 'Republik Ruanda', common: 'Ruanda' },
      fr: { official: 'R\u00e9publique rwandaise', common: 'Rwanda' },
      it: { official: 'Repubblica del Ruanda', common: 'Ruanda' },
      es: { official: 'Rep\u00fablica de Rwanda', common: 'Ruanda' }
    }
  },
  {
    cca2: 'SA',
    translations: {
      en: { common: 'Saudi Arabia' },
      nl: { common: 'Saoedi-Arabië' },
      de: {
        official: 'K\u00f6nigreich Saudi-Arabien',
        common: 'Saudi-Arabien'
      },
      fr: { official: "Royaume d'Arabie Saoudite", common: 'Arabie Saoudite' },
      it: { official: 'Arabia Saudita', common: 'Arabia Saudita' },
      es: { official: 'Reino de Arabia Saudita', common: 'Arabia Saud\u00ed' }
    }
  },
  {
    cca2: 'SD',
    translations: {
      en: { common: 'Sudan' },
      nl: { common: 'Soedan' },
      de: { official: 'Republik Sudan', common: 'Sudan' },
      fr: { official: 'R\u00e9publique du Soudan', common: 'Soudan' },
      it: { official: 'Repubblica del Sudan', common: 'Sudan' },
      es: { official: 'Rep\u00fablica de Sud\u00e1n', common: 'Sud\u00e1n' }
    }
  },
  {
    cca2: 'SN',
    translations: {
      en: { common: 'Senegal' },
      nl: { common: 'Senegal' },
      de: { official: 'Republik Senegal', common: 'Senegal' },
      fr: {
        official: 'R\u00e9publique du S\u00e9n\u00e9gal',
        common: 'S\u00e9n\u00e9gal'
      },
      it: { official: 'Repubblica del Senegal', common: 'Senegal' },
      es: { official: 'Rep\u00fablica de Senegal', common: 'Senegal' }
    }
  },
  {
    cca2: 'SG',
    translations: {
      en: { common: 'Singapore' },
      nl: { common: 'Singapore' },
      de: { official: 'Republik Singapur', common: 'Singapur' },
      fr: { official: 'R\u00e9publique de Singapour', common: 'Singapour' },
      it: { official: 'Repubblica di Singapore', common: 'Singapore' },
      es: { official: 'Rep\u00fablica de Singapur', common: 'Singapur' }
    }
  },
  {
    cca2: 'GS',
    translations: {
      en: { common: 'South Georgia' },
      nl: { common: 'South Georgia' },
      de: {
        official: 'S\u00fcdgeorgien und die S\u00fcdlichen Sandwichinseln',
        common: 'S\u00fcdgeorgien und die S\u00fcdlichen Sandwichinseln'
      },
      fr: {
        official: 'G\u00e9orgie du Sud et les \u00eeles Sandwich du Sud',
        common: 'G\u00e9orgie du Sud-et-les \u00celes Sandwich du Sud'
      },
      it: {
        official: 'Georgia del Sud e isole Sandwich del Sud',
        common: 'Georgia del Sud e Isole Sandwich Meridionali'
      },
      es: {
        official: 'Georgia del Sur y las Islas Sandwich del Sur',
        common: 'Islas Georgias del Sur y Sandwich del Sur'
      }
    }
  },
  {
    cca2: 'SJ',
    translations: {
      en: { common: 'Svalbard and Jan Mayen' },
      nl: { common: 'Svalbard en Jan Mayen' },
      de: { official: 'Inselgruppe Spitzbergen', common: 'Spitzbergen' },
      fr: { official: 'Jan Mayen Svalbard', common: 'Svalbard et Jan Mayen' },
      it: { official: 'Svalbard og Jan Mayen', common: 'Svalbard e Jan Mayen' },
      es: {
        official: 'Svalbard og Jan Mayen',
        common: 'Islas Svalbard y Jan Mayen'
      }
    }
  },
  {
    cca2: 'SB',
    translations: {
      en: { common: 'Solomon Islands' },
      nl: { common: 'Solomon eilanden' },
      de: { official: 'Salomon-Inseln', common: 'Salomonen' },
      fr: { official: '\u00celes Salomon', common: '\u00celes Salomon' },
      it: { official: 'Isole Salomone', common: 'Isole Salomone' },
      es: { official: 'islas Salom\u00f3n', common: 'Islas Salom\u00f3n' }
    }
  },
  {
    cca2: 'SL',
    translations: {
      en: { common: 'Sierra Leone' },
      nl: { common: 'Sierra Leone' },
      de: { official: 'Republik Sierra Leone', common: 'Sierra Leone' },
      fr: {
        official: 'R\u00e9publique de Sierra Leone',
        common: 'Sierra Leone'
      },
      it: { official: 'Repubblica della Sierra Leone', common: 'Sierra Leone' },
      es: { official: 'Rep\u00fablica de Sierra Leona', common: 'Sierra Leone' }
    }
  },
  {
    cca2: 'SV',
    translations: {
      en: { common: 'El Salvador' },
      nl: { common: 'El Salvador' },
      de: { official: 'Republik El Salvador', common: 'El Salvador' },
      fr: { official: 'R\u00e9publique du Salvador', common: 'Salvador' },
      it: { official: 'Repubblica di El Salvador', common: 'El Salvador' },
      es: { official: 'Rep\u00fablica de El Salvador', common: 'El Salvador' }
    }
  },
  {
    cca2: 'SM',
    translations: {
      en: { common: 'San Marino' },
      nl: { common: 'San Marino' },
      de: { official: 'Republik San Marino', common: 'San Marino' },
      fr: { official: 'R\u00e9publique de Saint-Marin', common: 'Saint-Marin' },
      it: {
        official: 'Serenissima Repubblica di San Marino',
        common: 'San Marino'
      },
      es: {
        official: 'Seren\u00edsima Rep\u00fablica de San Marino',
        common: 'San Marino'
      }
    }
  },
  {
    cca2: 'SO',
    translations: {
      en: { common: 'Somalia' },
      nl: { common: 'Somalië' },
      de: { official: 'Bundesrepublik Somalia', common: 'Somalia' },
      fr: {
        official: 'R\u00e9publique f\u00e9d\u00e9rale de Somalie',
        common: 'Somalie'
      },
      it: { official: 'Repubblica federale di Somalia', common: 'Somalia' },
      es: { official: 'Rep\u00fablica Federal de Somalia', common: 'Somalia' }
    }
  },
  {
    cca2: 'PM',
    translations: {
      en: { common: 'Saint Pierre and Miquelon' },
      nl: { common: 'Saint Pierre en Miquelon' },
      de: {
        official: 'St. Pierre und Miquelon',
        common: 'Saint-Pierre und Miquelon'
      },
      fr: {
        official: 'Saint-Pierre-et-Miquelon',
        common: 'Saint-Pierre-et-Miquelon'
      },
      it: {
        official: 'Saint Pierre e Miquelon',
        common: 'Saint-Pierre e Miquelon'
      },
      es: {
        official: 'San Pedro y Miquel\u00f3n',
        common: 'San Pedro y Miquel\u00f3n'
      }
    }
  },
  {
    cca2: 'RS',
    translations: {
      en: { common: 'Serbia' },
      nl: { common: 'Servië' },
      de: { official: 'Republik Serbien', common: 'Serbien' },
      fr: { official: 'R\u00e9publique de Serbie', common: 'Serbie' },
      it: { official: 'Repubblica di Serbia', common: 'Serbia' },
      es: { official: 'Rep\u00fablica de Serbia', common: 'Serbia' }
    }
  },
  {
    cca2: 'SS',
    translations: {
      en: { common: 'South Sudan' },
      nl: { common: 'Zuid Soedan' },
      de: { official: 'Republik S\u00fcdsudan', common: 'S\u00fcdsudan' },
      fr: {
        official: 'R\u00e9publique du Soudan du Sud',
        common: 'Soudan du Sud'
      },
      it: { official: 'Repubblica del Sudan del Sud', common: 'Sudan del sud' },
      es: {
        official: 'Rep\u00fablica de Sud\u00e1n del Sur',
        common: 'Sud\u00e1n del Sur'
      }
    }
  },
  {
    cca2: 'ST',
    translations: {
      en: { common: 'S\u00e3o Tom\u00e9 and Pr\u00edncipe' },
      nl: { common: 'S\u00e3o Tom\u00e9 en Pr\u00edncipe' },
      de: {
        official: 'Demokratische Republik S\u00e3o Tom\u00e9 und Pr\u00edncipe',
        common: 'S\u00e3o Tom\u00e9 und Pr\u00edncipe'
      },
      fr: {
        official:
          'R\u00e9publique d\u00e9mocratique de S\u00e3o Tom\u00e9 et Pr\u00edncipe',
        common: 'S\u00e3o Tom\u00e9 et Pr\u00edncipe'
      },
      it: {
        official:
          'Repubblica democratica di S\u00e3o Tom\u00e9 e Pr\u00edncipe',
        common: 'S\u00e3o Tom\u00e9 e Pr\u00edncipe'
      },
      es: {
        official:
          'Rep\u00fablica Democr\u00e1tica de Santo Tom\u00e9 y Pr\u00edncipe',
        common: 'Santo Tom\u00e9 y Pr\u00edncipe'
      }
    }
  },
  {
    cca2: 'SR',
    translations: {
      en: { common: 'Suriname' },
      nl: { common: 'Suriname' },
      de: { official: 'Republik Suriname', common: 'Suriname' },
      fr: { official: 'R\u00e9publique du Suriname', common: 'Surinam' },
      it: { official: 'Repubblica del Suriname', common: 'Suriname' },
      es: { official: 'Rep\u00fablica de Suriname', common: 'Surinam' }
    }
  },
  {
    cca2: 'SK',
    translations: {
      en: { common: 'Slovakia' },
      nl: { common: 'Slowakije' },
      de: { official: 'Slowakische Republik', common: 'Slowakei' },
      fr: { official: 'R\u00e9publique slovaque', common: 'Slovaquie' },
      it: { official: 'Repubblica slovacca', common: 'Slovacchia' },
      es: {
        official: 'Rep\u00fablica Eslovaca',
        common: 'Rep\u00fablica Eslovaca'
      }
    }
  },
  {
    cca2: 'SI',
    translations: {
      en: { common: 'Slovenia' },
      nl: { common: 'Slovenië' },
      de: { official: 'Republik Slowenien', common: 'Slowenien' },
      fr: {
        official: 'R\u00e9publique de Slov\u00e9nie',
        common: 'Slov\u00e9nie'
      },
      it: { official: 'Repubblica di Slovenia', common: 'Slovenia' },
      es: { official: 'Rep\u00fablica de Eslovenia', common: 'Eslovenia' }
    }
  },
  {
    cca2: 'SE',
    translations: {
      en: { common: 'Sweden' },
      nl: { common: 'Zweden' },
      de: { official: 'K\u00f6nigreich Schweden', common: 'Schweden' },
      fr: { official: 'Royaume de Su\u00e8de', common: 'Su\u00e8de' },
      it: { official: 'Regno di Svezia', common: 'Svezia' },
      es: { official: 'Reino de Suecia', common: 'Suecia' }
    }
  },
  {
    cca2: 'SZ',
    translations: {
      en: { common: 'Swaziland' },
      nl: { common: 'Swaziland' },
      de: { official: 'K\u00f6nigreich Swasiland', common: 'Swasiland' },
      fr: { official: 'Royaume du Swaziland', common: 'Swaziland' },
      it: { official: 'Regno dello Swaziland', common: 'Swaziland' },
      es: { official: 'Reino de Swazilandia', common: 'Suazilandia' }
    }
  },
  {
    cca2: 'SX',
    translations: {
      en: { common: 'Sint Maarten' },
      nl: { common: 'Sint Maarten' },
      de: { official: 'Sint Maarten', common: 'Sint Maarten' },
      fr: { official: 'Sint Maarten', common: 'Saint-Martin' },
      it: { official: 'Sint Maarten', common: 'Sint Maarten' },
      es: { official: 'Sint Maarten', common: 'Sint Maarten' }
    }
  },
  {
    cca2: 'SC',
    translations: {
      en: { common: 'Seychelles' },
      nl: { common: 'Seychellen' },
      de: { official: 'Republik der Seychellen', common: 'Seychellen' },
      fr: { official: 'R\u00e9publique des Seychelles', common: 'Seychelles' },
      it: { official: 'Repubblica delle Seychelles', common: 'Seychelles' },
      es: { official: 'Rep\u00fablica de las Seychelles', common: 'Seychelles' }
    }
  },
  {
    cca2: 'SY',
    translations: {
      en: { common: 'Syria' },
      nl: { common: 'Syrië' },
      de: { official: 'Arabische Republik Syrien', common: 'Syrien' },
      fr: { official: 'R\u00e9publique arabe syrienne', common: 'Syrie' },
      it: { official: 'Repubblica araba siriana', common: 'Siria' },
      es: { official: 'Rep\u00fablica \u00c1rabe Siria', common: 'Siria' }
    }
  },
  {
    cca2: 'TC',
    translations: {
      en: { common: 'Turks and Caicos Islands' },
      nl: { common: 'Turks- en Caicoseilanden' },
      de: {
        official: 'Turks und Caicos Inseln',
        common: 'Turks-und Caicosinseln'
      },
      fr: {
        official: '\u00celes Turques et Ca\u00efques',
        common: '\u00celes Turques-et-Ca\u00efques'
      },
      it: { official: 'Turks e Caicos', common: 'Isole Turks e Caicos' },
      es: { official: 'Islas Turcas y Caicos', common: 'Islas Turks y Caicos' }
    }
  },
  {
    cca2: 'TD',
    translations: {
      en: { common: 'Chad' },
      nl: { common: 'Tsjaad' },
      de: { official: 'Republik Tschad', common: 'Tschad' },
      fr: { official: 'R\u00e9publique du Tchad', common: 'Tchad' },
      it: { official: 'Repubblica del Ciad', common: 'Ciad' },
      es: { official: 'Rep\u00fablica de Chad', common: 'Chad' }
    }
  },
  {
    cca2: 'TG',
    translations: {
      en: { common: 'Togo' },
      nl: { common: 'Togo' },
      de: { official: 'Republik Togo', common: 'Togo' },
      fr: { official: 'R\u00e9publique togolaise', common: 'Togo' },
      it: { official: 'Repubblica del Togo', common: 'Togo' },
      es: { official: 'Rep\u00fablica de Togo', common: 'Togo' }
    }
  },
  {
    cca2: 'TH',
    translations: {
      en: { common: 'Thailand' },
      nl: { common: 'Thailand' },
      de: { official: 'K\u00f6nigreich Thailand', common: 'Thailand' },
      fr: { official: 'Royaume de Tha\u00eflande', common: 'Tha\u00eflande' },
      it: { official: 'Regno di Thailandia', common: 'Tailandia' },
      es: { official: 'Reino de Tailandia', common: 'Tailandia' }
    }
  },
  {
    cca2: 'TJ',
    translations: {
      en: { common: 'Tajikistan' },
      nl: { common: 'Tadzjikistan' },
      de: { official: 'Republik Tadschikistan', common: 'Tadschikistan' },
      fr: { official: 'R\u00e9publique du Tadjikistan', common: 'Tadjikistan' },
      it: { official: 'Repubblica del Tajikistan', common: 'Tagikistan' },
      es: {
        official: 'Rep\u00fablica de Tayikist\u00e1n',
        common: 'Tayikist\u00e1n'
      }
    }
  },
  {
    cca2: 'TK',
    translations: {
      en: { common: 'Tokelau' },
      nl: { common: 'Tokelau' },
      de: { official: 'Tokelau', common: 'Tokelau' },
      fr: { official: '\u00celes Tokelau', common: 'Tokelau' },
      it: { official: 'Tokelau', common: 'Isole Tokelau' },
      es: { official: 'Tokelau', common: 'Islas Tokelau' }
    }
  },
  {
    cca2: 'TM',
    translations: {
      en: { common: 'Turkmenistan' },
      nl: { common: 'Turkmenistan' },
      de: { official: 'Turkmenistan', common: 'Turkmenistan' },
      fr: { official: 'Turkm\u00e9nistan', common: 'Turkm\u00e9nistan' },
      it: { official: 'Turkmenistan', common: 'Turkmenistan' },
      es: { official: 'Turkmenist\u00e1n', common: 'Turkmenist\u00e1n' }
    }
  },
  {
    cca2: 'TL',
    translations: {
      en: { common: 'Timor-Leste' },
      nl: { common: 'Timor-Leste' },
      de: {
        official: 'Demokratische Republik Timor-Leste',
        common: 'Timor-Leste'
      },
      fr: {
        official: 'R\u00e9publique d\u00e9mocratique du Timor oriental',
        common: 'Timor oriental'
      },
      it: {
        official: 'Repubblica Democratica di Timor Est',
        common: 'Timor Est'
      },
      es: {
        official: 'Rep\u00fablica Democr\u00e1tica de Timor-Leste',
        common: 'Timor Oriental'
      }
    }
  },
  {
    cca2: 'TO',
    translations: {
      en: { common: 'Tonga' },
      nl: { common: 'Tonga' },
      de: { official: 'K\u00f6nigreich Tonga', common: 'Tonga' },
      fr: { official: 'Royaume des Tonga', common: 'Tonga' },
      it: { official: 'Regno di Tonga', common: 'Tonga' },
      es: { official: 'Reino de Tonga', common: 'Tonga' }
    }
  },
  {
    cca2: 'TT',
    translations: {
      en: { common: 'Trinidad and Tobago' },
      nl: { common: 'Trinidad en Tobago' },
      de: {
        official: 'Republik Trinidad und Tobago',
        common: 'Trinidad und Tobago'
      },
      fr: {
        official: 'R\u00e9publique de Trinit\u00e9-et-Tobago',
        common: 'Trinit\u00e9-et-Tobago'
      },
      it: {
        official: 'Repubblica di Trinidad e Tobago',
        common: 'Trinidad e Tobago'
      },
      es: {
        official: 'Rep\u00fablica de Trinidad y Tobago',
        common: 'Trinidad y Tobago'
      }
    }
  },
  {
    cca2: 'TN',
    translations: {
      en: { common: 'Tunisia' },
      nl: { common: 'Tunesië' },
      de: { official: 'Tunesische Republik', common: 'Tunesien' },
      fr: { official: 'R\u00e9publique tunisienne', common: 'Tunisie' },
      it: { official: 'Repubblica tunisina', common: 'Tunisia' },
      es: { official: 'Rep\u00fablica de T\u00fanez', common: 'T\u00fanez' }
    }
  },
  {
    cca2: 'TR',
    translations: {
      en: { common: 'Turkey' },
      nl: { common: 'Turkije' },
      de: { official: 'Republik T\u00fcrkei', common: 'T\u00fcrkei' },
      fr: { official: 'R\u00e9publique de Turquie', common: 'Turquie' },
      it: { official: 'Repubblica di Turchia', common: 'Turchia' },
      es: { official: 'Rep\u00fablica de Turqu\u00eda', common: 'Turqu\u00eda' }
    }
  },
  {
    cca2: 'TV',
    translations: {
      en: { common: 'Tuvalu' },
      nl: { common: 'Tuvalu' },
      de: { official: 'Tuvalu', common: 'Tuvalu' },
      fr: { official: 'Tuvalu', common: 'Tuvalu' },
      it: { official: 'Tuvalu', common: 'Tuvalu' },
      es: { official: 'Tuvalu', common: 'Tuvalu' }
    }
  },
  {
    cca2: 'TW',
    translations: {
      en: { common: 'Taiwan' },
      nl: { common: 'Taiwan' },
      de: { official: 'Republik China (Taiwan)', common: 'Taiwan' },
      fr: {
        official: 'R\u00e9publique de Chine (Ta\u00efwan)',
        common: 'Ta\u00efwan'
      },
      it: { official: 'Repubblica cinese (Taiwan)', common: 'Taiwan' },
      es: {
        official: 'Rep\u00fablica de China en Taiw\u00e1n',
        common: 'Taiw\u00e1n'
      }
    }
  },
  {
    cca2: 'TZ',
    translations: {
      en: { common: 'Tanzania' },
      nl: { common: 'Tanzania' },
      de: { official: 'Vereinigte Republik Tansania', common: 'Tansania' },
      fr: { official: 'R\u00e9publique -Unie de Tanzanie', common: 'Tanzanie' },
      it: { official: 'Repubblica Unita di Tanzania', common: 'Tanzania' },
      es: { official: 'Rep\u00fablica Unida de Tanzania', common: 'Tanzania' }
    }
  },
  {
    cca2: 'UG',
    translations: {
      en: { common: 'Uganda' },
      nl: { common: 'Oeganda' },
      de: { official: 'Republik Uganda', common: 'Uganda' },
      fr: { official: "R\u00e9publique de l'Ouganda", common: 'Ouganda' },
      it: { official: 'Repubblica di Uganda', common: 'Uganda' },
      es: { official: 'Rep\u00fablica de Uganda', common: 'Uganda' }
    }
  },
  {
    cca2: 'UA',
    translations: {
      en: { common: 'Ukraine' },
      nl: { common: 'Oekraïne' },
      de: { official: 'Ukraine', common: 'Ukraine' },
      fr: { official: 'Ukraine', common: 'Ukraine' },
      it: { official: 'Ucraina', common: 'Ucraina' },
      es: { official: 'Ucrania', common: 'Ucrania' }
    }
  },
  {
    cca2: 'UM',
    translations: {
      en: { common: 'United States Minor Outlying Islands' },
      nl: { common: 'Kleine afgelegen eilanden van de Verenigde Staten' },
      de: {
        official: 'USA, kleinere ausgelagerte Inseln',
        common: 'Kleinere Inselbesitzungen der Vereinigten Staaten'
      },
      fr: {
        official: '\u00celes mineures \u00e9loign\u00e9es des \u00c9tats-Unis',
        common: '\u00celes mineures \u00e9loign\u00e9es des \u00c9tats-Unis'
      },
      it: {
        official: 'Stati Uniti Isole Minori',
        common: "Isole minori esterne degli Stati Uniti d'America"
      },
      es: {
        official: 'Estados Unidos Islas menores alejadas de',
        common: 'Islas Ultramarinas Menores de Estados Unidos'
      }
    }
  },
  {
    cca2: 'UY',
    translations: {
      en: { common: 'Uruguay' },
      nl: { common: 'Uruguay' },
      de: { official: 'Republik \u00d6stlich des Uruguay', common: 'Uruguay' },
      fr: {
        official: "R\u00e9publique orientale de l'Uruguay",
        common: 'Uruguay'
      },
      it: { official: "Repubblica Orientale dell'Uruguay", common: 'Uruguay' },
      es: { official: 'Rep\u00fablica Oriental del Uruguay', common: 'Uruguay' }
    }
  },
  {
    cca2: 'US',
    translations: {
      en: { common: 'United States' },
      nl: { common: 'Verenigde Staten' },
      de: {
        official: 'Vereinigte Staaten von Amerika',
        common: 'Vereinigte Staaten von Amerika'
      },
      fr: {
        official: "Les \u00e9tats-unis d'Am\u00e9rique",
        common: '\u00c9tats-Unis'
      },
      it: {
        official: "Stati Uniti d'America",
        common: "Stati Uniti d'America"
      },
      es: {
        official: 'Estados Unidos de Am\u00e9rica',
        common: 'Estados Unidos'
      }
    }
  },
  {
    cca2: 'UZ',
    translations: {
      en: { common: 'Uzbekistan' },
      nl: { common: 'Oezbekistan' },
      de: { official: 'Republik Usbekistan', common: 'Usbekistan' },
      fr: {
        official: "R\u00e9publique d'Ouzb\u00e9kistan",
        common: 'Ouzb\u00e9kistan'
      },
      it: { official: 'Repubblica di Uzbekistan', common: 'Uzbekistan' },
      es: {
        official: 'Rep\u00fablica de Uzbekist\u00e1n',
        common: 'Uzbekist\u00e1n'
      }
    }
  },
  {
    cca2: 'VA',
    translations: {
      en: { common: 'Vatican City' },
      nl: { common: 'Vaticaanstad' },
      de: { official: 'Staat Vatikanstadt', common: 'Vatikanstadt' },
      fr: { official: 'Cit\u00e9 du Vatican', common: 'Cit\u00e9 du Vatican' },
      it: {
        official: 'Citt\u00e0 del Vaticano',
        common: 'Citt\u00e0 del Vaticano'
      },
      es: { official: 'Ciudad del Vaticano', common: 'Ciudad del Vaticano' }
    }
  },
  {
    cca2: 'VC',
    translations: {
      en: { common: 'Saint Vincent and the Grenadines' },
      nl: { common: 'Saint Vincent en de Grenadines' },
      de: {
        official: 'St. Vincent und die Grenadinen',
        common: 'Saint Vincent und die Grenadinen'
      },
      fr: {
        official: 'Saint-Vincent-et-les Grenadines',
        common: 'Saint-Vincent-et-les-Grenadines'
      },
      it: {
        official: 'Saint Vincent e Grenadine',
        common: 'Saint Vincent e Grenadine'
      },
      es: {
        official: 'San Vicente y las Granadinas',
        common: 'San Vicente y Granadinas'
      }
    }
  },
  {
    cca2: 'VE',
    translations: {
      en: { common: 'Venezuela' },
      nl: { common: 'Venezuela' },
      de: { official: 'Bolivarische Republik Venezuela', common: 'Venezuela' },
      fr: {
        official: 'R\u00e9publique bolivarienne du Venezuela',
        common: 'Venezuela'
      },
      it: {
        official: 'Repubblica Bolivariana del Venezuela',
        common: 'Venezuela'
      },
      es: {
        official: 'Rep\u00fablica Bolivariana de Venezuela',
        common: 'Venezuela'
      }
    }
  },
  {
    cca2: 'VG',
    translations: {
      en: { common: 'British Virgin Islands' },
      nl: { common: 'Britse Maagdeneilanden' },
      de: { official: 'Jungferninseln', common: 'Britische Jungferninseln' },
      fr: {
        official: '\u00eeles Vierges',
        common: '\u00celes Vierges britanniques'
      },
      it: { official: 'Isole Vergini', common: 'Isole Vergini Britanniche' },
      es: {
        official: 'Islas V\u00edrgenes',
        common: 'Islas V\u00edrgenes del Reino Unido'
      }
    }
  },
  {
    cca2: 'VI',
    translations: {
      en: { common: 'United States Virgin Islands' },
      nl: { common: 'Amerikaanse Maagdeneilanden' },
      de: {
        official: 'Jungferninseln der Vereinigten Staaten',
        common: 'Amerikanische Jungferninseln'
      },
      fr: {
        official: '\u00celes Vierges des \u00c9tats-Unis',
        common: '\u00celes Vierges des \u00c9tats-Unis'
      },
      it: {
        official: 'Isole Vergini degli Stati Uniti',
        common: 'Isole Vergini americane'
      },
      es: {
        official: 'Islas V\u00edrgenes de los Estados Unidos',
        common: 'Islas V\u00edrgenes de los Estados Unidos'
      }
    }
  },
  {
    cca2: 'VN',
    translations: {
      en: { common: 'Vietnam' },
      nl: { common: 'Vietnam' },
      de: { official: 'Sozialistische Republik Vietnam', common: 'Vietnam' },
      fr: {
        official: 'R\u00e9publique socialiste du Vi\u00eat Nam',
        common: 'Vi\u00eat Nam'
      },
      it: { official: 'Repubblica socialista del Vietnam', common: 'Vietnam' },
      es: {
        official: 'Rep\u00fablica Socialista de Vietnam',
        common: 'Vietnam'
      }
    }
  },
  {
    cca2: 'VU',
    translations: {
      en: { common: 'Vanuatu' },
      nl: { common: 'Vanuatu' },
      de: { official: 'Vanuatu', common: 'Vanuatu' },
      fr: { official: 'R\u00e9publique de Vanuatu', common: 'Vanuatu' },
      it: { official: 'Repubblica di Vanuatu', common: 'Vanuatu' },
      es: { official: 'Rep\u00fablica de Vanuatu', common: 'Vanuatu' }
    }
  },
  {
    cca2: 'WF',
    translations: {
      en: { common: 'Wallis and Futuna' },
      nl: { common: 'Wallis en Futuna' },
      de: {
        official: 'Gebiet der Wallis und Futuna',
        common: 'Wallis und Futuna'
      },
      fr: {
        official: 'Territoire des \u00eeles Wallis et Futuna',
        common: 'Wallis-et-Futuna'
      },
      it: {
        official: 'Territorio delle Isole Wallis e Futuna',
        common: 'Wallis e Futuna'
      },
      es: {
        official: 'Territorio de las Islas Wallis y Futuna',
        common: 'Wallis y Futuna'
      }
    }
  },
  {
    cca2: 'WS',
    translations: {
      en: { common: 'Samoa' },
      nl: { common: 'Samoa' },
      de: { official: 'Unabh\u00e4ngige Staat Samoa', common: 'Samoa' },
      fr: { official: 'Samoa', common: 'Samoa' },
      it: { official: 'Stato indipendente di Samoa', common: 'Samoa' },
      es: { official: 'Estado Independiente de Samoa', common: 'Samoa' }
    }
  },
  {
    cca2: 'YE',
    translations: {
      en: { common: 'Yemen' },
      nl: { common: 'Jemen' },
      de: { official: 'Republik Jemen', common: 'Jemen' },
      fr: { official: 'R\u00e9publique du Y\u00e9men', common: 'Y\u00e9men' },
      it: { official: 'Repubblica dello Yemen', common: 'Yemen' },
      es: { official: 'Rep\u00fablica de Yemen', common: 'Yemen' }
    }
  },
  {
    cca2: 'ZA',
    translations: {
      en: { common: 'South Africa' },
      nl: { common: 'Zuid-Afrika' },
      de: {
        official: 'Republik S\u00fcdafrika',
        common: 'Republik S\u00fcdafrika'
      },
      fr: {
        official: "R\u00e9publique d'Afrique du Sud",
        common: 'Afrique du Sud'
      },
      it: { official: 'Repubblica del Sud Africa', common: 'Sud Africa' },
      es: {
        official: 'Rep\u00fablica de Sud\u00e1frica',
        common: 'Rep\u00fablica de Sud\u00e1frica'
      }
    }
  },
  {
    cca2: 'ZM',
    translations: {
      en: { common: 'Zambia' },
      nl: { common: 'Zambia' },
      de: { official: 'Republik Sambia', common: 'Sambia' },
      fr: { official: 'R\u00e9publique de Zambie', common: 'Zambie' },
      it: { official: 'Repubblica di Zambia', common: 'Zambia' },
      es: { official: 'Rep\u00fablica de Zambia', common: 'Zambia' }
    }
  },
  {
    cca2: 'ZW',
    translations: {
      en: { common: 'Zimbabwe' },
      nl: { common: 'Zimbabwe' },
      de: { official: 'Republik Simbabwe', common: 'Simbabwe' },
      fr: { official: 'R\u00e9publique du Zimbabwe', common: 'Zimbabwe' },
      it: { official: 'Repubblica dello Zimbabwe', common: 'Zimbabwe' },
      es: { official: 'Rep\u00fablica de Zimbabue', common: 'Zimbabue' }
    }
  }
];
export default countries;
