import React, { Component } from 'react';

export const point = {
  path:
    'M250 -180q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm256 0q0 109-33 179l-364 774q-16 33-47.5 52t-67.5 19-67.5-19-46.5-52l-365-774q-33-70-33-179 0-212 150-362t362-150 362 150 150 362z',
  size: 1792
};
export const checkeredFlag = {
  path:
    'M10 62v-192q-181 16-384 117v185q205-96 384-110zm0-418v-197q-172 8-384 126v189q215-111 384-118zm832 463v-184q-235 116-384 71v-224q-20-6-39-15-5-3-33-17t-34.5-17-31.5-15-34.5-15.5-32.5-13-36-12.5-35-8.5-39.5-7.5-39.5-4-44-2q-23 0-49 3v222h19q102 0 192.5 29t197.5 82q19 9 39 15v188q42 17 91 17 120 0 293-92zm0-427v-189q-169 91-306 91-45 0-78-8v196q148 42 384-90zm-1344-362q0 35-17.5 64t-46.5 46v1266q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-1266q-29-17-46.5-46t-17.5-64q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm1472 64v763q0 39-35 57-10 5-17 9-218 116-369 116-88 0-158-35l-28-14q-64-33-99-48t-91-29-114-14q-102 0-235.5 44t-228.5 102q-15 9-33 9-16 0-32-8-32-19-32-56v-742q0-35 31-55 35-21 78.5-42.5t114-52 152.5-49.5 155-19q112 0 209 31t209 86q38 19 89 19 122 0 310-112 22-12 31-17 31-16 62 2 31 20 31 55z',
  size: 1792
};
export const circleGenerator = r => ({
  path: `m -${r}, 0 a ${r},${r} 0 1,0 ${r * 2},0 a ${r},${r} 0 1,0 -${r * 2},0`,
  size: r * 2
});

export class FinishSVG extends Component {
  render() {
    return (
      <svg width="34" height="34" viewBox="0 0 34 34">
        <g fill="none" fillRule="evenodd">
          <circle
            cx="16"
            cy="16"
            r="16"
            fill="#FFF"
            stroke="#000"
            strokeWidth="2"
            transform="translate(1 1)"
          />
          <g fill="#000" fillRule="nonzero">
            <path d="M10.91 10.045v3.021c1.662 1.197 3.324 1.197 5.005.798v-3.021c-1.681.399-3.343.399-5.005-.798zM10.91 16.068v3.021c1.662 1.197 3.324 1.197 5.005.798v-3.021c-1.681.418-3.343.418-5.005-.798z" />
            <path d="M15.915 13.864v3.021c1.661-.399 3.323-1.197 5.004-1.615v-3.021c-1.68.418-3.343 1.216-5.004 1.615z" />
            <path d="M25.904 13.066v-3.021c-1.661-1.197-3.323-1.197-5.004-.798v3.021c1.68-.418 3.343-.418 5.004.798zM25.904 19.089v-3.021c-1.661-1.197-3.323-1.197-5.004-.798v3.021c1.68-.399 3.343-.399 5.004.798zM9.954 9C9.436 9 9 9.306 9 9.666v16.668c0 .36.436.666.954.666.519 0 .955-.306.955-.666V9.666c.027-.36-.41-.666-.955-.666z" />
          </g>
        </g>
      </svg>
    );
  }
}

export class StartSVG extends Component {
  render() {
    return (
      <svg width="34" height="34" viewBox="0 0 34 34">
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
          <circle
            cx="16"
            cy="16"
            r="16"
            fill="#FFF"
            stroke="#000"
            strokeWidth="2"
          />
          <path
            fill="#000"
            fillRule="nonzero"
            d="M23 14.247C23 10.245 19.866 7 16 7s-7 3.245-7 7.247c0 1.69.56 3.244 1.497 4.477l3.537 6.1A2.257 2.257 0 0 0 16 26c.811 0 1.56-.448 1.966-1.175l3.537-6.101A7.378 7.378 0 0 0 23 14.247zm-7-2.742c1.463 0 2.649 1.228 2.649 2.742 0 1.515-1.186 2.743-2.649 2.743s-2.649-1.228-2.649-2.743c0-1.514 1.186-2.742 2.649-2.742z"
          />
        </g>
      </svg>
    );
  }
}
