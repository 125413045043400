import serverRequest from '../utils/Api';
import history from '../utils/history';
import {
  EUCountries,
  TAXAMO_SUPPORTED_COUNTRIES,
  localPaymentMethods
} from '../utils/constants';

import {
  getTransaction,
  getSelectedPlanInfo,
  getPaymentBillingInfo,
  checkIfShippingEqualsToDefault,
  checkIfBillingEqualsToDefault,
  findSelectedPlanById,
  getPaymentInfo
} from '../selectors/billing';
import { getBeaconsProducts, getCustomerCurrency } from '../selectors/products';
import { getCustomer } from '../selectors/customer';
import { setCompanyDetails, setCompanySubscription } from './company';
import { getCleanCompany } from '../utils/dataStore';

import { isEmpty, getDashboardLinkFor } from '../utils';
import { clientCreate, clientClose } from './braintree';
import {
  storeTransactionTaxamo,
  initiateTaxamo,
  getTransactionMockup
} from './taxamo';
import {
  taxamoReady,
  setInfo,
  retreiveCustomer,
  receiveCustomer
} from './billing';
import {
  updateUserDetails,
  getUserDetails,
  checkToShowNotifBar,
  updateUserSubscription
} from './user';
import { setError } from './errors';
import { createErrorObject } from '../utils/dataStore';
import { makeAddressObject } from '../utils/user';
import { URLs } from '../utils/urls';
import { getActiveCompanySubscription } from '../selectors/company';
import { t } from 'i18next/dist/commonjs';
import { getUserInfo } from '../selectors/user';

export const SET_TRANSACTION = 'SET_TRANSACTION';
export const SET_TRANSACTION_DEDUCTED = 'SET_TRANSACTION_DEDUCTED';
export const SET_TRANSACTION_NONCE = 'SET_TRANSACTION_NONCE';
export const SET_TRANSACTION_KEY = 'SET_TRANSACTION_KEY';
export const CLEAR_TRANSACTION = 'CLEAR_TRANSACTION';
export const SET_PAYMENT_COMPLETED = 'SET_PAYMENT_COMPLETED';
export const CLEAR_PAYMENT_COMPLETED = 'CLEAR_PAYMENT_COMPLETED';
export const SET_PAYMENT_FAILED = 'SET_PAYMENT_FAILED';

export const setTransaction = transaction => {
  return { type: SET_TRANSACTION, transaction };
};
export const setTransactionDeducted = isDeducted => {
  return { type: SET_TRANSACTION_DEDUCTED, isDeducted };
};
export const setTransactionNonce = nonce => {
  return { type: SET_TRANSACTION_NONCE, nonce };
};
export const setTransactionKey = key => {
  return { type: SET_TRANSACTION_KEY, key };
};
export const clearTransaction = () => {
  return { type: CLEAR_TRANSACTION };
};
const setPaymentCompleted = () => {
  return { type: SET_PAYMENT_COMPLETED };
};
export const clearPaymentCompleted = () => {
  return { type: CLEAR_PAYMENT_COMPLETED };
};
export const setPaymentFailed = () => {
  return { type: SET_PAYMENT_FAILED };
};

export const initiatePayments = (fn, type) => {
  return (dispatch, getState) => {
    const state = getState();
    const { services } = state.billing;
    !services.taxamo &&
      initiateTaxamo().then(() => {
        dispatch(taxamoReady());
        const { plans } = state.billing.plansData;
        const { beacons } = state.products;
        const pl = type === 'beacons' ? beacons : plans;
        isEmpty(pl) && fn && dispatch(fn());
      });
  };
};

export const getBraintreeToken = (fn, element, context = '') => {
  return (dispatch, getState) => {
    dispatch(setInfo({ loading: true }));
    const currency = getCustomerCurrency(getState());
    const { method, url } = URLs.customer.getBraintreeToken(currency);
    serverRequest(method, url, getState()).then(
      json => {
        const { token } = json;
        clientCreate(token, element, fn, dispatch, getState, context);
      },
      err => {
        dispatch(setInfo({ loading: false }));
        console.error('getBraintreeToken: ', err);
      }
    );
  };
};

export const closeBraintree = () => {
  clientClose();
};

export const doBraintreePayment = tokenObj => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      billing: {
        plansData: { selectedPlan },
        transaction: { key: transaction_key },
        payment: {
          promo: { valid, code: discount },
          billingInfo,
          update_subscription_id
        }
      }
    } = state;

    const billing_address = {
      first_name: billingInfo.firstName,
      last_name: billingInfo.lastName,
      locality: billingInfo.locality,
      company: billingInfo.company,
      postal_code: billingInfo.postalCode,
      region: billingInfo.region,
      street_address: billingInfo.address,
      extended_address: null,
      country_code_alpha2: billingInfo.country
    };
    const plan = findSelectedPlanById(state);
    const upgradingToEnterprise = plan.plan_type === 'team';

    let body = update_subscription_id
      ? Object.assign(
          { transaction_key },
          Object.assign({ token: null, nonce: null }, tokenObj)
        )
      : upgradingToEnterprise
      ? Object.assign(
          { plan_id: selectedPlan, transaction_key, billing_address },
          Object.assign({ token: null, nonce: null }, tokenObj)
        )
      : Object.assign({}, { plan: selectedPlan, transaction_key }, tokenObj);
    body = valid ? Object.assign(body, { discount }) : body;

    const { method, url } = update_subscription_id
      ? URLs.plans.updateSubscriptionPrice(update_subscription_id)
      : URLs.plans.buySubscription(upgradingToEnterprise);
    serverRequest(method, url, state, { body }).then(
      json => {
        if (update_subscription_id) {
          const { subscription } = getUserInfo(state);
          if (update_subscription_id === subscription.id) {
            dispatch(updateUserSubscription(json));
          }
          dispatch(setPaymentCompleted());
        } else if (upgradingToEnterprise) {
          const userObj = Object.assign({}, json.user, {
            subscription: json.subscription
          });
          dispatch(updateUserDetails(userObj));
          dispatch(receiveCustomer(json.customer));
          dispatch(setPaymentCompleted());
          dispatch(getUserDetails());
        } else if (json.valid) {
          if (json.user) {
            dispatch(updateUserDetails(json.user));
          }
          dispatch(retreiveCustomer(true));
          dispatch(setPaymentCompleted());
          dispatch(checkToShowNotifBar());
        } else
          console.warn(
            'Either `nonce` or `token` must be supplied by the client!'
          );
      },
      ({ err, statusCode }) => {
        dispatch(
          setError(
            createErrorObject(err.message, 'invalid_subscription_payment')
          )
        );
        dispatch(setPaymentFailed());
        dispatch(setInfo({ ready: true, loading: false }));
        // console.error('doProductPayment: ', err, statusCode);
      }
    );
  };
};

export const doProductPayment = tokenObj => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      billing: {
        transaction: { key: transaction_key },
        payment: { sameAddressBillingShipping, billingInfo, shippingInfo }
      },
      products: {
        beacons: { selectedBeacon, quantity, selectedShippingMethod }
      }
    } = state;
    const { billing_address_id, shipping_address_id } = getCustomer(state);
    const bodyObj = { transaction_key };

    const products = [
      {
        product: selectedBeacon,
        quantity
      },
      {
        product: selectedShippingMethod,
        quantity: 1
      }
    ];
    bodyObj.products = products;
    bodyObj.use_billing_address = sameAddressBillingShipping;
    checkIfBillingEqualsToDefault(state)
      ? (bodyObj.billing_address_id = billing_address_id)
      : (bodyObj.billing_address = makeAddressObject(billingInfo));
    if (!sameAddressBillingShipping) {
      checkIfShippingEqualsToDefault(state)
        ? (bodyObj.shipping_address_id = shipping_address_id)
        : (bodyObj.shipping_address = makeAddressObject(shippingInfo));
    }
    const tokenAndNonce = Object.assign(
      { nonce: null, token: null, pid: null },
      tokenObj
    );

    const body = Object.assign({}, bodyObj, tokenAndNonce);

    const { method, url } = URLs.beacon.placeOrder();
    serverRequest(method, url, state, { body }).then(
      json => {
        if (json.status === 'pending') {
          // Payment waiting completion...
        } else if (json.status === 'submitted') {
          dispatch(retreiveCustomer(true));
          dispatch(setPaymentCompleted());
          dispatch(setTransactionNonce(null));
          history.push(
            getDashboardLinkFor('beaconOrderDetails', {
              id: json.id
            })
          );
        } else
          console.warn(
            'Either `nonce` or `token` must be supplied by the client!'
          );
      },
      ({ err, statusCode }) => {
        if (statusCode === 403) {
          dispatch(setError(createErrorObject(err.detail, 'not_allowed_op')));
          dispatch(setPaymentFailed());
        } else {
          dispatch(
            setError(createErrorObject(err.message, 'invalid_beacon_payment'))
          );
          dispatch(setPaymentFailed());
        }
        dispatch(setTransactionNonce(null));
      }
    );
  };
};

/**
 * Cancel PENDING Beacon product payment (when using local payment method)
 */
export const cancelProductPayment = () => {
  return (dispatch, getState) => {
    const state = getState();
    /**
     * Cancel product order pending local payment confirmation
     * Called when the payment process is canceled or fails during
     * braintree Local Payment flow.
     */
    const { pid } = getPaymentInfo(state);
    if (pid) {
      const { method, url } = URLs.beacon.deletePendingOrder(pid);
      serverRequest(method, url, state, {}).then(json => {
        dispatch(setPaymentFailed());
      });
    }
  };
};

const updateCustomerAndPay = (address, tokenObj) => {
  return (dispatch, getState) => {
    const state = getState();
    const { billing_address_id: billingId } = getCustomer(state);
    const { method, url } = billingId
      ? URLs.customer.updateCustomerAddress(billingId)
      : URLs.customer.updateCustomer();
    serverRequest(method, url, getState(), { body: address }).then(
      () => {
        dispatch(doBraintreePayment(tokenObj));
      },
      err => {
        console.error('updateCustomerAndPay: ', err);
      }
    );
  };
};

const saveTransaction = (tokenObj, calculateFor) => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      billing: {
        transaction: { ready },
        payment: {
          billingInfo: { country, taxId }
        }
      },
      user: {
        details: { email }
      },
      products: {
        beacons: {
          selectedBeacon,
          quantity,
          selectedShippingMethod,
          shipping: allShippingProducts
        }
      }
    } = state;
    let selectedPlan;
    let selectedProduct;
    let shippingMethod;
    let currency;
    if (calculateFor === 'product') {
      selectedProduct = getBeaconsProducts(state).find(
        el => el.id === selectedBeacon
      );
      shippingMethod = allShippingProducts.find(
        el => el.id === selectedShippingMethod
      );
      currency = selectedProduct.currency;
    } else {
      selectedPlan = getSelectedPlanInfo(state);
      currency = selectedPlan.currency;
    }

    const isEUCountry = EUCountries.includes(country);
    const options = {
      email,
      taxId,
      currency,
      userDeclaredCountry: country,
      isEUCountry
    };
    if (calculateFor === 'product') {
      const productOptions = {
        quantity,
        product: selectedProduct,
        shippingMethod
      };
      Object.assign(options, productOptions);
    } else {
      options.plan = selectedPlan;
    }

    const transaction = getTransactionMockup(options, calculateFor);

    // get the transaction key
    ready &&
      storeTransactionTaxamo(transaction).then(
        transactionKey => {
          dispatch(setTransactionKey(transactionKey));

          if (calculateFor === 'product') {
            dispatch(doProductPayment(tokenObj));
          } else {
            const paymentBillingInfo = getPaymentBillingInfo(state);
            const address = makeAddressObject(paymentBillingInfo);
            dispatch(updateCustomerAndPay(address, tokenObj));
          }
        },
        err => {
          console.error('saveTransaction: ', err);
        }
      );
  };
};

export const paymentMethod = (calculateFor = 'subscription') => {
  return (dispatch, getState) => {
    const state = getState();
    const { type, token, pid } = state.billing.payment.info;
    const { nonce } = state.billing.transaction;
    const localPaymentMethod = Object.values(localPaymentMethods).find(
      x => x.type === type
    );
    switch (type) {
      case 'primary':
      case 'card existing':
      case 'paypal existing':
        if (nonce) {
          dispatch(saveTransaction({ token, nonce }, calculateFor));
        } else {
          dispatch(saveTransaction({ token }, calculateFor));
        }
        break;
      case 'card':
      case 'paypal':
        dispatch(saveTransaction({ nonce }, calculateFor));
        break;
      default:
        if (localPaymentMethod) {
          if (nonce) {
            // payment completed using a local payment method, e.g. iDeal, giropay, etc.
            // and we got the nonce from Braintree following successful payment
            // Taxamo transaction already saved when local payment process started.
            const {
              billing: {
                transaction: { key: transaction_key }
              }
            } = state;
            if (!isEmpty(transaction_key)) {
              dispatch(doProductPayment({ nonce, pid }));
              return;
            }
          }
          dispatch(saveTransaction({ nonce, pid }, calculateFor));
        }
        break;
    }
  };
};

export const cleanUpAndSetTransaction = transaction => dispatch => {
  const updatedTransaction = Object.assign({}, transaction);
  if (
    transaction.billing_country_code &&
    !TAXAMO_SUPPORTED_COUNTRIES.includes(
      transaction.billing_country_code.toUpperCase()
    ) &&
    transaction.buyer_tax_number_valid === false
  ) {
    updatedTransaction.buyer_tax_number_valid = true;
  }

  dispatch(setTransaction(updatedTransaction));
};

export const getCompanySubscription = () => (dispatch, getState) => {
  const { url, method } = URLs.plans.getCompanySubscription();
  return serverRequest(method, url, getState()).then(response => {
    dispatch(receiveCustomer(response.customer));
    dispatch(setCompanyDetails(getCleanCompany(response)));
  });
};

export const clearCompanySubscription = () => {
  return dispatch => {
    dispatch(setCompanySubscription({}));
  };
};

export const updateCompanySubscription = () => (dispatch, getState) => {
  const { url, method } = URLs.plans.updateCompanySubscription();
  const state = getState();
  const {
    billing: {
      transaction: {
        nonce,
        transaction: { total_amount: sca_amount }
      },
      payment: {
        info: { token }
      }
    }
  } = state;
  let body = {
    token,
    nonce,
    sca_amount
  };
  return serverRequest(method, url, getState(), { body }).then(
    response => {
      dispatch(setPaymentCompleted());
    },
    err => {
      console.error('updateCompanySubscription: ', err);
      let message =
        err.details || (err.err && err.err.non_field_errors)
          ? err.err.non_field_errors[0]
          : '';
      dispatch(setError(createErrorObject(message, 'not_allowed_op')));
      dispatch(setPaymentFailed());
      dispatch(setInfo({ ready: true, loading: false }));
    }
  );
};
