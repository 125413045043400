import serverRequest from '../utils/Api';
import { URLs } from '../utils/urls';

export const SET_HELP_PAGE_DATA = 'SET_HELP_PAGE_DATA';
export const SET_SEARCH_ID = 'SET_SEARCH_ID';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const UNSET_SEARCH_ID = 'UNSET_SEARCH_ID';
export const UNSET_SEARCH_TEXT = 'UNSET_SEARCH_TEXT';

const setHelpPageData = data => ({
  type: SET_HELP_PAGE_DATA,
  data
});
export const setSearchId = hid => ({
  type: SET_SEARCH_ID,
  hid
});
export const unsetSearchId = () => ({
  type: UNSET_SEARCH_ID
});
export const setSearchText = text => ({
  type: SET_SEARCH_TEXT,
  text
});
export const unsetSearchText = () => ({
  type: UNSET_SEARCH_TEXT
});

const getCleanData = list =>
  list.map(el =>
    Object.assign(el, {
      answer: el.answer
        .replace(new RegExp('&nbsp;', 'g'), ' ')
        .replace(new RegExp('&rsquo;', 'g'), "'")
        .replace(new RegExp('&ldquo;', 'g'), '"')
        .replace(new RegExp('&rdquo;', 'g'), '"')
    })
  );

export const fetchHelpPageData = forLanguage => (dispatch, getState) => {
  let language = forLanguage;
  if (!forLanguage) {
    const { appLanguage } = getState();
    language = appLanguage;
  }

  const { url, method } = URLs.help.getHelpPageData();

  serverRequest(method, url, getState(), {
    headers: { 'accept-language': language }
  }).then(json => {
    const { faq, troubleshooting } = json;
    dispatch(
      setHelpPageData({
        faq: getCleanData(faq),
        troubleshooting: getCleanData(troubleshooting),
        listLanguage: language
      })
    );
  });
};
