import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  isLoggedIn,
  history: {
    location: { search }
  },
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect to={`/login${search}`} />
      )
    }
  />
);

export default withRouter(PrivateRoute);
