import React from 'react';
import PropTypes from 'prop-types';
import HelpPage from '../containers/Help';
import Header from '../containers/PublicRouteHeader';
import Footer from '../containers/PublicRouteFooter';
import { Helmet } from 'react-helmet';
import { translate } from 'react-i18next';

const PublicHelpPage = ({ t }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{t('dashboard.help')}</title>
        <link rel="canonical" href={`${process.env.REACT_APP_base_url}/help`} />
      </Helmet>
      <Header />
      <HelpPage />
      <Footer />
    </React.Fragment>
  );
};

PublicHelpPage.propTypes = {
  t: PropTypes.func
};

export default translate()(PublicHelpPage);
