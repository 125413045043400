const updateSmoochUser = user => {
  if (window.Smooch) {
    window.Smooch.updateUser({
      givenName: user.first_name,
      surname: user.last_name,
      properties: {
        email: user.email,
        uid: user.id,
        language: user.language,
        country: user.country,
        subscription: user.subscriptionType
      }
    });
  }
};
const destroySmooch = () => {
  if (window.Smooch) {
    window.Smooch.destroy();
  }
};

export const initSmooch = user => {
  return new Promise((resolve, reject) => {
    if (window.Smooch) {
      window.Smooch.init({
        appId: process.env.REACT_APP_smooch_key,
        displayStyle: 'button',
        buttonIconUrl: ''
      }).then(() => {
        updateSmoochUser(user);
        resolve();
      });
    } else {
      reject();
    }
  });
};

export const logoutUserFromSmooch = () => {
  if (window.Smooch) {
    window.Smooch.logout().then(() => destroySmooch());
  }
};

export const sendSmoochMessage = message => {
  if (window.Smooch) {
    window.Smooch.open();
    window.Smooch.sendMessage(message);
  }
};
