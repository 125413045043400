import { SET_PROMOTIONS } from '../actions';

const initialState = {};

export const promotions = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROMOTIONS:
      return Object.assign({}, state, action.promotions);

    default:
      return state;
  }
};
