export const getCountryFromIp = state => state.countryByIp;

export const getAppLanguage = state => state.appLanguage;

export const getIsApp = state => state.isApp;

export const getDatePreference = state => state.datePreference;

export const getTimeFormatPreference = state => state.timeFormatPreference;

export const getUserSettings = state => state.userSettings;
