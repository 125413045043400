import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';

import SectionTitle from './SectionTitle';
import PlanCard from './PlanCard';

import { isEmpty, getPublicLinkFor } from '../../utils';
import {
  landingPlansFreeFeatures,
  landingPlansProFeatures,
  landingPlansEnterpriseFeatures
} from '../../utils/landingPro';

class PlansSection extends Component {
  getButtonsForAllPlans = () => {
    const {
      userLoggedIn,
      trackEventForLogin,
      trackEventForMoreInfo,
      t
    } = this.props;
    const buttons = {};
    buttons.pro = (
      <Link
        to={getPublicLinkFor('solutionsPro')}
        className="action"
        onClick={() => trackEventForMoreInfo('pro')}
      >
        {t(`home.plans.moreInfo`)}
      </Link>
    );
    buttons.enterprise = (
      <Link
        to={getPublicLinkFor('solutionsEnterprise')}
        className="action"
        onClick={() => trackEventForMoreInfo('enterprise')}
      >
        {t(`home.plans.moreInfo`)}
      </Link>
    );
    if (!userLoggedIn) {
      buttons.free = (
        <Link
          to={getPublicLinkFor('login')}
          className="action"
          onClick={trackEventForLogin}
        >
          {t(`home.plans.signUp`)}
        </Link>
      );
    }

    return buttons;
  };

  render() {
    const { allPlans, t } = this.props;
    const plansToShow = allPlans.reduce(
      (acc, plan) => {
        if (plan.plan_type === 'pro' && plan.billing_frequency === 1) {
          acc.pro = plan;
        } else if (
          plan.plan_type === 'team' &&
          plan.default_employee_count === 2
        ) {
          acc.enterprise = plan;
        }
        return acc;
      },
      {
        pro: {},
        enterprise: {}
      }
    );

    const buttonsForPlans = this.getButtonsForAllPlans();

    return (
      <div className="plansSection" id="pricing">
        <div className="section">
          <div className="titleContainer">
            <SectionTitle secondLine={t(`home.plans.titlePartTwo`)} />
            <div className="sectionSubTitle">
              {t(`home.enterprise.plans.subtitle`)}
            </div>
          </div>
          <div className="plansContainer">
            <PlanCard
              title="Free"
              features={landingPlansFreeFeatures()}
              showMonthly
              currency={plansToShow.pro.currency}
              price={0}
              frequency={t(`home.plans.plans.free.item1`)}
              button={buttonsForPlans.free}
            />
            <PlanCard
              title="Pro"
              features={landingPlansProFeatures()}
              showMonthly={!isEmpty(plansToShow.pro)}
              currency={plansToShow.pro.currency}
              price={plansToShow.pro.price / plansToShow.pro.billing_frequency}
              frequency={t(`home.plans.plans.pro.billedMonthly`)}
              button={buttonsForPlans.pro}
            />
            <PlanCard
              title="Enterprise"
              features={landingPlansEnterpriseFeatures()}
              showMonthly={!isEmpty(plansToShow.enterprise)}
              currency={plansToShow.enterprise.currency}
              price={
                plansToShow.enterprise.price /
                plansToShow.enterprise.billing_frequency
              }
              from={t(`home.plans.plans.enterprise.from`)}
              frequencyBold={
                plansToShow.enterprise.default_employee_count +
                ' ' +
                t(`home.enterprise.plans.employees`)
              }
              frequency={t(`home.plans.plans.enterprise.billedMonthly`)}
              button={buttonsForPlans.enterprise}
            />
          </div>
        </div>
      </div>
    );
  }
}

PlansSection.propTypes = {
  allPlans: PropTypes.array,
  userLoggedIn: PropTypes.bool,
  trackEventForLogin: PropTypes.func,
  trackEventForMoreInfo: PropTypes.func,
  t: PropTypes.func.isRequired
};
PlansSection.defaultProps = {
  allPlans: []
};

export default translate()(withRouter(PlansSection));
