import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon, Image } from 'semantic-ui-react';

import SectionTitle from './SectionTitle';

import { USER_TESTIMONIALS } from '../../utils/landingConstants';

class TestimonialsSection extends Component {
  state = {
    activeSlideNumber: 1
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.language !== nextProps.language) {
      this.setState({ activeSlideNumber: 1 });
    }
  }

  onNav = (navTo = 'prev') => {
    const { activeSlideNumber } = this.state;
    const { language } = this.props;

    const numberOfQuotesToShow = USER_TESTIMONIALS.numberOfQuotes[language];
    let newSlideNumber = activeSlideNumber + 1;
    if (navTo === 'prev') {
      newSlideNumber = activeSlideNumber - 1;
    }
    if (newSlideNumber > numberOfQuotesToShow) {
      newSlideNumber = 1;
    } else if (newSlideNumber < 1) {
      newSlideNumber = numberOfQuotesToShow;
    }

    this.setState({ activeSlideNumber: newSlideNumber });
  };

  getActiveSlide = slideNumber => {
    const { t } = this.props;
    switch (slideNumber) {
      case 1:
        return {
          image: t('home.testimonials.quotes.quote1.background'),
          topic: {
            title: t('home.testimonials.quotes.quote1.categoryTitle'),
            desc: t('home.testimonials.quotes.quote1.categoryDesc')
          },
          quote: {
            name: t('home.testimonials.quotes.quote1.name'),
            designation: t('home.testimonials.quotes.quote1.userTitle'),
            text: t('home.testimonials.quotes.quote1.quote')
          }
        };

      case 2:
        return {
          image: t('home.testimonials.quotes.quote2.background'),
          topic: {
            title: t('home.testimonials.quotes.quote2.categoryTitle'),
            desc: t('home.testimonials.quotes.quote2.categoryDesc')
          },
          quote: {
            name: t('home.testimonials.quotes.quote2.name'),
            designation: t('home.testimonials.quotes.quote2.userTitle'),
            text: t('home.testimonials.quotes.quote2.quote')
          }
        };

      case 3:
        return {
          image: t('home.testimonials.quotes.quote3.background'),
          topic: {
            title: t('home.testimonials.quotes.quote3.categoryTitle'),
            desc: t('home.testimonials.quotes.quote3.categoryDesc')
          },
          quote: {
            name: t('home.testimonials.quotes.quote3.name'),
            designation: t('home.testimonials.quotes.quote3.userTitle'),
            text: t('home.testimonials.quotes.quote3.quote')
          }
        };

      case 4:
        return {
          image: t('home.testimonials.quotes.quote4.background'),
          topic: {
            title: t('home.testimonials.quotes.quote4.categoryTitle'),
            desc: t('home.testimonials.quotes.quote4.categoryDesc')
          },
          quote: {
            name: t('home.testimonials.quotes.quote4.name'),
            designation: t('home.testimonials.quotes.quote4.userTitle'),
            text: t('home.testimonials.quotes.quote4.quote')
          }
        };

      default:
        return {};
    }
  };

  render() {
    const { activeSlideNumber } = this.state;
    const {
      image,
      topic: { title, desc } = {},
      quote: { name, designation, text } = {}
    } = this.getActiveSlide(activeSlideNumber);

    const { trackEvent, t } = this.props;

    return (
      <div className="testimonialsSection" id="testimonials">
        <div className="background" />
        <div className="section">
          <div className="titleContainer">
            <div
              className="image"
              style={{ backgroundImage: `url('${image}')` }}
            />
            <SectionTitle
              firstLine={t(`home.testimonials.titlePartOne`)}
              secondLine={t(`home.testimonials.titlePartTwo`)}
            />
          </div>

          <div className="testimonialsContainer">
            <div className="feature">
              <div className="title">
                <div className="text">{title}</div>

                <div className="actions nav desk">
                  <div
                    className="navBtn prev"
                    onClick={() => this.onNav('prev')}
                  >
                    <Icon name="chevron left" />
                  </div>
                  <div
                    className="navBtn next"
                    onClick={() => this.onNav('next')}
                  >
                    <Icon name="chevron right" />
                  </div>
                </div>
              </div>

              <div
                className="subTitle"
                dangerouslySetInnerHTML={{ __html: desc }}
              />
              <div className="mobile actions nav">
                <div className="navBtn prev" onClick={() => this.onNav('prev')}>
                  <Icon name="chevron left" />
                </div>
                <div className="navBtn next" onClick={() => this.onNav('next')}>
                  <Icon name="chevron right" />
                </div>
              </div>
            </div>

            <div className="testimonial">
              <div className="details">
                <div className="author">
                  <div className="name">{name}</div>
                  <div className="designation">{designation}</div>
                </div>
                <Image src="/images/quotes.png" size="small" />
              </div>
              <div className="quote">{`— “${text}”`}</div>

              <Link to="/login" className="action" onClick={trackEvent}>
                {t(`home.testimonials.getStarted`)}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TestimonialsSection.propTypes = {
  language: PropTypes.string,
  trackEvent: PropTypes.func,
  t: PropTypes.func
};

export default translate()(TestimonialsSection);
