import serverRequest from '../utils/Api';
import { EUCountries } from '../utils/constants';

import { calculateTransaction } from './taxamo';
import { paymentMethod, cleanUpAndSetTransaction } from './payments';
import {
  deriveBeaconsProductData,
  getCustomerCurrency
} from '../selectors/products';
import {
  setBillingInfo,
  setInfo,
  billingInfoValidations,
  shippingInfoValidations
} from './billing';
import { getPaymentInfoType, allFieldsValid } from '../selectors/billing';
import { setError, deleteErrorsOfBillingForm } from './errors';
import { createErrorObject } from '../utils/dataStore';
import { URLs } from '../utils/urls';

export const RECEIVE_BEACONS_PRODUCTS = 'RECEIVE_BEACONS_PRODUCTS';
export const SET_BEACON_QUANTITY = 'SET_BEACON_QUANTITY';
export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD';
export const SET_SELECTED_BEACON = 'SET_SELECTED_BEACON';

const receiveBeaconsProducts = obj => ({
  type: RECEIVE_BEACONS_PRODUCTS,
  obj
});

export const setSelectedBeacon = selectedBeacon => ({
  type: SET_SELECTED_BEACON,
  selectedBeacon
});

export const setBeaconQuantity = quantity => ({
  type: SET_BEACON_QUANTITY,
  quantity
});

export const setShippingMethod = selectedShippingMethod => ({
  type: SET_SHIPPING_METHOD,
  selectedShippingMethod
});

const setBeaconFromCustomerCurrency = () => (dispatch, getState) => {
  const customerCurrency = getCustomerCurrency(getState());

  const {
    items,
    shipping,
    quantity,
    selectedBeacon
  } = getState().products.beacons;
  const newBeacon =
    items && items.find(beacon => beacon.currency === customerCurrency);
  const differentCurrency = newBeacon && newBeacon.id !== selectedBeacon;
  const selectedShippingMethod =
    shipping &&
    shipping.find(
      shippingMethod =>
        shippingMethod.currency === customerCurrency &&
        shippingMethod.product_type.includes('free')
    );

  if (differentCurrency) {
    newBeacon && dispatch(setSelectedBeacon(newBeacon.id));
    selectedShippingMethod &&
      dispatch(setShippingMethod(selectedShippingMethod.id));
  }
  !quantity && dispatch(setBeaconQuantity(1));
};

export const requestBeaconsProducts = () => (dispatch, getState) => {
  const { method, url } = URLs.beacon.getBeaconProducts();
  serverRequest(method, url, getState())
    .then(resp =>
      resp.results.reduce(
        (acc, el) => {
          if (el.product_type === 'beacon.usb') {
            acc.items.push(el);
          } else {
            acc.shipping.push(el);
          }
          return acc;
        },
        { items: [], shipping: [] }
      )
    )
    .then(json => {
      dispatch(receiveBeaconsProducts(json));

      dispatch(setBeaconFromCustomerCurrency());
    });
};

const calculateBeaconPlans = options => {
  return (dispatch, getState) => {
    const {
      productData,
      country,
      taxId,
      email,
      quantity,
      shippingMethod,
      isEUCountry,
      taxIdHasChanged
    } = options;

    const {
      products: { beacons: allBeaconProducts }
    } = getState();

    if (productData && productData.ready) {
      const transactionOptions = {
        email,
        taxId,
        currency: productData.currency,
        userDeclaredCountry: country,
        product: productData.products.find(
          product => product.id === allBeaconProducts.selectedBeacon
        ),
        quantity,
        shippingMethod: allBeaconProducts.shipping.find(
          method => method.id === shippingMethod
        ),
        isEUCountry
      };
      calculateTransaction(transactionOptions, 'product')
        .then(
          transaction => {
            dispatch(cleanUpAndSetTransaction(transaction));

            if (
              taxIdHasChanged &&
              transaction.billing_country_code !== country
            ) {
              const {
                billing: {
                  payment: { billingInfo }
                }
              } = getState();
              dispatch(
                setBillingInfo(
                  Object.assign({}, billingInfo, {
                    country: transaction.billing_country_code
                  })
                )
              );
            }
          },
          err => {
            console.error('calculateTransaction: ', err);
          }
        )
        .catch(err => console.error('calculateBeaconPlans: ', err));
    }
  };
};

export const calculateBeacon = (taxIdHasChanged = false) => (
  dispatch,
  getState
) => {
  const state = getState();
  const {
    billing: {
      payment: {
        billingInfo: { country, taxId }
      }
    },
    user: {
      details: { email }
    },
    products: {
      beacons: { selectedShippingMethod, quantity }
    }
  } = state;

  const productData = deriveBeaconsProductData(state);
  const isEUCountry = EUCountries.includes(country);

  productData.ready &&
    dispatch(
      calculateBeaconPlans({
        productData,
        country,
        isEUCountry,
        taxId,
        email,
        quantity,
        shippingMethod: selectedShippingMethod,
        taxIdHasChanged
      })
    );
};

export const setBeaconProductAndCalculate = taxIdHasChanged => dispatch => {
  dispatch(setBeaconFromCustomerCurrency());

  dispatch(calculateBeacon(taxIdHasChanged));
};

export const doPayBeaconsClick = () => (dispatch, getState) => {
  dispatch(deleteErrorsOfBillingForm('beacon'));
  const { sameAddressBillingShipping } = getState().billing.payment;
  dispatch(billingInfoValidations());
  !sameAddressBillingShipping && dispatch(shippingInfoValidations());

  const { valid, validations } = allFieldsValid(getState(), true);

  if (valid) {
    const paymentInfoType = getPaymentInfoType(getState());
    if (paymentInfoType.includes('paypal')) {
      dispatch(setInfo({ ready: false, verifying: true }));
      dispatch(paymentMethod('product'));
    }
  } else {
    dispatch(
      setError(createErrorObject('', 'form_validations_error', validations))
    );
  }
};
