import React from 'react';
import PropTypes from 'prop-types';

const SlidingMenu = ({
  profileHeader,
  menuItems,
  isOpen,
  footer,
  onItemClick,
  onClose
}) => {
  return (
    <React.Fragment>
      <div className={`mobileSidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebarMenu">
          {profileHeader && (
            <div className="sidebarMenuHeaderItem">
              <span>{profileHeader}</span>
            </div>
          )}
          {menuItems.map(item => (
            <div
              className={`sidebarMenuItem ${item.isActive ? 'active' : ''}`}
              key={item.key}
              item-key={item.key}
              onClick={onItemClick}
            >
              {item.element}
            </div>
          ))}
        </div>

        {footer && <div className="footer">{footer}</div>}
      </div>
      <div
        className={`dimmedTeambarBg${isOpen ? ` visible` : ``}`}
        onClick={onClose}
      />
    </React.Fragment>
  );
};

SlidingMenu.propTypes = {
  profileHeader: PropTypes.string,
  menuItems: PropTypes.array,
  isOpen: PropTypes.bool,
  footer: PropTypes.element,
  onItemClick: PropTypes.func,
  onClose: PropTypes.func
};

export default SlidingMenu;
