import { setPreferredTimeFormat } from './user';
import { setDateFormatOptions, setPreferredDateFormat } from './datePreference';

import { setLocal } from '../selectors/locale';

import { getDateFormatOptionsFromPreference } from '../utils';

export const SET_SELECTED_TEAM = 'SET_SELECTED_TEAM';
export const USER_HAS_TEAMS = 'USER_HAS_TEAMS';
export const USER_HAS_NO_TEAMS = 'USER_HAS_NO_TEAMS';
export const USER_HAS_TEAM_SUBSCRIPTION = 'USER_HAS_TEAM_SUBSCRIPTION';

export const setSelectedTeam = (teamId, roles, permissions) => ({
  type: SET_SELECTED_TEAM,
  teamId,
  roles,
  permissions
});
export const setHasTeams = () => ({
  type: USER_HAS_TEAMS
});
export const setHasNoTeams = () => ({
  type: USER_HAS_NO_TEAMS
});
export const setHasTeamSubscription = () => ({
  type: USER_HAS_TEAM_SUBSCRIPTION
});

export const setCurrentTeamInfo = teamId => (dispatch, getState) => {
  const {
    user: { joiningTeamId },
    teams: { list: allTeams }
  } = getState();
  // find role and permissions for selected team
  const currentTeam = allTeams.find(team => team.id === teamId);
  const userRoles = currentTeam.user_roles.reduce((acc, next) => {
    const role = next.role.name.toLowerCase();
    acc[role] = {
      manageRates: next.manage_rates,
      manageRoles: next.manage_roles,
      manageVehicles: next.manage_vehicles,
      approveOwnReports: next.approve_own_report
    };
    if (role === 'manager' || role === 'admin') {
      acc[role] = Object.assign({}, acc[role], {
        minApprovalAmount:
          next.min_approval === -1
            ? Number.MIN_SAFE_INTEGER
            : next.min_approval,
        maxApprovalAmount:
          next.max_approval === -1 ? Number.MAX_SAFE_INTEGER : next.max_approval
      });
    }

    return acc;
  }, {});

  const rolesInSelectedTeam = Object.keys(userRoles);
  let permissionsInSelectedTeam = userRoles.employee;
  if (userRoles.admin) {
    permissionsInSelectedTeam = userRoles.admin;
  } else if (userRoles.manager) {
    permissionsInSelectedTeam = userRoles.manager;
  }

  setLocal('selectedTeam', teamId);

  dispatch(setPreferredDateFormat(currentTeam.date_format));
  dispatch(
    setDateFormatOptions(
      getDateFormatOptionsFromPreference(currentTeam.date_format)
    )
  );
  dispatch(setPreferredTimeFormat(currentTeam.time_format));

  dispatch(
    setSelectedTeam(
      joiningTeamId || teamId,
      rolesInSelectedTeam,
      permissionsInSelectedTeam
    )
  );
};
