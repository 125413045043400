import {
  SET_HELP_PAGE_DATA,
  SET_SEARCH_ID,
  SET_SEARCH_TEXT,
  UNSET_SEARCH_ID,
  UNSET_SEARCH_TEXT
} from '../actions/help';

const initialState = {
  list: [],
  questionIdToLookup: null,
  searchText: null,
  listLanguage: 'en'
};

export const helpPage = (state = initialState, action) => {
  switch (action.type) {
    case SET_HELP_PAGE_DATA:
      return {
        ...state,
        list: action.data.faq.concat(action.data.troubleshooting),
        listLanguage: action.data.listLanguage
      };
    case SET_SEARCH_ID:
      return {
        ...state,
        questionIdToLookup: action.hid
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.text
      };
    case UNSET_SEARCH_ID:
      return {
        ...state,
        questionIdToLookup: null
      };
    case UNSET_SEARCH_TEXT:
      return {
        ...state,
        searchText: null
      };
    default:
      return state;
  }
};
