import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { Image, Icon } from 'semantic-ui-react';

import SlidingMenu from '../components/SlidingMenu';

import { setAppLanguageAction } from '../actions';

import { getAppLanguage } from '../selectors/app';

import { getPublicLinkFor } from '../utils';
import { availableLanguages } from '../utils/landingConstants';
import { MAIL_TO_LINKS } from '../utils/constants';

class PublicRouteHeaderMobile extends Component {
  state = {
    isMenuOpen: false
  };

  onLanguageChange = language => {
    this.props.dispatch(setAppLanguageAction(language));
  };

  getMenuItems = () => {
    const { t, location } = this.props;
    return [
      {
        key: 'pro',
        isActive: location.pathname === getPublicLinkFor('solutionsPro'),
        element: <Link to={getPublicLinkFor('solutionsPro')}>Psngr Pro</Link>
      },
      {
        key: 'enterprise',
        isActive: location.pathname === getPublicLinkFor('solutionsEnterprise'),
        element: (
          <Link to={getPublicLinkFor('solutionsEnterprise')}>
            Psngr Enterprise
          </Link>
        )
      },
      {
        key: 'blog',
        isActive: false,
        element: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://blog.psngr.co"
          >
            {t('home.footer.blog')}
          </a>
        )
      },
      {
        key: 'faq',
        isActive: location.pathname === getPublicLinkFor('help'),
        element: (
          <Link to={getPublicLinkFor('help')}>{t('home.footer.faq')}</Link>
        )
      },
      {
        key: 'contactUs',
        isActive: false,
        element: (
          <a href={MAIL_TO_LINKS.GENERAL_CONTACT}>
            {t('home.questions.contact')}
          </a>
        )
      }
    ];
  };
  getMenuFooter = () => {
    const { appLanguage } = this.props;

    return (
      <React.Fragment>
        {availableLanguages.map(lang => (
          <span
            className={`langItem ${
              appLanguage === lang.value ? 'selected' : ''
            }`}
            key={lang.value}
            onClick={() => this.onLanguageChange(lang.value)}
          >
            {lang.smallText}
          </span>
        ))}
      </React.Fragment>
    );
  };

  toggleMenu = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  render() {
    const { trackEvent, t } = this.props;
    const { isMenuOpen } = this.state;

    return (
      <div id="landingHeader" className="mobile">
        <div className="section">
          <Link to="/" className="homeLogo">
            <Image src="/images/psngr-logo.png" />
          </Link>

          <Link to="/dashboard" className="signInBtn" onClick={trackEvent}>
            {t(`home.header.goToDashboard`)}
          </Link>

          <div className="menuIcon">
            <Icon
              name={isMenuOpen ? `cancel` : `sidebar`}
              onClick={this.toggleMenu}
              size="large"
              color="grey"
            />
          </div>
        </div>

        <SlidingMenu
          isOpen={isMenuOpen}
          menuItems={this.getMenuItems()}
          footer={this.getMenuFooter()}
          onItemClick={this.toggleMenu}
          onClose={this.toggleMenu}
        />
      </div>
    );
  }
}

PublicRouteHeaderMobile.propTypes = {
  appLanguage: PropTypes.string,
  trackEvent: PropTypes.func,
  t: PropTypes.func,
  dispatch: PropTypes.func,
  location: PropTypes.object
};

const mapStateToProps = state => {
  return {
    appLanguage: getAppLanguage(state)
  };
};
const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default translate()(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(PublicRouteHeaderMobile)
  )
);
