import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import MobileDetect from 'mobile-detect';

import Header from '../containers/PublicRouteHeader';
import Footer from '../containers/PublicRouteFooter';

import IntroSection from '../components/Landing/LandingIntroSection';
import StatsSection from '../components/Landing/StatsSection';
import HowItWorksSection from '../components/Landing/HowItWorksSection';
import MeetAppSection from '../components/Landing/MeetAppSection';
import BeaconIntroSection from '../components/Landing/BeaconIntroSection';
import TestimonialsSection from '../components/Landing/TestimonialsSection';
import PlansSection from '../components/Landing/PlansSection';
import PlansSectionMobile from '../components/Landing/PlansSectionMobile';
import StoreButtons from '../components/StoreButtons';

import { fetchAppStats, setAppLanguageAction } from '../actions';
import { retreivePlans } from '../actions/billing';

import {
  isUserLoggedIn,
  getActiveUserSubscription,
  getIsMobile,
  getIsTablet
} from '../selectors/user';
import { getBillingCountry } from '../selectors/managePlan';
import { getAllPlans } from '../selectors/billing';
import { getAppLanguage } from '../selectors/app';

import { getPlansForCountry } from '../utils/plans';
import { availableLanguages } from '../utils/landingConstants';
import {
  trackAppDownload,
  trackLoginFromGetStarted,
  trackMoreInfo
} from '../utils/analytics';

import '../css/landing.css';
import '../css/landing_intro.css';
import '../css/landing_stats.css';
import '../css/landing_howItWorks.css';
import '../css/landing_meetApp.css';
import '../css/landing_beacon.css';
import '../css/landing_testimonials.css';
import '../css/landing_plans.css';

class LandingPage extends Component {
  constructor(props) {
    super(props);
    const md = new MobileDetect(window.navigator.userAgent);
    this.os = md.os();
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchAppStats());
    dispatch(retreivePlans(true));
  }
  componentDidMount() {
    const page = document.getElementById('landingPage');
    if (page && !window.location.href.includes('#')) {
      page.scrollTop = 0;
    }
    const langObjToSelect = availableLanguages.find(
      lang => lang.value === this.props.language
    );
    if (!langObjToSelect) {
      this.props.dispatch(setAppLanguageAction('en'));
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.language !== nextProps.language) {
      const langObjToSelect = availableLanguages.find(
        lang => lang.value === nextProps.language
      );
      let languageToSelect = 'en';
      if (langObjToSelect) {
        languageToSelect = langObjToSelect.value;
      }
      this.props.dispatch(setAppLanguageAction(languageToSelect));
    }
  }

  getIntroActions = language =>
    Array(1).fill(
      <StoreButtons
        key={'ev_download'}
        showIBtn={this.os !== 'AndroidOS'}
        showAndroidBtn={this.os !== 'iOS'}
        language={language}
        trackEventForDownload={this.trackEventForDownload}
      />
    );
  trackEventForDownload = label => {
    trackAppDownload('main', label);
  };
  trackEventForGetStarted = () => {
    if (!this.props.userLoggedIn) {
      trackLoginFromGetStarted('main');
    }
  };
  trackEventForMoreInfo = label => {
    trackMoreInfo('main', label);
  };

  render() {
    const {
      language,
      appStats,
      allPlans,
      userLoggedIn,
      currentSubscription,
      t,
      isMobile,
      isTablet
    } = this.props;
    return (
      <div id="landingPage" className="home">
        <Header />

        <IntroSection
          appName="Psngr"
          title={t(`home.intro.title`)}
          subTitle={t(`home.intro.subtitle`)}
          actions={Array(1).fill(this.getIntroActions(language))}
        />
        <StatsSection language={language} appStats={appStats} />
        <HowItWorksSection />
        <MeetAppSection
          isMobile={isMobile}
          trackEvent={this.trackEventForGetStarted}
        />
        <BeaconIntroSection />
        {!(isMobile || isTablet) && (
          <TestimonialsSection
            language={language}
            trackEvent={this.trackEventForGetStarted}
          />
        )}
        {isMobile ? (
          <PlansSectionMobile
            allPlans={allPlans}
            t={t}
            trackEventForLogin={this.trackEventForGetStarted}
            trackEventForMoreInfo={this.trackEventForMoreInfo}
          />
        ) : (
          <PlansSection
            allPlans={allPlans}
            userLoggedIn={userLoggedIn}
            currentSubscription={currentSubscription}
            trackEventForLogin={this.trackEventForGetStarted}
            trackEventForMoreInfo={this.trackEventForMoreInfo}
          />
        )}

        <Footer />
      </div>
    );
  }
}

LandingPage.propTypes = {
  language: PropTypes.string,
  appStats: PropTypes.object,
  allPlans: PropTypes.array,
  userLoggedIn: PropTypes.bool,
  currentSubscription: PropTypes.object,
  t: PropTypes.func,
  dispatch: PropTypes.func,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool
};

const mapStateToProps = state => {
  const { appStats } = state;
  return {
    language: getAppLanguage(state),
    appStats,
    allPlans: getPlansForCountry(getAllPlans(state), getBillingCountry(state)),
    userLoggedIn: isUserLoggedIn(state),
    currentSubscription: getActiveUserSubscription(state),
    isMobile: getIsMobile(state),
    isTablet: getIsTablet(state)
  };
};
const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default translate()(
  connect(mapStateToProps, mapDispatchToProps)(LandingPage)
);
