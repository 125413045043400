import { URLs } from '../utils/urls';
import serverRequest from '../utils/Api';
import { getUserVehicles } from '../selectors/vehicles';
import { updateUserVehicles } from './vehicles';
import { getMileageRates } from '../selectors/mileageRates';
import { setError, deleteMileageRateErrors } from './errors';
import { createErrorObject } from '../utils/dataStore';

export const SET_USER_MILEAGE_RATES = 'SET_USER_MILEAGE_RATES';

export const setUserMileageRates = list => ({
  type: SET_USER_MILEAGE_RATES,
  list
});

export const requestUserMileageRates = () => (dispatch, getState) => {
  const { method, url } = URLs.mileageRates.getUserMileageRates();
  serverRequest(method, url, getState()).then(json => {
    dispatch(setUserMileageRates(json.mileage_rates));
  });
};

export const Visibility = Object.freeze({
  PRIVATE: 1,
  TEAM: 2,
  PUBLIC: 3
});

const handleUpdateMileageRate = (vehicles, mileageRateTag, mileageRateId) => (
  dispatch,
  getState
) => {
  const state = getState();

  const userVehicles = getUserVehicles(state);
  const updatedVehicles = userVehicles.reduce((acc, vehicle) => {
    const updatedVehicle = vehicles.find(item => item.id === vehicle.id);
    if (updatedVehicle) {
      acc.push(updatedVehicle);
    } else {
      acc.push(vehicle);
    }
    return acc;
  }, []);
  dispatch(updateUserVehicles(updatedVehicles));

  const vehicleIds = vehicles.map(item => item.id);
  const updatedMileageRates = [...getMileageRates(state)];
  // invalidate trips list after mileage rate change
  state.listNew.trips.isInvalid = true;

  if (mileageRateId) {
    const updatedMileageRateIndex = updatedMileageRates.findIndex(
      item => item.id === mileageRateId
    );
    updatedMileageRates[updatedMileageRateIndex] = Object.assign(
      {},
      updatedMileageRates[updatedMileageRateIndex],
      {
        vehicle_ids: vehicleIds
      }
    );
  } else {
    const updatedMileageRate = vehicles[0].mileage_rates.find(
      item => item.tag === mileageRateTag
    );
    if (updatedMileageRate) {
      updatedMileageRates.push(
        Object.assign({}, updatedMileageRate, { vehicle_ids: vehicleIds })
      );
    }
  }

  dispatch(setUserMileageRates(updatedMileageRates));
};

export const addExistingMileageRateToVehicle = (vehicleId, mileageRateId) => (
  dispatch,
  getState
) => {
  const state = getState();
  const existingMileageRates = getMileageRates(state);
  const existingMileageRate = existingMileageRates.find(
    mileageRate => mileageRate.id === mileageRateId
  );
  let body = {};
  if (existingMileageRate) {
    body = {
      user_vehicles: {
        ids: existingMileageRate.vehicle_ids.concat([vehicleId])
      }
    };
  }
  dispatch(deleteMileageRateErrors);
  const { method, url } = URLs.mileageRates.updateMileageRate(mileageRateId);
  serverRequest(method, url, state, { body }).then(
    resp => {
      if (resp.valid) {
        dispatch(
          handleUpdateMileageRate(
            resp.user_vehicles,
            existingMileageRate.tag,
            mileageRateId
          )
        );
      }
    },
    ({ err, statusCode }) => {
      console.log(err.message);
      dispatch(
        setError(
          createErrorObject(err.message, 'mileage_rate_error', {
            err,
            statusCode
          })
        )
      );
    }
  );
};

export const addNewMileageRateToVehicle = (vehicleId, mileageRate) => (
  dispatch,
  getState
) => {
  const state = getState();
  const {
    name,
    tag,
    amount,
    currency,
    unit,
    startDate,
    shareWithTeam,
    team_id
  } = mileageRate;
  let expUnit = ['km', 'mile'].includes(unit)
    ? '*d'
    : unit === 'hour'
    ? '*t'
    : '';
  const body = {
    mileage_rate: {
      currency: currency.toUpperCase(),
      name: name.trim(),
      tag: tag.trim(),
      unit,
      team_id: shareWithTeam ? team_id : null,
      visibility: shareWithTeam ? Visibility.TEAM : Visibility.PRIVATE,
      travel_mode: 'DRIVING',
      rules: [
        {
          start_date: Math.round(startDate.getTime() / 1000),
          start_total_distance: 0,
          expression: `${parseFloat(amount)}${expUnit}`
        }
      ]
    },
    user_vehicles: {
      ids: [vehicleId]
    }
  };

  const { method, url } = URLs.mileageRates.addToVehicle();
  serverRequest(method, url, state, { body }).then(resp => {
    if (resp.valid) {
      dispatch(handleUpdateMileageRate(resp.user_vehicles, tag));
    }
  });
};
