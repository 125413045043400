import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import SectionTitle from '../SectionTitle';

const CustomersSection = ({ t }) => (
  <div className="customersSection">
    <div className="section">
      <div className="logosContainer" />
      <div className="textContainer">
        <SectionTitle secondLine={t(`home.enterprise.customer.title`)} />
        <div className="desc">
          {/* <span className="bold">Psngr</span> */}
          <span>{t(`home.enterprise.customer.description`)}</span>
        </div>
        {/* <div className="title">{t(`home.enterprise.customer.companies`)}</div> */}
      </div>
    </div>
  </div>
);

CustomersSection.propTypes = {
  t: PropTypes.func.isRequired
};

export default translate()(CustomersSection);
