import React from 'react';
import _isEmpty from 'lodash/isEmpty';

import { Currency } from '../components/Units';
import { getNameOfVclass } from '../utils/vehicles';

import { columnNames } from './constants';
import i18n from './i18n';
import { getCountryName, parseTripsDisplayFields } from '../selectors/locale';

export function getDefaultTripColumnsForEmployee() {
  return [
    columnNames.CHECK,
    columnNames.ID,
    columnNames.DATE,
    columnNames.TEAM_TRIP_EMPLOYEE,
    columnNames.DURATION,
    columnNames.MODE_VEHICLE,
    columnNames.ADDRESS_FROM_TO,
    columnNames.CITY_FROM_TO,
    columnNames.TAGS_NOTES,
    columnNames.EMISSIONS,
    columnNames.START_ODOMETER,
    columnNames.END_ODOMETER,
    columnNames.DISTANCE,
    columnNames.REVISION,
    columnNames.EXPENSES_RATE,
    columnNames.APPROVAL_STATUS,
    columnNames.MORE
  ];
}
export function getDefaultTripColumns() {
  return [
    columnNames.CHECK,
    columnNames.ID,
    columnNames.DATE,
    columnNames.DURATION,
    columnNames.MODE_VEHICLE,
    columnNames.ADDRESS_FROM_TO,
    columnNames.CITY_FROM_TO,
    columnNames.TAGS_NOTES,
    columnNames.EMISSIONS,
    columnNames.START_ODOMETER,
    columnNames.END_ODOMETER,
    columnNames.DISTANCE,
    columnNames.REVISION,
    columnNames.EXPENSES_RATE,
    columnNames.MORE
  ];
}

export function getDefaultTripColumnsForManager() {
  return [
    // columnNames.CHECK,
    columnNames.CHECK,
    columnNames.ID,
    columnNames.DATE,
    columnNames.TEAM_TRIP_EMPLOYEE,
    columnNames.DURATION,
    columnNames.MODE_VEHICLE,
    columnNames.ADDRESS_FROM_TO,
    columnNames.CITY_FROM_TO,
    columnNames.TAGS_NOTES,
    columnNames.EMISSIONS,
    columnNames.START_ODOMETER,
    columnNames.END_ODOMETER,
    columnNames.DISTANCE,
    columnNames.REVISION,
    columnNames.EXPENSES_RATE,
    columnNames.APPROVAL_STATUS,
    columnNames.MORE
  ];
}

export function getDefaultVehiclesColumns() {
  return [
    columnNames.ID,
    columnNames.LICENSE,
    columnNames.MAKE,
    columnNames.MODEL,
    columnNames.YEAR,
    columnNames.BEACON,
    columnNames.CURRENTODOMETER,
    columnNames.LASTODOMETER,
    columnNames.MILEAGERATE
  ];
}

export const getDefaultReportsColumns = () => {
  return [
    columnNames.CHECK,
    columnNames.ID,
    columnNames.TITLE,
    columnNames.REPORT_DATE_RANGE,
    columnNames.FILTERS,
    columnNames.TRIPS,
    columnNames.REPORT_DISTANCE,
    columnNames.REPORT_EXPENSES
  ];
};
export const getDefaultReportsColumnsEmployee = () => {
  return [
    columnNames.CHECK,
    columnNames.ID,
    columnNames.TITLE,
    columnNames.REPORT_DATE_RANGE,
    columnNames.FILTERS,
    columnNames.TEAM_REPORT_STATUS,
    columnNames.TRIPS,
    columnNames.REPORT_DISTANCE,
    columnNames.REPORT_EXPENSES
  ];
};
export const getDefaultReportsColumnsManager = () => {
  return [
    columnNames.CHECK,
    columnNames.ID,
    columnNames.TEAM_REPORT_REPORTED_BY,
    columnNames.TITLE,
    columnNames.REPORT_DATE_RANGE,
    columnNames.FILTERS,
    columnNames.TEAM_REPORT_STATUS,
    columnNames.TRIPS,
    columnNames.REPORT_DISTANCE,
    columnNames.REPORT_EXPENSES
  ];
};

export const getDefaultReportRulesColumns = () => {
  return [
    columnNames.CHECK,
    columnNames.ID,
    columnNames.NAME,
    columnNames.FILTERS,
    columnNames.FREQUENCY,
    columnNames.AUTO_SUBMIT,
    columnNames.ENABLED
  ];
};

export const getDefaultTeamsColumns = () => {
  return [
    columnNames.ID,
    columnNames.TEAM_NAME,
    columnNames.TEAM_MEMBERS,
    columnNames.TEAM_COUNTRY,
    columnNames.TEAM_DATE_FORMAT,
    columnNames.TEAM_CURRENCY
  ];
};

export const getDefaultTeamMembersColumns = () => {
  return [
    columnNames.CHECK,
    columnNames.ID,
    columnNames.MEMBER_NAME,
    columnNames.MEMBER_EMAIL,
    columnNames.MEMBER_ROLES,
    columnNames.MEMBER_INVITE_STATUS,
    columnNames.MEMBER_SINCE
  ];
};

export function getInitialColumns() {
  return [
    columnNames.CHECK,
    columnNames.ID,

    // trips
    columnNames.DATE,
    columnNames.TEAM_TRIP_EMPLOYEE,
    columnNames.DURATION,
    columnNames.MODE_VEHICLE,
    columnNames.ADDRESS_FROM_TO,
    columnNames.TAGS_NOTES,
    columnNames.DISTANCE,
    columnNames.EXPENSES_RATE,
    columnNames.APPROVAL_STATUS,
    columnNames.MORE,

    // reports
    columnNames.TITLE,
    columnNames.REPORT_DATE_RANGE,
    columnNames.FILTERS,
    columnNames.REPORT_DISTANCE,
    columnNames.REPORT_EXPENSES,
    columnNames.TRIPS,
    columnNames.REPORTID,
    columnNames.DISTANCE,
    // reports teams
    columnNames.TEAM_REPORT_REPORTED_BY,
    columnNames.TEAM_REPORT_STATUS,

    // automatic reports
    columnNames.NAME,
    columnNames.FILTERS,
    columnNames.FREQUENCY,
    columnNames.AUTO_SUBMIT,
    columnNames.ENABLED,

    // vehicles
    columnNames.LICENSE,
    columnNames.MAKE,
    columnNames.MODEL,
    columnNames.YEAR,
    columnNames.BEACON,
    columnNames.CURRENTODOMETER,
    columnNames.LASTODOMETER,
    columnNames.MILEAGERATE,

    // teams
    columnNames.TEAM_NAME,
    columnNames.TEAM_MEMBERS,
    columnNames.TEAM_COUNTRY,
    // columnNames.TEAM_LANGUAGE,
    columnNames.TEAM_DATE_FORMAT,
    columnNames.TEAM_CURRENCY,

    // team members
    columnNames.MEMBER_NAME,
    columnNames.MEMBER_EMAIL,
    columnNames.MEMBER_ROLES,
    columnNames.MEMBER_INVITE_STATUS,
    columnNames.MEMBER_SINCE
  ];
}

export const getRate = (trip, mileageRateMatcher) => {
  if (
    !trip.user_vehicles ||
    !trip.user_vehicles ||
    trip.user_vehicles.length === 0 ||
    !trip.mileage_expenses ||
    !trip.stats
  ) {
    return '-';
  }
  const tags = trip.tags.map(item => {
    return item.name;
  });
  const mileageRate = mileageRateMatcher(trip.user_vehicles[0], tags);
  if (!mileageRate || !mileageRate.rules) {
    return '-';
  }
  let result = trip.mileage_expenses.rate;
  let unit =
    mileageRate.unit === 'trip'
      ? i18n.t('common.trip')
      : ['us', 'gb', 'lr', 'mm'].indexOf(mileageRate.country.toLowerCase()) > -1
      ? i18n.t('units.mi')
      : i18n.t('units.km');

  if (result) {
    return (
      <div>
        <span>
          <Currency
            value={result}
            iso={trip.mileage_expenses.currency}
            round={3}
          />
        </span>
        <span>{` / ${unit}`}</span>
      </div>
    );
  } else if (mileageRate.min_value === mileageRate.max_value) {
    return (
      <div>
        <span>
          <Currency
            value={mileageRate.min_value}
            iso={trip.mileage_expenses.currency}
            round={3}
          />
        </span>
        <span>{` / ${unit}`}</span>
      </div>
    );
  } else {
    // mileage rate has several rules with varying rates.
    // find the rate closest to the calculated value
    // const calculated = trip.mileage_expenses.value / trip.stats.distance;
    // let lastDelta = Infinity;
    // for (let i = 0; i < mileageRate.rules.length; i++) {
    //   const rule = mileageRate.rules[i];
    //   const actual = parseFloat(rule.expression.replace(/[^\d.]/g, ''));
    //   const delta = Math.min(Math.abs(calculated - actual), lastDelta);
    //   if (delta < lastDelta) {
    //     lastDelta = delta;
    //     result = actual;
    //   }
    // }
    result = (
      (trip.mileage_expenses.value / trip.stats.distance) *
      1000
    ).toFixed(3);
    if (result === 0) {
      return (
        <div>
          <span>
            [
            <Currency
              value={mileageRate.min_value}
              iso={trip.mileage_expenses.currency}
              round={3}
            />
            &nbsp;-&nbsp;
            <Currency
              value={mileageRate.max_value}
              iso={trip.mileage_expenses.currency}
              round={3}
            />
            ]
          </span>
          <span>{` / ${unit}`}</span>
        </div>
      );
    } else {
      return (
        <div>
          <span>
            <Currency
              value={result}
              iso={trip.mileage_expenses.currency}
              round={3}
            />
          </span>
          <span>{` / ${unit}`}</span>
        </div>
      );
    }
  }
};

export const getModeVehicleConversion = trip => {
  const { travel_mode, trip_vehicles } = trip;
  const userVehicles = [];
  if (_isEmpty(trip_vehicles)) {
    userVehicles.push({
      index: 0,
      travelMode: trip.travel_mode,
      vehicleName: trip.vehicle && i18n.t(`travelModes.${trip.vehicle}`)
    });
  } else {
    trip_vehicles.forEach((el, i) => {
      userVehicles.push({
        index: el.travel_mode === travel_mode ? -1 : i,
        travelMode: el.travel_mode,
        vehicleName: el.name || (el.vclass && getNameOfVclass(el.vclass))
      });
    });
  }
  userVehicles.sort((a, b) => a.index - b.index);
  return (
    <div className="modeVehicleContainer">
      <div className="modeContainer">
        {userVehicles.map(({ travelMode }, index) => {
          if (index > 0 && travelMode === userVehicles[index - 1].travelMode) {
            return null;
          }
          return (
            <div
              className={`travelMode ${travelMode} ${
                index === 0 ? 'primary' : 'secundary'
              }`}
              style={{ zIndex: 10 - index }}
              key={travelMode}
            />
          );
        })}
      </div>
      {trip.beacon && <div className="BEACON" />}
      <div className="vehicleNameContainer">
        {userVehicles.map(({ vehicleName }, index) => {
          const classList = ['vehicleName'];
          if (index !== 0) {
            classList.push('secundary');
            return (
              <span className={classList.join(' ')} key={vehicleName}>
                {`${vehicleName}`}
              </span>
            );
          }
          return (
            <div className={classList.join(' ')} key={vehicleName}>
              {vehicleName && vehicleName.toLowerCase() === 'flying'
                ? i18n.t(`list.flight`)
                : vehicleName}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const getReportDisplayFields = (onlyDefaults, forTeam, aggregate) => {
  if (onlyDefaults && !forTeam) {
    return [
      'id',
      'startdate',
      'starttime',
      'addressfrom',
      'endtime',
      'addressto',
      'travelmode',
      'vehicle',
      'notes',
      'distance',
      'revisions',
      'expenses'
    ];
  } else if (onlyDefaults && forTeam) {
    if (aggregate) {
      return [
        'id',
        'startdate',
        'employee_name',
        'starttime',
        'addressfrom',
        'endtime',
        'addressto',
        'travelmode',
        'vehicle',
        'notes',
        'distance',
        'revisions',
        'approval_status',
        'expenses'
      ];
    } else {
      return [
        'id',
        'startdate',
        'starttime',
        'addressfrom',
        'endtime',
        'addressto',
        'travelmode',
        'vehicle',
        'notes',
        'distance',
        'revisions',
        'approval_status',
        'expenses'
      ];
    }
  } else if (!onlyDefaults && !forTeam) {
    return [
      'id',
      'startdate',
      'starttime',
      'cityfrom',
      'addressfrom',
      'endtime',
      'cityto',
      'addressto',
      'travelmode',
      'vehicle',
      'tags',
      'notes',
      'emissions',
      'start_odometer',
      'end_odometer',
      'distance',
      'revisions',
      'rate',
      'expenses'
    ];
  }

  return [
    'id',
    'startdate',
    'employee_name',
    'starttime',
    'cityfrom',
    'addressfrom',
    'endtime',
    'cityto',
    'addressto',
    'duration',
    'travelmode',
    'vehicle',
    'tags',
    'notes',
    'emissions',
    'start_odometer',
    'end_odometer',
    'distance',
    'revisions',
    'approval_status',
    'rate',
    'expenses'
  ];
};

// TODO: optimize this function
export const getTripsExportColumns = (
  fieldsToBe,
  allHeaders,
  allDisplayFields,
  forTrips = false
) => {
  if (forTrips) {
    // when exporting trips list only export notes column out of tags and notes
    allDisplayFields.splice(allDisplayFields.indexOf('tags'), 1);
  }
  const mappedAllDisplayFields = allDisplayFields.reduce(
    (acc, field, index) => {
      acc[field] = index;
      return acc;
    },
    {}
  );

  const parsedFieldsToBe = parseTripsDisplayFields(fieldsToBe, allHeaders);
  const mappedParsedFieldsToBe = parsedFieldsToBe.reduce((acc, field) => {
    acc[field] = mappedAllDisplayFields[field];
    return acc;
  }, {});
  const parsedFieldsToBeArr = Object.keys(mappedParsedFieldsToBe).reduce(
    (acc, field) => {
      const index = mappedParsedFieldsToBe[field];
      if (index !== undefined && index !== null) {
        acc.push({ index, field });
      }

      return acc;
    },
    []
  );
  parsedFieldsToBeArr.sort((a, b) => {
    if (a.index > b.index) {
      return 1;
    } else if (a.index < b.index) {
      return -1;
    }
    return 0;
  });

  return parsedFieldsToBeArr.map(fieldObj => fieldObj.field);
};

export const orderDisplayFields = (unorderedList, allOrderedDisplayFields) => {
  const mappedAllDisplayFields = {};
  allOrderedDisplayFields.forEach((field, index) => {
    mappedAllDisplayFields[field] = index;
  });
  const finalMap = [];
  unorderedList.forEach(field => {
    const positionToBe = mappedAllDisplayFields[field];
    finalMap[positionToBe] = field;
  });
  // remove empty values from finalMap
  return finalMap.filter(item => item !== undefined || item !== null);
};

export const getAutoSubmitCell = autoSubmit => {
  const { value, unit } = autoSubmit || {};
  return value === -1
    ? i18n.t(`common.never`)
    : `${value} ${i18n.t(`time.${unit === 'd' ? `day` : `hour`}`, {
        count: value
      })}`;
};

// TODO: revisit this function; maybe this is same as getTripRevisionTitle???
export const getTripRevisionDetails = revision => {
  const revisionDetails = [];
  let diff = revision - 16;
  if (diff >= 0) {
    revisionDetails.push(i18n.t('list.revisionDetails.merged_short'));
    if (diff === 0) {
      return revisionDetails.join(', ');
    }
    diff -= 8;
  } else {
    diff = revision - 8;
  }
  if (diff >= 0) {
    revisionDetails.push(i18n.t('list.revisionDetails.deletedSteps_short'));
    if (diff === 0) {
      return revisionDetails.join(', ');
    }
    diff -= 4;
  } else {
    diff = revision - 4;
  }
  if (diff >= 0) {
    revisionDetails.push(i18n.t('list.revisionDetails.vehicle_short'));
    if (diff === 0) {
      return revisionDetails.join(', ');
    }
    diff -= 2;
  } else {
    diff = revision - 2;
  }
  if (diff >= 0) {
    revisionDetails.push(i18n.t('list.revisionDetails.travelMode_short'));
    if (diff === 0) {
      return revisionDetails.join(', ');
    }
    diff -= 1;
  } else {
    diff = revision - 1;
  }
  if (diff >= 0) {
    revisionDetails.push(i18n.t('list.revisionDetails.location_short'));
  }

  return revisionDetails.join(', ');
};
