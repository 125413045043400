import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Image } from 'semantic-ui-react';
import { translate } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import PublicRouteHeaderMobile from './PublicRouteHeaderMobile';

import { setAppLanguageAction } from '../actions';

import { getIsMobile, isUserLoggedIn } from '../selectors/user';
import { getAppLanguage } from '../selectors/app';

import { getPublicLinkFor } from '../utils';
import { availableLanguages } from '../utils/landingConstants';
import { trackLoginFromGoToDashboard } from '../utils/analytics';
import { getLocal } from '../selectors/locale';

const SolutionsMenu = ({ t }) => (
  <Dropdown
    trigger={<div className="menuItem">{t(`home.header.plans`)}</div>}
    className="solutionsDropdown"
    icon="chevron down"
  >
    <Dropdown.Menu>
      <Dropdown.Item key="pro">
        <Link to={getPublicLinkFor('solutionsPro')}>Pro</Link>
      </Dropdown.Item>
      <Dropdown.Item key="enterprise">
        <Link to={getPublicLinkFor('solutionsEnterprise')}>Enterprise</Link>
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

SolutionsMenu.propTypes = {
  t: PropTypes.func.isRequired
};

class PublicRouteHeader extends Component {
  state = {
    appLanguage: getLocal('appLanguage') || 'en'
  };
  componentDidMount() {
    const { appLanguage } = this.state;
    const langObjToSelect = availableLanguages.find(
      lang => lang.value === appLanguage
    );
    let languageToSelect = 'en';
    if (langObjToSelect) {
      languageToSelect = langObjToSelect.value;
    }

    this.setState({ appLanguage: languageToSelect });
  }

  onLogoClick = () => this.props.history.push('/');

  onLanguageDropdownChange = (_, { value }) => {
    this.setState({ appLanguage: value });
    const { dispatch } = this.props;
    dispatch(setAppLanguageAction(value));
  };

  getLangOptions = () => {
    const { appLanguage } = this.state;
    return availableLanguages.reduce((acc, lang) => {
      if (lang.value !== appLanguage) {
        acc.push({
          key: lang.value,
          value: lang.value,
          text: lang.smallText
        });
      }
      return acc;
    }, []);
  };

  trackLogin = () => {
    const {
      userLoggedIn,
      location: { pathname }
    } = this.props;
    if (!userLoggedIn) {
      let eventCategory = 'main';
      if (pathname === getPublicLinkFor('solutionsPro')) {
        eventCategory = 'pro';
      } else if (pathname === getPublicLinkFor('solutionsEnterprise')) {
        eventCategory = 'enterprise';
      }
      trackLoginFromGoToDashboard(eventCategory);
    }
  };

  render() {
    const { appLanguage } = this.state;
    const { isMobile, t } = this.props;

    return isMobile ? (
      <PublicRouteHeaderMobile trackEvent={this.trackLogin} />
    ) : (
      <div id="landingHeader">
        <div className="section">
          <div className="left">
            <div className="menuItem logo">
              <Image src="/images/psngr-logo.png" onClick={this.onLogoClick} />
            </div>
            <SolutionsMenu t={t} />
            <a
              className="menuItem"
              target="_blank"
              rel="noopener noreferrer"
              href="https://blog.psngr.co"
            >
              {t(`home.footer.blog`)}
            </a>
            <Link to={getPublicLinkFor('help')} className="menuItem">
              {t(`home.footer.faq`)}
            </Link>
          </div>
          <div className="right">
            <Dropdown
              icon="chevron down"
              className="languageDropdown"
              selectOnBlur={true}
              value={appLanguage}
              text={appLanguage.toUpperCase()}
              options={this.getLangOptions()}
              // onBlur={() => this.onLanguageChange(appLanguage)}
              onChange={this.onLanguageDropdownChange}
              name="language"
            />
            <Link
              to="/dashboard"
              className="signInBtn"
              onClick={this.trackLogin}
            >
              {t(`home.header.goToDashboard`)}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

PublicRouteHeader.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  userLoggedIn: PropTypes.bool,
  location: PropTypes.object,
  dispatch: PropTypes.func
};

const mapStateToProps = state => {
  return {
    isMobile: getIsMobile(state),
    userLoggedIn: isUserLoggedIn(state)
  };
};
const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default translate()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicRouteHeader))
);
