import {
  SIGNING_UP,
  SIGNED_UP,
  SIGNIN_DONE,
  SIGNIN_FAILED,
  EMAIL_EXISTS,
  EMAIL_AVAILABLE,
  ACCOUNT_IS_ACTIVE,
  WRONG_PASSWORD,
  CHANGED_PASSWORD,
  USER_SIGNOUT,
  SET_ISMOBILE,
  SET_ISTABLET,
  OTP_REQUIRED_FOR_LOGIN,
  OTP_AUTHENTICATION_FAILED,
  SET_USER_EMAIL,
  STORE_USER_INFO_FROM_INVITE,
  SET_USER_CURRENCY
} from '../actions/login';
import {
  RECEIVE_USER_DETAILS,
  UPDATE_USER_DETAILS,
  SET_TRIPS_LEFT,
  UPDATE_USER_DETAILS_REMOVE_PENDING,
  UPDATE_USER_SUBSCRIPTION
} from '../actions/user';

import { getCookie } from '../selectors/locale';

export const user = (
  state = {
    id: getCookie('user_id') ? parseInt(getCookie('user_id'), 10) : '',
    token: getCookie('user_token'),
    details: { vehicles: [], country: '' },
    emailExists: false,
    wrongPassword: false,
    signingUp: false,
    isMobile: null,
    isTablet: false,
    otpRequired: false,
    otpAuthFailed: false,
    currency: ''
  },
  action
) => {
  const getObjectToMerge = () => {
    let details = {};
    switch (action.type) {
      case RECEIVE_USER_DETAILS:
        return { details: action.details, detailsPresent: true };
      case UPDATE_USER_DETAILS:
        details = Object.assign({}, state.details, action.details);
        return Object.assign({}, state, { details });
      case SET_TRIPS_LEFT:
        details = Object.assign({}, state.details, {
          trips_left: action.count
        });
        return Object.assign({}, state, { details });
      case UPDATE_USER_DETAILS_REMOVE_PENDING:
        const {
          pending_subscription: pendingSubscription,
          ...restDetails
        } = state.details;
        details = Object.assign({}, restDetails, action.details);
        return Object.assign({}, state, { details });
      case SIGNED_UP:
        return {
          id: action.user_id,
          token: action.user_token
        };
      case USER_SIGNOUT:
        return { id: null, token: null };
      case EMAIL_EXISTS:
        return { emailExists: true };
      case EMAIL_AVAILABLE:
        return { emailExists: false };
      case ACCOUNT_IS_ACTIVE:
        return {
          isActive: action.data.active || true,
          message: action.data.message
        };
      case WRONG_PASSWORD:
        return { wrongPassword: true };
      case CHANGED_PASSWORD:
        return { wrongPassword: false };
      case SIGNING_UP:
        return { signingUp: true };
      case SIGNIN_FAILED:
        return {
          signingUp: false
        };
      case SIGNIN_DONE:
        return {
          signingUp: false,
          otpAuthFailed: false
        };
      case SET_USER_CURRENCY:
        return {
          currency: action.currency
        };
      case SET_ISMOBILE:
        return { isMobile: action.isMobile };
      case SET_ISTABLET:
        return { isTablet: action.isTablet };
      case OTP_REQUIRED_FOR_LOGIN:
        return { otpRequired: true };
      case OTP_AUTHENTICATION_FAILED:
        return { otpAuthFailed: true, signingUp: false };
      case SET_USER_EMAIL:
        details = Object.assign({}, state.details, { email: action.email });
        return Object.assign({}, state, { details });
      case STORE_USER_INFO_FROM_INVITE:
        details = Object.assign({}, state.details, {
          email: action.email,
          first_name: action.firstName,
          last_name: action.lastName
        });
        return Object.assign({}, state, {
          details,
          isInviteFlow: true,
          inviteCode: action.inviteCode,
          joiningTeamId: action.teamId
        });
      case UPDATE_USER_SUBSCRIPTION:
        details = Object.assign({}, state.details, {
          subscription: action.subscription
        });
        return Object.assign({}, state, { details });
      default:
        return state;
    }
  };
  return Object.assign({}, state, getObjectToMerge());
};
