export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const ADD_NOTIFICATION_FOR_BAR = 'ADD_NOTIFICATION_FOR_BAR';
export const REMOVE_NOTIFICATION_FROM_BAR = 'REMOVE_NOTIFICATION_FROM_BAR';

export const addNotification = notification => ({
  type: ADD_NOTIFICATION,
  notification
});

export const removeNotification = notificationId => ({
  type: REMOVE_NOTIFICATION,
  notificationId
});

export const addNotificationForBar = notification => ({
  type: ADD_NOTIFICATION_FOR_BAR,
  notification
});
export const removeNotificationFromBar = notificationType => ({
  type: REMOVE_NOTIFICATION_FROM_BAR,
  notificationType
});
