import React, { Fragment } from 'react';
import { createSelector } from 'reselect';
import { Popup } from 'semantic-ui-react';

import { Distance, Currency, Weight } from '../../components/Units';
import Time from '../../components/Time';

import {
  getKeyByValue,
  getTripRevisionTitle,
  getUserFullName
} from '../../utils';
import { columnNames, REPORT_STATUS } from '../../utils/constants';
import { getModeVehicleConversion, getRate } from '../../utils/listUtils';
import i18n from '../../utils/i18n';

import { getDateFormatter, getWeightCalculator, getCountry } from '../locale';
import { getActiveListGroup, getActiveList } from '../list';
import { getUserId, getUserInfo } from '../user';
import { getMileageRateMatcher } from '../vehicles';
import {
  getTeamUserInfo,
  getIsEmployee,
  getIsEmployeeAndManager,
  getRolesInSelectedTeam,
  getHasTeams
} from '../teams';
import { sectionSelector } from '../../utils/selector';

const columnsForUser = [
  columnNames.CHECK,
  columnNames.ID,
  columnNames.DATE,
  // columnNames.DURATION,
  columnNames.MODE_VEHICLE,
  columnNames.ADDRESS_FROM_TO,
  columnNames.CITY_FROM_TO,
  columnNames.TAGS_NOTES,
  columnNames.EMISSIONS,
  columnNames.DISTANCE,
  columnNames.EXPENSES_RATE,
  columnNames.MORE
];

const columnsForEmployee = [
  columnNames.CHECK,
  columnNames.ID,
  columnNames.DATE,
  // columnNames.DURATION,
  columnNames.MODE_VEHICLE,
  columnNames.ADDRESS_FROM_TO,
  columnNames.CITY_FROM_TO,
  columnNames.TAGS_NOTES,
  columnNames.EMISSIONS,
  columnNames.DISTANCE,
  columnNames.EXPENSES_RATE,
  columnNames.APPROVAL_STATUS,
  columnNames.MORE
];

const columnsForManager = [
  // columnNames.CHECK,
  columnNames.ID,
  columnNames.DATE,
  columnNames.TEAM_TRIP_EMPLOYEE,
  columnNames.MODE_VEHICLE,
  columnNames.ADDRESS_FROM_TO,
  columnNames.CITY_FROM_TO,
  columnNames.TAGS_NOTES,
  columnNames.EMISSIONS,
  columnNames.DISTANCE,
  columnNames.EXPENSES_RATE,
  columnNames.APPROVAL_STATUS,
  columnNames.MORE
];

const getAllColumns = createSelector(
  [
    getDateFormatter,
    getWeightCalculator,
    getMileageRateMatcher,
    getUserId,
    getCountry
  ],
  (
    dateFormatter,
    weightCalculator,
    mileageRateMatcher,
    loggedInUserId,
    countryName
  ) => {
    return {
      [columnNames.CHECK]: {
        label: 'checkbox',
        conversion: check => check,
        expandable: false,
        width: 78,
        classes: 'checkItem checkboxColumn',
        sortKey: '',
        sortable: false,
        actualKeys: ['']
      },
      [columnNames.ID]: {
        label: 'list.id',
        conversion: item => item.id,
        expandable: false,
        width: 60,
        classes: 'id-column',
        sortKey: 'id',
        sortable: false,
        actualKeys: ['id']
      },
      [columnNames.DATE]: {
        label: 'list.dateTime',
        conversion: trip => {
          return (
            <Fragment>
              <span>{dateFormatter(trip.departure_time * 1000)}</span>
              <div className="timesContainer">
                <Time value={trip.departure_time * 1000} />
                <span className="timeDash"> - </span>
                <Time value={trip.arrival_time * 1000} />
              </div>
            </Fragment>
          );
        },
        width: 135,
        expandable: false,
        classes: 'left date-time-column',
        sortKey: 'departure_time',
        sortable: true,
        actualKeys: ['startdate', 'starttime', 'endtime']
      },
      [columnNames.TEAM_TRIP_EMPLOYEE]: {
        label: 'list.teamTripEmployee',
        conversion: trip => {
          const { id } = trip.owner;
          const isYou = id === loggedInUserId;
          let fullName = '';
          if (trip.owner.full_name) {
            fullName = trip.owner.full_name;
          } else {
            fullName = getUserFullName(trip.owner);
          }
          return (
            <span className={isYou ? 'you' : ''}>
              {`${fullName} ${isYou ? `(${i18n.t('common.you')})` : ``}`}
            </span>
          );
        },
        width: 115,
        expandable: false,
        classes: 'left employee-name-column',
        sortKey: 'owner.name',
        sortable: true,
        actualKeys: []
      },
      [columnNames.DURATION]: {
        label: 'list.duration',
        conversion: trip => (
          <Time value={trip.stats && trip.stats.elapsed * 1000} duration />
        ),
        expandable: false,
        width: 70,
        classes: 'duration-column',
        sortKey: 'stats.elapsed',
        sortable: false,
        actualKeys: ['duration']
      },
      [columnNames.MODE_VEHICLE]: {
        label: 'list.modeVehicle',
        conversion: trip => getModeVehicleConversion(trip),
        width: 136,
        expandable: false,
        classes: 'mode-vehicle-column',
        sortKey: 'travel_mode',
        sortable: false,
        actualKeys: ['travelmode', 'vehicle']
      },
      [columnNames.ADDRESS_FROM_TO]: {
        label: 'list.fromTo',
        conversion: trip => {
          return (
            <Fragment>
              <div>
                {trip.flight
                  ? trip.flight.departureAirport.name
                  : (trip.origin && trip.origin.name) || '-'}
              </div>
              <div>
                {trip.flight
                  ? trip.flight.arrivalAirport.name
                  : (trip.destination && trip.destination.name) || '-'}
              </div>
            </Fragment>
          );
        },
        expandable: true,
        // width: 180,
        classes: 'address-column',
        sortKey: 'origin.name',
        sortable: false,
        actualKeys: ['addressfrom', 'addressto']
      },
      [columnNames.CITY_FROM_TO]: {
        label: 'list.cityFromTo',
        conversion: trip => {
          return (
            <Fragment>
              <div className="city">{trip.origin && trip.origin.city}</div>
              <div className="city">
                {trip.destination && trip.destination.city}
              </div>
            </Fragment>
          );
        },
        width: 100,
        expandable: false,
        classes: 'city-column',
        sortKey: 'origin.city',
        sortable: false,
        actualKeys: ['cityfrom', 'cityto']
      },
      [columnNames.TAGS_NOTES]: {
        label: 'list.tagsNotes',
        conversion: trip => {
          const classList = [];
          const tagsToShow = [];
          let notesToDisplay = trip.notes || '';
          if (!trip.tags.length) {
            tagsToShow.push(i18n.t(`list.untagged`));
            classList.push('unclassified');
          } else {
            trip.tags.forEach(tag => {
              const tagName = `#${tag.name}`;
              notesToDisplay = notesToDisplay.replace(`${tagName}`, '');
              tagsToShow.push(tagName);
            });
          }
          return (
            <Fragment>
              <div className={classList.join(' ')}>{tagsToShow.join(' ')}</div>
              {notesToDisplay.length > 20 ? (
                <Popup
                  trigger={<div className="notesContent">{notesToDisplay}</div>}
                  on="hover"
                  position="bottom left"
                  className="revisionTooltip small"
                >
                  {trip.notes}
                </Popup>
              ) : (
                <div className="notesContent">{notesToDisplay}</div>
              )}
            </Fragment>
          );
        },
        width: 130,
        expandable: true,
        classes: 'tags-column',
        sortable: false,
        actualKeys: ['tags', 'notes']
      },
      [columnNames.EMISSIONS]: {
        label: {
          key: 'list.emissionsUnit',
          parameters: { unit: weightCalculator().unitShort }
        },
        conversion: trip => {
          return (
            trip.stats && <Weight value={trip.stats.emissions} format="*v" />
          );
        },
        expandable: false,
        width: 100,
        classes: 'right emissions-column',
        sortKey: 'stats.emissions',
        sortable: true,
        actualKeys: ['emissions']
      },
      [columnNames.DISTANCE]: {
        label:
          ['us', 'gb', 'lr', 'mm'].indexOf(countryName.toLowerCase()) > -1
            ? 'list.distanceMi'
            : 'list.distanceKm',
        conversion: trip => {
          const revisionTitle = getTripRevisionTitle(trip.revised);
          return trip.stats ? (
            <div className="distanceContainer">
              <Distance value={trip.stats.distance} showUnit={false} />
              <span className="info">
                {revisionTitle ? (
                  <Popup
                    trigger={
                      <img
                        alt="revision"
                        src="/images/trip_revision_icon.png"
                        className="icon"
                      />
                    }
                    on="hover"
                    position="bottom center"
                    className="revisionTooltip small"
                  >
                    {revisionTitle}
                  </Popup>
                ) : (
                  ''
                )}
              </span>
            </div>
          ) : (
            '—'
          );
        },
        expandable: false,
        width: 80,
        classes: 'right distance-column',
        sortKey: 'stats.distance',
        sortable: true,
        actualKeys: ['distance']
      },
      [columnNames.EXPENSES_RATE]: {
        label: 'list.expensesRate',
        conversion: trip => {
          const status = getKeyByValue(
            REPORT_STATUS,
            trip.new_approval_status || trip.approval_status
          ).toLowerCase();
          return (
            <Fragment>
              {trip.mileage_expenses && trip.mileage_expenses.value ? (
                <Popup
                  trigger={
                    <div>
                      <Currency
                        value={trip.mileage_expenses.value}
                        iso={trip.mileage_expenses.currency}
                      />
                    </div>
                  }
                  on="hover"
                  position="bottom right"
                  className="revisionTooltip small"
                >
                  <Fragment>
                    <span>{i18n.t(`tooltip.expenseRate.mileageRate`)}</span>
                    {getRate(trip, mileageRateMatcher)}
                  </Fragment>
                </Popup>
              ) : (
                '—'
              )}
              <div className="rateContainer">
                <span className={`reportStatus ${status}`}>{status}</span>
              </div>
            </Fragment>
          );
        },
        expandable: false,
        width: 114,
        classes: 'right expenses-rate-column',
        sortKey: 'mileage_expenses.value',
        sortable: true,
        actualKeys: ['expenses', 'mileage_rate']
      },
      [columnNames.MORE]: {
        label: 'more',
        conversion: more => more,
        expandable: false,
        width: 44,
        classes: 'moreColumnPicker',
        sortKey: 'empty',
        sortable: false,
        actualKeys: ['']
      }
    };
  }
);

export const getColumns = createSelector(
  [
    getAllColumns,
    getActiveListGroup,
    getTeamUserInfo,
    getActiveList,
    getIsEmployee,
    getIsEmployeeAndManager
  ],
  (
    allColumns,
    { displayFields = {} },
    { rolesInSelectedTeam },
    activeList,
    isEmplyee,
    isEmployeeAndManager
  ) => {
    let columns = isEmplyee ? columnsForEmployee : columnsForUser;
    if (
      (isEmployeeAndManager && activeList === 'teamTrips') ||
      rolesInSelectedTeam.includes('admin') ||
      rolesInSelectedTeam.includes('manager')
    ) {
      columns = columnsForManager;
    }

    return columns.reduce((acc, column) => {
      if (displayFields[column]) {
        acc[column] = allColumns[column];
      }

      return acc;
    }, {});
  }
);

export const determineActiveTripsListType = state => {
  const {
    listNew: { activeList }
  } = state;
  const {
    stats: { total_trip_count }
  } = getUserInfo(state);
  if (activeList) {
    return activeList;
  }
  const hasTrips = total_trip_count > 0;
  const managerAndEmployee = getIsEmployeeAndManager(state);
  const hasTeams = getHasTeams(state);
  const rolesInSelectedTeam = getRolesInSelectedTeam(state);
  if (hasTeams) {
    if (managerAndEmployee) {
      return hasTrips ? 'trips' : 'teamTrips';
    } else if (rolesInSelectedTeam.includes('employee')) {
      return 'trips';
    }
    return 'teamTrips';
  }
  return 'trips';
};

export const getTripsHeaderTitle = state => {
  const managerAndEmployee = getIsEmployeeAndManager(state);
  const hasTeams = getHasTeams(state);
  const rolesInSelectedTeam = getRolesInSelectedTeam(state);
  if (hasTeams) {
    if (managerAndEmployee) {
      return listType =>
        listType === 'teamTrips'
          ? i18n.t(`dashboard.teamTrips`)
          : i18n.t(`dashboard.myTrips`);
    } else if (rolesInSelectedTeam.includes('employee')) {
      return () => i18n.t(`dashboard.myTrips`);
    }
    return () => i18n.t(`dashboard.teamTrips`);
  }
  return () => i18n.t(`dashboard.trips`);
};

export const getStatusMenuItems = (status, listType) => {
  const items = [];
  if (listType == 'trips') {
    items.push({
      key: 'completed',
      value: 'completed',
      text: i18n.t(`filters.completed`),
      active: status === 'completed'
    });
    items.push({
      key: 'aborted',
      value: 'aborted',
      text: i18n.t(`filters.deleted`),
      active: status === 'aborted'
    });
    items.push({
      key: 'active',
      value: 'active',
      text: i18n.t(`filters.active`),
      active: status === 'active'
    });
  } else if (listType == 'teamTrips') {
    items.push({
      key: 'new',
      value: 'new',
      text: i18n.t(`filters.new`),
      active: status === 'new'
    });
    items.push({
      key: 'approved',
      value: 'approved',
      text: i18n.t(`filters.approved`),
      active: status === 'approved'
    });
    items.push({
      key: 'aborted',
      value: 'aborted',
      text: i18n.t(`filters.deleted`),
      active: status === 'aborted'
    });
    items.push({
      key: 'active',
      value: 'active',
      text: i18n.t(`filters.active`),
      active: status === 'active'
    });
  }

  return items;
};

export const selectorTripsList = createSelector(
  sectionSelector('listNew.trips'),
  data => {
    const { list: trips = [], sortBy } = data;
    return {
      trips,
      sortBy
    };
  }
);
