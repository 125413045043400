import {
  SET_TEAMS_LIST,
  SELECT_TEAMS_LIST_ITEM,
  DESELECT_TEAMS_LIST_ITEM,
  DESELECT_ALL_TEAM_LIST_ITEMS,
  FETCHING_TEAMS_LIST,
  SET_TEAM_CURRENCY,
  UPDATE_TEAM_IN_LIST
} from '../actions/teams';

const initialState = {
  more: {},
  stats: {
    count: 0
  },
  pagination: {
    next: null
  },
  list: [],
  isFetching: false
};

export const teams = (state = initialState, action) => {
  const updatedList = [...state.list];
  const listItem = updatedList[action.itemIndex];
  let list = [];
  switch (action.type) {
    case FETCHING_TEAMS_LIST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case SET_TEAMS_LIST:
      return Object.assign({}, state, {
        stats: action.stats,
        pagination: action.pagination,
        list: action.list,
        isFetching: false
      });
    case SELECT_TEAMS_LIST_ITEM:
      updatedList[action.itemIndex] = Object.assign({}, listItem, {
        isSelected: true
      });
      return Object.assign({}, state, {
        list: updatedList
      });

    case DESELECT_TEAMS_LIST_ITEM:
      updatedList[action.itemIndex] = Object.assign({}, listItem, {
        isSelected: false
      });
      return Object.assign({}, state, {
        list: updatedList
      });
    case DESELECT_ALL_TEAM_LIST_ITEMS:
      return Object.assign({}, state, {
        list: updatedList.map(element =>
          Object.assign({}, element, { isSelected: false })
        )
      });
    case SET_TEAM_CURRENCY:
      return Object.assign({}, state, {
        more: Object.assign({}, state.more, {
          currencyCode: action.currencyCode
        })
      });
    case UPDATE_TEAM_IN_LIST:
      list = list.concat(state.list);
      list[action.index] = action.team;
      return Object.assign({}, state, { list });
    default:
      return state;
  }
};
