import {
  ADD_NOTIFICATION_FOR_BAR,
  REMOVE_NOTIFICATION_FROM_BAR
} from '../actions/notifications';

export const notificationBar = (state = [], action) => {
  const currentNotifications = [...state];
  switch (action.type) {
    case ADD_NOTIFICATION_FOR_BAR:
      return [
        ...currentNotifications.filter(
          notification => notification.type !== action.notification.type
        ),
        action.notification
      ];

    case REMOVE_NOTIFICATION_FROM_BAR:
      return currentNotifications.filter(
        notification => notification.type !== action.notificationType
      );

    default:
      return state;
  }
};
