import React from 'react';
import PropTypes from 'prop-types';
import MonthlyPrice from '../../ManagePlan/MonthlyPrice';

import SectionTitle from '../SectionTitle';
import { getDashboardLinkFor } from '../../../utils';

const UpgradeProSectionMobile = ({ plans, trackEvent, t }) => {
  const plansToShow = plans.reduce(
    (acc, plan) => {
      if (plan.billing_frequency === 1) {
        acc.monthly = plan;
      } else if (plan.billing_frequency === 12) {
        acc.annual = plan;
      }

      return acc;
    },
    {
      monthly: {},
      annual: {}
    }
  );
  return (
    <div className="upgradeProSection mobile" id="pricing">
      <div className="section">
        <div className="titleWrapper">
          <SectionTitle secondLine={t(`home.header.plans`)} white />
          <div className="sectionSubTitle">
            {t(`home.pro.upgrade.description`)}
          </div>
        </div>
        <div className="plansContainer">
          <a
            href={getDashboardLinkFor('managePlan')}
            className="planContainer"
            onClick={trackEvent}
          >
            <div className="details">
              <div className="planName">Pro Monthly</div>
              <div className="frequency">{t('manage.billedMonthly')}</div>
            </div>
            <div className="priceWrapper">
              <MonthlyPrice
                currency={plansToShow.monthly.currency}
                price={plansToShow.monthly.base_price}
                hidePerMonth={true}
              />
              <div className="frequency">
                {t('home.plans.plans.free.perMonth')}
              </div>
            </div>
          </a>

          <a
            href={getDashboardLinkFor('managePlan')}
            className="planContainer"
            onClick={trackEvent}
          >
            <div className="details">
              <div className="planName">Pro Annual</div>
              <div className="frequency">{t('manage.billedYearly')}</div>
            </div>
            <div className="priceWrapper">
              <MonthlyPrice
                currency={plansToShow.annual.currency}
                price={plansToShow.annual.base_price}
                hidePerMonth={true}
              />
              <div className="frequency">
                {t('home.plans.plans.free.perYear')}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

UpgradeProSectionMobile.propTypes = {
  plans: PropTypes.array,
  trackEvent: PropTypes.func,
  t: PropTypes.func
};

export default UpgradeProSectionMobile;
