import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  getDistanceCalculator,
  getStringifyNumber,
  getCurrencyCalculator,
  getWeightCalculator
} from '../selectors/locale';

class distance extends Component {
  static propTypes = {
    // meters
    value: PropTypes.number,
    format: PropTypes.string,
    showUnit: PropTypes.bool,
    showDecimal: PropTypes.bool
  };
  static defaultProps = {
    value: 0,
    showUnit: true,
    showDecimal: true
  };

  render() {
    const {
      calculator,
      value,
      format,
      showUnit,
      stringifyNumber,
      showDecimal,
      t
    } = this.props;
    if (value) {
      const result = stringifyNumber(calculator(value, showDecimal), format, t);
      return (
        <span className="unit distance">
          <span>{showUnit && result}</span>
          <span className="quantity">{!showUnit && result.split(' ')[0]}</span>
          {/* {showUnit && <span className="unit">{result.split(' ')[1]}</span>} */}
        </span>
      );
    }
    return <span className="unit distance">-</span>;
  }
}
const mapStateToDistance = (state, props) => {
  return {
    calculator: getDistanceCalculator(state),
    stringifyNumber: getStringifyNumber(state)
  };
};

class weight extends Component {
  static propTypes = {
    // kg
    value: PropTypes.number.isRequired,
    format: PropTypes.string
  };

  render() {
    const { calculator, value, format, stringifyNumber, t } = this.props;
    const result = stringifyNumber(calculator(value), format, t);
    return <span className="unit weight">{result}</span>;
  }
}
const mapStateToWeight = (state, props) => {
  return {
    calculator: getWeightCalculator(state),
    stringifyNumber: getStringifyNumber(state)
  };
};

class currency extends Component {
  static propTypes = {
    value: PropTypes.any,
    iso: PropTypes.string,
    format: PropTypes.string,
    round: PropTypes.number,
    showZero: PropTypes.bool,
    forPayForm: PropTypes.bool
  };

  static defaultProps = {
    iso: 'EUR',
    format: '*s*v',
    round: 2,
    forPayForm: false
  };

  render() {
    const {
      calculator,
      value,
      format = '*s*v',
      stringifyNumber,
      iso,
      round,
      showZero,
      forPayForm
    } = this.props;

    const result =
      typeof value !== 'undefined' && value !== null
        ? stringifyNumber(
            calculator(value, iso, round, forPayForm && iso === 'USD'),
            format
          )
        : '-';
    return (
      <span className="unit currency">{value || showZero ? result : '-'}</span>
    );
  }
}
const mapStateToCurrency = (state, props) => {
  return {
    calculator: getCurrencyCalculator(state),
    stringifyNumber: getStringifyNumber(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};
export const Distance = translate()(
  connect(mapStateToDistance, mapDispatchToProps)(distance)
);
export const Weight = translate()(
  connect(mapStateToWeight, mapDispatchToProps)(weight)
);
export const Currency = translate()(
  connect(mapStateToCurrency, mapDispatchToProps)(currency)
);
