import { createSelector } from 'reselect';
import _isEqual from 'lodash/isEqual';
import { isEmpty, checkUndefined } from '../utils';
import { calculatePlans } from '../actions/billing';

import { getTaxCountry } from './locale';
import { getCustomer } from './customer';
import { makeAddressObject } from '../utils/user';
import { isUndefined } from 'lodash';
import { getActiveCompanySubscription } from './company';
import { getPendingUserSubscription } from './user';

export const getAllPlans = state => {
  return state.billing.plansData.plans.filter(pl => !pl.id.includes('test'));
};
export const getTransaction = state => {
  return state.billing.transaction.transaction;
};
export const getTransactionReady = state => {
  return state.billing.transaction.ready;
};
export const getServices = state => {
  return state.billing.services;
};
export const getUserDetailsInfo = state => {
  return state.user.details;
};
export const getCurrentPlanDetails = state => {
  const { plans = [] } = state.billing.plansData;
  const { subscription = {} } = state.user.details;
  return plans.find(plan => plan.id === subscription.product);
};
export const getPendingPlanDetails = state => {
  const { plans = [] } = state.billing.plansData;
  const { pending_subscription } = state.user.details;
  if (!pending_subscription) return;
  return plans.find(plan => plan.id === pending_subscription.product);
};
export const getCountry = createSelector(
  [getUserDetailsInfo, getCustomer],
  (userDetails, customer) => {
    if (customer.billing_address) {
      return customer.billing_address.country_code_alpha2;
    }
    return userDetails.country;
  }
);

export const getSelectedPlan = state => {
  return state.billing.plansData.selectedPlan;
};
export const getSameAddressBillingShipping = state => {
  return state.billing.payment.sameAddressBillingShipping;
};
export const getPaymentInfo = state => {
  return state.billing.payment.info;
};
export const getPaymentBillingInfo = state => {
  return state.billing.payment.billingInfo;
};
export const getPaymentShippingInfo = state => {
  return state.billing.payment.shippingInfo;
};
export const getPaymentPromo = state => {
  return state.billing.payment.promo;
};
export const getPremium = state => {
  return state.billing.premium;
};
export const getSelectedPlanInfo = createSelector(
  [getAllPlans, getSelectedPlan],
  (plans, selectedPlan) => {
    return plans.find(plan => plan.id === selectedPlan) || { id: selectedPlan };
  }
);
export const findUserPlan = createSelector(
  [getAllPlans, getUserDetailsInfo],
  (plans, userDetails) => {
    if (!userDetails || !userDetails.subscription) return null;
    return plans.find(item => item.id === userDetails.subscription.product);
  }
);

export const findSelectedPlanById = createSelector(
  [getAllPlans, getSelectedPlan],
  (plans, selectedPlanId) => {
    return plans.find(plan => plan.id === selectedPlanId);
  }
);

export const checkIfBillingEqualsToDefault = state => {
  const billingInfo = getPaymentBillingInfo(state);
  const { billing_address } = getCustomer(state);
  if (!billing_address) return false;
  const { id, extended_address, ...billingAddress } = billing_address;
  return _isEqual(makeAddressObject(billingInfo), billingAddress);
};
export const checkIfShippingEqualsToDefault = state => {
  const shippingInfo = getPaymentShippingInfo(state);
  const { shipping_address } = getCustomer(state);
  if (!shipping_address) return false;
  const { id, extended_address, ...shippingAddress } = shipping_address;
  return _isEqual(makeAddressObject(shippingInfo), shippingAddress);
};

export const findSelectedPlan = createSelector(
  getAllPlans,
  getSelectedPlan,
  (plans, selectedPlan) => plans.find(plan => plan.id === selectedPlan)
);

export const derivePlanData = createSelector(
  [getAllPlans, getServices, getSelectedPlanInfo],
  (plans, services, selectedPlan) => {
    // plans, discount, selectedPlan (+ preferred currency), fetching
    return {
      plans,
      ready: !isEmpty(plans) && services.taxamo,
      currency: selectedPlan && selectedPlan.currency
    };
  }
);

export const isUnlimitedSubscription = state => {
  const { subscription = {} } = state.user.details;
  if (subscription) {
    const { is_active: active, type } = subscription;
    return active && type !== 'free';
  }

  return false;
};

/**
 * returns the next billing amount of the active subscription (user or company)
 */
export const getSubscriptionAmount = state => {
  let companySubscription = getActiveCompanySubscription(state);
  if (
    companySubscription &&
    companySubscription.is_active &&
    !companySubscription.cancellation_date
  ) {
    return (
      companySubscription.pending_billing_amount ||
      companySubscription.next_billing_amount
    );
  }
  const { subscription = {} } = state.user.details;
  if (subscription) {
    const { is_active: active, type, cancellation_date, price } = subscription;
    if (active && ['braintree', 'team'].includes(type) && !cancellation_date) {
      return Number(price);
    }
  }
  const pendingSubscription = getPendingUserSubscription(state);
  if (pendingSubscription) {
    return pendingSubscription.next_billing_amount &&
      Number(pendingSubscription.next_billing_amount) > 0
      ? Number(pendingSubscription.next_billing_amount)
      : pendingSubscription.price;
  }
  return 0;
};

export const getPaymentMethod = (state, token) => {
  if (!token) {
    return null;
  }
  const { payment_methods = {} } = getCustomer(state);
  let method = {};
  !isEmpty(payment_methods) &&
    Object.values(payment_methods).forEach(methods =>
      methods.forEach(m => {
        if (m.token === token) method = m;
      })
    );
  return method;
};

export const getPrimaryPaymentMethod = state => {
  const { payment_methods = {} } = getCustomer(state);
  let primary = {};
  !isEmpty(payment_methods) &&
    Object.values(payment_methods).forEach(methods =>
      methods.forEach(method => {
        if (method.default) primary = method;
      })
    );
  return primary;
};

export const isCreditCard = (token, state) => {
  const customer = getCustomer(state);
  if (isEmpty(customer.payment_methods)) {
    return false;
  }
  const { cards = {} } = customer.payment_methods.credit_cards;
  return (
    !isEmpty(cards) &&
    !isUndefined(
      Object.values(cards).find(card => {
        return card.token === token;
      })
    )
  );
};

export const hasPrimaryPaymentMethod = state => {
  const primaryMethod = getPrimaryPaymentMethod(state) || {};
  return primaryMethod.default;
};

export const mergeDiscountPlans = (plans, discountPlans) => {
  return plans.reduce((acc, plan) => {
    const matchPlan = discountPlans.filter(
      discountPlan => plan.id === discountPlan.id
    );
    !isEmpty(matchPlan) ? acc.push(matchPlan[0]) : acc.push(plan);
    return acc;
  }, []);
};

export const calculate = (options = {}) => (dispatch, getState) => {
  const state = getState();
  const {
    billing: {
      payment: { updateVAT }
    },
    user: { details = {} }
  } = state;
  const customer = getCustomer(state);

  const plansData = checkUndefined(options.planData) || derivePlanData(state);
  const country = checkUndefined(options.country) || customer.country;
  const taxId =
    options.taxId !== undefined ? options.taxId : updateVAT || customer.tax_id;
  const { email } = details;

  plansData.ready &&
    dispatch(
      calculatePlans({
        plansData,
        country,
        taxId,
        email
      })
    );
};

export const isBillingInfoValid = state => {
  const { validations } = getPaymentBillingInfo(state);
  const allFields = Object.assign({}, validations, { company: true });
  const elements = Object.keys(allFields).length;
  return Object.values(allFields).filter(e => e).length === elements;
};
export const isBillingCardholderValid = state => {
  const { validations } = getPaymentInfo(state);
  return validations.cardholder;
};
export const isPaymentInfoValid = state => {
  const { validations, type } = getPaymentInfo(state);
  if (type === 'card') {
    return Object.keys(validations).every(el => validations[el]);
  }
  return true;
};
export const getPaymentInfoType = state => {
  const { type } = getPaymentInfo(state);
  return type;
};
const getPromo = state => {
  const { payment: { promo = {} } = {} } = state.billing;
  return promo;
};

export const requiredInputs = state => {
  const {
    firstName,
    lastName,
    address,
    postalCode,
    country
  } = getPaymentBillingInfo(state);

  const { code, valid } = getPromo(state);

  return Boolean(
    firstName &&
      lastName &&
      address &&
      postalCode &&
      country &&
      (!code || (code && valid))
  );
};
export const requiredEnterpriseInputs = state => {
  const {
    firstName,
    lastName,
    address,
    postalCode,
    country,
    company
  } = getPaymentBillingInfo(state);

  return Boolean(
    firstName && lastName && address && postalCode && country && company
  );
};

export const getEUVatFromCountryPlaceholder = country => {
  switch (country) {
    case 'AT':
      return 'ATU12345678';
    case 'BE':
      return 'BE1234567890';
    case 'BG':
      return 'BG1234567890';
    case 'HR':
      return 'HR12345678901';
    case 'CY':
      return 'CY12345678X';
    case 'CZ':
      return 'CZ123456789';
    case 'DK':
      return 'DK12345678';
    case 'EE':
      return 'EE12345678';
    case 'FI':
      return 'FI12345678';
    case 'FR':
      return 'FR12345678901';
    case 'DE':
      return 'DE123456789';
    case 'EL':
      return 'EL123456789';
    case 'HU':
      return 'HU12345678';
    case 'IE':
      return 'IE1234567FA';
    case 'IT':
      return 'IT12345678901';
    case 'LV':
      return 'LV12345678901';
    case 'LT':
      return 'LT123456789012';
    case 'LU':
      return 'LU12345678';
    case 'MT':
      return 'MT12345678';
    case 'NL':
      return 'NL123456789B01';
    case 'NO':
      return 'NO123456789';
    case 'PL':
      return 'PL1234567890';
    case 'PT':
      return 'PT123456789';
    case 'RO':
      return 'RO1234567890';
    case 'SK':
      return 'SK1234567890';
    case 'SI':
      return 'SI12345678';
    case 'ES':
      return 'ESX1234567X';
    case 'SE':
      return 'SE123456789012';
    case 'CHE':
      return 'CHE123456789XXXX';
    case 'GB':
      return 'GB123456789';
    default:
      return '123456789';
  }
};

export const getEUVatPlaceholder = state => {
  const country = getTaxCountry(state);
  return getEUVatFromCountryPlaceholder(country);
};

export const isValidPromoCode = (discountPlans, state) => {
  const { billing_frequency: frequency } = findSelectedPlan(state);
  const discountMatched = discountPlans.filter(
    plan => plan.billing_frequency === frequency
  ).length;
  return Boolean(discountMatched);
};

export const isPaymentCompleted = state =>
  state.billing.premium.paymentCompleted;

export const getBillingInfoCountry = state => {
  const { billingInfo: { country } = {} } = state.billing.payment;
  return country;
};

const validateFormRequiredFields = (requiredFields, infoToValidate) => {
  return requiredFields.reduce(
    (acc, field) => {
      if (
        !infoToValidate[field] ||
        (infoToValidate[field] && infoToValidate[field].trim() === '')
      ) {
        acc.valid = false;
        acc.fields = acc.fields.concat(field);
      }

      return acc;
    },
    {
      valid: true,
      fields: []
    }
  );
};

const checkAndReturnBillingInfoValidation = state => {
  const { company, region, taxId, ...infoToValidate } = getPaymentBillingInfo(
    state
  );
  const requiredFields = [
    'firstName',
    'lastName',
    'address',
    'locality',
    'postalCode',
    'country'
  ];
  return validateFormRequiredFields(requiredFields, infoToValidate);
};
const checkAndReturnShippingInfoValidation = state => {
  const { company, region, taxId, ...infoToValidate } = getPaymentShippingInfo(
    state
  );
  const requiredFields = [
    'firstName',
    'lastName',
    'address',
    'locality',
    'postalCode',
    'country'
  ];
  return validateFormRequiredFields(requiredFields, infoToValidate);
};

const checkAndReturnBillingInfoValidationForTeam = state => {
  const { region, taxId, ...infoToValidate } = getPaymentBillingInfo(state);
  const requiredFields = [
    'firstName',
    'lastName',
    'address',
    'locality',
    'postalCode',
    'country',
    'company'
  ];
  return validateFormRequiredFields(requiredFields, infoToValidate);
};

export const allFieldsValid = (state, beacon = false) => {
  const transaction = getTransaction(state) || {};
  const { type } = getSelectedPlanInfo || {};
  const { sameAddressBillingShipping } = state.billing.payment;

  const validations = {};
  let valid = true;

  const taxIdValid = transaction.buyer_tax_number_valid;
  if (taxIdValid === false) {
    valid = false;
    validations.taxId = ['taxId'];
  }

  let billingInfoValid = checkAndReturnBillingInfoValidation(state);
  if (type === 'team') {
    billingInfoValid = checkAndReturnBillingInfoValidationForTeam(state);
  }
  validations.billingInfo = billingInfoValid.fields;
  if (!billingInfoValid.valid) valid = false;

  if (beacon) {
    let shippingInfoValid = billingInfoValid;
    if (!sameAddressBillingShipping) {
      shippingInfoValid = checkAndReturnShippingInfoValidation(state);
    }
    validations.shippingInfo = shippingInfoValid.fields;
    if (!shippingInfoValid.valid) valid = false;
  }

  if (!isPaymentInfoValid(state)) {
    valid = false;
    validations.paymentInfo = ['cardholder'];
  }
  return {
    valid,
    validations
  };
};
