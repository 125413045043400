import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import { getDateFormatter, getCommaSeparatedValues } from '../selectors/locale';
import { genGetVehicleName } from '../selectors/vehicles';
import { getFilters } from '../selectors/filters';

import { isEmpty, flatten } from '../utils';

class FilterText extends Component {
  static propTypes = {
    criteria: PropTypes.object.isRequired,
    expanded: PropTypes.bool,
    t: PropTypes.func.isRequired,
    getVehicleName: PropTypes.func
  };

  expanded = ({ travelModes, vehicles, tags, tagged } = {}, t) => {
    const hasTravelModes = !isEmpty(travelModes[0]);
    const hasVehicles = !isEmpty(vehicles[0]);
    const hasTags = !isEmpty(tags[0]);
    return (
      <div className="inlineFilters">
        <div className="inlineFilters">
          <span className="">
            {hasTravelModes ? travelModes.join(', ') : t('common.all')}
          </span>
          {tagged !== undefined &&
            tagged !== null && (
              <span className="spaced">{t('list.untagged')}</span>
            )}
          <span className="spaced grey">{t('common.trips')}</span>
        </div>
        {hasVehicles && (
          <div className="inlineFilters">
            <span className="spaced grey">
              {t('automaticReports.takenWith')}
            </span>
            <span className="spaced">
              {getCommaSeparatedValues(vehicles[0])}
            </span>
          </div>
        )}
        {hasTags && (
          <div className="inlineFilters">
            <span className="spaced grey">{t('list.taggedAs')}</span>
            <span className="spaced">{tags.join(', ')}</span>
          </div>
        )}
      </div>
    );
  };

  noExpanded = (filters = [], t, notes) => (
    <div>
      <div className="tag">{filters[0] || '-'}</div>
      <div className="inline-more">
        {filters.length > 1 && (
          <Popup
            wide="very"
            size="mini"
            position="bottom left"
            trigger={
              <div className="tagMore spaced popupPointer">
                + {filters.length - 1} {t('list.more')}
              </div>
            }
            content={filters.slice(1, filters.length).join(', ')}
          />
        )}
        {notes &&
          notes.length > 1 && (
            <Popup
              wide="very"
              size="mini"
              position="bottom left"
              trigger={
                <div className="tagMore spaced popupPointer">
                  {filters.length > 1 ? '&' : '+'} {t('list.note')}
                </div>
              }
              content={notes}
            />
          )}
      </div>
    </div>
  );

  render() {
    const { criteria, expanded, t, getVehicleName } = this.props;
    const filtersObj = getFilters(criteria, getVehicleName);
    const filters = flatten(
      Object.entries(filtersObj)
        .map(([_, values]) => values)
        .filter(e => e)
    );

    return (
      <div className={expanded && 'inlineFilters'}>
        {expanded
          ? this.expanded(filtersObj, t)
          : this.noExpanded(filters, t, criteria.notes)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    getVehicleName: genGetVehicleName(state),
    dateFormatter: getDateFormatter(state)
  };
};

export default translate()(connect(mapStateToProps)(FilterText));
