import {
  SET_DATE_FORMAT_OPTIONS,
  SET_DATE_FORMAT
} from '../actions/datePreference';

const initialState = {
  preferredFormat: '',
  options: []
};

export const datePreference = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATE_FORMAT_OPTIONS:
      return Object.assign({}, state, {
        options: action.options
      });

    case SET_DATE_FORMAT:
      return Object.assign({}, state, {
        preferredFormat: action.format
      });

    default:
      return state;
  }
};
