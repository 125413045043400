import serverRequest from '../utils/Api';
import { URLs } from '../utils/urls';

export const SET_STATE_LIST = 'SET_STATE_LIST';

const setStateList = stateList => ({
  type: SET_STATE_LIST,
  stateList
});

export const fetchStateListForCountry = country => (dispatch, getState) => {
  const { method, url } = URLs.common.getStatesForCountry(
    country.toLowerCase()
  );
  serverRequest(method, url, getState(), { withoutApiVersion: true }).then(
    resp => {
      if (resp) {
        dispatch(
          setStateList(
            resp.map(state => {
              return {
                key: state,
                value: state,
                text: state
              };
            })
          )
        );
      }
    }
  );
};
