import serverRequest from '../utils/Api';
import { URLs } from '../utils/urls';

export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const UPDATE_USER_SETTING = 'UPDATE_USER_SETTING';
export const HAS_TRANSFER_REQUEST = 'HAS_TRANSFER_REQUEST';

export const setUserSettings = settings => ({
  type: SET_USER_SETTINGS,
  settings
});
export const updateUserSetting = settingToBeUpdated => ({
  type: UPDATE_USER_SETTING,
  settingToBeUpdated
});
export const updateHasTransferRequest = email => ({
  type: HAS_TRANSFER_REQUEST,
  transferRequestEmail: email
});

const mapUserSettings = settings => {
  return settings.reduce((acc, next) => {
    acc[next.key] = next;
    return acc;
  }, {});
};

export const fetchUserSettings = () => (dispatch, getState) => {
  const { method, url } = URLs.user.getSettings();
  serverRequest(method, url, getState())
    .then(json => {
      dispatch(setUserSettings(mapUserSettings(json.settings)));
    })
    .catch(err => {
      console.log('error in getting settings: ', err);
    });
};

export const changeUserSettings = ({ key, value }) => (dispatch, getState) => {
  const state = getState();
  const { userSettings: currentAllSettings } = state;

  const currentSettingToBeUpdated = { ...currentAllSettings[key] };
  currentSettingToBeUpdated.value = value;
  dispatch(
    updateUserSetting({
      [key]: currentSettingToBeUpdated
    })
  );

  const body = {
    [key]: value
  };
  const { method, url } = URLs.user.updateSettings();
  serverRequest(method, url, state, { body })
    .then(json => {
      if (json.valid) {
        dispatch(setUserSettings(mapUserSettings(json.settings)));
      } else {
        dispatch(setUserSettings(currentAllSettings));
      }
    })
    .catch(() => {
      dispatch(setUserSettings(currentAllSettings));
    });
};
