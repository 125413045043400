import get from 'lodash/get';
import omit from 'lodash/omit';

const newTripRequestPayloadTransformer = data => {
  const { route, origin, destination, departure_time, vehicle } = data;
  const duration = get(route, ['duration', 'value'], 0);
  const departureTimeUnix = departure_time || 0;
  const arrival_time =
    departureTimeUnix && duration ? departureTimeUnix + duration : 0;
  const originPlace = origin.isRecentPlace
    ? { id: origin.id }
    : {
        provider_id: `Google/${origin.place_id}`,
        city: origin.city,
        address: origin.address,
        place_id: origin.place_id,
        name: origin.name,
        location: origin.location,
        provider: `Google`,
        country: origin.country,
        reference: origin.reference
      };
  const destinationPlace = destination.isRecentPlace
    ? { id: destination.id }
    : {
        provider_id: `Google/${destination.place_id}`,
        city: destination.city,
        address: destination.address,
        place_id: destination.place_id,
        name: destination.name,
        location: destination.location,
        provider: `Google`,
        country: destination.country,
        reference: destination.reference
      };

  return {
    origin: {
      ...(origin.isRecentPlace ? originPlace : { place: originPlace }),
      departure_time: departureTimeUnix,
      location: origin.location
    },
    destination: {
      ...(destination.isRecentPlace
        ? destinationPlace
        : { place: destinationPlace }),
      arrival_time,
      location: destination.location
    },
    route: omit(
      route,
      'routeId',
      'transitSteps',
      'travelMode',
      'departureTime'
    ),
    vehicle
  };
};

export { newTripRequestPayloadTransformer };
