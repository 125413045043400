import MobileDetect from 'mobile-detect';

import { setLocal, setCookie, deleteCookie } from '../selectors/locale';

import { getPublicLinkFor, isNotUndefined } from '../utils';
import serverRequest from '../utils/Api';
import { logoutUserFromSmooch } from '../utils/smooch';
import { trackSignIn, trackSignUp } from '../utils/analytics';
import { getAppLanguage } from '../selectors/app';
import { setApiAbortController, unsetApiAbortController } from './apiMeta';
import { URLs } from '../utils/urls';

export const SIGNING_UP = 'SIGNING_UP';
export const SIGNIN_FAILED = 'SIGNIN_FAILED';
export const WRONG_PASSWORD = 'WRONG_PASSWORD';
export const CHANGED_PASSWORD = 'CHANGED_PASSWORD';
export const SIGNIN_DONE = 'SIGNIN_DONE';
export const EMAIL_EXISTS = 'EMAIL_EXISTS';
export const EMAIL_AVAILABLE = 'EMAIL_AVAILABLE';
export const ACCOUNT_IS_ACTIVE = 'ACCOUNT_IS_ACTIVE';
export const USER_SIGNOUT = 'USER_SIGNOUT';
export const SIGNED_UP = 'SIGNED_UP';
export const SET_ISMOBILE = 'SET_ISMOBILE';
export const SET_ISTABLET = 'SET_ISTABLET';
export const OTP_REQUIRED_FOR_LOGIN = 'OTP_REQUIRED_FOR_LOGIN';
export const OTP_AUTHENTICATION_FAILED = 'OTP_AUTHENTICATION_FAILED';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const INVITATION_CODE_VALID = 'INVITATION_CODE_VALID';
export const STORE_USER_INFO_FROM_INVITE = 'STORE_USER_INFO_FROM_INVITE';
export const SET_USER_CURRENCY = 'SET_USER_CURRENCY';

export const userSignout = () => ({ type: USER_SIGNOUT });
export const changePassword = () => ({ type: CHANGED_PASSWORD });
export const signInStart = () => ({ type: SIGNING_UP });
export const signInDone = () => ({ type: SIGNIN_DONE });
export const signInFailed = () => ({ type: SIGNIN_FAILED });
export const wrongPassword = () => ({ type: WRONG_PASSWORD });
export const emailExists = endpoint => ({ type: EMAIL_EXISTS, endpoint });
export const emailAvailable = endpoint => ({ type: EMAIL_AVAILABLE, endpoint });
export const accountIsActive = data => ({ type: ACCOUNT_IS_ACTIVE, data });
export const signedUp = (user_token, user_id) => ({
  type: SIGNED_UP,
  user_token,
  user_id
});
export const setIsMobile = isMobile => ({ type: SET_ISMOBILE, isMobile });
export const setIsTablet = isTablet => ({ type: SET_ISTABLET, isTablet });
export const setOTPRequired = () => ({ type: OTP_REQUIRED_FOR_LOGIN });
export const otpAuthenticationFailed = () => ({
  type: OTP_AUTHENTICATION_FAILED
});
export const setUserEmail = email => ({ type: SET_USER_EMAIL, email });
export const storeUserInfoFromInvite = (
  firstName,
  lastName,
  companyName,
  email,
  inviteCode,
  teamId
) => ({
  type: STORE_USER_INFO_FROM_INVITE,
  firstName,
  lastName,
  companyName,
  email,
  inviteCode,
  teamId
});
export const setUserCurrency = currency => ({
  type: SET_USER_CURRENCY,
  currency
});

const validLogin = data => {
  return dispatch => {
    const {
      user: { token, id }
    } = data;
    setCookie({
      name: 'user_token',
      value: btoa(token)
    });
    setCookie({
      name: 'user_id',
      value: id
    });
    dispatch(signedUp(btoa(token), id));
  };
};
export const setTokenAndUserCookies = (token, id) => {
  return dispatch => {
    setCookie({
      name: 'user_token',
      value: token
    });
    setCookie({
      name: 'user_id',
      value: id
    });
    dispatch(signedUp(token, id));
  };
};
export const getCountryByIp = () => {
  return new Promise((resolve, reject) => {
    fetch('https://ipapi.co/json')
      .then(response => response.json())
      .then(
        response => resolve(response),
        (data, status) => {
          console.warn('getCountryByIp', status);
          reject();
        }
      );
  });
};
export const signInExternal = (
  {
    email,
    first_name,
    last_name,
    accessToken,
    google_id_token,
    client_id,
    provider_id
  },
  party
) => {
  return (dispatch, getState) => {
    const provider = provider_id || party;
    trackSignIn(provider);
    const { url, method } = URLs.user.login();
    serverRequest(method, url, getState(), {
      body: {
        google_id_token: google_id_token,
        access_token: accessToken,
        provider_id: provider,
        client_id: client_id,
        email: email,
        first_name: first_name,
        last_name: last_name
      },
      doNotAuthorize: true
    }).then(
      data => {
        /**
         * when signing-in with external identity provider (e.g. Apple)
         * and MFA is enabled, the response will contain the user's email.
         * The reason is that Apple sign-in does not expose the user's email
         * so we must get it from the backend after the user is found based on
         * the access-token posted in the initial login request.
         */
        if (email === undefined && isNotUndefined(data.email)) {
          email = data.email;
        }
        dispatch(setUserEmail(email));
        dispatch(signInDone());
        if (data.valid) {
          dispatch(validLogin(data));
        } else if (
          typeof data.details === 'string' &&
          data.details === 'mfa_required'
        ) {
          dispatch(setOTPRequired());
        } else {
          dispatch(signInFailed());
        }
      },
      (data, status) => {
        dispatch(signInFailed());
      }
    );
  };
};
const createUser = (email, password, history, country) => (
  dispatch,
  getState
) => {
  const {
    user: { isInviteFlow, inviteCode }
  } = getState();

  const language = getAppLanguage(getState());
  const body = {
    email,
    password,
    language
  };

  if (isInviteFlow && inviteCode) {
    body.invitation_code = inviteCode;
  }

  country && Object.assign(body, { country });
  const { method, url } = URLs.user.createUser();
  serverRequest(method, url, getState(), { body }).then(
    data => {
      dispatch(signInDone());
      if (data.valid) {
        dispatch(validLogin(data, history));
      }
    },
    (err, status) => {
      console.error('signup', err, status);
      dispatch(signInFailed());
    }
  );
};
export const signup = (email, password, history) => dispatch => {
  dispatch(signInStart());
  trackSignUp('byEmail');
  getCountryByIp()
    .then(({ country }) => {
      dispatch(createUser(email, password, history, country));
    })
    .catch(() => dispatch(createUser(email, password, history)));
};
export const signin = (email, password, history) => {
  return (dispatch, getState) => {
    const {
      user: { isInviteFlow, inviteCode }
    } = getState();

    trackSignIn('byEmail');

    dispatch(signInStart());
    const body = {
      email: email.toLowerCase(),
      password
    };
    if (isInviteFlow && inviteCode) {
      body.invitation_code = inviteCode;
    }

    const { url, method } = URLs.user.login();
    serverRequest(method, url, getState(), {
      body,
      doNotAuthorize: true
    }).then(
      data => {
        dispatch(signInDone());
        if (data.valid) {
          dispatch(validLogin(data, history));
        } else if (
          typeof data.details === 'string' &&
          data.details === 'mfa_required'
        ) {
          dispatch(setOTPRequired());
        } else {
          dispatch(wrongPassword());
        }
      },
      (data, status) => {
        dispatch(signInFailed());
      }
    );
  };
};
export const authenticateUserWithOTP = (email, otp, history) => (
  dispatch,
  getState
) => {
  dispatch(signInStart());
  const { url, method } = URLs.user.authentitateOTPForUser();
  serverRequest(method, url, getState(), {
    body: {
      email,
      otp
    },
    doNotAuthorize: true
  }).then(
    data => {
      dispatch(signInDone());
      dispatch(validLogin({ user: data }, history));
    },
    (data, status) => {
      dispatch(otpAuthenticationFailed());
    }
  );
};

export const checkEmail = email => {
  return (dispatch, getState) => {
    const state = getState();
    const { apiMeta } = state;
    const keyForAbortController = 'checkEmail';
    if (apiMeta[keyForAbortController]) {
      apiMeta[keyForAbortController].abort &&
        apiMeta[keyForAbortController].abort();
      dispatch(unsetApiAbortController(keyForAbortController));
    }
    const optionsForApi = { isCancellable: true };
    try {
      const abortController = new window.AbortController();
      dispatch(setApiAbortController(keyForAbortController, abortController));
      optionsForApi.abortController = abortController;
    } catch (err) {
      optionsForApi.isCancellable = false;
    }

    const { url, method } = URLs.user.checkEmail(email.toLowerCase());
    serverRequest(method, url, state, optionsForApi)
      .then(data => {
        data.exists
          ? dispatch(emailExists(data.endpoint))
          : dispatch(emailAvailable(data.endpoint));

        dispatch(accountIsActive(data));
      })
      .catch(err => {
        console.error('checkEmail failed: ', err);
      });
  };
};

export const invalidEmail = () => ({
  type: EMAIL_AVAILABLE,
  endpoint: false
});

export const signOutCurrentUser = () => (dispatch, getState) => {
  deleteCookie('user_id');
  deleteCookie('user_token');
  deleteCookie('sessionid');
  setLocal('visibleColumns', null);
  setLocal('tripsFilters', null);
  setLocal('datePreference', null);
  setLocal('timePreference', null);
  setLocal('selectedTeam', null);
  setLocal('appLanguage', null);

  if (getState().isSmoochInitialized) {
    logoutUserFromSmooch();
  }

  dispatch(userSignout());
};

export const signOut = history => dispatch => {
  dispatch(signOutCurrentUser());
  history.push(getPublicLinkFor('login'));
};

export const setKindOfDeviceUsingUserAgent = () => {
  return dispatch => {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = Boolean(md.mobile() && !md.tablet());
    const isTablet = Boolean(md.tablet());
    dispatch(setIsMobile(isMobile));
    dispatch(setIsTablet(isTablet));
  };
};

export const setKindOfDeviceUsingWindowWidth = () => dispatch => {
  if (window.innerWidth <= 1024 && window.innerWidth > 767) {
    dispatch(setIsTablet(true));
    dispatch(setIsMobile(false));
  } else if (window.innerWidth <= 767) {
    dispatch(setIsTablet(false));
    dispatch(setIsMobile(true));
  } else {
    dispatch(setIsTablet(false));
    dispatch(setIsMobile(false));
  }
};

export const checkInvitationCode = inviteCode => (dispatch, getState) => {
  const { url, method } = URLs.user.getInviteCodeDetails(inviteCode);
  serverRequest(method, url, getState()).then(resp => {
    // TODO: handle invalid
    dispatch(
      storeUserInfoFromInvite(
        resp.first_name,
        resp.last_name,
        resp.company_name,
        resp.email,
        inviteCode,
        resp.team.id
      )
    );
  });
};
