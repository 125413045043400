import fetch from 'isomorphic-fetch';
const IPADDR_URL = 'https://api.ipify.org/?format=json';

export default () => {
  return new Promise((resolve, reject) => {
    fetch(IPADDR_URL)
      .then(response => {
        if (!response.ok) {
          reject(response);
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};
