import { setLocal } from '../selectors/locale';
import { getTeamsList, getSelectedTeamId } from '../selectors/teams';

import {
  getDateFormatOptions,
  getDateFormatOptionsFromPreference,
  getTimeFormatForLocale
} from '../utils';
import { setPreferredTimeFormat } from './user';

export const SET_DATE_FORMAT_OPTIONS = 'SET_DATE_FORMAT_OPTIONS';
export const SET_DATE_FORMAT = 'SET_DATE_FORMAT';

export const setDateFormatOptions = options => ({
  type: SET_DATE_FORMAT_OPTIONS,
  options
});
export const setDateFormat = format => ({
  type: SET_DATE_FORMAT,
  format
});

export const setPreferredDateFormat = format => dispatch => {
  setLocal('datePreference', format);
  dispatch(setDateFormat(format.toUpperCase()));
};

export const reCalculateDateFormatOptions = (
  country,
  language,
  selectedFormat,
  maintainSelection = false
) => dispatch => {
  const dateFormats = getDateFormatOptions(country, language);
  const dateFormatValues = dateFormats.map(format => format.value);
  if (!maintainSelection || !dateFormatValues.includes(selectedFormat)) {
    dispatch(setPreferredDateFormat(dateFormatValues[0]));
  }
  dispatch(setDateFormatOptions(dateFormats));
  const timeFormatToBe = getTimeFormatForLocale(`${language}-${country}`);
  dispatch(setPreferredTimeFormat(timeFormatToBe));
};

export const setDatePreferenceFromSelectedTeam = () => (dispatch, getState) => {
  const state = getState();
  const allTeams = getTeamsList(state);
  const selectedTeamId = getSelectedTeamId(state);
  const { date_format: selectedTeamDatePreference } = allTeams.find(
    team => team.id === selectedTeamId
  );
  dispatch(setDateFormat(selectedTeamDatePreference.toUpperCase()));
  dispatch(
    setDateFormatOptions(
      getDateFormatOptionsFromPreference(selectedTeamDatePreference)
    )
  );
};
