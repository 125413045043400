import _orderBy from 'lodash/orderBy';

import { languageOptions } from './constants';

const languageToSet = userLanguage => {
  const langObj = languageOptions.find(lang => lang.value === userLanguage);
  if (langObj) {
    return userLanguage;
  }

  return 'en';
};

export const getCleanUserDetails = details => {
  const cleanUserDetails = {};
  cleanUserDetails.confirmed = details.confirmed;
  cleanUserDetails.created = details.created;
  cleanUserDetails.country = details.country;
  cleanUserDetails.email = details.email;
  cleanUserDetails.first_name = details.first_name;
  cleanUserDetails.beacon_count = details.beacon_count;
  cleanUserDetails.image = details.image;
  cleanUserDetails.is_company_owner = details.is_company_owner;
  cleanUserDetails.last_name = details.last_name;
  cleanUserDetails.has_user_customer = details.has_user_customer;
  cleanUserDetails.language = languageToSet(details.language);
  cleanUserDetails.stats = details.stats;
  cleanUserDetails.subscription = details.subscription;
  cleanUserDetails.pending_subscription =
    details.pending_subscription || undefined;
  cleanUserDetails.trips_left = details.trips_left;
  cleanUserDetails.trips_taken_this_month = details.trips_taken_this_month;
  cleanUserDetails.untagged_trip_count = details.untagged_trip_count;
  cleanUserDetails.hasTrips = details.stats.total_trip_count > 0;

  return cleanUserDetails;
};

export const getCleanTeamReportRuleDetails = details => {
  const cleanDetails = Object.assign({}, details);
  const {
    criteria: { dates, places, vehicles }
  } = details;
  if (!dates) {
    delete cleanDetails.criteria.dates;
  }
  if (!places) {
    delete cleanDetails.criteria.places;
  }
  if (!vehicles) {
    delete cleanDetails.criteria.vehicles;
  }

  return cleanDetails;
};

export const getCleanTeamMembers = members => {
  return members.map(member => {
    const sortedRoles = member.user_roles.sort((role1, role2) => {
      if (
        (role1.role.name.toLowerCase() === 'admin' ||
          role1.role.name.toLowerCase() === 'manager') &&
        role2.role.name.toLowerCase() === 'employee'
      ) {
        return -1;
      }
      if (
        role1.role.name.toLowerCase() === 'employee' &&
        (role2.role.name.toLowerCase() === 'admin' ||
          role2.role.name.toLowerCase() === 'manager')
      ) {
        return 1;
      }

      return 0;
    });

    return Object.assign({}, member, { user_roles: sortedRoles });
  });
};

export const getCleanCompany = details => {
  const { customer, set_admin, user, ...companyDetails } = details;
  return companyDetails;
};

export const createErrorObject = (errorMessage, errorType, errorDetails) => {
  return {
    id: Date.now(),
    text: errorMessage,
    type: errorType,
    details: errorDetails
  };
};
export const createNotificationObject = (
  notifMessage,
  notifType,
  notifDetails
) => {
  return {
    id: Date.now(),
    text: notifMessage,
    type: notifType,
    details: notifDetails
  };
};

export const createNotificationObjectWithUrl = (
  notifMessage,
  notifType,
  notifDetails,
  notifURL,
  notifAction
) => {
  return {
    id: Date.now(),
    text: notifMessage,
    type: notifType,
    details: notifDetails,
    url: notifURL,
    action: notifAction
  };
};

export const getCleanTeamStats = teamStats => {
  const cleanStats = {};
  for (const statKey in teamStats) {
    if (!teamStats.hasOwnProperty(statKey)) continue; // eslint-disable-line
    const value = teamStats[statKey];
    if (typeof value === 'object') {
      cleanStats[statKey] = value.total;
    } else {
      cleanStats[statKey] = value;
    }
  }

  return cleanStats;
};

export const getCleanTeamFromDetails = teamDetails => {
  const team = teamDetails.team || teamDetails;
  const stats = getCleanTeamStats(team.stats);
  return {
    id: team.id,
    name: team.name,
    country: team.country,
    currency: team.currency,
    date_format: team.date_format.toUpperCase(),
    time_format: team.time_format,
    member_count: team.member_count,
    user_roles:
      teamDetails.team && teamDetails.team.user_roles
        ? teamDetails.team.user_roles
        : teamDetails.user_roles,
    stats
  };
};

export const mergeTeamToList = (teamToMerge, existingTeams) => {
  const cleanTeam = getCleanTeamFromDetails({ team: teamToMerge });
  const currentIndex = existingTeams.findIndex(team => {
    if (team.id === teamToMerge.id) {
      return true;
    }
    return false;
  });
  const allTeams = [...existingTeams];
  let finalTeamsList = [];
  if (currentIndex !== -1) {
    allTeams.splice(currentIndex, 1, cleanTeam);
    finalTeamsList = allTeams;
  } else {
    finalTeamsList = allTeams.concat([cleanTeam]);
  }

  return _orderBy(finalTeamsList);
};

export const mergeMemberToList = (memberToMerge, existingMembers) => {
  const { pendingMembers, activeMembers } = existingMembers.reduce(
    (acc, member) => {
      if (member.status === 'pending') {
        acc.pendingMembers.push(member);
      } else {
        acc.activeMembers.push(member);
      }

      return acc;
    },
    {
      pendingMembers: [],
      activeMembers: []
    }
  );
  return pendingMembers.concat([memberToMerge]).concat(activeMembers);
};

export const getCleanVClasses = vClassesFromServer => {
  const cleanVClasses = vClassesFromServer.filter(
    vClass => vClass.icon !== null
  );
  return _orderBy(cleanVClasses, ['id'], ['asc']);
};
