import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

const FeatureCard = ({ featureKey, image, title, description }) => (
  <div className={`${featureKey} featureCard`}>
    <div className="firstPart">
      <div className="imageContainer">
        <Image src={image} className={`${featureKey} featureImage`} />
      </div>
      <div className="featureTitle">{title}</div>
    </div>
    <div className="featureDescription">{description}</div>
  </div>
);

FeatureCard.propTypes = {
  featureKey: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
};

export default FeatureCard;
