import {
  SET_COMPANY_DETAILS,
  SET_COMPANY_ADDRESS_LIST,
  UPDATE_COMPANY_ADRRESS_LIST,
  UPDATE_COMPANY_LOGO,
  SET_COMPANY_SUBSCRIPTION
} from '../actions/company';
import { STORE_USER_INFO_FROM_INVITE } from '../actions/login';

const initialState = {};

export const company = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_DETAILS:
      return Object.assign({}, state, action.details);

    case SET_COMPANY_ADDRESS_LIST:
      return Object.assign({}, state, { addressList: action.addresslist });

    case UPDATE_COMPANY_ADRRESS_LIST:
      const addressList = [...state.addressList];
      const address = addressList.find(el => el.id === action.address.id);
      if (!address) {
        return Object.assign({}, state, {
          addressList: addressList.concat(action.address)
        });
      }

      addressList[addressList.indexOf(address)] = action.address;
      return Object.assign({}, state, {
        addressList
      });

    case STORE_USER_INFO_FROM_INVITE:
      return Object.assign({}, state, {
        name: action.companyName
      });
    case UPDATE_COMPANY_LOGO:
      return Object.assign({}, state, {
        logo: action.logo
      });
    case SET_COMPANY_SUBSCRIPTION:
      return Object.assign({}, state, { subscription: action.subscription });

    default:
      return state;
  }
};
