import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Image } from 'semantic-ui-react';

import SectionTitle from '../SectionTitle';
import { isEmpty, getPublicLinkFor, getDashboardLinkFor } from '../../../utils';
import MonthlyPrice from '../../ManagePlan/MonthlyPrice';
import { Currency } from '../../Units';

import { MAIL_TO_LINKS } from '../../../utils/constants';

const Plan = ({ plan, iconURL, isCustomPlan, button, t }) => (
  <div className={`planContainer ${isCustomPlan ? 'custom' : ''}`}>
    <div className="top">
      <Image src={iconURL} size="tiny" className={`${plan.name}`} />
      <div className="plan">{plan.name}</div>
      <div className="employeeCount">
        <span className="bold">{plan.default_employee_count}</span>
        <span>{t(`home.enterprise.plans.employees`)}</span>
      </div>
    </div>
    <div className="bottom">
      <div className="planPrice">
        {isCustomPlan ? (
          <span>{t(`home.enterprise.plans.customPricing`)}</span>
        ) : !isEmpty(plan) ? (
          <MonthlyPrice
            currency={plan.currency}
            price={plan.base_price / plan.billing_frequency}
            hidePerMonth={true}
          />
        ) : (
          ''
        )}
      </div>
      <div className="addOnInfo">
        {isCustomPlan ? (
          ''
        ) : !isEmpty(plan) ? (
          <React.Fragment>
            <span className="inline">+</span>
            <Currency
              iso={plan.currency}
              value={
                plan.add_ons[0].amount > 1
                  ? plan.add_ons[0].amount /
                    Math.max(plan.add_ons[0].quantity, 1)
                  : plan.add_ons[0].amount
              }
              round={2}
            />
            <span>{t(`home.enterprise.plans.perExtraEmployee`)}</span>
          </React.Fragment>
        ) : (
          ''
        )}
      </div>

      <div className="actionsContainer">{button}</div>
    </div>
  </div>
);
Plan.propTypes = {
  plan: PropTypes.object,
  iconURL: PropTypes.string,
  isCustomPlan: PropTypes.bool,
  button: PropTypes.element,
  t: PropTypes.func.isRequired
};

class PlansSection extends Component {
  getButtonForPlan = plan => {
    const { userLoggedIn, currentSubscription, trackEvent, t } = this.props;
    if (!userLoggedIn) {
      return (
        <a
          href={getPublicLinkFor('login')}
          className="action"
          onClick={trackEvent}
        >
          {t(`home.enterprise.plans.1monthFree`)}
        </a>
      );
    }
    if (
      currentSubscription.type === 'team' &&
      currentSubscription.id === plan.id
    ) {
      return (
        <a
          href={getDashboardLinkFor('managePlan')}
          className="action"
          onClick={trackEvent}
        >
          {t(`home.enterprise.plans.currentPlan`)}
        </a>
      );
    }

    return (
      <a href={getDashboardLinkFor('managePlan')} className="action">
        {t(`home.enterprise.plans.upgrade`)}
      </a>
    );
  };

  render() {
    const { enterprisePlans, trackEventForCustomPlan, t } = this.props;
    const plansSortedBySize = enterprisePlans.sort((plan1, plan2) =>
      plan1.id > plan2.id ? 1 : -1
    );
    const plansToShow = {
      silver: plansSortedBySize[0] || {},
      gold: plansSortedBySize[1] || {}
    };

    return (
      <div className="plansSection" id="pricing">
        <div className="section">
          <div className="titleContainer">
            <SectionTitle
              // firstLine={t(`home.enterprise.plans.titlePartOne`)}
              secondLine={t(`home.enterprise.plans.titlePartTwo`)}
            />
            <div className="sectionSubTitle">
              {t(`home.enterprise.plans.subtitle`)}
            </div>
          </div>
          <div className="plansContainer">
            <Plan
              iconURL="/images/enterprise-silver.png"
              plan={plansToShow.silver}
              button={this.getButtonForPlan(plansToShow.silver)}
              t={t}
            />
            <Plan
              iconURL="/images/enterprise-gold.png"
              plan={plansToShow.gold}
              button={this.getButtonForPlan(plansToShow.gold)}
              t={t}
            />
            <Plan
              iconURL="/images/enterprise-platinum.png"
              plan={{
                name: 'Platinum',
                default_employee_count: '50+'
              }}
              isCustomPlan
              button={
                <a
                  className="action"
                  href={MAIL_TO_LINKS.CONTACT_FOR_CUSTOM_PLANS}
                  onClick={trackEventForCustomPlan}
                >
                  {t(`home.enterprise.plans.contactUs`)}
                </a>
              }
              t={t}
            />
          </div>
        </div>
      </div>
    );
  }
}

PlansSection.propTypes = {
  enterprisePlans: PropTypes.array,
  userLoggedIn: PropTypes.bool,
  currentSubscription: PropTypes.object,
  trackEvent: PropTypes.func,
  trackEventForCustomPlan: PropTypes.func,
  t: PropTypes.func.isRequired
};

PlansSection.defaultProps = {
  enterprisePlans: [],
  currentSubscription: {}
};

export default withRouter(PlansSection);
