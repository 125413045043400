import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';
import Switch from 'react-switch';
import { withRouter } from 'react-router-dom';

import MonthlyPrice from '../../ManagePlan/MonthlyPrice';

import { getPublicLinkFor, getDashboardLinkFor } from '../../../utils';

class UpgradeCard extends Component {
  state = {
    frequency: 12
  };

  onSwitchToggle = () => {
    this.setState({ frequency: this.state.frequency === 1 ? 12 : 1 });
  };
  getActivePlan = () => {
    const { plans } = this.props;
    const { frequency } = this.state;
    return plans.find(plan => plan.billing_frequency === frequency);
  };
  getButton = () => {
    const {
      userLoggedIn,
      currentSubscription: { type: subscriptionType } = {},
      trackEvent,
      t
    } = this.props;
    if (!userLoggedIn) {
      return (
        <a
          href={getPublicLinkFor('login')}
          className="upgradeBtn"
          onClick={trackEvent}
        >
          {t(`home.plans.signUp`)}
        </a>
      );
    }

    if (subscriptionType === 'free') {
      return (
        <a href={getDashboardLinkFor('managePlan')} className="upgradeBtn">
          {t(`home.pro.intro.upgradeToPro`)}
        </a>
      );
    }
    return (
      <a href={getDashboardLinkFor('managePlan')} className="upgradeBtn">
        {t(`home.pro.upgrade.managePlan`)}
      </a>
    );
  };

  render() {
    const { frequency } = this.state;
    const { t } = this.props;
    const activePlan = this.getActivePlan();

    return (
      <div className="upgradeCard">
        <Image src="/images/plan-car.png" />
        <div className="priceContainer">
          {activePlan && (
            <MonthlyPrice
              currency={activePlan.currency}
              perMonth={true}
              price={activePlan.price / activePlan.billing_frequency}
            />
          )}
        </div>
        <div className="switchContainer">
          <span className={`${frequency === 1 ? `active` : ``} switchLabel`}>
            {`${t(`time.monthlyCap`)}`}
          </span>
          <Switch
            className="switch"
            checked={frequency === 12}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={36}
            handleDiameter={16}
            onColor="#24b626"
            onChange={this.onSwitchToggle}
          />
          <span className={`${frequency === 12 ? `active` : ``} switchLabel`}>
            {`${t(`time.annual`)}`}
          </span>
        </div>
        <div className="actions">{this.getButton()}</div>
      </div>
    );
  }
}

UpgradeCard.propTypes = {
  userLoggedIn: PropTypes.bool,
  currentSubscription: PropTypes.object,
  plans: PropTypes.array,
  trackEvent: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default withRouter(UpgradeCard);
