import qs from 'qs';
import _orderBy from 'lodash/orderBy';

import {
  updateOpenedItems,
  updateListItem,
  toggleListInvalid,
  setReportsBadgeCount,
  setList,
  getList,
  addListItem,
  setOpenedItem,
  setDisplayAvailableFields,
  sortListByColumn,
  toggleisUpdatingData,
  // incrementReportsBadgeCount,
  setOpenedReportId
} from './list';
import { setError } from './errors';

import { buildCleanReportCriteria } from '../selectors/locale';
import {
  getSelectedItems,
  getActiveList,
  getListFromReportId
} from '../selectors/list';
import { parseTags } from '../selectors/filters';

import serverRequest from '../utils/Api';
import { LIST_LIMITS, travelModes } from '../utils/constants';
import history from '../utils/history';
import { createQueryObject } from '../utils/search';
import { createErrorObject } from '../utils/dataStore';
import { getDashboardLinkFor } from '../utils';
import {
  getReportsListGroupByReportId,
  getReportsListTypeByReportId
} from '../selectors/list/reports';
import { updateTeamReportStats } from './teams';
import { getHasTeams, getIsEmployee } from '../selectors/teams';
import { URLs } from '../utils/urls';
import { isUndefined } from 'lodash';

export const SET_REPORT_STATS = 'SET_REPORT_STATS';

export const setReportStats = stats => ({
  type: SET_REPORT_STATS,
  listType: 'reportTrips',
  stats
});

export const getReports = (listType, optionsForApi = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const hasTeams = getHasTeams(state);
  const { selectedTeam } = state.teamUserInfo;
  const { filters } = state.listNew[listType];

  const queryObj = createQueryObject(filters, hasTeams, 'reports');
  queryObj.limit = `${LIST_LIMITS.TWENTY}`;

  const { method, url } = URLs.reports.getReports(
    listType === 'teamReports',
    selectedTeam,
    qs.stringify(queryObj)
  );
  serverRequest(
    method,
    url,
    state,
    Object.assign({}, optionsForApi, { cancelType: 'list' })
  )
    .then(resp => {
      const reportsList = resp.reports;
      if (hasTeams) {
        let countToShow = 0;
        if (listType === 'teamReports') {
          countToShow +=
            resp.pagination.stats.submitted + resp.pagination.stats.resolved;
        }
        if (listType === 'reports') {
          countToShow +=
            resp.pagination.stats.new + resp.pagination.stats.flagged;
        }
        dispatch(setReportsBadgeCount(countToShow, listType));
      }
      dispatch(setList(reportsList, resp.pagination, listType));
      dispatch(toggleisUpdatingData(listType));
    })
    .catch(err => console.error(`getReports: ${listType}: `, err));
};

export const createReport = details => (dispatch, getState) => {
  const state = getState();
  const hasTeams = getHasTeams(state);
  const { selectedTeam } = state.teamUserInfo;
  const isEmployee = getIsEmployee(state);

  const {
    name,
    aggregate,
    tags,
    vehicles,
    travelModes: modes,
    dataFields,
    dateRange: { start, end },
    owner,
    status
  } = details;

  const body = { name };
  const criteria = {
    dates: {
      start: start / 1000,
      end: end / 1000
    },
    display_fields: dataFields
  };
  if (tags && tags.length) {
    if (tags.includes('untagged')) {
      criteria.tags = [];
      criteria.tagged = false;
    } else {
      criteria.tags = tags;
      criteria.tagged = null;
    }
  } else {
    criteria.tags = [];
    criteria.tagged = null;
  }

  if (vehicles && vehicles.length) {
    criteria.vehicles = {
      ids: vehicles
    };
  }
  if (travelModes && travelModes.length) criteria.travel_modes = modes;
  if (hasTeams) {
    criteria.status = status ? [status] : [];
    if (aggregate !== undefined) {
      body.aggregate = aggregate;
    }
  }
  if (owner) {
    body.owner = owner;
  }
  Object.assign(body, { criteria });

  const { method, url } = URLs.reports.create(isEmployee ? null : selectedTeam);
  return serverRequest(method, url, state, { body }).then(response => {
    if (response.report) {
      const activeList = getActiveList(state);
      dispatch(toggleListInvalid(activeList));
      dispatch(addListItem(response.report, activeList));
      history.push(
        getDashboardLinkFor('reportTrips', { id: response.report.id })
      );
      response.team &&
        dispatch(
          updateTeamReportStats(response.team.id, response.team.report_stats)
        );
    }
  });
};

export const updateReport = (id, values) => (dispatch, getState) => {
  const state = getState();
  const hasTeams = getHasTeams(state);

  const criteria = {
    dates: {
      start: values.dateRange.start / 1000,
      end: values.dateRange.end / 1000
    },
    vehicles: {
      ids: values.vehicles
    },
    travel_modes: values.travelModes,
    display_fields: values.dataFields
  };

  if (values.tags.length) {
    if (values.tags.includes('untagged')) {
      criteria.tags = [];
      criteria.tagged = false;
    } else {
      criteria.tags = values.tags;
      criteria.tagged = null;
    }
  } else {
    criteria.tags = [];
    criteria.tagged = null;
  }

  const body = {
    name: values.name,
    criteria
  };
  if (hasTeams) {
    body.criteria.status = values.status ? [values.status] : [];
    if (values.aggregate !== undefined) {
      body.aggregate = values.aggregate;
    }
  }
  if (values.owner) {
    body.owner = values.owner;
  }

  const { method, url } = URLs.reports.update(id);
  serverRequest(method, url, state, { body }).then(response => {
    dispatch(getList('reportTrips'));
    const listType = getReportsListTypeByReportId(state);
    dispatch(setOpenedItem(response.report, id, listType));
    dispatch(toggleListInvalid('reports'));
    response.team &&
      dispatch(
        updateTeamReportStats(response.team.id, response.team.report_stats)
      );
  });
};

export const getReportTrips = () => (dispatch, getState) => {
  const state = getState();
  const hasTeams = getHasTeams(state);
  const listGroup = getReportsListGroupByReportId(state);
  const { id } = listGroup.openedItems[listGroup.openedItem];

  const { method, url } = URLs.reports.getTrips(id, LIST_LIMITS.TWENTY_FIVE);
  serverRequest(method, url, state, { cancelType: 'list' })
    .then(resp => {
      let finalList = resp.trips;
      if (!hasTeams) {
        const sortByColumn = listGroup.sortBy || { departure_time: 'desc' };
        const orderColumn = Object.keys(sortByColumn)[0];
        finalList = _orderBy(
          resp.trips,
          [orderColumn],
          [sortByColumn[orderColumn]]
        );
        dispatch(sortListByColumn(sortByColumn, finalList));
      }
      dispatch(
        setList(finalList, resp.pagination, 'reportTrips', listGroup.openedItem)
      );
      // find the display fields to show from the report criteria
      dispatch(setDisplayAvailableFields());
      dispatch(toggleisUpdatingData());
      dispatch(setOpenedReportId(id, 'reportTrips'));
    })
    .catch(({ err, statusCode }) => {
      if (statusCode === 404) {
        dispatch(setError(createErrorObject(err.detail, 'invalid_report')));
        dispatch(toggleisUpdatingData());
      }
    });
};

export const updateOpenedReportStats = reportId => (dispatch, getState) => {
  const state = getState();
  const { method, url } = URLs.reports.getTrips(reportId, 1);
  serverRequest(method, url, state, { cancelType: 'list' })
    .then(resp => {
      let stats = resp.pagination.stats;
      dispatch(setReportStats(stats));
      dispatch(toggleisUpdatingData());
    })
    .catch(({ err, statusCode }) => {
      if (statusCode === 404) {
        dispatch(setError(createErrorObject(err.detail, 'invalid_report')));
        dispatch(toggleisUpdatingData());
      }
    });
};

export const requestSelectedReportTrips = (state, item) => {
  const { id } = item;
  const url = `users/<user_id>/reports/${id}/trips?limit=${LIST_LIMITS.ONE}`;

  return serverRequest('get', url, state);
};

export const deleteReports = (state, reports) => {
  const ids = reports.join(',');
  const { selectedTeam, rolesInSelectedTeam } = state.teamUserInfo;
  const { method, url } = URLs.reports.deleteMultiple(
    ids,
    selectedTeam && !rolesInSelectedTeam.includes('employee')
      ? selectedTeam
      : null
  );
  return serverRequest(method, url, state);
};

export const deleteOpenedReport = () => (dispatch, getState) => {
  const state = getState();
  const {
    listNew: {
      reportTrips: { openedReportId: openedReportId }
    }
  } = state;
  const { method, url } = URLs.reports.delete(openedReportId);
  serverRequest(method, url, state).then(resp => {
    const listType = getReportsListTypeByReportId(state);
    dispatch(toggleListInvalid(listType, true));
    resp.team &&
      dispatch(updateTeamReportStats(resp.team.id, resp.team.report_stats));
    history.push(getDashboardLinkFor('reports', { for: listType }));
  });
};

export const exportReport = (state, format, listType) => {
  const { timeFormatPreference } = state;
  const hasTeams = getHasTeams(state);
  const listGroup = state.listNew[listType];
  const { id, name: reportName } = listGroup.openedItems[listGroup.openedItem];
  const { filters } = listGroup;

  const queryObj = createQueryObject(filters, hasTeams);
  queryObj.format = format;
  queryObj['time-format'] = timeFormatPreference;

  const { method, url } = URLs.reports.export(id, qs.stringify(queryObj));
  return new Promise(resolve =>
    serverRequest(method, url, state, { format: 'blob' }).then(blob => {
      resolve({ blob, fileName: `${reportName}.${format}` });
    })
  );
};

export const exportReportsList = (state, format, listType) => {
  const { timeFormatPreference } = state;
  const hasTeams = getHasTeams(state);
  const { filters } = state.listNew[listType];
  const selectedItems = getSelectedItems(state);

  const queryObj = createQueryObject(filters, hasTeams);
  queryObj.format = format;
  queryObj['time-format'] = timeFormatPreference;

  return Object.entries(selectedItems).map(
    ([_, { id, name }]) =>
      new Promise(resolve => {
        const { method, url } = URLs.reports.export(id, qs.stringify(queryObj));
        serverRequest(method, url, state, { format: 'blob' }).then(blob =>
          resolve({
            blob,
            fileName: `report_${name.split(' ').join('_')}.${format}`
          })
        );
      })
  );
};

const getOpenedReport = state => {
  const {
    listNew: {
      reportTrips: { openedReportId },
      reports,
      teamReports
    }
  } = state;
  var openedItem;
  if (reports && !isUndefined(reports.openedItems)) {
    openedItem = reports.openedItems[openedReportId];
  }
  if (openedItem === undefined && teamReports !== undefined) {
    openedItem = teamReports.openedItems[openedReportId];
  }
  return openedItem;
};

const updateReportRequest = (id, body) => (dispatch, getState) => {
  const state = getState();
  const openedItem = getOpenedReport(state);
  const { method, url } = URLs.reports.update(openedItem.id);
  return serverRequest(method, url, state, { body }).then(response => {
    const activeList = getListFromReportId(state, openedItem.id);
    if (isUndefined(activeList)) {
      return;
    }
    const { list: reportsList = [] } = state.listNew[activeList];
    if (reportsList.length) {
      const indexInList = reportsList.findIndex(
        item => item.id === openedItem.id
      );
      dispatch(updateListItem(response.report, indexInList, activeList));
    }

    dispatch(updateOpenedItems(response.report, openedItem.id, activeList));
  });
};

export const changeDisplayFields = displayFields => (dispatch, getState) => {
  const openedItem = getOpenedReport(getState());
  let criteriaDisplayFields = Object.assign({}, openedItem.criteria, {
    display_fields: displayFields
  });
  criteriaDisplayFields = buildCleanReportCriteria(criteriaDisplayFields);
  criteriaDisplayFields.tags = parseTags(criteriaDisplayFields.tags);
  const report = {
    id: openedItem.id,
    name: openedItem.name,
    criteria: criteriaDisplayFields,
    owner: isUndefined(openedItem.owner) ? null : openedItem.owner.id,
    aggregate: openedItem.aggregate,
    approval_status: openedItem.approval_status
  };
  dispatch(updateReportRequest(openedItem.id, report));
};

export const getReportsListToShowOnTeamChange = () => (dispatch, getState) => {
  const state = getState();
  const {
    teamUserInfo: { rolesInSelectedTeam }
  } = state;
  let listType = getActiveList(state) || 'reports';
  if (rolesInSelectedTeam.length === 1) {
    if (
      rolesInSelectedTeam.includes('admin') ||
      rolesInSelectedTeam.includes('manager')
    ) {
      listType = 'teamReports';
    }
  }

  history.push(getDashboardLinkFor('reports', { for: listType }));
  dispatch(getList(listType, true));
};
