import React from 'react';
import PropTypes from 'prop-types';

const PageHeader = ({ className, leftSection, rightSection }) => (
  <div className={`listHeaderContainer ${className}`}>
    <div className="listHeader limitWidth">
      <span className="leftSection">{leftSection}</span>
      <span className="rightSection">{rightSection}</span>
    </div>
  </div>
);

PageHeader.propTypes = {
  className: PropTypes.string,
  leftSection: PropTypes.element,
  rightSection: PropTypes.element
};
PageHeader.defaultProps = {
  className: ''
};

export default PageHeader;
