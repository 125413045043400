const registered = [];

class ReduxActionType {
  name;

  constructor(name) {
    if (registered.includes(name)) {
      throw Error(
        `[ERROR]: DUPLICATE ACTION TYPE: Action type with name <${name}> has already have been registered!`
      );
    }

    registered.push(name);
    this.name = name;
  }

  normalizeName() {
    return this.name.toUpperCase();
  }

  get process() {
    return `${this.normalizeName()}-PROCESS`;
  }

  get success() {
    return `${this.normalizeName()}-SUCCESS`;
  }

  get error() {
    return `${this.normalizeName()}-ERROR`;
  }
}

export { ReduxActionType };
