import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import '../css/carousel.css';

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trans: 0,
      transl: 0,
      swiping: false,
      itemWidth: 0
    };
    this.activeSlide = props.initialSlide;
    this.swipe = {};
    this.transMin = 0;
  }

  componentDidMount() {
    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, { passive: false });
    this.props.children.length && this.setWidth();
  }
  componentDidUpdate(prevProps) {
    !prevProps.children.length && this.props.children.length && this.setWidth();
    prevProps.initialSlide !== this.props.initialSlide && this.setActiveSlide();
  }
  componentWillUnmount() {
    window.removeEventListener('touchstart', this.touchStart);
    window.removeEventListener('touchmove', this.preventTouch, {
      passive: false
    });
  }

  setWidth() {
    const carouselList = document.getElementById('carousel-list').children;
    this.itemWidthWithPadding = carouselList[0].offsetWidth;
    const itemWidth = this.itemWidthWithPadding - this.props.paddingRight;
    this.minDistance = itemWidth / 2;
    this.transMax = (carouselList.length - 1) * this.itemWidthWithPadding;
    this.setActiveSlide({ itemWidth });
  }
  setActiveSlide(state = {}) {
    this.activeSlide = this.props.initialSlide;
    const trans = -this.activeSlide * this.itemWidthWithPadding;
    const newState = { trans, ...state };
    this.setState(newState);
  }

  touchStart = e => {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  };

  preventTouch = e => {
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.swipe.x;
    this.clientY = e.touches[0].clientY - this.swipe.y;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  onTouchStart = e => {
    const touch = e.touches[0];
    this.swipe = { x: touch.clientX, y: touch.clientY };
  };

  onTouchMove = e => {
    const { trans } = this.state;
    if (e.changedTouches && e.changedTouches.length) {
      const touch = e.changedTouches[0];
      this.setState({
        transl: trans + touch.clientX - this.swipe.x,
        swiping: true
      });
    }
  };

  onTouchEnd = e => {
    const touch = e.changedTouches[0];
    const xDistance = touch.clientX - this.swipe.x;
    const absX = Math.abs(xDistance);
    if (absX > this.minDistance) {
      xDistance > 0 ? this.moveRight() : this.moveLeft();
    }
    this.setState({ swiping: false, transl: 0 });
    this.swipe = {};
  };

  moveLeft = () => {
    const { trans: oldTrans } = this.state;
    let trans = oldTrans;
    if (oldTrans > -this.transMax) {
      trans = oldTrans - this.itemWidthWithPadding;
      this.activeSlide += 1;
      this.props.onSlideChange && this.props.onSlideChange(this.activeSlide);
    }
    if (trans < -this.transMax) trans = -this.transMax;
    this.setState({ trans });
  };

  moveRight = () => {
    const { trans: oldTrans } = this.state;
    let trans = oldTrans;
    if (oldTrans < this.transMin) {
      trans = oldTrans + this.itemWidthWithPadding;
      this.activeSlide -= 1;
      this.props.onSlideChange && this.props.onSlideChange(this.activeSlide);
    }
    if (trans > this.transMin) trans = this.transMin;
    this.setState({ trans });
  };

  render() {
    const { trans, transl, swiping, itemWidth } = this.state;
    const { showNavButtons, children, paddingRight } = this.props;
    return (
      <div className="carouselContainer">
        <div
          className="carousel"
          style={{ marginLeft: `calc(50% - ${itemWidth / 2}px)` }}
        >
          <div
            className="list"
            id="carousel-list"
            style={{
              transform: `translateX(${swiping ? transl : trans}px)`
            }}
            onTouchStart={this.onTouchStart}
            onTouchMove={this.onTouchMove}
            onTouchEnd={this.onTouchEnd}
          >
            {React.Children.map(children, (child, i) => (
              <div
                className={`carouselItem ${
                  this.activeSlide === i ? `active` : ``
                }`}
                style={{ paddingRight }}
              >
                {child}
              </div>
            ))}
          </div>
        </div>
        {showNavButtons ? (
          <Fragment>
            <div
              className={`prevNavBtn navBtn ${
                this.activeSlide === 0 ? 'hide' : ''
              }`}
            >
              <Icon name="chevron left" onClick={this.moveRight} />
            </div>
            <div
              className={`nextNavBtn navBtn ${
                this.activeSlide === children.length - 1 ? 'hide' : ''
              }`}
            >
              <Icon name="chevron right" onClick={this.moveLeft} />
            </div>
          </Fragment>
        ) : null}
      </div>
    );
  }
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  showNavButtons: PropTypes.bool,
  paddingRight: PropTypes.number,
  initialSlide: PropTypes.number,
  onSlideChange: PropTypes.func
};

Carousel.defaultProps = {
  showNavButtons: false,
  initialSlide: 0
};

export default Carousel;
