import { getCurrencyFromCountry } from '../selectors/locale';

import i18n from '../utils/i18n';

export const getPlansForCountry = (allPlans, country) => {
  const currency = getCurrencyFromCountry(country);
  return allPlans.filter(plan => plan.currency === currency);
};
export const getProPlansForCountry = (allPlans, country) => {
  const plansForCountry = getPlansForCountry(allPlans, country);
  return plansForCountry
    .filter(plan => plan.plan_type === 'pro')
    .sort((a, b) => a.billing_frequency - b.billing_frequency)
    .map(
      el =>
        el.billing_frequency === 1
          ? Object.assign({}, el, { name: i18n.t(`time.monthlyCap`) })
          : el.billing_frequency === 12
            ? Object.assign({}, el, { name: i18n.t(`time.annuallyCap`) })
            : el
    );
};
export const getEnterprisePlansForCountry = (allPlans, country) => {
  const plansForCountry = getPlansForCountry(allPlans, country);
  return plansForCountry
    .filter(
      plan => plan.plan_type === 'team' && plan.default_employee_count <= 50
    )
    .sort((a, b) => a.default_employee_count - b.default_employee_count);
};
export const getSaveProAnnual = (allPlans, country) => {
  const proPlans = getProPlansForCountry(allPlans, country);
  const annual = proPlans.find(el => el.billing_frequency === 12) || {};
  const monthly = proPlans.find(el => el.billing_frequency === 1) || {};
  return Math.round((1 - annual.price / 12 / monthly.price) * 100);
};
export const isCustomPlan = planId => {
  return planId.startsWith('custom');
};
