import serverRequest from '../utils/Api';
import { isEmpty } from '../utils';

import {
  mergeDiscountPlans,
  getPaymentBillingInfo,
  getPaymentShippingInfo,
  getPaymentInfo,
  getUserDetailsInfo,
  derivePlanData,
  getPaymentInfoType,
  getSelectedPlan,
  allFieldsValid
} from '../selectors/billing';
import { getCustomerCurrency } from '../selectors/products';
import { getCustomer } from '../selectors/customer';

import { calculateTransaction } from './taxamo';
import {
  paymentMethod,
  clearTransaction,
  cleanUpAndSetTransaction
} from './payments';
import {
  updateUserDetailsRemovePending,
  updateUserDetails,
  updateUserSubscription,
  checkToShowNotifBar
} from './user';
import { wrongPassword, changePassword } from './login';
import { getUserInfo } from '../selectors/user';
import { createErrorObject } from '../utils/dataStore';
import { setError, deleteErrorsOfBillingForm } from './errors';
import { URLs } from '../utils/urls';
import { getCompany } from '../selectors/company';
import { fetchVaultedPaymentMethodNonce } from './braintree';

export const RECEIVE_CUSTOMER = 'RECEIVE_CUSTOMER';
export const RECEIVE_PLANS = 'RECEIVE_PLANS';
export const RECEIVE_PLAN = 'RECEIVE_PLAN';
export const SELECT_PLAN = 'SELECT_PLAN';
export const SET_BILLING_INFO = 'SET_BILLING_INFO';
export const CLEAR_BILLING_INFO = 'CLEAR_BILLING_INFO';
export const SET_BILLING_INFO_VALIDATIONS = 'SET_BILLING_INFO_VALIDATIONS';
export const CLEAR_BILLING_INFO_VALIDATIONS = 'CLEAR_BILLING_INFO_VALIDATIONS';
export const SET_INFO = 'SET_INFO';
export const CLEAR_INFO = 'CLEAR_INFO';
export const SET_INFO_VALIDATIONS = 'SET_INFO_VALIDATIONS';
export const CLEAR_INFO_VALIDATIONS = 'CLEAR_INFO_VALIDATIONS';
export const SET_PROMO = 'SET_PROMO';
export const CLEAR_PROMO = 'CLEAR_PROMO';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const MAKE_PRIMARY = 'MAKE_PRIMARY';
export const SET_BILLING_HISTORY = 'SET_BILLING_HISTORY';
export const SET_ADD_METHOD = 'SET_ADD_METHOD';
export const TAXAMO_READY = 'TAXAMO_READY';
export const SET_UPGRADE_ANNUALLY = 'SET_UPGRADE_ANNUALLY';
export const CLEAR_UPGRADE_ANNUALLY = 'CLEAR_UPGRADE_ANNUALLY';
export const SET_UPDATE_PLAN = 'SET_UPDATE_PLAN';
export const SET_CANCELLED_SUBSCRIPTION = 'SET_CANCELLED_SUBSCRIPTION';
export const CLEAR_CANCELLED_SUBSCRIPTION = 'CLEAR_CANCELLED_SUBSCRIPTION';
export const SET_SHIPPING_INFO = 'SET_SHIPPING_INFO';
export const CLEAR_SHIPPING_INFO = 'CLEAR_SHIPPING_INFO';
export const CLEAR_SHIPPING_INFO_VALIDATIONS =
  'CLEAR_SHIPPING_INFO_VALIDATIONS';
export const SET_SHIPPING_INFO_VALIDATIONS = 'SET_SHIPPING_INFO_VALIDATIONS';
export const SET_BILLING_UPDATE_SUBSCRIPTION =
  'SET_BILLING_UPDATE_SUBSCRIPTION';
export const CLEAR_BILLING_UPDATE_SUBSCRIPTION =
  'CLEAR_BILLING_UPDATE_SUBSCRIPTION';
export const TOGGLE_SAME_ADDRESS_BILLING_SHIPPING =
  'TOGGLE_SAME_ADDRESS_BILLING_SHIPPING';
export const SET_CUSTOMER_ADDRESS_LIST = 'SET_CUSTOMER_ADDRESS_LIST';
export const UPDATE_CUSTOMER_ADDRESS_LIST = 'UPDATE_CUSTOMER_ADDRESS_LIST';
export const SET_UPDATE_VAT = 'SET_UPDATE_VAT';
export const CLEAR_UPDATE_VAT = 'CLEAR_UPDATE_VAT';
export const UPDATE_SELECTED_PLAN = 'UPDATE_SELECTED_PLAN';

export const receiveCustomer = customer => {
  return { type: RECEIVE_CUSTOMER, customer };
};
const receivePlans = plans => {
  return { type: RECEIVE_PLANS, plans };
};
const receivePlan = plan => {
  return { type: RECEIVE_PLAN, plan };
};
export const setCustomerAddressList = list => {
  return { type: SET_CUSTOMER_ADDRESS_LIST, list };
};
export const updateCustomerAddressList = address => {
  return { type: UPDATE_CUSTOMER_ADDRESS_LIST, address };
};
export const selectPlan = plan => {
  return { type: SELECT_PLAN, plan };
};
export const updateSelectedPlan = plan => {
  return { type: UPDATE_SELECTED_PLAN, plan };
};
export const setBillingInfo = billingInfo => {
  return { type: SET_BILLING_INFO, billingInfo };
};
export const clearBillingInfo = () => {
  return { type: CLEAR_BILLING_INFO };
};
export const setShippingInfo = shippingInfo => {
  return { type: SET_SHIPPING_INFO, shippingInfo };
};
export const clearShippingInfo = () => {
  return { type: CLEAR_SHIPPING_INFO };
};
export const setBillingSubscriptionToUpdate = subscription_id => {
  return { type: SET_BILLING_UPDATE_SUBSCRIPTION, subscription_id };
};
export const clearBillingSubscriptionToUpdate = () => {
  return { type: CLEAR_BILLING_UPDATE_SUBSCRIPTION };
};

export const setBillingInfoValidations = validations => {
  return { type: SET_BILLING_INFO_VALIDATIONS, validations };
};
export const setShippingInfoValidations = validations => {
  return { type: SET_SHIPPING_INFO_VALIDATIONS, validations };
};
export const clearBillingInfoValidations = () => {
  return { type: CLEAR_BILLING_INFO_VALIDATIONS };
};
export const clearShippingInfoValidations = () => {
  return { type: CLEAR_SHIPPING_INFO_VALIDATIONS };
};
export const toggleSameAddressBillingShipping = () => {
  return { type: TOGGLE_SAME_ADDRESS_BILLING_SHIPPING };
};
export const setInfo = info => {
  return { type: SET_INFO, info };
};
export const clearInfo = () => {
  return { type: CLEAR_INFO };
};
export const setInfoValidations = validations => {
  return { type: SET_INFO_VALIDATIONS, validations };
};
export const clearInfoValidations = () => {
  return { type: CLEAR_INFO_VALIDATIONS };
};
export const setPromo = promo => {
  return { type: SET_PROMO, promo };
};
export const clearPromo = () => {
  return { type: CLEAR_PROMO };
};
const setBillingHistory = historyList => {
  return { type: SET_BILLING_HISTORY, historyList };
};
export const setAddMethod = addMethod => {
  return { type: SET_ADD_METHOD, addMethod };
};
const deletePayment = token => {
  return { type: DELETE_PAYMENT, token };
};
const makePrimary = token => {
  return { type: MAKE_PRIMARY, token };
};
export const taxamoReady = () => {
  return { type: TAXAMO_READY };
};
export const setUpgradeAnnually = () => {
  return { type: SET_UPGRADE_ANNUALLY };
};
export const clearUpgradeAnnually = () => {
  return { type: CLEAR_UPGRADE_ANNUALLY };
};
export const setUpdatePlan = plan => {
  return { type: SET_UPDATE_PLAN, plan };
};
export const setCancelledSubscription = () => {
  return { type: SET_CANCELLED_SUBSCRIPTION };
};
export const clearCancelledSubscription = () => {
  return { type: CLEAR_CANCELLED_SUBSCRIPTION };
};
export const taxUpdate = taxId => ({ type: SET_UPDATE_VAT, taxId });
export const clearUpdateVAT = () => ({ type: CLEAR_UPDATE_VAT });

export const sendConfirmationEmail = () => {
  return (dispatch, getState) => {
    const { url, method } = URLs.user.sendConfirmationEmail();
    serverRequest(method, url, getState(), {}).then(
      json => {
        if (!json.valid) {
          console.error('Confirmation email was not send properly');
        }
      },
      err => {
        console.error('sendConfirmationEmail', err);
      }
    );
  };
};

// Customer information START

export const createCustomer = (customer, dispatch, state) => {
  return new Promise((resolve, reject) => {
    const { url, method } = URLs.customer.createCustomer();
    serverRequest(method, url, state, { body: customer }).then(
      json => {
        dispatch(updateUserDetails({ has_user_customer: true }));
        dispatch(receiveCustomer(json));
        resolve(json);
      },
      err => {
        console.error('createCustomer: ', err);
        reject(err);
      }
    );
  });
};

export const retreiveCustomer = refresh => {
  return (dispatch, getState) => {
    const state = getState();
    const customer = getCustomer(state);
    if (!customer.id || refresh) {
      const { url, method } = URLs.customer.getCustomer();
      serverRequest(method, url, state).then(
        json => {
          dispatch(receiveCustomer(Object.assign(json, { received: true })));
        },
        err => {
          console.error('retreiveCustomer: ', err);
        }
      );
    }
  };
};

const editCustomer = body => (dispatch, getState) => {
  const { url, method } = URLs.customer.updateCustomer();
  serverRequest(method, url, getState(), { body }).then(
    json => {
      dispatch(receiveCustomer(json));
      // build address list
      dispatch(retreiveAddressList());
    },
    err => {
      console.error('editCustomer: ', err);
    }
  );
};

export const updateCustomer = (body, dispatch, state) => {
  return new Promise((resolve, reject) => {
    const { url, method } = URLs.customer.updateCustomer();
    serverRequest(method, url, state, { body }).then(
      json => {
        dispatch(receiveCustomer(json));
        dispatch(retreiveAddressList());
        resolve(json);
      },
      err => {
        console.error('updateCustomer: ', err);
        reject(err);
      }
    );
  });
};

export const saveCustomerDefaultBilling = id => dispatch => {
  dispatch(editCustomer({ billing_address_id: id }));
};
export const saveCustomerDefaultShipping = id => dispatch => {
  dispatch(editCustomer({ shipping_address_id: id }));
};
export const saveCustomerDefaultBoth = id => dispatch => {
  dispatch(editCustomer({ shipping_address_id: id, billing_address_id: id }));
};
export const updateCustomerVat = vat => dispatch => {
  dispatch(editCustomer({ tax_id: vat }));
};

export const retreiveAddressList = () => {
  return (dispatch, getState) => {
    const { url, method } = URLs.customer.getCustomerAddressList();
    serverRequest(method, url, getState()).then(
      json => {
        dispatch(setCustomerAddressList(json.results));
      },
      err => {
        console.error('retrieveAddressList', err);
      }
    );
  };
};
const sendCustomerAddress = (values, method, id) => (dispatch, getState) => {
  const url = `premium/customer/address${id ? `/${id}` : ''}`;
  const body = {
    first_name: values.firstName,
    last_name: values.lastName,
    locality: values.locality,
    company: values.company,
    postal_code: values.postalCode,
    region: values.region,
    street_address: values.address,
    // extended_address: values,
    country_code_alpha2: values.country
  };
  serverRequest(method, url, getState(), { body }).then(
    json => {
      dispatch(updateCustomerAddressList(json));
      dispatch(retreiveCustomer(true)); // temporary FIX
    },
    err => {
      console.error('sendCustomerAddress: ', err);
    }
  );
};
export const saveCustomerAddress = values => dispatch => {
  dispatch(sendCustomerAddress(values, 'post'));
};
export const editCustomerAddress = (values, id) => dispatch => {
  dispatch(sendCustomerAddress(values, 'put', id));
};
export const deleteCustomerAddress = id => (dispatch, getState) => {
  const state = getState();
  const { url, method } = URLs.customer.deleteCustomerAddress(id);
  serverRequest(method, url, getState()).then(() => {
    const { addressList } = getCustomer(state);

    // mark deleted address as 'deleted'
    const updatedAddressList = addressList.map(addr => {
      if (addr.id === id) {
        return Object.assign({}, addr, { isDeleted: true });
      }

      return addr;
    });
    dispatch(setCustomerAddressList(updatedAddressList));
  });
};

export const removeCustomerAddress = id => (dispatch, getState) => {
  const { addressList } = getCustomer(getState());
  dispatch(setCustomerAddressList(addressList.filter(addr => addr.id !== id)));
};
// Customer information END
// Plans START

const setPlanFromCustomerCurrency = forTeam => (dispatch, getState) => {
  const state = getState();
  const customerCurrency = getCustomerCurrency(state);
  const { plans = {}, selectedPlan } = state.billing.plansData;
  const selectedPlanObj = plans.find(el => el.id === selectedPlan);

  if (isEmpty(plans)) {
    return;
  }
  let plansToSearch = plans;
  if (!forTeam) {
    // get the pro plans
    plansToSearch = plans.filter(plan => plan.plan_type === 'pro');
  } else {
    // get the team plans
    plansToSearch = plans.filter(
      plan =>
        plan.plan_type === 'team' &&
        plan.default_employee_count === selectedPlanObj.default_employee_count
    );
  }

  const newPlan = plansToSearch.find(
    el =>
      !el.id.includes('test') &&
      el.billing_frequency === selectedPlanObj.billing_frequency &&
      el.currency === customerCurrency
  );
  const diffentCurrency = selectedPlan !== newPlan.id;

  diffentCurrency && dispatch(selectPlan(newPlan.id));
};

export const updateAddOnsForUpgrade = () => (dispatch, getState) => {
  const state = getState();
  const company = getCompany(state);
  const { plans = {}, selectedPlan } = state.billing.plansData;
  const updatedPlan = Object.assign(
    {},
    plans.find(el => el.id === selectedPlan)
  );

  var add_ons = [];
  if (updatedPlan.add_ons) {
    add_ons = updatedPlan.add_ons.slice();
  }
  if (
    company.employee_count > 1 &&
    add_ons.length > 0 &&
    add_ons[0].id.includes('employee') &&
    add_ons[0].quantity == 0
  ) {
    const quantity = Math.max(
      0,
      company.employee_count - updatedPlan.default_employee_count
    );
    add_ons[0].quantity = quantity;
    let amount = add_ons[0].amount * quantity;
    add_ons[0].amount = amount;
    updatedPlan.add_ons = add_ons;
    updatedPlan.price =
      '' + (Number(updatedPlan.base_price) + amount).toFixed(2);
    dispatch(updateSelectedPlan(updatedPlan));
  }
};

// FIX: decouple
export const calculatePlans = options => (dispatch, getState) => {
  const { plansData, country, taxId, email } = options;
  const {
    billing: { plansData: allPlans }
  } = getState();
  if (plansData && plansData.ready) {
    const transactionOptions = {
      email,
      taxId,
      currency: plansData.currency,
      userDeclaredCountry: country,
      plan: plansData.plans.find(plan => plan.id === allPlans.selectedPlan)
    };
    calculateTransaction(transactionOptions).then(
      transaction => {
        dispatch(cleanUpAndSetTransaction(transaction));
        if (transaction.billing_country_code !== country) {
          const { billingInfo } = getState().billing.payment;
          dispatch(
            setBillingInfo(
              Object.assign({}, billingInfo, {
                country: transaction.billing_country_code
              })
            )
          );
        }
      },
      err => {
        console.error('calculatePlans: ', err);
      }
    );
  }
};

export const calculate = () => (dispatch, getState) => {
  dispatch(updateAddOnsForUpgrade());
  const state = getState();
  const {
    billing: {
      payment: {
        billingInfo: { country, taxId },
        updateVAT
      }
    },
    user: {
      details: { email }
    }
  } = state;

  const plansData = derivePlanData(state);

  plansData.ready &&
    dispatch(
      calculatePlans({
        plansData,
        country,
        taxId: updateVAT || taxId,
        email
      })
    );
};

export const setPlanAndCalculate = forTeam => dispatch => {
  dispatch(setPlanFromCustomerCurrency(forTeam));
  dispatch(calculate());
};

export const retreivePlans = (notSpecificForUser = false) => {
  return (dispatch, getState) => {
    const { url, method } = URLs.plans.getAllPlans();
    const state = getState();
    const options = {};
    if (notSpecificForUser) {
      options.doNotAuthorize = true;
    }
    serverRequest(method, url, state, options).then(
      json => {
        json.plans
          ? dispatch(receivePlans(json.plans))
          : console.error('retreivePlans: Success but no plans in data');
      },
      err => {
        console.error('retreivePlans', err);
      }
    );
  };
};

export const getSpecificPlan = (planId, calc = false) => (
  dispatch,
  getState
) => {
  const { method, url } = URLs.plans.getSpecificPlan(planId);
  serverRequest(method, url, getState()).then(
    json => {
      json.plans && json.plans[0]
        ? dispatch(receivePlan(json.plans[0])) && calc && dispatch(calculate())
        : console.error('getSpecificPlan: Success but no plan details');
    },
    err => console.error('getSpecificPlans: ', err)
  );
};

const verifyPromoCodeForSpecificPlan = code => (dispatch, getState) => {
  const state = getState();
  const selectedPlanId = getSelectedPlan(state);
  const { url, method } = URLs.plans.verifyPromoCodeForPlan(
    selectedPlanId,
    code
  );
  serverRequest(method, url, state).then(({ plans: discountedPlans }) => {
    const isDiscountValid = discountedPlans.length;
    if (!code) {
      dispatch(setPromo(null));
    } else {
      dispatch(setPromo({ valid: isDiscountValid, verified: true }));
    }
    if (isDiscountValid) {
      const { plans } = getState().billing.plansData;
      dispatch(receivePlans(mergeDiscountPlans(plans, discountedPlans)));
    } else {
      dispatch(getSpecificPlan(selectedPlanId, true));
    }
    dispatch(calculate());
  });
};

export const verifyCodeAndSet = code => dispatch => {
  dispatch(setPromo({ code }));
  dispatch(verifyPromoCodeForSpecificPlan(code));
};

export const setSelectPlan = () => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      subscription,
      pending_subscription: pendingSubscription
    } = getUserDetailsInfo(state);
    let plan = '';
    if (pendingSubscription) {
      plan = pendingSubscription.product;
    } else plan = subscription.product;
    dispatch(selectPlan(plan));
  };
};

// Plans END
// Payment methods START

export const addPaymentMethod = () => {
  return (dispatch, getState) => {
    // FIX: isDefault needs to be handled
    const state = getState();
    const {
      billing: {
        transaction: { nonce }
      }
    } = state;
    const body = { nonce };
    const { url, method } = URLs.customer.addPaymentMethod();
    serverRequest(method, url, state, { body }).then(
      json => {
        dispatch(setInfo({ ready: false, loading: false, valid: true }));
        dispatch(setAddMethod(false));
        dispatch(receiveCustomer(json.customer));
      },
      err => {
        dispatch(
          setInfo({ verifying: false, ready: true, valid: false, error: err })
        );
        console.error('addPaymentMethod: ', err);
      }
    );
  };
};

export const deletePaymentMethod = token => {
  return (dispatch, getState) => {
    const { url, method } = URLs.customer.deletePaymentMethod(token);
    serverRequest(method, url, getState()).then(
      json => {
        dispatch(deletePayment(token));
      },
      err => {
        console.error('deletePaymentMethod: ', err);
        retreiveCustomer(true);
      }
    );
  };
};

export const validatePaymentMethodForPrimary = () => (dispatch, getState) => {
  const { token } = getPaymentInfo(getState());
  const {
    subscription,
    pending_subscription: pendingSubscription
  } = getUserDetailsInfo(getState());
  let shouldVerifyWith3DSecure =
    (!isEmpty(subscription) &&
      subscription.status.toLowerCase() === 'active' &&
      ['braintree', 'team'].includes(subscription.type)) ||
    !isEmpty(pendingSubscription);

  fetchVaultedPaymentMethodNonce(
    token,
    shouldVerifyWith3DSecure,
    getState,
    markPaymentMethodAsPrimary,
    dispatch,
    'markPaymentMethodAsPrimary'
  );
};

export const markPaymentMethodAsPrimary = () => (dispatch, getState) => {
  const state = getState();
  const { token } = getPaymentInfo(state);
  const {
    billing: {
      transaction: { nonce }
    }
  } = state;
  const body = { set_as_default: true, nonce: nonce };
  const { url, method } = URLs.customer.setPrimaryPaymentMethod(token);
  serverRequest(method, url, getState(), { body }).then(
    () => {
      dispatch(setInfo({ loading: null, error: null }));
      dispatch(makePrimary(token));
    },
    err => {
      dispatch(
        setInfo({
          loading: null,
          error: {
            token: token,
            message: err.err.message
          }
        })
      );
      console.error('markPaymentMethodAsPrimary: ', err);
      retreiveCustomer(true);
    }
  );
};

// Payment methods END
// Validations START

const hostedFieldsValidations = tokenizeErr => {
  return dispatch => {
    const { code, details = {} } = tokenizeErr;
    const { invalidFieldKeys } = details;
    let validations = {};
    switch (code) {
      case 'HOSTED_FIELDS_FIELDS_EMPTY':
        validations = {
          number: false,
          cvv: false,
          expirationDate: false
        };
        dispatch(setInfoValidations(validations));
        break;
      case 'HOSTED_FIELDS_FIELDS_INVALID':
        validations = invalidFieldKeys.reduce(
          (acc, key) => Object.assign({}, acc, { [key]: false }),
          {}
        );
        dispatch(setInfoValidations(validations));
        break;
      default:
        break;
    }
  };
};

const cardholderValidation = () => {
  return (dispatch, getState) => {
    const { data: { cardholder } = {} } = getPaymentInfo(getState());
    const validations = {
      cardholder: Boolean(cardholder)
    };
    dispatch(setInfoValidations(validations));
  };
};

export const submitValidations = tokenizeErr => dispatch => {
  dispatch(hostedFieldsValidations(tokenizeErr));
};

export const billingInfoValidations = () => {
  return (dispatch, getState) => {
    const {
      firstName,
      lastName,
      country,
      locality,
      postalCode,
      address,
      company
    } = getPaymentBillingInfo(getState());
    const validations = {
      firstName: Boolean(firstName),
      lastName: Boolean(lastName),
      country: Boolean(country),
      locality: Boolean(locality),
      postalCode: Boolean(postalCode),
      address: Boolean(address),
      company: Boolean(company)
    };
    dispatch(setBillingInfoValidations(validations));
    dispatch(cardholderValidation());
  };
};

export const validateAndPay = () => (dispatch, getState) => {
  dispatch(deleteErrorsOfBillingForm());
  const state = getState();
  dispatch(billingInfoValidations());
  const { valid, validations } = allFieldsValid(getState());

  if (valid) {
    const { type, isCard } = getPaymentInfo(state);
    if (!isCard && (type.includes('primary') || type.includes('existing'))) {
      dispatch(setInfo({ ready: false, verifying: true }));
      dispatch(paymentMethod());
    }
  } else {
    dispatch(
      setError(createErrorObject('', 'form_validations_error', validations))
    );
  }
};

export const shippingInfoValidations = () => {
  return (dispatch, getState) => {
    const {
      firstName,
      lastName,
      country,
      locality,
      postalCode,
      address,
      company
    } = getPaymentShippingInfo(getState());
    const validations = {
      firstName: Boolean(firstName),
      lastName: Boolean(lastName),
      country: Boolean(country),
      locality: Boolean(locality),
      postalCode: Boolean(postalCode),
      address: Boolean(address),
      company: Boolean(company)
    };
    dispatch(setShippingInfoValidations(validations));
  };
};

export const clearInfoValidationsAction = () => {
  return (dispatch, getState) => {
    const {
      billing: {
        payment: { info }
      }
    } = getState();
    !isEmpty(info.validations) && dispatch(clearInfoValidations());
  };
};

// Validations END

export const cancelSubscription = (
  password,
  subscriptionId,
  updateVAT = false
) => {
  return (dispatch, getState) => {
    const state = getState();
    const body = { password };
    const { url, method } = URLs.customer.cancelSubscription(subscriptionId);
    serverRequest(method, url, state, { body }).then(
      json => {
        const { subscription } = getUserInfo(state);
        if (subscriptionId === subscription.id) {
          dispatch(updateUserSubscription(json));
        }
        dispatch(changePassword());
        if (updateVAT) {
          dispatch(billingInfoValidations());
          dispatch(paymentMethod());
        } else {
          dispatch(updateUserDetailsRemovePending(json.user));
          dispatch(setCancelledSubscription());
          dispatch(retreivePlans());

          dispatch(checkToShowNotifBar());
        }
      },
      err => {
        console.error('cancelSubscription: ', err);
        dispatch(wrongPassword());
      }
    );
  };
};

export const retreiveHistory = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { history } = state.billing.premium;
    if (history) {
      const { method, url } = URLs.customer.getBillingHistory();
      serverRequest(method, url, state).then(
        json => {
          dispatch(setBillingHistory(json.transactions));
        },
        err => {
          console.error('retreiveHistory: ', err);
        }
      );
    }
  };
};

export const closePaymentMethod = infoToSet => dispatch => {
  dispatch(clearInfo());
  dispatch(setInfo(infoToSet));
  dispatch(setAddMethod(false));
};

export const clearBillingData = (shipping = false) => dispatch => {
  dispatch(clearTransaction());
  dispatch(clearUpgradeAnnually());
  dispatch(clearBillingInfo());
  shipping && dispatch(clearShippingInfo());
  dispatch(clearUpdateVAT());
};
