import { createSelector } from 'reselect';

import { getUserVehicles } from './vehicles';
import { getNameOfVclass } from '../utils/vehicles';

export const getBeaconList = state => {
  return state.beacons.beacons.list;
};
export const getBeaconOrders = state => {
  return state.beacons.orders;
};
export const getIsFetchingLists = state => {
  return state.beacons.isFetching;
};
export const getIsFetchingBeaconOrderList = state => {
  const { isFetching } = getBeaconOrders(state);
  return isFetching;
};
export const getBeaconOrdersList = state => {
  return getBeaconOrders(state).list || [];
};
export const getBeaconOrdersCount = state => {
  return getBeaconOrders(state).count || 0;
};
export const getUserBeaconOrderDetails = state => {
  const ordersList = getBeaconOrdersList(state);
  return orderId => {
    return ordersList.find(beacon => beacon.id === orderId);
  };
};

export const getUserBeaconsWithVehicleInfo = createSelector(
  [getBeaconList, getUserVehicles],
  (beaconList = [], vehicles) => {
    return beaconList.map(
      el =>
        el.user_vehicle
          ? Object.assign({}, el, {
              user_vehicle: vehicles.find(vehic => vehic.id === el.user_vehicle)
            })
          : el
    );
  }
);

export const getVehicleNameById = state => {
  const vehicles = getUserVehicles(state);
  return vehicleId => {
    const match = vehicles.find(el => el.id === vehicleId);
    if (match === undefined) {
      return '';
    }
    const { vehicle, vclass, license } = match;
    if (vehicle !== null) {
      const { make = '', model = '', year = '' } = vehicle;
      return [make, model, year, license].filter(el => el).join(' ');
    } else if (vclass != null) {
      return [getNameOfVclass(vclass), license].filter(el => el).join(' ');
    } else {
      return license;
    }
  };
};
