import { setFiltersFromSearchText } from '../';
import {
  updateListItem,
  updateOpenedItems,
  listDeselectAll,
  setFilterSearchText,
  listSelect,
  setListItem,
  setOpenedItem,
  findIndexAndSelect,
  findIndexAndDeselect,
  toggleListInvalid,
  removeItemFromList,
  updateListStats,
  toggleisUpdatingData
} from '../list';
import { getReportTrips } from '../reports';
import { updateTeamReportStats } from '../teams';

import { getReportsListTypeByReportId } from '../../selectors/list/reports';
import { getHasTeams } from '../../selectors/teams';

import { getFiltersFromTextForReportSearch } from '../../utils';
import serverRequest from '../../utils/Api';
import { REPORT_STATUS } from '../../utils/constants';
import { URLs } from '../../utils/urls';

export const ACTIVATE_FLAG_MODE = 'ACTIVATE_FLAG_MODE';
export const DEACTIVATE_FLAG_MODE = 'DEACTIVATE_FLAG_MODE';
export const CLEAR_REMOVED_LIST = 'CLEAR_REMOVED_LIST';
export const UPDATE_REPORT_TRIPS_REMOVE_LIST =
  'UPDATE_REPORT_TRIPS_REMOVE_LIST';

export const activateFlagMode = () => ({
  type: ACTIVATE_FLAG_MODE
});
export const deactivateFlagMode = () => ({
  type: DEACTIVATE_FLAG_MODE
});

export const updateReportTripsRemovedList = removedList => ({
  type: UPDATE_REPORT_TRIPS_REMOVE_LIST,
  removedList
});
export const clearRemovedList = () => ({
  type: CLEAR_REMOVED_LIST
});

export const searchReports = (searchQuery, listType) => (
  dispatch,
  getState
) => {
  const hasTeams = getHasTeams(getState());

  dispatch(listDeselectAll(listType));
  dispatch(setFilterSearchText(searchQuery, listType));
  dispatch(toggleListInvalid(listType));

  const exactTerms = [];
  const restArray = [];
  const searchArray = searchQuery.split(`"`);
  for (let i = 0; i < searchArray.length; i++) {
    if (i % 2 !== 0) {
      exactTerms.push(`"${searchArray[i]}"`);
    } else {
      searchArray[i] && restArray.push(searchArray[i]);
    }
  }

  const searchFilters = getFiltersFromTextForReportSearch(restArray.join(`,`));

  if (searchFilters.text && exactTerms.length) {
    searchFilters.text = `${searchFilters.text},${exactTerms.join(`,`)}`;
  } else if (exactTerms.length) {
    searchFilters.text = exactTerms.join(`,`);
  }

  dispatch(setFiltersFromSearchText(searchFilters, hasTeams, listType));
};

// Everything do to with changing approval status of report START

const updateReportInList = (updatedReport, listType) => (
  dispatch,
  getState
) => {
  const { list } = getState().listNew[listType];
  if (list && list.length) {
    const index = list.findIndex(el => el.id === updatedReport.id);
    dispatch(updateListItem(updatedReport, index, listType));
  }
  dispatch(updateOpenedItems(updatedReport, null, listType));
};

export const submitReport = (teamId, reportId) => (dispatch, getState) => {
  dispatch(toggleisUpdatingData(null, true));
  const state = getState();

  const reportsListType = getReportsListTypeByReportId(state);
  const body = {
    approval_status: REPORT_STATUS.SUBMITTED,
    trip_operations: []
  };

  const { method, url } = URLs.reports.update(reportId);
  serverRequest(method, url, state, { body }).then(resp => {
    dispatch(updateReportInList(resp.report, reportsListType));
    dispatch(toggleListInvalid(reportsListType));
    dispatch(getReportTrips());
    resp.team &&
      dispatch(updateTeamReportStats(resp.team.id, resp.team.report_stats));
  });
};

export const undoSubmitReport = (teamId, reportId) => (dispatch, getState) => {
  dispatch(toggleisUpdatingData(null, true));
  const state = getState();

  const reportsListType = getReportsListTypeByReportId(state);
  const body = {
    approval_status: REPORT_STATUS.NEW,
    trip_operations: []
  };

  const { method, url } = URLs.reports.update(reportId);
  serverRequest(method, url, state, { body }).then(resp => {
    dispatch(updateReportInList(resp.report, reportsListType));
    dispatch(toggleListInvalid(reportsListType));
    dispatch(getReportTrips());
    resp.team &&
      dispatch(updateTeamReportStats(resp.team.id, resp.team.report_stats));
  });
};

export const approveReport = (teamId, reportId) => (dispatch, getState) => {
  dispatch(toggleisUpdatingData(null, true));
  const state = getState();

  const reportsListType = getReportsListTypeByReportId(state);
  const body = {
    approval_status: REPORT_STATUS.APPROVED,
    trip_operations: [],
    approved_rest: true
  };
  const { method, url } = URLs.reports.update(reportId);
  serverRequest(method, url, state, { body }).then(resp => {
    dispatch(listDeselectAll());
    dispatch(updateReportInList(resp.report, reportsListType));
    dispatch(toggleListInvalid(reportsListType));
    dispatch(getReportTrips());
    resp.team &&
      dispatch(updateTeamReportStats(resp.team.id, resp.team.report_stats));
  });
};

// setting ui approval_status for tripslist in report
export const setTripApprovalStatus = (tripId, approvalStatus) => (
  dispatch,
  getState
) => {
  const { list } = getState().listNew.reportTrips;
  let tripToChange = {};
  let tripIndex = null;
  list.some((trip, index) => {
    if (trip.id === tripId) {
      tripToChange = trip;
      tripIndex = index;
      return true;
    }
    return false;
  });
  tripToChange.new_approval_status = approvalStatus;
  dispatch(setListItem(tripToChange, tripIndex, 'reportTrips'));
};

// setting ui approval_status for trips details
export const setOpenedItemApprovalStatus = (tripId, approvalStatus) => (
  dispatch,
  getState
) => {
  const { openedItems } = getState().listNew.reportTrips;
  const itemToChange = Object.assign({}, openedItems[tripId], {
    new_approval_status: approvalStatus
  });
  dispatch(setOpenedItem(itemToChange, tripId));
};

// select flagged items once flag mode is turned on
export const selectFlaggedItems = () => (dispatch, getState) => {
  const { list } = getState().listNew.reportTrips;
  list.forEach((el, index) => {
    const approvalStatus = el.new_approval_status || el.approval_status;
    approvalStatus === REPORT_STATUS.FLAGGED && dispatch(listSelect(index));
  });
};

const setNewStatsForFlagging = (wasSubmitted, flagging = false) => (
  dispatch,
  getState
) => {
  const { stats } = getState().listNew.reportTrips;
  const { flagged, submitted, resolved } = stats;
  const newStats = Object.assign({}, stats, {
    flagged: flagging ? flagged + 1 : flagged - 1,
    submitted: wasSubmitted
      ? flagging
        ? submitted - 1
        : submitted + 1
      : submitted,
    resolved: wasSubmitted ? resolved : flagging ? resolved - 1 : resolved + 1
  });
  dispatch(updateListStats(newStats, 'reportTrips'));
};

export const setReportTripFlagged = tripId => (dispatch, getState) => {
  dispatch(activateFlagMode());
  dispatch(selectFlaggedItems());
  dispatch(setTripApprovalStatus(tripId, REPORT_STATUS.FLAGGED));
  // check if this item exists in openedItems
  const { openedItems = {}, list } = getState().listNew.reportTrips;
  const trip = list.find(el => el.id === tripId);
  const wasSubmitted = trip.approval_status === REPORT_STATUS.SUBMITTED;
  if (openedItems[tripId]) {
    dispatch(setOpenedItemApprovalStatus(tripId, REPORT_STATUS.FLAGGED));
  }
  dispatch(findIndexAndSelect(tripId, 'reportTrips'));
  dispatch(toggleListInvalid('reportTrips'));

  dispatch(setNewStatsForFlagging(wasSubmitted, true));
};
export const setReportTripUnflagged = tripId => (dispatch, getState) => {
  dispatch(activateFlagMode());
  dispatch(selectFlaggedItems());
  const { openedItems = {}, list } = getState().listNew.reportTrips;
  const trip = list.find(el => el.id === tripId);
  const wasSubmitted = trip.approval_status === REPORT_STATUS.SUBMITTED;
  const newApproval = wasSubmitted
    ? REPORT_STATUS.SUBMITTED
    : REPORT_STATUS.RESOLVED;
  dispatch(setTripApprovalStatus(tripId, newApproval));
  // check if this item exists in openedItems
  if (openedItems[tripId]) {
    dispatch(setOpenedItemApprovalStatus(tripId, newApproval));
  }
  dispatch(findIndexAndDeselect(tripId, 'reportTrips'));
  dispatch(toggleListInvalid('reportTrips'));

  dispatch(setNewStatsForFlagging(wasSubmitted));
};
export const setReportTripResolved = tripId => (dispatch, getState) => {
  dispatch(setTripApprovalStatus(tripId, REPORT_STATUS.RESOLVED));
  const { openedItems = {}, stats } = getState().listNew.reportTrips;
  if (openedItems[tripId]) {
    dispatch(setOpenedItemApprovalStatus(tripId, REPORT_STATUS.RESOLVED));
  }
  dispatch(toggleListInvalid('reportTrips'));

  const newStats = Object.assign({}, stats, {
    resolved: stats.resolved + 1,
    flagged: stats.flagged - 1
  });
  dispatch(listDeselectAll('reportTrips'));
  dispatch(updateListStats(newStats, 'reportTrips'));
};
export const setReportTripNew = (tripId, approvalStatus) => (
  dispatch,
  getState
) => {
  const {
    openedItems = {},
    stats,
    removed = [],
    list
  } = getState().listNew.reportTrips;
  const trip = list.find(item => item.id === tripId);

  dispatch(removeItemFromList(tripId, 'reportTrips'));

  const removedList = removed.concat(tripId);
  dispatch(updateReportTripsRemovedList(removedList));
  if (openedItems[tripId]) {
    dispatch(setOpenedItemApprovalStatus(tripId, REPORT_STATUS.NEW));
  }
  dispatch(toggleListInvalid('reportTrips'));

  const objectToMerge = { new: stats.new + 1 };
  if (approvalStatus === REPORT_STATUS.FLAGGED) {
    objectToMerge.flagged = stats.flagged - 1;
  }
  if (approvalStatus === REPORT_STATUS.RESOLVED) {
    objectToMerge.resolved = stats.resolved - 1;
  }

  objectToMerge.total_distance = stats.total_distance - trip.stats.distance;
  objectToMerge.mileage_expenses = {
    value: stats.mileage_expenses.value - trip.mileage_expenses.value,
    currency: stats.mileage_expenses.currency
  };

  const newStats = Object.assign({}, stats, objectToMerge);
  dispatch(listDeselectAll('reportTrips'));
  dispatch(updateListStats(newStats, 'reportTrips'));
};

export const toggleReportFlagMode = () => (dispatch, getState) => {
  const { flagMode, isInvalid } = getState().listNew.reportTrips;
  if (flagMode) {
    dispatch(deactivateFlagMode());
    dispatch(listDeselectAll('reportTrips'));
    isInvalid && dispatch(getReportTrips());
  } else {
    dispatch(activateFlagMode());
    dispatch(selectFlaggedItems());
  }
};

export const flagReport = (
  teamId,
  reportId,
  comment = '',
  approveOtherTrips = false
) => (dispatch, getState) => {
  dispatch(toggleisUpdatingData(null, true));
  const state = getState();
  const { list, stats } = state.listNew.reportTrips;

  const tripsModified = list.reduce(
    (acc, trip) => {
      if (trip.new_approval_status === REPORT_STATUS.FLAGGED) {
        acc[REPORT_STATUS.FLAGGED].push(trip.id);
      } else if (trip.new_approval_status === REPORT_STATUS.RESOLVED) {
        acc[REPORT_STATUS.RESOLVED].push(trip.id);
      }
      return acc;
    },
    {
      [REPORT_STATUS.FLAGGED]: [],
      [REPORT_STATUS.RESOLVED]: []
    }
  );
  const reportApprovalStatus =
    stats.flagged > 0 ? REPORT_STATUS.FLAGGED : REPORT_STATUS.RESOLVED;
  const reportsListType = getReportsListTypeByReportId(state);
  const body = {
    approval_status: reportApprovalStatus,
    trip_operations: [],
    approved_rest: approveOtherTrips
  };
  if (tripsModified[REPORT_STATUS.FLAGGED].length) {
    body.trip_operations.push({
      approval_status: REPORT_STATUS.FLAGGED,
      trip_ids: tripsModified[REPORT_STATUS.FLAGGED]
    });
  }
  if (tripsModified[REPORT_STATUS.RESOLVED].length) {
    body.trip_operations.push({
      approval_status: REPORT_STATUS.RESOLVED,
      trip_ids: tripsModified[REPORT_STATUS.RESOLVED]
    });
  }
  if (comment) {
    body.comment = comment;
  }
  const { method, url } = URLs.reports.update(reportId);
  serverRequest(method, url, state, { body }).then(resp => {
    dispatch(toggleReportFlagMode());
    dispatch(updateReportInList(resp.report, reportsListType));
    dispatch(toggleListInvalid(reportsListType));
    dispatch(toggleisUpdatingData());
    resp.team &&
      dispatch(updateTeamReportStats(resp.team.id, resp.team.report_stats));
  });
};

export const resubmitReport = (teamId, reportId, comment = '') => (
  dispatch,
  getState
) => {
  dispatch(toggleisUpdatingData(null, true));
  const state = getState();
  const { list, stats, removed = [], isInvalid } = state.listNew.reportTrips;

  const reportsListType = getReportsListTypeByReportId(state);
  const tripsModified = list.reduce(
    (acc, trip) => {
      if (trip.new_approval_status === REPORT_STATUS.RESOLVED) {
        acc[REPORT_STATUS.RESOLVED].push(trip.id);
      } else if (trip.new_approval_status === REPORT_STATUS.NEW) {
        acc[REPORT_STATUS.NEW].push(trip.id);
      }
      return acc;
    },
    {
      [REPORT_STATUS.RESOLVED]: [],
      [REPORT_STATUS.NEW]: []
    }
  );

  const body = {
    approval_status: REPORT_STATUS.RESOLVED,
    trip_operations: []
  };

  if (stats.resolved > 0) {
    body.trip_operations.push({
      approval_status: REPORT_STATUS.RESOLVED,
      trip_ids: tripsModified[REPORT_STATUS.RESOLVED]
    });
  }
  if (stats.new > 0) {
    body.trip_operations.push({
      approval_status: REPORT_STATUS.NEW,
      trip_ids: removed
    });
  }

  if (comment) {
    body.comment = comment;
  }

  const { method, url } = URLs.reports.update(reportId);
  serverRequest(method, url, state, { body }).then(resp => {
    dispatch(listDeselectAll('reportTrips'));
    if (isInvalid) dispatch(getReportTrips());
    else dispatch(toggleisUpdatingData());
    dispatch(updateReportInList(resp.report, reportsListType));
    dispatch(toggleListInvalid(reportsListType));
    dispatch(clearRemovedList());
    resp.team &&
      dispatch(updateTeamReportStats(resp.team.id, resp.team.report_stats));
  });
};
