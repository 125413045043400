import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import { Modal } from 'semantic-ui-react';

import Header from '../containers/PublicRouteHeader';
import Footer from '../containers/PublicRouteFooter';
import LandingIntro from '../components/Landing/LandingIntroSection';
import FeaturesSection from '../components/Landing/Pro/FeaturesSection';
import UpgradeProSection from '../components/Landing/Pro/UpgradeProSection';
import UpgradeProSectionMobile from '../components/Landing/Pro/UpgradeProSectionMobile';

import { retreivePlans } from '../actions/billing';

import {
  isUserLoggedIn,
  getActiveUserSubscription,
  getIsMobile
} from '../selectors/user';
import { getBillingCountry } from '../selectors/managePlan';
import { getAllPlans } from '../selectors/billing';

import { getProPlansForCountry } from '../utils/plans';
import { getDashboardLinkFor } from '../utils';
import { proFeatures } from '../utils/landingPro';
import { trackLoginFromGetStarted } from '../utils/analytics';

import '../css/landing.css';
import '../css/landing_intro.css';
import '../css/landing_features.css';
import '../css/landingPro.css';

class LandingProPage extends Component {
  componentWillMount() {
    this.props.dispatch(retreivePlans());
    this.state = {
      playVideo: false
    };
  }
  componentDidMount() {
    const page = document.getElementById('landingPage');
    if (page && !window.location.href.includes('#')) {
      page.scrollTop = 0;
    }
  }

  trackLogin = () => {
    if (!this.props.userLoggedIn) {
      trackLoginFromGetStarted('pro');
    }
  };

  onPlay = () => {
    this.setState({ playVideo: true });
  };

  stopVideo = () => {
    this.setState({ playVideo: false });
  };

  render() {
    const {
      proPlans,
      userLoggedIn,
      currentSubscription,
      t,
      isMobile
    } = this.props;
    const { playVideo } = this.state;

    return (
      <div id="landingPage" className="pro">
        <Header />

        <Modal
          id="introVideo"
          open={playVideo}
          onClose={this.stopVideo}
          size={isMobile ? 'fullscreen' : 'large'}
        >
          <ReactPlayer
            url="https://www.youtube.com/embed/xdLEmdYz_Ok"
            playing={playVideo}
            controls={false}
            className="react-player"
            width="100%"
            height={isMobile ? '320px' : '640px'}
            onEnded={this.stopVideo}
          />
        </Modal>
        <LandingIntro
          appName="Psngr Pro"
          title={t(`home.pro.intro.title`)}
          subTitle={t(`home.pro.intro.subtitle`)}
          onPlay={this.onPlay}
          actions={
            <a
              href={getDashboardLinkFor('managePlan')}
              className="action upgradeBtn"
              onClick={this.trackLogin}
            >
              {t(`home.pro.intro.upgradeToPro`)}
            </a>
          }
        />
        <FeaturesSection
          sectionSubTitle={t(`home.pro.features.subtitle`)}
          featuresList={proFeatures()}
        />
        {isMobile ? (
          <UpgradeProSectionMobile
            t={t}
            plans={proPlans}
            trackEvent={this.trackLogin}
          />
        ) : (
          <UpgradeProSection
            t={t}
            plans={proPlans}
            userLoggedIn={userLoggedIn}
            currentSubscription={currentSubscription}
            trackEvent={this.trackLogin}
          />
        )}

        <Footer />
      </div>
    );
  }
}

LandingProPage.propTypes = {
  proPlans: PropTypes.array,
  userLoggedIn: PropTypes.bool,
  currentSubscription: PropTypes.object,
  t: PropTypes.func,
  dispatch: PropTypes.func,
  isMobile: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    proPlans: getProPlansForCountry(
      getAllPlans(state),
      getBillingCountry(state)
    ),
    userLoggedIn: isUserLoggedIn(state),
    currentSubscription: getActiveUserSubscription(state),
    isMobile: getIsMobile(state)
  };
};
const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default translate()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingProPage))
);
