import {
  RECEIVE_BEACONS_PRODUCTS,
  SET_BEACON_QUANTITY,
  SET_SHIPPING_METHOD,
  SET_SELECTED_BEACON
} from '../actions/products';

const initalState = {
  beacons: { selectedBeacon: null, quantity: 1, selectedShippingMethod: null },
  plans: {}
};

export const products = (state = initalState, action) => {
  switch (action.type) {
    case RECEIVE_BEACONS_PRODUCTS:
      return {
        ...state,
        beacons: Object.assign({}, state.beacons, action.obj)
      };
    case SET_SELECTED_BEACON:
      return {
        ...state,
        beacons: Object.assign({}, state.beacons, {
          selectedBeacon: action.selectedBeacon
        })
      };
    case SET_BEACON_QUANTITY:
      return {
        ...state,
        beacons: Object.assign({}, state.beacons, { quantity: action.quantity })
      };
    case SET_SHIPPING_METHOD:
      return {
        ...state,
        beacons: Object.assign({}, state.beacons, {
          selectedShippingMethod: action.selectedShippingMethod
        })
      };
    default:
      return state;
  }
};
