import { createSelector } from 'reselect';
import i18n from '../utils/i18n';
import { getOpenedItem } from './list';
import { isEmpty } from '../utils';
import { getMileageRatesTags } from './mileageRates';

export const getLoadedTags = state => {
  return state.loadedTags;
};

export const parseTags = tags => {
  return !isEmpty(tags) ? Object.values(tags).map(tag => tag.name) : [];
};

export const getTagsTrip = state => {
  const openedItem = getOpenedItem(state);
  return openedItem ? openedItem.tags.map(item => item.name) : [];
};
export const getNotesTrip = state => {
  const openedItem = getOpenedItem(state);
  return openedItem ? openedItem.notes : '';
};
export const getTagsAutomaticReports = state => {
  const { criteria } = getOpenedItem(state);
  return criteria.tags ? criteria.tags.map(item => item.name) : [];
};
export const getTagOptions = state => {
  const loadedTags = getLoadedTags(state);
  const mileageRates = getMileageRatesTags(state);
  const tags = [...loadedTags];
  mileageRates.forEach(tag => {
    const tagObj = tags.find(el => el.name === tag);
    if (!tagObj) {
      tags.push({
        name: tag,
        slug: tag,
        num_trips: 0
      });
    }
  });
  const personal = i18n.t(`common.personal`);
  const personalTag = tags.find(el => el.name === personal);
  !personalTag &&
    tags.push({
      name: personal,
      slug: personal,
      num_trips: 0
    });
  return tags.map(item => ({
    text: `#${item.name}`,
    value: item.name,
    name: item.name,
    slug: item.slug,
    key: item.name
  }));
};
// export const getTagOptions = createSelector([getLoadedTags], tags => {
//   return tags.map(item => ({
//     text: `#${item.name}`,
//     value: item.name,
//     key: item.name
//   }));
// });
export const getTagOptionsWithUntagged = createSelector(
  [getTagOptions],
  tagOptions => {
    const tagOptionsUntagged = [
      {
        text: i18n.t(`list.untagged`),
        value: 'untagged',
        name: 'untagged',
        slug: 'untagged',
        tag: 'untagged',
        key: 'untagged'
      }
    ];
    return tagOptionsUntagged.concat(tagOptions);
  }
);

export const getFilters = (criteria, getVehicleName) => {
  const travelModes = [];
  const vehicles = [];
  const tags = [];
  let dates = null;

  Object.entries(criteria).forEach(([name, filterGroup = []]) => {
    switch (name) {
      case 'travel_modes':
        !isEmpty(filterGroup) &&
          filterGroup.map(travelMode =>
            travelModes.push(i18n.t(`travelModes.${travelMode}`))
          );
        break;
      case 'tags':
        !isEmpty(filterGroup) &&
          filterGroup.map(tag => tags.push(`#${tag.name}`));
        break;
      case 'vehicles':
        !isEmpty(filterGroup) &&
          vehicles.push(filterGroup.ids.map(id => getVehicleName(id)));
        break;
      case 'dates':
        dates = !isEmpty(filterGroup) ? Object.assign({}, filterGroup) : null;
        break;
      default:
        break;
    }
  });
  return { travelModes, tags, vehicles, dates, tagged: criteria.tagged };
};
