import { travelModes } from '../utils/constants';
import { getLoadedTags } from './filters';

export const getMileageRates = state => state.mileageRates;

export const getPublicMileageRates = state => {
  if (state.mileageRates) {
    return state.mileageRates.filter(
      mr => mr.is_public && !mr.vehicle_specific && mr.travel_mode == 'DRIVING'
    );
  }
  return [];
};

export const getMileageRatesError = state => {
  return state.errors.find(el => el.type === 'mileage_rate_error');
};

export const getMileageRatesTags = state =>
  state.mileageRates
    .map(el => el.tag)
    .filter((el, index, self) => self.indexOf(el) === index);

export const getTagOptionsForVehicle = (vehicleId, state) => {
  const loadedTags = getLoadedTags(state);
  const mileageRates = getMileageRates(state);

  const tags = mileageRates.reduce((acc, mileageRate) => {
    const tagObj = acc.find(item => item.value === mileageRate.tag);
    if (!tagObj) {
      acc.push({
        name: mileageRate.name,
        text: `#${mileageRate.tag}`,
        tag: mileageRate.tag,
        value: mileageRate.tag,
        key: mileageRate.tag,
        ismileageratetag: 'true'
      });
    }
    return acc;
  }, []);

  loadedTags.forEach(tag => {
    const tagObj = tags.find(item => item.value === tag.name);
    if (!tagObj) {
      tags.push({
        name: tag.name,
        text: `#${tag.name}`,
        tag: tag.name,
        value: tag.name,
        key: tag.name,
        ismileageratetag: 'false'
      });
    }
  });
  return tags.sort((a, b) => {
    if (a.value > b.value) {
      return 1;
    } else if (a.value < b.value) {
      return -1;
    }
    return 0;
  });
};
