import {
  RECEIVE_CUSTOMER,
  SET_CUSTOMER_ADDRESS_LIST,
  UPDATE_CUSTOMER_ADDRESS_LIST,
  DELETE_PAYMENT,
  MAKE_PRIMARY
} from '../actions/billing';

export const customer = (state = {}, action) => {
  let list = [];
  let address = {};
  let result = {};
  switch (action.type) {
    case RECEIVE_CUSTOMER:
      return Object.assign({}, state, action.customer);

    case SET_CUSTOMER_ADDRESS_LIST:
      return Object.assign({}, state, {
        addressList: action.list
      });

    case UPDATE_CUSTOMER_ADDRESS_LIST:
      list = state.addressList || [];
      address = list.find(el => el.id === action.address.id);
      if (!address) {
        return Object.assign({}, state, {
          addressList: list.concat(action.address)
        });
      }
      list[list.indexOf(address)] = action.address;
      return Object.assign({}, state, {
        addressList: list
      });

    case DELETE_PAYMENT: // TODO: fix
      result = Object.assign({}, state);
      let i = result.payment_methods.paypal_accounts.findIndex(
        item => item.token === action.token
      );
      if (i === -1) {
        i = result.payment_methods.credit_cards.findIndex(
          item => item.token === action.token
        );
        result.payment_methods.credit_cards.splice(i, 1);
        result.payment_methods.credit_cards = result.payment_methods.credit_cards.concat();
      } else {
        result.payment_methods.paypal_accounts.splice(i, 1);
        result.payment_methods.paypal_accounts = result.payment_methods.paypal_accounts.concat();
      }
      return result;

    case MAKE_PRIMARY: // TODO: fix
      result = Object.assign({}, state);
      const makePrimary = array => {
        array.forEach(item => {
          if (item.token === action.token) {
            item.default = true;
          } else {
            item.default = false;
          }
        });
        return array.slice(0);
      };
      result.payment_methods.paypal_accounts = makePrimary(
        result.payment_methods.paypal_accounts
      );
      result.payment_methods.credit_cards = makePrimary(
        result.payment_methods.credit_cards
      );
      return result;

    default:
      return state;
  }
};
