import { SET_USER_MILEAGE_RATES } from '../actions/mileageRates';

export const mileageRates = (state = [], action) => {
  switch (action.type) {
    case SET_USER_MILEAGE_RATES:
      return action.list;
    default:
      return state;
  }
};
