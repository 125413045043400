// import i18n from './utils/i18n';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { Router } from 'react-router-dom';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

import App from './App';

import reducer from './reducers';

import history from './utils/history';

const middleware = [thunk];
let enhancedMiddleware = applyMiddleware(...middleware);
if (process.env.REACT_APP_ENV !== 'production') {
  middleware.push(createLogger());
  enhancedMiddleware = composeWithDevTools({})(applyMiddleware(...middleware));
}

// const composeEnhancers = composeWithDevTools({});
const store = createStore(reducer, enhancedMiddleware);

render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);
