import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Segment, Button, Icon } from 'semantic-ui-react';

import { MAIL_TO_LINKS } from '../../utils/constants';

import '../../css/help.css';

class HelpSupport extends Component {
  static propTypes = {
    appLanguage: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  mailSupport = () => {
    window.location.href = MAIL_TO_LINKS.GENERAL_CONTACT;
  };

  tweetPsngr = () => {
    window.location.href = 'https://twitter.com/intent/tweet?text=@psngrapp%20';
  };

  render() {
    const { appLanguage, t } = this.props;

    return (
      <div className="supportSection">
        <Segment className="supportSegment">
          <h1 className="psngrSupport"> {t('settings.psngrSupport')}</h1>
          <p className="dont-want-to-search">
            {t('settings.psngrSupportSubtitle')}
          </p>
          <div className="supportButtons">
            <Button
              icon
              labelPosition="left"
              className="helpButton helpButtonMail"
              onClick={this.mailSupport}
            >
              <Icon name="mail" className="helpButtonIcon" />
              {t('settings.contact')}
            </Button>
            <Button
              icon
              labelPosition="left"
              className="helpButton helpButtonTwitter"
              onClick={this.tweetPsngr}
            >
              {t('settings.tweet')}
              <Icon name="twitter" className="helpButtonIconTwitter" />
            </Button>
          </div>
          <p
            className="to-learn-more-about"
            dangerouslySetInnerHTML={{ __html: t('settings.learnMore') }}
          />
        </Segment>
        <div className="findOut">
          <p
            dangerouslySetInnerHTML={{
              __html: t('settings.findOut', { lang: appLanguage })
            }}
          />
        </div>
      </div>
    );
  }
}

export default translate()(HelpSupport);
