import serverRequest from '../utils/Api';
import { getCleanCompany, getCleanTeamFromDetails } from '../utils/dataStore';
import { URLs } from '../utils/urls';

import { receiveCustomer } from './billing';
import { setHasTeams, setCurrentTeamInfo } from './teamUserInfo';
import { setTeamsList } from './teams';
import { getUserInfo } from '../selectors/user';
import { receiveDetails } from './user';

export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';
export const SET_COMPANY_ADDRESS_LIST = 'SET_COMPANY_ADDRESS_LIST';
export const UPDATE_COMPANY_ADRRESS_LIST = 'UPDATE_COMPANY_ADRRESS_LIST';
export const UPDATE_COMPANY_LOGO = 'UPDATE_COMPANY_LOGO';
export const SET_COMPANY_SUBSCRIPTION = 'SET_COMPANY_SUBSCRIPTION';

export const setCompanyDetails = details => ({
  type: SET_COMPANY_DETAILS,
  details
});
const setCompanyAddressList = addresslist => ({
  type: SET_COMPANY_ADDRESS_LIST,
  addresslist
});
const updateCompanyAddressList = address => ({
  type: UPDATE_COMPANY_ADRRESS_LIST,
  address
});
const updateCompanyLogo = logo => ({
  type: UPDATE_COMPANY_LOGO,
  logo
});
export const setCompanySubscription = subscription => ({
  type: SET_COMPANY_SUBSCRIPTION,
  subscription
});

export const getCompanyDetails = () => (dispatch, getState) => {
  const { url, method } = URLs.company.getDetails();
  return serverRequest(method, url, getState()).then(response => {
    dispatch(receiveCustomer(response.customer));
    dispatch(setCompanyDetails(getCleanCompany(response)));
  });
};
export const getCompanyCustomer = () => (dispatch, getState) => {
  const { url, method } = URLs.company.getDetails(['customer']);
  return serverRequest(method, url, getState()).then(response => {
    dispatch(receiveCustomer(response.customer));
  });
};
export const getCompanyDataForManagePlan = () => (dispatch, getState) => {
  const { url, method } = URLs.company.getDetails([
    'subscription',
    'team_count',
    'employee_count'
  ]);
  return serverRequest(method, url, getState()).then(response => {
    dispatch(setCompanyDetails(getCleanCompany(response)));
  });
};

export const fetchCompanyAddresses = () => (dispatch, getState) => {
  const { url, method } = URLs.company.getCompanyAddresses();
  return serverRequest(method, url, getState()).then(resp => {
    dispatch(setCompanyAddressList(resp.results));
  });
};

export const changeCompanyName = name => (dispatch, getState) => {
  const { method, url } = URLs.company.updateDetails();
  return serverRequest(method, url, getState(), {
    body: { name }
  }).then(resp => {
    dispatch(setCompanyDetails(resp));
  });
};

const saveCompanyAddress = (method, url, values) => (dispatch, getState) => {
  const body = {
    first_name: values.firstName,
    last_name: values.lastName,
    locality: values.locality,
    company: values.company,
    postal_code: values.postalCode,
    region: values.region,
    street_address: values.address,
    country_code_alpha2: values.country
  };

  serverRequest(method, url, getState(), { body }).then(resp => {
    dispatch(updateCompanyAddressList(resp));
  });
};

export const createCompanyAddress = values => dispatch => {
  const { method, url } = URLs.company.addAddress();
  dispatch(saveCompanyAddress(method, url, values));
};

export const editCompanyAddress = (id, values) => dispatch => {
  const { method, url } = URLs.company.updateAddress(id);
  dispatch(saveCompanyAddress(method, url, values));
};

export const deleteCompanyAddress = id => (dispatch, getState) => {
  const { url, method } = URLs.company.deleteAddress(id);
  serverRequest(method, url, getState()).then(resp => {
    const {
      company: { addressList }
    } = getState();
    // mark deleted address as 'deleted'
    const updatedAddressList = addressList.map(addr => {
      if (addr.id === id) {
        return Object.assign({}, addr, { isDeleted: true });
      }
      return addr;
    });
    dispatch(setCompanyAddressList(updatedAddressList));
  });
};

export const removeCompanyAddress = id => (dispatch, getState) => {
  const {
    company: { addressList }
  } = getState();
  dispatch(setCompanyAddressList(addressList.filter(el => el.id !== id)));
};

// use this to mark address as default billing and/or shipping address
export const setAddressMeta = (id, meta) => (dispatch, getState) => {
  const {
    company: { addressList }
  } = getState();
  const thisAddress = addressList.filter(el => el.id === id);
  if (thisAddress.length) {
    const { url, method } = URLs.company.updateAddress(id);
    serverRequest(method, url, getState(), {
      body: Object.assign({}, thisAddress[0], {
        is_billing:
          meta.isBilling !== undefined
            ? meta.isBilling
            : thisAddress.is_billing,
        is_shipping:
          meta.isShipping !== undefined
            ? meta.isShipping
            : thisAddress.is_shipping
      })
    }).then(resp => {
      dispatch(fetchCompanyAddresses());
    });
  }
};

export const setCompanyLogo = logoToBeUploaded => (dispatch, getState) => {
  const form = new FormData();
  form.append('logo', logoToBeUploaded);

  const { method, url } = URLs.company.updateCompanyLogo();
  serverRequest(method, url, getState(), { form })
    .then(json => {
      dispatch(updateCompanyLogo(json.logo));
    })
    .catch(err => {
      console.error('failed to upload company logo: ', err);
    });
};

export const createFreeCompany = details => (dispatch, getState) => {
  const body = {
    company: { name: details.companyName },
    team: {
      name: details.teamName,
      country: details.teamCountry.toUpperCase(),
      date_format: details.teamDateFormat,
      time_format: details.teamTimeFormat,
      currency: details.teamCurrency
    }
  };

  const { method, url } = URLs.company.create();
  serverRequest(method, url, getState(), { body }).then(response => {
    const userInfo = getUserInfo(getState());
    dispatch(
      receiveDetails(Object.assign({}, userInfo, { is_company_owner: true }))
    );
    dispatch(setTeamsList([response.team].map(getCleanTeamFromDetails)));
    dispatch(setCurrentTeamInfo(response.team.id));
    dispatch(setCompanyDetails(response.company));
    dispatch(setHasTeams());
  });
};
