import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Image } from 'semantic-ui-react';

import { Distance } from '../Units';

import { STATS_USER_TESTIMONIALS } from '../../utils/landingConstants';

class StatsSection extends Component {
  state = {
    activeTestimonialNumber: 1
  };

  componentWillMount() {
    this.setTestimonialInterval();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.language !== nextProps.language) {
      this.clearTestimonialInterval();
      this.setState(
        { activeTestimonialNumber: 1 },
        this.setTestimonialInterval
      );
    }
  }
  componentWillUnmount() {
    this.clearTestimonialInterval();
  }

  getTestimonial = number => {
    const { t } = this.props;
    return {
      name: t(`home.stats.quotes.quote${number}.name`),
      quote: t(`home.stats.quotes.quote${number}.quote`)
    };
  };
  setTestimonialInterval = () => {
    this.changeTestimonialInterval = setInterval(
      this.showNextTestimonial,
      10000
    );
  };
  clearTestimonialInterval = () => {
    this.changeTestimonialInterval &&
      clearInterval(this.changeTestimonialInterval);
  };
  showNextTestimonial = () => {
    const { activeTestimonialNumber } = this.state;
    const { language } = this.props;
    const numberOfQuotesToShow =
      STATS_USER_TESTIMONIALS.numberOfQuotes[language];

    let nextNumber = activeTestimonialNumber + 1;
    if (nextNumber > numberOfQuotesToShow) {
      nextNumber = 1;
    }
    this.setState({ activeTestimonialNumber: nextNumber });
  };

  awardClicked = url => {
    window.open(url, '_blank');
  };

  render() {
    const { activeTestimonialNumber } = this.state;
    const { name, quote } = this.getTestimonial(activeTestimonialNumber);
    const { appStats, t } = this.props;

    return (
      <div className="statsSection" id="stats">
        <div className="section">
          <div className="distanceStats">
            <div className="distanceWrapper">
              <Distance
                value={appStats.moving_distance}
                showUnit
                showDecimal={false}
              />
            </div>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: t('home.stats.tracked')
              }}
            />
          </div>

          <div className="ratingStats">
            <div className="rating">
              <div className="ratingStars">
                <div className="star" />
                <div className="star" />
                <div className="star" />
                <div className="star" />
                <div className="star half" />
              </div>
              <div className="ratingText">{t('home.stats.rating')}</div>
            </div>
            <div className="testimonial">
              <div className="pointed box">
                <div>{quote}</div>
              </div>
              <div className="author">{name}</div>
            </div>
          </div>

          <div className="awardsStats">
            <div className="awardImage">
              <Image
                onClick={() =>
                  this.awardClicked(
                    'https://bestmobileappawards.com/app-submission/psngr'
                  )
                }
                src="/images/landing/award_badge.png"
                size="small"
                className="awardImg"
              />
              <Image
                onClick={() =>
                  this.awardClicked(
                    'https://bestmobileappawards.com/app-submission/psngr-2'
                  )
                }
                src="/images/landing/badge-silver-award-winner-2023.png"
                size="small"
                className="awardImg"
              />
            </div>
            <div className="text">{t('home.stats.awardTitle')}</div>
          </div>
        </div>
        <div className="section tablet mobile">
          <div className="firstRow">
            <div className="distanceStats">
              <div className="distanceWrapper">
                <Distance
                  value={appStats.moving_distance}
                  showUnit
                  showDecimal={false}
                />
              </div>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: t('home.stats.tracked')
                }}
              />
            </div>

            <div className="awardsStats">
              <div className="awardImage">
                <Image
                  onClick={() =>
                    this.awardClicked(
                      'https://bestmobileappawards.com/app-submission/psngr'
                    )
                  }
                  src="/images/landing/award_badge.png"
                  size="small"
                  className="awardImg"
                />
                <Image
                  onClick={() =>
                    this.awardClicked(
                      'https://bestmobileappawards.com/app-submission/psngr-2'
                    )
                  }
                  src="/images/landing/badge-silver-award-winner-2023.png"
                  size="small"
                  className="awardImg"
                />
              </div>
              <div className="text">{t('home.stats.awardTitle')}</div>
            </div>
          </div>
          <div className="secondRow">
            <div className="ratingStats">
              <div className="rating">
                <div className="ratingStars">
                  <div className="star" />
                  <div className="star" />
                  <div className="star" />
                  <div className="star" />
                  <div className="star half" />
                </div>
                <div className="ratingText">{t('home.stats.rating')}</div>
              </div>
              <div className="testimonial">
                <div className="pointed box">
                  <div>{quote}</div>
                </div>
                <div className="author">{name}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StatsSection.propTypes = {
  language: PropTypes.string,
  appStats: PropTypes.object,
  t: PropTypes.func
};

export default translate()(StatsSection);
