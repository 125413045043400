import i18n from './i18n';

export const getWeekItems = () => {
  return [
    { text: i18n.t('days.monday'), value: '1' },
    { text: i18n.t('days.tuesday'), value: '2' },
    { text: i18n.t('days.wednesday'), value: '3' },
    { text: i18n.t('days.thursday'), value: '4' },
    { text: i18n.t('days.friday'), value: '5' },
    { text: i18n.t('days.saturday'), value: '6' },
    { text: i18n.t('days.sunday'), value: '7' }
  ];
};
export const getMonthItems = () => {
  return [
    { text: i18n.t(`automaticReports.dayOfMonth`), value: 'dayOfMonth' },
    { text: i18n.t('numeric.first'), value: '1-7' },
    { text: i18n.t('numeric.second'), value: '8-14' },
    { text: i18n.t('numeric.third'), value: '15-21' },
    { text: i18n.t('numeric.fourth'), value: '22-28' },
    { text: i18n.t('numeric.last'), value: '25-31' }
  ];
};
export const getYearItems = () => {
  return [
    { text: i18n.t('months.january'), value: '1' },
    { text: i18n.t('months.february'), value: '2' },
    { text: i18n.t('months.march'), value: '3' },
    { text: i18n.t('months.april'), value: '4' },
    { text: i18n.t('months.may'), value: '5' },
    { text: i18n.t('months.june'), value: '6' },
    { text: i18n.t('months.july'), value: '7' },
    { text: i18n.t('months.august'), value: '8' },
    { text: i18n.t('months.september'), value: '9' },
    { text: i18n.t('months.october'), value: '10' },
    { text: i18n.t('months.november'), value: '11' },
    { text: i18n.t('months.december'), value: '12' }
  ];
};
export const getDaysOfMonth = () => {
  const days = [];
  for (let i = 1; i < 32; i++) {
    days.push({ text: i, value: i });
  }

  return days;
};

export const getFrequencyText = frequency => {
  let displayText = '';
  if (frequency[0] === '*') {
    if (frequency[2] === '*') {
      // onDayOfEvryMonth
      displayText = i18n.t(`automaticReports.monthlyFrequency`, {
        count: frequency[1]
      });
    } else if (frequency[2] === '*/3') {
      // quarterly
      displayText = i18n.t(`time.quarterYear`);
    } else {
      // onDayOfMonthEvryYear
      const { text: monthText } = getYearItems().find(
        item => item.value === frequency[2]
      );
      displayText = i18n.t(`automaticReports.yearlyFrequency`, {
        count: frequency[1],
        month: monthText
      });
    }
  } else if (frequency[1] === '*') {
    // onDayOfEvryWeek
    const { text: dayText } = getWeekItems().find(
      item => item.value === frequency[0]
    );
    displayText = i18n.t(`automaticReports.weeklyFrequency`, { day: dayText });
  } else {
    // onKindOfDayOfEveryMonth
    const { text: dayText } = getWeekItems().find(
      item => item.value === frequency[0]
    );
    const { text: kindOfDayText } = getMonthItems().find(
      item => item.value === frequency[1]
    );
    displayText = i18n.t(`automaticReports.monthDayOfWeekFrequency`, {
      dayOfWeek: dayText,
      number: kindOfDayText
    });
  }

  return displayText;
};

export const whatKindOfFrequency = frequency => {
  if (frequency[0] === '*') {
    if (frequency[2] === '*') {
      return 'monthly';
    } else if (frequency[2] === '*/3') {
      return 'quarterly';
    }
    return 'yearly';
  } else if (frequency[1] === '*') {
    return 'weekly';
  }
  return 'monthly';
};
