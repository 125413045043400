import { combineReducers } from 'redux';
import { listNew } from './list';
import { billing } from './billing';
import { vehicles } from './vehicles';
import { mileageRates } from './mileageRates';
import { beacons } from './beacons';
import { products } from './products';
import { userSettings } from './userSettings';
import { user } from './login';
import { helpPage } from './helpPage';
import { teams } from './teams';
import { teamMembers } from './teamMembers';
import { teamUserInfo } from './teamUserInfo';
import { company } from './company';
import { promotions } from './promotions';
import { customer } from './customer';
import { notificationBar } from './notificationBar';
import { errors } from './errors';
import { notifications } from './notifications';
import { datePreference } from './datePreference';
import { stateList } from './stateList';
import { apiMeta } from './apiMeta';
import { ui } from './ui';

import {
  timeFormatPreference,
  loadedTags,
  selectedDateRange,
  nextPageToBe,
  countryByIp,
  appLanguage,
  appStats,
  isApp,
  isSmoochInitialized,
  locationByIp as location
} from './locale';

const appReducer = combineReducers({
  billing,
  nextPageToBe,
  datePreference,
  timeFormatPreference,
  listNew,
  loadedTags,
  selectedDateRange,
  helpPage,
  user,
  vehicles,
  mileageRates,
  beacons,
  products,
  userSettings,
  teams,
  teamMembers,
  teamUserInfo,
  company,
  customer,
  promotions,
  notificationBar,
  errors,
  countryByIp,
  appLanguage,
  appStats,
  isApp,
  isSmoochInitialized,
  notifications,
  stateList,
  apiMeta,
  ui,
  location
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_SIGNOUT') {
    const actionToBroadcast = {
      type: action.type,
      countryByIp: state.countryByIp,
      appLanguage: state.appLanguage
    };
    return appReducer(undefined, actionToBroadcast);
  }
  return appReducer(state, action);
};

export default rootReducer;
