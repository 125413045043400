import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

import MonthlyPrice from '../ManagePlan/MonthlyPrice';

const PlanCard = ({
  image,
  title,
  features,
  showMonthly,
  from,
  currency,
  price,
  frequencyBold,
  frequency,
  button
}) => (
  <div className={`planContainer ${title.toLowerCase()}`} key={title}>
    <div className="top">
      {image && <Image src={image} size="small" />}
      <div className="plan">{title}</div>

      <div
        className="features"
        dangerouslySetInnerHTML={{
          __html: features
        }}
      />
    </div>

    <div className="bottom">
      <div className="planPrice">
        {from && <span>{from}</span>}
        {showMonthly && (
          <MonthlyPrice
            currency={currency || 'EUR'}
            price={price}
            hidePerMonth={true}
          />
        )}
      </div>
      <div className="frequency">
        <span className="employee bold">{frequencyBold}</span>
        <span>{frequency}</span>
      </div>
      <div className="actionsContainer">{button}</div>
    </div>
  </div>
);

PlanCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  features: PropTypes.string,
  showMonthly: PropTypes.bool,
  from: PropTypes.string,
  currency: PropTypes.string,
  price: PropTypes.number,
  frequencyBold: PropTypes.string,
  frequency: PropTypes.string,
  button: PropTypes.element
};

export default PlanCard;
