import {
  getUserVehicles,
  getOpenedVehicleDetails
} from '../selectors/vehicles';

import { getDashboardLinkFor } from '../utils';
import serverRequest from '../utils/Api';
import i18n from '../utils/i18n';
import { URLs } from '../utils/urls';
import { getNameOfVclass, getVehicleClassOptions } from '../utils/vehicles';
import { getVehicleDrivers } from '../selectors/vehicles';
import history from '../utils/history';
import { getDistanceUnit } from '../selectors/locale';
import { requestTagslist } from './tags';
import { getMileageRates } from '../selectors/mileageRates';
import { requestUserMileageRates } from './mileageRates';

export const SET_VEHICLE_CLASSES = 'SET_VEHICLE_CLASSES';

export const SET_VEHICLE_YEAR_OPTIONS = 'SET_VEHICLE_YEAR_OPTIONS';
export const SET_VEHICLE_MAKE_OPTIONS = 'SET_VEHICLE_MAKE_OPTIONS';
export const SET_VEHICLE_MODEL_OPTIONS = 'SET_VEHICLE_MODEL_OPTIONS';
export const SET_VEHICLE_OPTIONS = 'SET_VEHICLE_OPTIONS';

export const SET_VEHICLE = 'SET_VEHICLE';
export const SET_USER_VEHICLES = 'SET_USER_VEHICLES';
export const SET_VEHICLES_MAP = 'SET_VEHICLES_MAP';
export const UPDATE_VEHICLE_ODOMETER = 'UPDATE_VEHICLE_ODOMETER';

const setVehicleClasses = json => ({
  type: SET_VEHICLE_CLASSES,
  json
});
const setVehicleYearOptions = json => ({
  type: SET_VEHICLE_YEAR_OPTIONS,
  json
});
const setVehicleMakeOptions = json => ({
  type: SET_VEHICLE_MAKE_OPTIONS,
  json
});
const setVehicleModelOptions = json => ({
  type: SET_VEHICLE_MODEL_OPTIONS,
  json
});
const setVehicleOptions = json => ({
  type: SET_VEHICLE_OPTIONS,
  json
});

export const setVehicle = json => ({
  type: SET_VEHICLE,
  json
});
const setUserVehicles = vehiclesList => {
  return {
    type: SET_USER_VEHICLES,
    vehiclesList
  };
};

export const setVehiclesMap = vehiclesMap => ({
  type: SET_VEHICLES_MAP,
  vehiclesMap
});

const updateVehicleOdometer = (vehicleId, odometer) => ({
  type: UPDATE_VEHICLE_ODOMETER,
  vehicleId,
  odometer
});

export const requestVehicleClasses = () => {
  return (dispatch, getState) => {
    const { method, url } = URLs.vehicles.getVehicleClasses();
    return serverRequest(method, url, getState()).then(json => {
      const { classes } = json;
      dispatch(setVehicleClasses({ classes: getVehicleClassOptions(classes) }));
    });
  };
};

export const requestVehicleYearOptions = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { method, url } = URLs.vehicles.getYears();
    return serverRequest(method, url, state).then(json =>
      dispatch(setVehicleYearOptions(json))
    );
  };
};
export const requestVehicleMakeOptions = year => {
  return (dispatch, getState) => {
    const state = getState();
    const { method, url } = URLs.vehicles.getMakes(year);
    return serverRequest(method, url, state).then(json =>
      dispatch(setVehicleMakeOptions(json))
    );
  };
};
export const requestVehicleModelOptions = (params = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const { method, url } = URLs.vehicles.getModels(params.make, params.year);
    return serverRequest(method, url, state).then(json =>
      dispatch(setVehicleModelOptions(json))
    );
  };
};
export const requestVehicles = (year, make, model) => (dispatch, getState) => {
  const state = getState();
  const { method, url } = URLs.vehicles.getVehicles(year, make, model);
  return serverRequest(method, url, state).then(json => {
    dispatch(setVehicleOptions(json.results));
  });
};

export const createUserVehicle = ({
  vClassId,
  country,
  license,
  designation,
  vehicle,
  mileageRates
}) => (dispatch, getState) => {
  const body = {
    license,
    designation,
    country,
    vclass: {
      id: parseInt(vClassId, 10)
    },
    vehicle
  };
  if (mileageRates) {
    body['mileage_rates'] = mileageRates.map(mr => ({ id: mr.id }));
  }

  const { method, url } = URLs.vehicles.createUserVehicle();
  serverRequest(method, url, getState(), {
    body
  }).then(resp => {
    if (resp.valid && resp.user_vehicle) {
      const state = getState();
      const existingVehicles = getUserVehicles(state);
      if (existingVehicles) {
        dispatch(setUserVehicles(existingVehicles.concat([resp.user_vehicle])));
      } else {
        dispatch(setUserVehicles([resp.user_vehicle]));
      }
    }
  });
};

export const updateVehicleDetails = ({
  vClassId,
  country,
  license,
  designation,
  year,
  make,
  model,
  id,
  vehicle
}) => (dispatch, getState) => {
  const state = getState();
  const vehicleDetails = getOpenedVehicleDetails(id, state);
  const body = (({ id, country, license }) => ({ id, country, license }))(
    vehicleDetails
  );
  body.designation = designation;

  if (vehicle) {
    body.vehicle = vehicle;
  } else if (year && make && model) {
    if (body.vehicle) {
      body.vehicle.year = year;
      body.vehicle.make = make;
      body.vehicle.model = model;
    } else {
      body.vehicle = {
        make,
        model,
        year
      };
    }
  }
  body.license = license;
  body.country = country;

  const { method, url } = URLs.vehicles.updateUserVehicle(id);
  serverRequest(method, url, state, {
    body
  }).then(resp => {
    if (resp.valid && resp.user_vehicle) {
      const existingVehicles = [...getUserVehicles(state)];
      const index = existingVehicles.findIndex(
        vehicleItem => vehicleItem.id === id
      );
      existingVehicles[index] = resp.user_vehicle;
      dispatch(setUserVehicles(existingVehicles));
    }
  });
};

export const updateVehicleImage = (id, image) => (dispatch, getState) => {
  const state = getState();
  // const vehicleDetails = getOpenedVehicleDetails(id, state);

  const body = { image: image };
  const { method, url } = URLs.vehicles.updateUserVehicle(id);
  serverRequest(method, url, state, { body }).then(resp => {
    if (resp.valid && resp.user_vehicle) {
      const existingVehicles = [...getUserVehicles(state)];
      const index = existingVehicles.findIndex(vehicle => vehicle.id === id);
      existingVehicles[index] = resp.user_vehicle;
      dispatch(setUserVehicles(existingVehicles));
    }
  });
};

export const deleteVehicle = (vehicleId, showListLater = true) => (
  dispatch,
  getState
) => {
  const state = getState();
  const body = { hidden: true }; // vehicles are always only marked hidden and not deleted

  const { method, url } = URLs.vehicles.updateUserVehicle(vehicleId);
  serverRequest(method, url, state, { body }).then(() => {
    if (showListLater) {
      history.push(getDashboardLinkFor('vehicles'));
    }
    const vehiclesList = getUserVehicles(state);
    dispatch(
      setUserVehicles(vehiclesList.filter(vehicle => vehicle.id !== vehicleId))
    );
  });
};

export const deleteVehicles = (state, vehicles) => {
  const ids = vehicles;
  const { method, url } = URLs.vehicles.updateUserVehicle(ids);
  const body = { hidden: true };

  return serverRequest(method, url, state, { body });
};

const createVehicleMap = vehicles => dispatch => {
  const vehicleMapped = vehicles.reduce((acc, vehicle) => {
    const vehicleNames = [];
    vehicleNames.push(vehicle.license);
    if (vehicle.vehicle) {
      vehicleNames.push(vehicle.vehicle.make || null);
      vehicleNames.push(vehicle.vehicle.model || null);
    } else {
      vehicleNames.concat([null, null]);
    }
    vehicleNames.push(
      getNameOfVclass((vehicle.vclass && vehicle.vclass.id) || null)
    );
    vehicleNames.push(i18n.t(`travelModes.${vehicle.travel_mode}`));

    acc[vehicle.id] = vehicleNames;

    return acc;
  }, {});
  dispatch(setVehiclesMap(vehicleMapped));
};

export const updateUserVehicles = vehicles => dispatch => {
  dispatch(setUserVehicles(vehicles));
  dispatch(createVehicleMap(vehicles));
};

export const requestUserVehicles = (hidden = false) => (dispatch, getState) => {
  const { method, url } = URLs.vehicles.getUserVehicles(hidden);
  serverRequest(method, url, getState()).then(json => {
    json.vehicles &&
      json.vehicles.length &&
      dispatch(updateUserVehicles(json.vehicles));
    dispatch(requestTagslist());
  });
};

export const addOdometerValue = (vehicleId, data) => (dispatch, getState) => {
  const { date, odometer } = data;
  const { url, method } = URLs.odometer.addOdometerValue(vehicleId);
  const distanceUnit = getDistanceUnit(getState());
  const metersDevider = distanceUnit === 'mi' ? 0.000621371192 : 0.001;
  const body = {
    date: Math.round(date.getTime() / 1000),
    value: Math.round(+odometer / metersDevider)
  };
  serverRequest(method, url, getState(), { body }).then(resp => {
    if (resp.user_vehicle) {
      dispatch(updateVehicleOdometer(vehicleId, resp.user_vehicle.odometer));
    }
  });
};
export const deleteOdometerValue = (vehicId, odoId) => (dispatch, getState) => {
  const { url, method } = URLs.odometer.deleteOdometerValue(vehicId, odoId);
  serverRequest(method, url, getState()).then(resp => {
    if (resp.user_vehicle) {
      dispatch(updateVehicleOdometer(vehicId, resp.user_vehicle.odometer));
    }
  });
};

export const removeMileageRateFromVehicle = (vehicleId, mileageRateId) => (
  dispatch,
  getState
) => {
  const state = getState();
  const mileageRates = getMileageRates(state);
  const mileageRateToUpdate = mileageRates.find(
    mileageRate => mileageRate.id === mileageRateId
  );
  if (mileageRateToUpdate) {
    const body = {
      mileage_rate: Object.assign({}, mileageRateToUpdate, {
        vehicle_ids: mileageRateToUpdate.vehicle_ids.filter(
          id => id !== vehicleId
        )
      }),
      user_vehicles: {
        ids: mileageRateToUpdate.vehicle_ids.filter(id => id !== vehicleId)
      }
    };
    const { url, method } = URLs.mileageRates.updateMileageRate(mileageRateId);
    serverRequest(method, url, state, { body }).then(
      resp => {
        if (resp.valid) {
          dispatch(requestUserVehicles());
          dispatch(requestUserMileageRates());
          // invalidate trips list after mileage rate removed from vehicle
          state.listNew.trips.isInvalid = true;
        }
      },
      ({ err, statusCode }) => {
        dispatch(requestUserVehicles());
        dispatch(requestUserMileageRates());
      }
    );
  }
};

export const removeDriverFromVehicle = (vehicleId, driverId) => (
  dispatch,
  getState
) => {
  const state = getState();
  const driversToKeep = getVehicleDrivers(vehicleId, state).filter(
    driver => driver.id !== driverId
  );
  if (driversToKeep) {
    const body = {
      drivers: driversToKeep.map(driver => driver.id).join(',')
    };
    const { url, method } = URLs.vehicles.updateUserVehicle(vehicleId);
    serverRequest(method, url, state, { body }).then(resp => {
      if (resp.valid) {
        dispatch(requestUserVehicles());
      }
    });
  }
};

export const setVehicleDrivers = (vehicleId, driverIds) => (
  dispatch,
  getState
) => {
  const state = getState();
  const body = {
    drivers: driverIds.join(',')
  };
  const { url, method } = URLs.vehicles.updateUserVehicle(vehicleId);
  serverRequest(method, url, state, { body }).then(resp => {
    if (resp.valid) {
      dispatch(requestUserVehicles());
    }
  });
};
