import { isEmpty } from 'lodash';
import { getUserFullName } from '../utils';

export const getUserId = state => state.user.id;

export const getUserToken = state => state.user.token;

export const getUserInfo = state => state.user.details || {};

export const getIsMobile = state => state.user.isMobile;
export const getIsTablet = state => state.user.isTablet;
export const getWrongPassword = state => state.user.wrongPassword;

export const getUserLanguage = state => {
  const { language } = getUserInfo(state);
  return language;
};

export const getUserCountry = state => {
  const { country } = getUserInfo(state);
  return country;
};

export const getUserCurrency = state => {
  return state.user.currency;
};

export const getActiveUserSubscription = state => {
  const { subscription = {} } = getUserInfo(state);
  return subscription;
};

export const getPendingUserSubscription = state => {
  const { pending_subscription: pendingSubscription } = getUserInfo(state);
  return pendingSubscription || {};
};

export const getTeamSize = state => {
  const { subscription = {} } = getUserInfo(state);
  const {
    company: { employee_count }
  } = state;

  if (
    employee_count != undefined &&
    subscription.default_employee_count != undefined
  ) {
    return Math.max(employee_count, subscription.default_employee_count);
  } else {
    return 0;
  }
};

export const getSubscriptionTypeAsPro = state => {
  const { type } = getActiveUserSubscription(state);
  if (type === 'braintree' || type === 'apple_iap') return 'pro';
  return type;
};

export const getIsCompanyOwner = state => {
  return getUserInfo(state).is_company_owner;
};

export const getHasBeacons = state => {
  return getUserInfo(state).beacon_count > 0;
};

export const isUserLoggedIn = state => {
  const { id, token } = state.user;
  return Boolean(String(id) && token);
};

export const getAccountPendingActions = state => {
  const { first_name, last_name, confirmed } = getUserInfo(state);
  let pendingActions = 0;
  if (!(first_name && last_name)) pendingActions += 1;
  if (!confirmed) pendingActions += 1;

  return pendingActions;
};

export const getParsedUserDetails = state => {
  const {
    confirmed: isEmailConfirmed,
    first_name: firstName,
    last_name: lastName,
    email: userEmail,
    image: profileImageURL,
    beacon_count: beaconCount,
    has_user_customer: hasCustomer,
    trips_left: tripsLeft
  } = getUserInfo(state);

  const { name: companyName } = state.company;

  return {
    isEmailConfirmed,
    firstName,
    lastName,
    userEmail,
    profileImageURL,
    companyName,
    beaconCount,
    hasCustomer,
    tripsLeft
  };
};

export const getProfileMenuHeader = state => {
  const user = getUserInfo(state);
  const fullName = getUserFullName(user);
  return fullName || user.email;
};
// TODO: rename this function
export const getUserBasicInfo = state => {
  const {
    id,
    details: {
      first_name,
      last_name,
      country,
      language,
      email,
      subscription: { type = 'free' } = {}
    }
  } = state.user;
  return {
    id,
    first_name,
    last_name,
    country,
    language,
    email,
    subscriptionType: type
  };
};

export const getUserEmailExists = state => state.user.emailExists;
export const shouldAskToMigrateIAPSubscription = state => {
  const {
    subscription: { type, status, cancellation_date: cancellationDate },
    pending_subscription: pendingSubscription
  } = getUserInfo(state);
  if (
    status.toLowerCase() === 'active' &&
    !cancellationDate &&
    type === 'apple_iap' &&
    !pendingSubscription
  ) {
    return true;
  }
  return false;
};
