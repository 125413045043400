import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { currencySymbol } from '../../selectors/locale';

const MonthlyPrice = ({
  currency,
  price,
  perMonth,
  hidePerMonth,
  t,
  green,
  red,
  showDecimal,
  forPayForm
}) => (
  <div className={`priceContainer ${green ? `green` : ``} ${red ? `red` : ``}`}>
    <span className="currency">
      {forPayForm && currency === 'USD'
        ? `US ${currencySymbol(currency)}`
        : currencySymbol(currency)}
    </span>
    <span className="price">{Math.floor(price)}</span>
    {(Math.floor(price) != price || showDecimal) && (
      <Fragment>
        <span className="decimalPoint">,</span>
        <span className="decimal">
          {`${Math.round(100 * (price % 1)) < 10 ? `0` : ``}${Math.round(
            100 * (price % 1)
          )}`}
        </span>
      </Fragment>
    )}
    {!hidePerMonth && (
      <span className="perMonth">
        {perMonth ? t(`time.perMo`) : t('time.perYear')}
      </span>
    )}
  </div>
);

MonthlyPrice.propTypes = {
  currency: PropTypes.string,
  price: PropTypes.number,
  perMonth: PropTypes.bool,
  hidePerMonth: PropTypes.bool,
  t: PropTypes.func,
  green: PropTypes.bool,
  red: PropTypes.bool,
  showDecimal: PropTypes.bool,
  forPayForm: PropTypes.bool
};

export default translate()(MonthlyPrice);
