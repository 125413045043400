import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';

import { setLocal, checkNewDashboard } from '../selectors/locale';
import { isUserLoggedIn } from '../selectors/user';

import {
  isEmpty,
  getLanguageFromLocal,
  getDashboardLinkFor,
  getPublicLinkFor
} from '../utils';
import history from '../utils/history';
import i18n from '../utils/i18n';
import serverRequest from '../utils/Api';
import { languageOptions } from '../utils/constants';

import {
  deleteListGroup,
  setActiveList,
  closeItem,
  setFilterSearchText,
  listDeselectAll,
  setOpenedReportId
} from './list';
import { updateStep } from './trips';
import {
  getCountryByIp,
  setKindOfDeviceUsingUserAgent,
  setTokenAndUserCookies,
  signOutCurrentUser
} from './login';
import { loadPreferencesFromLocal, getUserDetails } from './user';
import { searchReports, deactivateFlagMode } from './team/reports';
import { loadCookieconsent } from '../utils/cookieconsent';
import { initSmooch } from '../utils/smooch';
import { getActiveListGroup } from '../selectors/list';
import { URLs } from '../utils/urls';
import moment from 'moment';

export const USER_ERROR = 'USER_ERROR';
export const DELETE_FILTERS = 'DELETE_FILTERS';
export const SELECT_PAGE = 'SELECT_PAGE';
export const HELP_PAGE = 'HELP_PAGE';
export const INVALIDATE_PAGE = 'INVALIDATE_PAGE';
export const SET_FILTER = 'SET_FILTER';
export const CHANGE_FILTERS = 'CHANGE_FILTERS';
export const RESET_TRIPS_FILTERS = 'RESET_TRIPS_FILTERS';
export const SELECT_DATE_FROM = 'SELECT_DATE_FROM';
export const SELECT_DATE_TO = 'SELECT_DATE_TO';
export const RESET_DATE_FILTER = 'RESET_DATE_FILTER';
export const RESET_TAGGED_FILTER = 'RESET_TAGGED_FILTER';
export const SHOW_UNCLASSIFIED_TRIPS = 'SHOW_UNCLASSIFIED_TRIPS';
export const SET_NEXT_PAGE_TO_BE = 'SET_NEXT_PAGE_TO_BE';
export const SET_COUNTRY_BY_IP = 'SET_COUNTRY_BY_IP';
export const SET_APP_LANGUAGE = 'SET_APP_LANGUAGE';
export const SET_APP_STATS = 'SET_APP_STATS';
export const SET_IS_APP = 'SET_IS_APP';
export const SET_PROMOTIONS = 'SET_PROMOTIONS';
export const TOGGLE_IS_SMOOOCH_INITIALIZED = 'TOGGLE_IS_SMOOOCH_INITIALIZED';
export const RESET_LIST_FILTERS = 'RESET_LIST_FILTERS';
export const SET_USER_GEO_BY_IP = 'SET_USER_GEO_BY_IP';

export const userError = error => ({
  type: USER_ERROR,
  error
});
const deleteFilters = () => ({
  type: DELETE_FILTERS
});
export const selectPage = page => ({
  type: SELECT_PAGE,
  page
});
export const helpPage = page => ({
  type: HELP_PAGE,
  page
});
export const invalidatePage = page => ({
  type: INVALIDATE_PAGE,
  page
});
export const setFilter = ({ key, value }) => ({
  type: SET_FILTER,
  key,
  value
});
export const changeFilters = (filters, listType) => ({
  type: CHANGE_FILTERS,
  listType,
  filters
});
export const resetTripsFilters = () => ({
  type: RESET_TRIPS_FILTERS
});
export const resetListFilters = listType => ({
  type: RESET_LIST_FILTERS,
  listType
});
export const resetTaggedFilter = () => ({
  type: RESET_TAGGED_FILTER
});
export const resetDateFilter = () => ({
  type: RESET_DATE_FILTER
});
export const selectDateFrom = from => ({
  type: SELECT_DATE_FROM,
  from
});
export const selectDateTo = to => ({
  type: SELECT_DATE_TO,
  to
});
export const setNextPageToBe = nextPage => ({
  type: SET_NEXT_PAGE_TO_BE,
  nextPage
});

export const setCountryByIp = countryByIp => ({
  type: SET_COUNTRY_BY_IP,
  countryByIp
});
export const setAppLanguage = appLanguage => ({
  type: SET_APP_LANGUAGE,
  appLanguage
});
export const setAppStats = stats => ({
  type: SET_APP_STATS,
  stats
});
export const setIsApp = () => ({
  type: SET_IS_APP
});
export const toggleIsSmoochInitialized = (isInitialized = false) => ({
  type: TOGGLE_IS_SMOOOCH_INITIALIZED,
  isInitialized
});
export const setPromotions = promotions => ({
  type: SET_PROMOTIONS,
  promotions
});

export const setAppLanguageAction = appLanguage => dispatch => {
  dispatch(setAppLanguage(appLanguage));
  i18n.changeLanguage(appLanguage);
  setLocal('appLanguage', appLanguage);
};

export const showTripsFilteredByTags = (listType, filterTag) => (
  dispatch,
  getState
) => {
  const { filters: { tags } = {} } = getState().listNew[listType] || {};
  dispatch(setActiveList(listType));
  dispatch(listDeselectAll(listType));
  const tagValue = filterTag === i18n.t('list.untagged') ? [''] : [];

  if (filterTag === i18n.t('list.untagged')) {
    dispatch(deleteFilters());
    dispatch(setFilter({ key: 'tagged', value: false }));
    if (!_isEmpty(tags)) {
      dispatch(setFilter({ key: 'tags', value: tagValue }));
    }
  } else if (!filterTag) {
    dispatch(deleteFilters());
  }
  dispatch(setFilterSearchText(filterTag, listType));

  history.push(getDashboardLinkFor('trips', { for: listType }));
};
export const showReportsFilteredByStatus = (
  listType,
  filterStatus
) => dispatch => {
  dispatch(setActiveList(listType));
  dispatch(searchReports(filterStatus, listType));
  history.push(getDashboardLinkFor('reports', { for: listType }));
};

export const changeDateRange = ({ start, end }) => {
  return (dispatch, getState) => {
    const { activeList } = getState().listNew;

    dispatch(listDeselectAll(activeList));
    dispatch(
      changeFilters({
        start_date: start,
        end_date: end
      })
    );
  };
};
export const deleteDateRange = () => {
  return (dispatch, getState) => {
    const { activeList } = getState().listNew;

    dispatch(listDeselectAll(activeList));
    dispatch(
      changeFilters({
        start_date: '',
        end_date: ''
      })
    );
    dispatch(selectDateFrom(''));
    dispatch(selectDateTo(''));
  };
};

export const setAllFilters = filtersObj => dispatch => {
  const filtersToBe = {};
  if (filtersObj.start_date) {
    filtersToBe.start_date = new Date(filtersObj.start_date * 1000);
    filtersToBe.end_date = new Date(filtersObj.end_date * 1000);
    dispatch(selectDateFrom(''));
    dispatch(selectDateTo(''));
  }
  if (filtersObj.status) {
    filtersToBe.status = filtersObj.status;
  }
  if (filtersObj.tags) {
    filtersToBe.tags = filtersObj.tags.split(',');
  }
  if (filtersObj.tagged) {
    filtersToBe.tags = [''];
  }

  dispatch(changeFilters(filtersToBe));
  dispatch(deleteListGroup());
};

export const clearFilters = () => {
  return dispatch => {
    dispatch(deleteFilters());
  };
};

export const setFiltersFromSearchText = (filters, hasTeams, listType) => (
  dispatch,
  getState
) => {
  const activeListGroup = getActiveListGroup(getState());
  const { filters: activeListGroupFilters = {} } = activeListGroup;
  const filtersToBe = {
    text: filters.text.trim(),
    start_date: activeListGroupFilters.start_date,
    end_date: activeListGroupFilters.end_date
  };
  if (filters.tagged === false) {
    filtersToBe.tags = [''];
    filtersToBe.tagged = false;
  } else if (filters.tagged) {
    filtersToBe.tags = filters.tags;
    filtersToBe.tagged = true;
  } else {
    filtersToBe.tags = [];
  }
  filtersToBe.vehicles = filters.vehicles;
  filtersToBe.status = activeListGroupFilters.status;
  if (hasTeams) {
    filtersToBe.approvalStatus =
      filters.approvalStatus || activeListGroupFilters.approvalStatus;
  }
  dispatch(changeFilters(filtersToBe, listType));
};

export const setStepTravelMode = (value, step) => {
  return (dispatch, getState) => {
    const state = getState();
    const body = {
      step: { travel_mode: value, vehicle: value }
    };
    step.travel_mode = value;
    updateStep(dispatch, state, step, body);
  };
};
export const setStepVehicle = (value, step) => {
  return (dispatch, getState) => {
    const state = getState();
    const body = {
      step: { travel_mode: 'DRIVING', vehicle: value }
    };
    step.travel_mode = 'DRIVING';
    if (step.user_vehicle) {
      step.user_vehicle.id = value;
    }
    updateStep(dispatch, state, step, body);
  };
};

export const closeTrip = () => {
  return dispatch => {
    dispatch(closeItem());
  };
};

export const closeReportTrip = () => {
  return dispatch => {
    dispatch(deactivateFlagMode());
    dispatch(listDeselectAll('reportTrips'));
    dispatch(deleteListGroup('reportTrips'));
    dispatch(setOpenedReportId(null, 'reportTrips'));
    dispatch(closeItem('reports'));
  };
};
export const closeAutomaticReport = () => closeItem();

export const getUserCountryByIp = () => (dispatch, getState) => {
  getCountryByIp().then(
    ({ country, languages, latitude: lat, longitude: lng }) => {
      dispatch(setCountryByIp(country));
      dispatch(setUserGeoByIp({ lat, lng }));
      const userAlreadyLoggedIn = isUserLoggedIn(getState());
      if (!userAlreadyLoggedIn) {
        const languageFromLocal = getLanguageFromLocal();
        // TODO: this is a temporary fix
        const languagesSupported = ['en', 'es', 'fr', 'de', 'it', 'nl'];
        let languageToSet = '';
        if (languageFromLocal && languageFromLocal.trim()) {
          if (languagesSupported.includes(languageFromLocal.toLowerCase())) {
            languageToSet = languageFromLocal.toLowerCase();
          }
        } else {
          const languagesArray = languages
            .split(`,`)
            .map(el => el.split('-')[0]);
          languageToSet = languagesArray.find(el =>
            languagesSupported.includes(el)
          );
        }
        if (!languageToSet) languageToSet = 'en';
        dispatch(setAppLanguageAction(languageToSet));
      } else {
        dispatch(getUserDetails());
      }
    }
  );
};

export const loadApp = () => dispatch => {
  const { search, pathname } = history.location;

  checkNewDashboard();

  const queryStringObj = qs.parse(search.slice(1));
  if (queryStringObj && !isEmpty(queryStringObj)) {
    const { action, key, uid, app, lang } = queryStringObj;
    if (action && action === 'invite') {
      dispatch(signOutCurrentUser());
      history.push(
        getPublicLinkFor('login', {
          'invitation-code': queryStringObj['invitation-code']
        })
      );
    } else if (key && uid) {
      dispatch(setTokenAndUserCookies(key, uid));
      dispatch(setNextPageToBe(`${pathname}${search}`));
    }
    if (app && app === '1') {
      dispatch(setIsApp());
    } else {
      loadCookieconsent();
    }

    if (lang) {
      const langToSelect = languageOptions.find(
        langObj => langObj.value === lang.toLowerCase()
      );
      if (langToSelect) {
        dispatch(setAppLanguageAction(lang.toLowerCase()));
      }
    }
  } else {
    loadCookieconsent();
  }

  dispatch(loadPreferencesFromLocal());
  dispatch(setKindOfDeviceUsingUserAgent());
  dispatch(getUserCountryByIp());
};

export const fetchAppStats = () => (dispatch, getState) => {
  const { method, url } = URLs.common.getAppStats(
    moment()
      .subtract(1, 'months')
      .unix(),
    moment().unix()
  );
  return serverRequest(method, url, getState(), {
    withoutApiVersion: true
  }).then(resp => {
    dispatch(setAppStats(resp.stats));
  });
};

export const initializeSmooch = userInfo => dispatch => {
  initSmooch(userInfo).then(() => {
    dispatch(toggleIsSmoochInitialized(true));
  });
};

export const setUserGeoByIp = data => ({
  type: SET_USER_GEO_BY_IP,
  payload: data
});
