// Taxamo documentation:
// https://www.taxamo.com/apidocs/docs.html#top

/* Some notes on the flow
  * Data:
  * From our Api, we get customer data, user data, plans and discounted plans
  * Taxamo is used to formalize plans into transactions and calculate taxes
  * From Braintree, we get new payment methods

  * Display:
  * For our displayed data we use the data from our api and let taxamo calculate the taxes

  * Actually starting a new subscription:
  * We get a new token (nonce) from braintree or take an already existing one from the default payment method
  * Then we save a transaction with that token with Taxamo
  * Lastly we create (or update) our customer

  * This file serves to abstract Taxamo and Braintree so that we can call it's methods in the same way
  * that we would use serverRequests.
*/
import { calculateBeaconPrice } from '../utils';
import i18n from '../utils/i18n';

export const loadTaxamo = () => {
  const taxScript = document.createElement('script');
  taxScript.src = 'https://api.taxamo.com/js/v1/taxamo.all.js';
  taxScript.async = true;
  document.body.appendChild(taxScript);
};

// TODO: these variables should go to redux
const errors = {
  TAXAMO_NOT_READY: 'Taxamo is not ready yet',
  NO_PLANS: 'No plans',
  NO_PRODUCTS: 'No products',
  NO_TRANSACTION: 'No transaction received'
};

export const initiateTaxamo = () => {
  return new Promise(resolve => {
    const { Taxamo } = window;
    if (typeof Taxamo === 'undefined') {
      setTimeout(() => initiateTaxamo().then(() => resolve()), 1000);
    } else {
      Taxamo.initialize(process.env.REACT_APP_taxamo_token);
      Taxamo.options.hideCountryOverlay = true;
      resolve();
    }
  });
};

export const getTransactionMockup = (
  options,
  calculateFor = 'subscription'
) => {
  const { Taxamo } = window;
  const {
    email,
    taxId,
    currency,
    userDeclaredCountry,
    plan,
    product,
    quantity,
    shippingMethod
  } = options;
  let transactionMockup = Taxamo.transaction()
    .buyerEmail(email)
    .currencyCode(currency || 'USD');
  if (userDeclaredCountry) {
    transactionMockup = transactionMockup.setField('evidence', {
      self_declaration: {
        evidence_value: userDeclaredCountry
      }
    });
    transactionMockup = transactionMockup.buyerCountryCode(userDeclaredCountry);
  }

  if (calculateFor === 'product') {
    transactionMockup = transactionMockup
      .transactionLine(product.product_type)
      .quantity(quantity)
      .description(product.name)
      // .comment(product.description)
      .productCode(product.id.toString())
      .productType(product.currency);

    const productPrice = parseFloat(
      calculateBeaconPrice(product.price, quantity, product.discounts)
    );
    // R.R. For products (beacons) we always charge by total amount incl. tax.
    // if (
    //   !isEUCountry ||
    //   (isEUCountry && !taxId) ||
    //   userDeclaredCountry === 'NL'
    // ) {
    transactionMockup = transactionMockup.totalAmount(productPrice);
    // } else {
    //   transactionMockup = transactionMockup.amount(productPrice);
    // }

    if (shippingMethod) {
      transactionMockup = transactionMockup
        .transactionLine(shippingMethod.product_type)
        .taxRate(0)
        .totalAmount(parseFloat(shippingMethod.price))
        .description(shippingMethod.name)
        // .comment(shippingMethod.description)
        .productCode(shippingMethod.id.toString())
        .productType(shippingMethod.currency)
        .setField('informative', true);
    }
  } else {
    /**
     * R.R. buyer tax ID (VAT number) is excluded from product transactions
     * because our product price always includes VAT */

    if (taxId) {
      transactionMockup = transactionMockup.buyerTaxNumber(taxId);
    }
    let planDescription = 'Psngr Subscription';
    let addOns = [];
    if (plan.plan_type === 'team') {
      planDescription = i18n.t(`billing.planDescription.monthly_team`, {
        planName: plan.name
      });
      addOns = plan.add_ons;
    } else {
      const idParts = plan.id.split('_');
      idParts.splice(idParts.length - 1, 1);
      planDescription = i18n.t(`billing.planDescription.${idParts.join('_')}`);
    }
    if (addOns.length > 0 && addOns[0].quantity > 0) {
      // create two transaction lines, one for the base plan
      // and the other for the add-ons
      transactionMockup = transactionMockup
        .transactionLine(plan.name)
        .amount(parseFloat(plan.base_price))
        .description(planDescription)
        .productCode(plan.id)
        .productType(plan.currency);

      addOns.forEach(addon => {
        transactionMockup = transactionMockup
          .transactionLine(addon.name)
          .unitPrice(addon.amount / addon.quantity)
          .quantity(addon.quantity)
          // .amount(parseFloat(addon.amount))
          .description(addon.quantity + ' x ' + addon.name)
          .productCode(addon.id)
          .productType(plan.currency);
      });
    } else {
      transactionMockup = transactionMockup
        .transactionLine(plan.name)
        .amount(parseFloat(plan.base_price))
        .description(planDescription)
        .productCode(plan.id)
        .productType(plan.currency);
      if (plan.discount) {
        // add transaction line for the discount
        transactionMockup = transactionMockup
          .transactionLine(plan.discount.name)
          .amount(parseFloat(-plan.discount.amount))
          .description(plan.discount.description)
          .productCode(plan.discount.id)
          .productType(plan.currency);
      }
    }
  }

  return transactionMockup.done();
};

export const calculateTransaction = (
  options,
  calculateFor = 'subscription'
) => {
  return new Promise((resolve, reject) => {
    const { Taxamo } = window;
    if (!Taxamo) {
      reject(errors.TAXAMO_NOT_READY);
      return;
    }

    if (calculateFor === 'product') {
      if (!options.product) {
        reject(errors.NO_PRODUCTS);
        return;
      }
    } else if (calculateFor === 'subscription') {
      if (!options.plan) {
        reject(errors.NO_PLAN);
        return;
      }
    }

    const transactionMockup = getTransactionMockup(options, calculateFor);

    Taxamo.calculateTax(
      transactionMockup,
      data => {
        data.transaction
          ? resolve(data.transaction)
          : reject(errors.NO_TRANSACTION);
      },
      data => {
        // TODO: what would happen if this fails; what does 'data' contain
        console.error('Failed to calculate tax:', data);
        data.countries ? resolve(data) : reject(data);
      }
    );
  });
};

// only at this point will the transaction be saved as an official transaction.
export const storeTransactionTaxamo = transaction => {
  return new Promise((resolve, reject) => {
    const { Taxamo } = window;
    if (!Taxamo) {
      reject('errors.TAXAMO_NOT_READY');
      return;
    }
    Taxamo.storeTransaction(
      transaction,
      data => {
        const { transaction: { key } = {} } = data;
        key ? resolve(key) : reject('No transaction key');
      },
      err => {
        console.error('Failed to store transaction: ', err);
      }
    );
  });
};
