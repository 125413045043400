import React from 'react';
import { Grid } from 'semantic-ui-react';
import { FinishSVG, StartSVG } from '../images/marker-svg';
import Time from '../components/Time';
import { Distance } from '../components/Units';
import TravelMode from '../components/TravelMode';
import { isEmpty } from './index';
import i18n from '../utils/i18n';
import {
  TRANSIT_TYPES,
  TRAVEl_MODE_ICON_COLORS,
  TRAVEl_MODES
} from './constants';

import DrivingIcon from '../images/icons/icon-driving.png';
import DrivingMediumIconBlack from '../images/icons/icon_car_medium_black.png';
import DrivingMediumIconRed from '../images/icons/icon_car_medium_red.png';
import DrivingMediumIconGray from '../images/icons/icon_car_medium_gray.png';

import CyclingIcon from '../images/icons/cycling-color.png';
import CyclingMediumIconBlack from '../images/icons/icon_bike_medium_black.png';
import CyclingMediumIconGray from '../images/icons/icon_bike_medium_gray.png';
import CyclingMediumIconYellow from '../images/icons/icon_bike_medium_yellow.png';

import WalkingIcon from '../images/icons/walking-color.png';
import WalkingMediumIconBlack from '../images/icons/icon_man_medium_black.png';
import WalkingMediumIconGray from '../images/icons/icon_man_medium_gary.png';
import WalkingMediumIconDarkGray from '../images/icons/icon_man_medium_dark_gray.png';

import TransitIcon from '../images/icons/bus-color.png';
import TransitMediumIconBlack from '../images/icons/icon_bus_medium_black.png';
import TransitMediumIconGray from '../images/icons/icon_bus_medium_gray.png';
import TransitMediumIconBlue from '../images/icons/icon_bus_medium_blue.png';

import FlyingIcon from '../images/icons/flying-color.png';
import CurrentLoc from '../images/icons/current-location-dot.png';

import get from 'lodash/get';
import moment from 'moment';
import { dateTimeNow } from './date';

// import RailIcon from '../images/icons/icon_rail_blue.png';
// import RailIconGray from '../images/icons/icon_rail_gray.png';
import TrainIcon from '../images/icons/icon_train_blue.png';
import TrainIconGray from '../images/icons/icon_train_gray.png';
import TramIcon from '../images/icons/icon_tram_blue.png';
import TramIconGray from '../images/icons/icon_tram_gray.png';
import SubwayIcon from '../images/icons/icon_subway_blue.png';
import SubwayIconGray from '../images/icons/icon_subway_gray.png';

import { decode, encode } from '@googlemaps/polyline-codec';

const getTravelModeImg = ({
  travelMode,
  isBig = true,
  color = TRAVEl_MODE_ICON_COLORS.default
}) => {
  const { FLYING, BICYCLING, DRIVING, TRANSIT, WALKING } = TRAVEl_MODES;
  const { SUBWAY, TRAM, TRAIN, RAIL, HEAVY_RAIL, BUS } = TRANSIT_TYPES;

  switch (travelMode) {
    case BUS:
    case TRANSIT:
      if (color === TRAVEl_MODE_ICON_COLORS.black) {
        return TransitMediumIconBlack;
      } else if (color === TRAVEl_MODE_ICON_COLORS.gray) {
        return TransitMediumIconGray;
      } else {
        return isBig ? TransitIcon : TransitMediumIconBlue;
      }
    case SUBWAY:
      if (color === TRAVEl_MODE_ICON_COLORS.gray) {
        return SubwayIconGray;
      } else {
        return SubwayIcon;
      }
    case TRAM:
      if (color === TRAVEl_MODE_ICON_COLORS.gray) {
        return TramIconGray;
      } else {
        return TramIcon;
      }
    case TRAIN:
    case RAIL:
    case HEAVY_RAIL:
      if (color === TRAVEl_MODE_ICON_COLORS.gray) {
        return TrainIconGray;
      } else {
        return TrainIcon;
      }
    case BICYCLING:
      if (color === TRAVEl_MODE_ICON_COLORS.black) {
        return CyclingMediumIconBlack;
      } else if (color === TRAVEl_MODE_ICON_COLORS.gray) {
        return CyclingMediumIconGray;
      } else {
        return isBig ? CyclingIcon : CyclingMediumIconYellow;
      }
    case FLYING:
      return FlyingIcon;
    case WALKING:
      if (color === TRAVEl_MODE_ICON_COLORS.black) {
        return WalkingMediumIconBlack;
      } else if (color === TRAVEl_MODE_ICON_COLORS.gray) {
        return WalkingMediumIconGray;
      } else {
        return isBig ? WalkingIcon : WalkingMediumIconDarkGray;
      }
    case DRIVING:
    default:
      if (color === TRAVEl_MODE_ICON_COLORS.black) {
        return DrivingMediumIconBlack;
      } else if (color === TRAVEl_MODE_ICON_COLORS.gray) {
        return DrivingMediumIconGray;
      } else {
        return isBig ? DrivingIcon : DrivingMediumIconRed;
      }
  }
};

const formatSteps = (trips, steps, travelMode) => {
  const list = [];
  const firstTrip = trips[0];
  const lastTrip = trips.slice(-1)[0];
  for (let i = 0; i <= steps.length - 1; i++) {
    const step = steps[i];
    const dep = step.departure_stop;
    const arrival = step.arrival_stop;
    list.push({
      type: 'node',
      time: dep.departure_time * 1000,
      location: dep.location,
      name: dep.place ? dep.place.name : dep.address ? dep.address : null,
      class: i === 0 ? 'appTheme' : step.travel_mode,
      icon:
        i === 0 ||
        (travelMode &&
          travelMode !== TRAVEl_MODES.TRANSIT &&
          !isEmpty(firstTrip.origin))
          ? 'point'
          : 'square outline',
      typeNode: 'start'
    });
    list.push({
      type: 'line',
      duration: step.moving_time * 1000,
      distance: step.moving_distance,
      class: step.travel_mode,
      step,
      polyline: step.polyline
    });
    if (i < steps.length - 1) {
      list.push({
        type: 'node',
        time: arrival.arrival_time * 1000,
        location: arrival.location,
        name: arrival.place
          ? arrival.place.name
          : arrival.address
          ? arrival.address
          : null,
        class: step.travel_mode,
        icon: 'square outline',
        typeNode: 'end'
      });
      list.push({
        type: 'small_line'
      });
    } else if (i == steps.length - 1 && lastTrip.status == 'active') {
      let pline = decode(step.polyline.points);
      const raw_location = pline[pline.length - 1];
      list.push({
        type: 'node',
        time: lastTrip.last_updated * 1000,
        location: { lat: raw_location[0], lng: raw_location[1] },
        name: i18n.t('trip.active.currentLocation'),
        class: 'currentLocation',
        icon: 'point',
        typeNode: 'current'
      });
    }
  }
  !isEmpty(lastTrip.destination) &&
    list.push({
      type: 'node',
      time: lastTrip.arrival_time * 1000,
      location: lastTrip.destination ? lastTrip.destination.location : 0,
      name: lastTrip.destination ? lastTrip.destination.name : '',
      class: 'red',
      icon: 'flag checkered'
    });
  return list;
};

const getTravelModePinImgPath = type => {
  const { FLYING, BICYCLING, DRIVING, TRANSIT, WALKING } = TRAVEl_MODES;

  if (type == null) {
    return '/images/pin-via-walking.svg';
  }

  switch (type) {
    case DRIVING:
      return '/images/pin-via-driving.svg';
    case BICYCLING:
      return '/images/pin-via-biking.svg';
    case FLYING:
      return '/images/pin-via-flying.svg';
    case TRANSIT:
      return '/images/pin-via-transit.svg';
    case WALKING:
    default:
      return '/images/pin-via-walking.svg';
  }
};

const stepNode = (time, place, travelMode, icon, typeNode, key) => {
  const mainIcons = icon === 'point' || icon === 'flag checkered';
  const travelModeFirstPic = getTravelModePinImgPath(travelMode);
  let classOfNode = typeNode;
  if (mainIcons) classOfNode = 'main';
  return (
    <Grid.Row columns={3} key={key}>
      <Grid.Column
        width={2}
        className={`stepIconHolder ${mainIcons && 'mainIcons'}`}
      >
        {mainIcons ? (
          icon === 'point' ? (
            typeNode === 'current' ? (
              <div className="currentLocation">
                <span className="outer-dot">
                  <span className="inner-dot"></span>
                </span>
              </div>
            ) : (
              <StartSVG />
            )
          ) : (
            <FinishSVG />
          )
        ) : (
          <div>
            <img
              src={travelModeFirstPic}
              alt="/images/pin-via-walking.svg"
              className={`stepIcon mid ${classOfNode} ${travelMode}`}
            />
          </div>
        )}
      </Grid.Column>
      <Grid.Column width={2} className="timeColumn secondCol">
        <div className={`timeColumn ${classOfNode}`}>{time}</div>
      </Grid.Column>
      <Grid.Column width={11} className="placeColumn">
        <div className={`placeColumn ${classOfNode}`}>{place}</div>
      </Grid.Column>
    </Grid.Row>
  );
};

const stepLine = ({
  trip,
  step,
  key,
  readOnlyStatus,
  onTravelModeChange,
  onOpen
}) => {
  const { trip_vehicles } = trip;
  return (
    <Grid.Row columns={3} key={key} className="gridNoPadding stepLine">
      <Grid.Column className="barContainer alignCenter" width={2}>
        <div className={`bar ${step.travel_mode}LINE`} />
      </Grid.Column>
      <Grid.Column width={2} className="secondCol alignCenter">
        <div className="stepStats">
          <div>
            <Time duration value={step.moving_time * 1000} />
          </div>
          <div>
            <Distance value={step.moving_distance} />
          </div>
        </div>
      </Grid.Column>
      <Grid.Column width={12} className="alignCenter">
        <div className="stepMode">
          <TravelMode
            step={step || {}}
            onOpen={onOpen}
            readOnly={readOnlyStatus}
            tripVehicles={trip_vehicles}
            onTravelModeChange={onTravelModeChange}
          />
        </div>
      </Grid.Column>
    </Grid.Row>
  );
};

const stepSmallLine = key => {
  return (
    <Grid.Row columns={3} key={key}>
      <Grid.Column width={2} className="barContainer">
        <div className="barBetween" />
      </Grid.Column>
    </Grid.Row>
  );
};

// TODO component: This helpers can be converted to appropriate components
const stepList = ({
  stepsArr,
  trip,
  onTravelModeChange,
  onOpen,
  readOnlyStatus = false
}) => {
  const list = [];
  const steps = stepsArr || [];
  for (let i = 0; i <= steps.length - 1; i++) {
    if (steps[i].type === 'node') {
      list.push(
        stepNode(
          <Time value={steps[i].time} />,
          steps[i].name,
          steps[i].class,
          steps[i].icon,
          steps[i].typeNode,
          i
        )
      );
    } else if (steps[i].type === 'small_line') {
      list.push(stepSmallLine(i));
    } else {
      list.push(
        stepLine({
          trip,
          step: steps[i].step,
          key: i,
          readOnlyStatus,
          onTravelModeChange,
          onOpen
        })
      );
    }
  }
  return list;
};

const getTripTimeScheduleInUnix = route => {
  const { departureTime } = route;
  const duration = get(route, ['duration', 'value'], 0);
  const departureTimeUnix = departureTime || 0;
  const arrivalTimeUnix =
    departureTimeUnix && duration ? departureTimeUnix + duration : 0;
  return { departureTimeUnix, arrivalTimeUnix };
};

const createTripShapeFromRoute = (
  origin,
  destination,
  mapRoute,
  travelMode
) => {
  const {
    start_address,
    start_location,
    end_address,
    end_location,
    steps: stepsList = []
  } = mapRoute;
  const isTransitMode = travelMode === TRAVEl_MODES.TRANSIT;
  const { arrivalTimeUnix, departureTimeUnix } = getTripTimeScheduleInUnix(
    mapRoute
  );
  const duration = get(mapRoute, ['duration', 'value'], 0);
  const distance = get(mapRoute, ['distance', 'value'], 0);
  const startLocationLat =
    origin && origin.location
      ? origin.location.lat
      : start_location && typeof start_location.lat === 'function'
      ? start_location.lat()
      : 0;
  const startLocationLng =
    origin && origin.location
      ? origin.location.lng
      : start_location && typeof start_location.lng === 'function'
      ? start_location.lng()
      : 0;
  const endLocationLat =
    destination && destination.location
      ? destination.location.lat
      : end_location && typeof end_location.lat === 'function'
      ? end_location.lat()
      : 0;
  const endLocationLng =
    destination && destination.location
      ? destination.location.lng
      : end_location && typeof end_location.lng === 'function'
      ? end_location.lng()
      : 0;

  var steps = [];
  if (isTransitMode) {
    steps = stepsList.map(step => ({
      ...step,
      departure_stop: {
        address: start_address,
        location: {
          lat: step.start_location.lat(),
          lng: step.start_location.lng()
        },
        departure_time: departureTimeUnix
      },
      arrival_stop: {
        address: end_address,
        location: {
          lat: step.end_location.lat(),
          lng: step.end_location.lng()
        },
        arrival_time: arrivalTimeUnix
      },
      moving_time: duration,
      moving_distance: distance,
      step
    }));
  } else {
    let step = stepsList.reduce(
      (acc, step) => {
        acc.path = acc.path.concat(step.path);
        acc.moving_distance += step.moving_distance;
        acc.moving_time += step.moving_time;
        return acc;
      },
      {
        ...stepsList[0],
        polyline: { points: null },
        path: [],
        moving_time: 0,
        moving_distance: 0,
        departure_stop: {
          address: start_address,
          location: {
            lat: startLocationLat,
            lng: startLocationLng
          },
          departure_time: departureTimeUnix
        },
        arrival_stop: {
          address: end_address,
          location: {
            lat: endLocationLat,
            lng: endLocationLng
          },
          arrival_time: arrivalTimeUnix
        }
      }
    );
    step.polyline.points = encode(step.path.map(p => [p.lat(), p.lng()]));
    steps = [step];
  }

  const trip = {
    arrival_time: arrivalTimeUnix,
    origin: {
      location: {
        lat: startLocationLat,
        lng: startLocationLng
      },
      name: start_address
    },
    destination: {
      location: {
        lat: endLocationLat,
        lng: endLocationLng
      },
      name: end_address
    },
    steps
  };

  return { trip, steps };
};

const getDepartureDateLimitOver = route => {
  if (!route) {
    return 0;
  }

  const { arrivalTimeUnix } = getTripTimeScheduleInUnix(route);
  return moment(moment(arrivalTimeUnix).diff(dateTimeNow()));
};

const getUnixDurationDifferenceFromNow = unixTimestamp =>
  moment()
    .subtract(moment.duration(moment.unix(unixTimestamp)))
    .fromNow();

const removeListDuplicatesById = (list = []) => {
  const uniqueMap = new Map();
  list.reduce(
    (unique, item) =>
      unique.has(item.id) ? unique : unique.set(item.id, item),
    uniqueMap
  );
  return [...uniqueMap.values()];
};

export {
  stepList,
  formatSteps,
  getTravelModePinImgPath,
  getTravelModeImg,
  createTripShapeFromRoute,
  getTripTimeScheduleInUnix,
  getDepartureDateLimitOver,
  getUnixDurationDifferenceFromNow,
  removeListDuplicatesById
};
