import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getTimeFormatter } from '../selectors/locale';
import { TIME_FORMATS } from '../utils/constants';

class Time extends Component {
  static propTypes = {
    t: PropTypes.func,
    timeFormatter: PropTypes.func.isRequired,
    value: PropTypes.number,
    duration: PropTypes.bool,
    is12Hour: PropTypes.bool,
    withUnits: PropTypes.bool
  };

  getTextTime = ({ h, m }, withUnits) => {
    let hours = h;
    let mins = m;
    if (h < 10) {
      hours = `0${h}`;
    }
    if (m < 10) {
      mins = `0${m}`;
    }
    return withUnits
      ? `${hours}:${mins} ${this.props.t('time.hours')}`
      : `${hours}:${mins}`;
  };
  secondsToTime = ms => {
    let s = Math.floor(ms / 1000);
    let m = Math.floor(s / 60);
    s %= 60;
    let h = Math.floor(m / 60);
    m %= 60;
    // const d = Math.floor(h / 24);
    // h %= 24;
    return { h, m, s };
  };

  render() {
    const { timeFormatter, value, duration, is12Hour, withUnits } = this.props;
    return (
      <span className="time">
        {value
          ? duration
            ? this.getTextTime(this.secondsToTime(value), withUnits)
            : timeFormatter(value, is12Hour).toUpperCase()
          : '-'}
      </span>
    );
  }
}

const mapStateToProps = state => {
  return {
    timeFormatter: getTimeFormatter(state),
    is12Hour: state.timeFormatPreference === TIME_FORMATS['12']
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};
export default translate()(connect(mapStateToProps, mapDispatchToProps)(Time));
