import {
  SET_API_ABORT_CONTROLLER,
  UNSET_API_ABPRT_CONTROLLER,
  API_CALL_STARTED,
  API_CALL_ENDED
} from '../actions/apiMeta';

const initialState = {};

export const apiMeta = (state = initialState, action) => {
  switch (action.type) {
    case SET_API_ABORT_CONTROLLER:
      return {
        ...state,
        [action.key]: action.controller
      };
    case UNSET_API_ABPRT_CONTROLLER:
      return {
        ...state,
        [action.key]: null
      };
    case API_CALL_STARTED:
      return {
        ...state,
        [action.url]: new Date().getTime()
      };
    case API_CALL_ENDED:
      return {
        ...state,
        [action.url]: null
      };
    default:
      return state;
  }
};
