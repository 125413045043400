import _remove from 'lodash/remove';

export const getCompany = state => state.company;

export const getCompanyAddresses = state => {
  const addresses = state.company.addressList;
  const addressList = [];
  if (addresses && addresses.length) {
    addresses.reduce((acc, addr) => {
      acc.push(
        Object.assign({}, addr, {
          firstName: addr.first_name,
          lastName: addr.last_name,
          address: addr.street_address,
          postalCode: addr.postal_code,
          country: addr.country_code_alpha2,
          isBillingAddress: addr.is_billing,
          isShippingAddress: addr.is_shipping
        })
      );

      return acc;
    }, addressList);
  }

  const billingAddr = _remove(
    addressList,
    addr => addr.isBillingAddress === true
  );
  const shippingAddr = _remove(
    addressList,
    addr => addr.isShippingAddress === true
  );

  return [...billingAddr, ...shippingAddr, ...addressList];
};

export const getActiveCompanySubscription = state => {
  if (state.company) {
    const { subscription = {} } = state.company;
    return subscription;
  }
  return {};
};

export const getPendingCompanySubscription = state => {
  const { pending_subscription = {} } = state.company;
  return pending_subscription;
};
export const getPendingAddOns = state => {
  let subscription = getActiveCompanySubscription(state);
  return subscription.pending_add_ons || {};
};
export const getEmployeeCount = state => {
  return state.company.employee_count;
};
export const getTeamCount = state => {
  return state.company.team_count;
};

export const getCompanyName = state => state.company.name;
export const getCompanyLogo = state => state.company.logo;
