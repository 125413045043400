import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Dropdown, Icon } from 'semantic-ui-react';

import { setStepTravelMode, setStepVehicle } from '../actions';

import {
  getDrivingVehicleOptions,
  getVehicleClassIcon
} from '../selectors/vehicles';
import { getOpenedItem } from '../selectors/list';
import { getTravelModes } from '../selectors/locale';

import { MIN_FLIGHT_DISTANCE, MIN_FLIGHT_SPEED } from '../utils/constants';
import { getStepTransitLine } from '../utils/vehicles';

class TravelMode extends Component {
  static propTypes = {
    onOpen: PropTypes.func.isRequired,
    owned: PropTypes.bool.isRequired,
    step: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    travelModes: PropTypes.array.isRequired,
    vehicleOptions: PropTypes.array.isRequired,
    onTravelModeChange: PropTypes.func
  };

  constructor(props) {
    super(props);

    const travelMode =
      props.step.travel_mode === 'TRANSIT'
        ? getStepTransitLine(props.step)
        : props.step.travel_mode;

    this.state = {
      travelModeValue: travelMode,
      vehicleValue: props.step.user_vehicle && props.step.user_vehicle.id
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.step.user_vehicle && nextProps.step.user_vehicle) {
      if (this.props.step.user_vehicle.id !== nextProps.step.user_vehicle.id) {
        this.setState({ vehicleValue: nextProps.step.user_vehicle.id });
      }
    }

    if (this.props.step.travel_mode !== nextProps.step.travel_mode) {
      const { value } = this.handleDropdownData(nextProps.step);
      this.setState({ travelModeValue: value });
    }
  }

  onOpen = (event, t) => {
    if (this.props.onOpen) {
      this.props.onOpen(event, t);
    }
  };

  getIcon = mode => {
    return mode ? (
      <div className={`iconTravelMode ${mode.toLowerCase()}`} />
    ) : (
      <Icon name="question" className="iconTravelMode unknown" />
    );
  };

  getTravelModesTransitDisabled = travelModes => {
    return travelModes.map(mode =>
      mode.key === 'TRANSIT'
        ? Object.assign({}, mode, { disabled: true })
        : mode
    );
  };
  changeMode = (_, { value }) => {
    this.setState({ travelModeValue: value });
    this.props.dispatch(setStepTravelMode(value, this.props.step));
    this.props.onTravelModeChange('travelMode');
  };
  changeVehicle = value => {
    if (value !== this.state.vehicleValue) {
      this.setState({ vehicleValue: value });
      this.props.dispatch(setStepVehicle(value, this.props.step));
      this.props.onTravelModeChange('vehicle');
    }
  };
  parseDropdownTransit = value => {
    const { travelModes } = this.props;
    return travelModes.map(mode =>
      mode.value === 'TRANSIT' ? { value, text: value, key: value } : mode
    );
  };
  handleDropdownData = step => {
    const { travelModes } = this.props;
    const value =
      step.travel_mode === 'TRANSIT'
        ? getStepTransitLine(step)
        : step.travel_mode;
    const options =
      step.travel_mode === 'TRANSIT' && value !== step.travel_mode
        ? this.parseDropdownTransit(value)
        : this.getTravelModesTransitDisabled(travelModes);

    const stepSpeed = step.moving_distance / step.moving_time;
    const disableFlightOption =
      step.moving_distance < MIN_FLIGHT_DISTANCE ||
      stepSpeed < MIN_FLIGHT_SPEED;

    // disabling flight option when moving distance is under 100km and moving speed is under 140 m/s
    options.forEach(el => {
      if (el.key === 'FLYING' && disableFlightOption) {
        Object.assign(el, { disabled: true });
      }
    });
    return { value, options };
  };
  render() {
    const {
      step,
      vehicleOptions,
      owned,
      vehiclesMap,
      readOnly,
      tripVehicles
    } = this.props;
    const { vehicleValue, travelModeValue } = this.state;

    const { options } = this.handleDropdownData(step);
    const travelModeDropdownOption = options.find(
      el => el.value === travelModeValue
    );
    const travelModeDropdownText =
      (travelModeDropdownOption && travelModeDropdownOption.text) ||
      travelModeValue;

    let notOwnedVehicle;
    let vehicleDropdownText;
    var vehicleClassIcon = null;
    if (step.travel_mode === 'DRIVING') {
      const vehicleDropdownOption = vehicleOptions.find(
        vehicle => vehicle.value === vehicleValue
      );
      const stepVehicleId = step.user_vehicle && step.user_vehicle.id;
      const notOwnedVehicleObj = tripVehicles.find(
        el => el.id === stepVehicleId
      );
      vehicleClassIcon =
        notOwnedVehicleObj && notOwnedVehicleObj.vclass
          ? getVehicleClassIcon(notOwnedVehicleObj.vclass)
          : null;
      notOwnedVehicle = notOwnedVehicleObj && notOwnedVehicleObj.name;
      vehicleDropdownText =
        (vehicleDropdownOption && vehicleDropdownOption.text) ||
        notOwnedVehicle;
    }

    return (
      <div id="travelMode">
        <div className="travelIconContainer">
          {this.getIcon(
            step.travel_mode === 'TRANSIT'
              ? step.vehicle
              : vehicleClassIcon
              ? vehicleClassIcon
              : step.travel_mode
          )}
        </div>
        {readOnly || !owned ? (
          <div className="travelModeDropdownReplacement">
            <span>
              {travelModeDropdownText ||
                this.state.travelModeValue.toLowerCase()}
            </span>
          </div>
        ) : (
          <Dropdown
            floating
            options={options}
            text={travelModeDropdownText}
            // value={this.state.travelModeValue}
            onChange={this.changeMode}
            selectOnBlur={false}
            button
            basic
            onOpen={this.onOpen}
            className="travelModeDropdown"
          />
        )}
        {step.travel_mode === 'DRIVING' &&
          (readOnly || !owned ? (
            <div
              className={
                vehiclesMap[vehicleValue] &&
                vehiclesMap[vehicleValue].hasBeaconConnected
                  ? 'drivingDropdownReplacement hasBeacon'
                  : 'drivingDropdownReplacement'
              }
            >
              <span>{vehicleDropdownText || notOwnedVehicle}</span>
            </div>
          ) : (
            <Dropdown
              floating
              text={vehicleDropdownText}
              selectOnBlur={false}
              button
              basic
              onOpen={this.onOpen}
              className={
                vehiclesMap[vehicleValue] &&
                vehiclesMap[vehicleValue].hasBeaconConnected
                  ? 'drivingDropdown hasBeacon'
                  : 'drivingDropdown'
              }
            >
              <Dropdown.Menu>
                {vehicleOptions.map(({ text, ...vehicle }) => (
                  <Dropdown.Item
                    {...vehicle}
                    active={vehicle.value === vehicleValue}
                    onClick={() => this.changeVehicle(vehicle.value)}
                  >
                    {vehiclesMap[vehicle.value] &&
                    vehiclesMap[vehicle.value].hasBeaconConnected ? (
                      <div className="BEACON" />
                    ) : (
                      ''
                    )}
                    <div>{text}</div>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ))}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const openedItem = getOpenedItem(state);
  return {
    owned: String(openedItem.owner.id) === String(state.user.id),
    travelModes: getTravelModes(),
    vehicleOptions: getDrivingVehicleOptions(state),
    vehiclesMap: state.vehicles.vehiclesMap || {},
    tripVehilces: openedItem.trip_vehicles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default translate()(
  connect(mapStateToProps, mapDispatchToProps)(TravelMode)
);
