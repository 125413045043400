import serverRequest from '../utils/Api';

import { wrongPassword, changePassword } from '../actions/login';
import { mergeTeamToList, getCleanTeamFromDetails } from '../utils/dataStore';
import { getTeamsList, getSelectedTeamId } from '../selectors/teams';
import { setCurrentTeamInfo, setHasNoTeams } from './teamUserInfo';

import history from '../utils/history';
import { getDashboardLinkFor } from '../utils';
import { URLs } from '../utils/urls';
import { setFilterSearchText, toggleListInvalid } from './list';

export const SET_TEAMS_LIST = 'SET_TEAMS_LIST';
export const SELECT_TEAMS_LIST_ITEM = 'SELECT_TEAMS_LIST_ITEM';
export const DESELECT_TEAMS_LIST_ITEM = 'DESELECT_TEAMS_LIST_ITEM';
export const DESELECT_ALL_TEAM_LIST_ITEMS = 'DESELECT_ALL_TEAM_LIST_ITEMS';
export const FETCHING_TEAMS_LIST = 'FETCHING_TEAMS_LIST';
export const SET_TEAM_CURRENCY = 'SET_TEAM_CURRENCY';
export const UPDATE_TEAM_IN_LIST = 'UPDATE_TEAM_IN_LIST';

export const selectTeamsListItem = itemIndex => ({
  type: SELECT_TEAMS_LIST_ITEM,
  itemIndex
});
export const deselectTeamsListItem = itemIndex => ({
  type: DESELECT_TEAMS_LIST_ITEM,
  itemIndex
});
export const deselectAllTeamListItems = () => ({
  type: DESELECT_ALL_TEAM_LIST_ITEMS
});
export const setTeamsList = (list, pagination = {}, stats = {}) => ({
  type: SET_TEAMS_LIST,
  list,
  pagination,
  stats
});
export const fetchingTeamsList = () => ({
  type: FETCHING_TEAMS_LIST
});
export const setTeamCurrency = currencyCode => ({
  type: SET_TEAM_CURRENCY,
  currencyCode
});
export const updateTeamInList = (index, team) => ({
  type: UPDATE_TEAM_IN_LIST,
  index,
  team
});

export const requestTeamsList = companyId => (dispatch, getState) => {
  const { method, url } = URLs.teams.getList(companyId);
  return serverRequest(method, url, getState()).then(response => {
    const list = response.teams.map(team => getCleanTeamFromDetails({ team }));
    dispatch(
      setTeamsList(list, response.pagination, {
        count: response.pagination.stats.team_count
      })
    );
  });
};

export const getNextPageForTeamsList = () => (dispatch, getState) => {
  const state = getState();
  const {
    teams: {
      pagination: { next },
      isFetching,
      list
    }
  } = state;
  if (isFetching || !next) {
    return;
  }
  dispatch(fetchingTeamsList());
  serverRequest('get', next, state).then(resp => {
    if (resp.results && resp.results.length) {
      dispatch(setTeamsList(list.concat(resp.results)));
    }
  });
};

const createTeam = (companyId, body) => (dispatch, getState) => {
  const { method, url } = URLs.teams.create(companyId);
  return serverRequest(method, url, getState(), { body })
    .then(response => {
      if (response.id) {
        const { list } = getState().teams;
        dispatch(setTeamsList(mergeTeamToList(response, list)));
      }
    })
    .catch(err => console.error('createTeam:', err));
};

export const addTeam = details => (dispatch, getState) => {
  const {
    company: { id }
  } = getState();
  const body = {
    name: details.teamName,
    country: details.teamCountry.toUpperCase(),
    language: details.teamLanguage,
    date_format: details.teamDateFormat,
    time_format: details.teamTimeFormat,
    currency: details.teamCurrency
  };
  dispatch(createTeam(id, body));
};

const editRequestTeam = (companyId, body, teamId) => (dispatch, getState) => {
  const { method, url } = URLs.teams.update(companyId, teamId);
  return serverRequest(method, url, getState(), { body })
    .then(response => {
      if (response.id) {
        const { list } = getState().teams;
        dispatch(setTeamsList(mergeTeamToList(response, list)));
      }
    })
    .catch(err => console.error('editRequestTeam:', err));
};

export const editTeam = (details, teamId) => (dispatch, getState) => {
  const {
    company: { id }
  } = getState();
  const body = {
    name: details.teamName,
    country: details.teamCountry.toUpperCase(),
    language: details.teamLanguage,
    date_format: details.teamDateFormat,
    time_format: details.teamTimeFormat,
    currency: details.teamCurrency
  };
  dispatch(editRequestTeam(id, body, teamId));
};

const deleteTeam = teamId => (dispatch, getState) => {
  const {
    company: { id }
  } = getState();
  const { method, url } = URLs.teams.delete(id, teamId);
  return serverRequest(method, url, getState()).then(resp => {
    dispatch(changePassword());
    const { list: teamsList } = getState().teams;
    const filteredList = teamsList.filter(team => team.id !== teamId);
    dispatch(setTeamsList(filteredList));
    if (filteredList.length > 0) {
      // set the selectedTeam to the first team in the list
      dispatch(setCurrentTeamInfo(filteredList[0].id));
      // show teams list page
      history.push(getDashboardLinkFor('teams'));
    } else {
      dispatch(setHasNoTeams());
      // show trips page
      history.push(getDashboardLinkFor('trips'));
    }
  });
};

export const checkPasswordAndDeleteTeam = (password, teamId) => (
  dispatch,
  getState
) => {
  const { email } = getState().user.details;
  const body = { email, password };
  const { method, url } = URLs.user.login();
  serverRequest(method, url, getState(), {
    body,
    doNotAuthorize: true
  }).then(response => {
    if (response.valid) {
      dispatch(deleteTeam(teamId));
    } else {
      dispatch(wrongPassword());
    }
  });
};

export const requestCurrency = countryCode => (dispatch, getState) => {
  const { method, url } = URLs.common.getCurrencyForCountry(countryCode);
  serverRequest(method, url, getState())
    .then(response =>
      dispatch(
        setTeamCurrency(response.length ? response[0].currency_code : 'EUR')
      )
    )
    .catch(err => console.error('requestCurrency:', err));
};

export const updateTeamReportStats = (teamId, stats) => (
  dispatch,
  getState
) => {
  const list = getTeamsList(getState());
  const index = list.findIndex(el => el.id === teamId);
  const team = list.find(el => el.id === teamId);
  team.stats = stats;
  dispatch(updateTeamInList(index, team));
};
