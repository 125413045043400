import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nl';

const dateTimeNow = () =>
  moment(moment().format('DD hh:mm A'), 'DD hh:mm A').unix();

const setMomentLocale = (locale = 'en-gb') => {
  moment.locale(locale);
};

setMomentLocale();

export { dateTimeNow, setMomentLocale };
