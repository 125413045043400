import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import queryString from 'qs';

import SearchableAnswers from './SearchableAnswers';
import HelpSupport from './HelpSupport';

import PageHeader from '../../components/PageHeader';

import {
  getHelpAnswersLists,
  getQuestionIdToLookup,
  getHelpListLanguage,
  getSearchText
} from '../../selectors/help';
import { getAppLanguage } from '../../selectors/app';

import {
  fetchHelpPageData,
  setSearchId,
  setSearchText,
  unsetSearchId,
  unsetSearchText
} from '../../actions/help';
import { addNotification } from '../../actions/notifications';

import { getPublicLinkFor } from '../../utils';
import { createNotificationObject } from '../../utils/dataStore';
import { MAIL_TO_LINKS } from '../../utils/constants';

class HelpPageContainer extends Component {
  componentWillMount() {
    const { appLanguage, history, dispatch } = this.props;
    let listLanguageToBe = appLanguage;
    if (history.location.search) {
      const queryStringObj = queryString.parse(
        history.location.search.slice(1)
      );
      if (queryStringObj.lang) {
        listLanguageToBe = queryStringObj.lang;
      }
      if (queryStringObj.hid) {
        dispatch(setSearchId(parseInt(queryStringObj.hid, 10)));
      } else if (queryStringObj.search) {
        dispatch(setSearchText(queryStringObj.search));
      }
    }
    dispatch(fetchHelpPageData(listLanguageToBe));
  }

  componentWillReceiveProps(newProps) {
    if (
      this.props.appLanguage &&
      this.props.appLanguage !== newProps.appLanguage
    ) {
      if (
        newProps.listLanguage &&
        newProps.listLanguage !== newProps.appLanguage
      ) {
        if (!newProps.isInDashboard) {
          newProps.dispatch(fetchHelpPageData(newProps.appLanguage));
          newProps.history.replace(
            getPublicLinkFor('help', {
              hid: newProps.questionIdToLookup,
              lang: newProps.appLanguage,
              search: newProps.searchText
            })
          );
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(unsetSearchId());
    this.props.dispatch(unsetSearchText());
  }

  onContactSupportClick = () => {
    window.location.href = MAIL_TO_LINKS.GENERAL_CONTACT;
  };
  onCopyLinkToClipboard = questionId => {
    const { listLanguage, t, dispatch } = this.props;
    const el = document.createElement('textarea');
    el.value = `${window.location.origin}${getPublicLinkFor('help', {
      hid: questionId,
      lang: listLanguage
    })}`;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    const notif = createNotificationObject(
      t('common.linkCopied'),
      'link_copied'
    );
    dispatch(addNotification(notif));
  };

  render() {
    const {
      items,
      questionIdToLookup,
      searchText,
      listLanguage,
      t
    } = this.props;

    return (
      <div className="helpPageContainer">
        <PageHeader
          className="helpPageHeaderContainer"
          leftSection={<div className="pageName">Psngr Help</div>}
          rightSection={
            <Button
              icon
              labelPosition="left"
              className="helpButton helpButtonMail"
              onClick={this.onContactSupportClick}
            >
              <Icon name="mail" className="helpButtonIcon" />
              {t('settings.contact')}
            </Button>
          }
        />
        <div className="content">
          <SearchableAnswers
            items={items}
            searchQuestionId={questionIdToLookup}
            searchText={searchText}
            onCopyLinkToClipboard={this.onCopyLinkToClipboard}
          />
          <HelpSupport appLanguage={listLanguage} />
        </div>
      </div>
    );
  }
}

HelpPageContainer.propTypes = {
  items: PropTypes.array,
  questionIdToLookup: PropTypes.number,
  searchText: PropTypes.string,
  listLanguage: PropTypes.string,
  appLanguage: PropTypes.string,
  history: PropTypes.object,
  dispatch: PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = (state, props) => {
  return {
    items: getHelpAnswersLists(state),
    questionIdToLookup: getQuestionIdToLookup(state),
    searchText: getSearchText(state),
    listLanguage: getHelpListLanguage(state),
    appLanguage: getAppLanguage(state),
    isInDashboard: props.history.location.pathname.includes('dashboard')
  };
};
const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default translate()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(HelpPageContainer))
);
