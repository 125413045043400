import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// import Cache from 'i18next-localstorage-cache';

const options = {
  // order and from where user language should be detected
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain'
  ],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'] // languages to not persist (cookie, localStorage)
};

i18n
  .use(XHR)
  // .use(Cache)
  .use(LanguageDetector)
  .init(
    {
      fallbackLng: ['en', 'de', 'fr', 'it', 'es', 'nl'],
      // lng: localStorage.getItem('language'),
      detection: options,
      // lng: 'en',

      // have a common namespace used around the full app
      ns: ['psngr'],
      defaultNS: 'psngr',

      debug: process.env.REACT_APP_ENV !== 'production',

      // cache: {
      //   enabled: true
      // },
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
      },
      react: {
        wait: true
      },

      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
        format: (value, format) => {
          if (format === 'uppercase') return value.toUpperCase();
          return value;
        }
      }
    },
    () => {
      i18n.t('key');
    }
  );

export default i18n;
