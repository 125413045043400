import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import MonthlyPrice from '../ManagePlan/MonthlyPrice';

import SectionTitle from './SectionTitle';
import { getPublicLinkFor } from '../../utils';

const PlansSectionMobile = ({
  allPlans,
  trackEventForLogin,
  trackEventForMoreInfo,
  t
}) => {
  const plansToShow = allPlans.reduce(
    (acc, plan) => {
      if (plan.plan_type === 'pro' && plan.billing_frequency === 1) {
        acc.pro = plan;
      } else if (
        plan.plan_type === 'team' &&
        plan.default_employee_count === 2
      ) {
        acc.enterprise = plan;
      }
      return acc;
    },
    {
      pro: {},
      enterprise: {}
    }
  );

  return (
    <div className="plansSection" id="pricing">
      <div className="section">
        <div className="titleContainer">
          <SectionTitle secondLine={t(`home.plans.titlePartTwo`)} />
          <div className="sectionSubTitle">
            {t(`home.enterprise.plans.subtitle`)}
          </div>
        </div>

        <div className="plansContainer">
          <Link
            to={getPublicLinkFor('login')}
            className="planContainer"
            onClick={trackEventForLogin}
          >
            <div className="details">
              <div className="planName">Free</div>
              <div className="features">
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('home.plans.plans.free.item2')
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('home.plans.plans.free.item3')
                  }}
                />
              </div>
            </div>
            <div className="priceWrapper">
              <div className="price">
                <span className="currency">
                  {getSymbolFromCurrency(plansToShow.pro.currency)}
                </span>
                <span className="value">0</span>
              </div>
              <div className="frequency">
                {t('home.plans.plans.free.item1')}
              </div>
            </div>
          </Link>

          <Link
            to={getPublicLinkFor('solutionsPro')}
            className="planContainer"
            onClick={() => trackEventForMoreInfo('pro')}
          >
            <div className="details">
              <div className="planName">Pro</div>
              <div className="features">
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('billing.unlimitedTrips')
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('home.plans.plans.pro.item3')
                  }}
                />
              </div>
            </div>
            <div className="priceWrapper">
              <MonthlyPrice
                currency={plansToShow.pro.currency}
                price={
                  plansToShow.pro.base_price / plansToShow.pro.billing_frequency
                }
                hidePerMonth={true}
              />
              {/* <div className="price">
                <span className="currency">
                  {getSymbolFromCurrency(plansToShow.pro.currency)}
                </span>
                <span className="value">
                  {Math.round(plansToShow.pro.base_price)}
                </span>
              </div> */}
              <div className="frequency">
                <div>{t('home.plans.plans.free.perMonth')}</div>
                <div>single user</div>
              </div>
            </div>
          </Link>

          <Link
            to={getPublicLinkFor('solutionsEnterprise')}
            className="planContainer"
            onClick={() => trackEventForMoreInfo('enterprise')}
          >
            <div className="details">
              <div className="planName">Enterprise</div>
              <div className="features">
                <div>
                  <span className="bold">
                    {t(`home.plans.plans.enterprise.item1`)}
                  </span>
                </div>
                <div>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(`home.plans.plans.enterprise.item3`)
                    }}
                  />
                </div>
                <div>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(`home.plans.plans.enterprise.item4`)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="priceWrapper">
              <MonthlyPrice
                currency={plansToShow.enterprise.currency}
                price={
                  plansToShow.enterprise.base_price /
                  plansToShow.enterprise.billing_frequency
                }
                hidePerMonth={true}
              />
              {/* <div className="price">
                <span className="currency">
                  {getSymbolFromCurrency(plansToShow.enterprise.currency)}
                </span>
                <span className="value">
                  {Math.round(plansToShow.enterprise.base_price)}
                </span>
              </div> */}
              <div className="frequency">
                <div>{t('home.plans.plans.free.perMonth')}</div>
                <div>
                  {plansToShow.enterprise.default_employee_count +
                    ' ' +
                    t(`home.enterprise.plans.employees`)}
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

PlansSectionMobile.propTypes = {
  allPlans: PropTypes.array,
  trackEventForLogin: PropTypes.func,
  trackEventForMoreInfo: PropTypes.func,
  t: PropTypes.func
};

export default PlansSectionMobile;
