import moment from 'moment';
import { createSelector } from 'reselect';
import getSymbolFromCurrency from 'currency-symbol-map';

import i18n from '../utils/i18n';
import countries from '../utils/countries';
import {
  languageOptions,
  months,
  travelModes,
  weekDays
} from '../utils/constants';
import { isEmpty } from '../utils';

import { getCustomer } from './customer';
import { getTeamCountry } from './locale_utils';

Number.prototype.countDecimals = function() {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split('.')[1].length || 0;
};

const clm = require('country-locale-map');

const getLocaleTimeString = (timeStamp, locale, options = {}) => {
  return new Date(timeStamp).toLocaleTimeString(locale || undefined, options);
};
const getDateStringFromMoment = (timeStamp, format) => {
  return moment(timeStamp).format(format);
};

export const getUserLocale = state => {
  if (!isEmpty(state.user.details)) {
    const userDetails = state.user.details;
    const country = getTeamCountry(state) || userDetails.country;
    if (country && userDetails.language) {
      return `${userDetails.language}-${country}`;
    }
  }

  return '';
};

export const getCountryLocale = state => {
  const teamCountry = getTeamCountry(state);
  if (!isEmpty(teamCountry)) {
    return clm.getLocaleByAlpha2(teamCountry).replace('_', '-');
  } else if (!isEmpty(state.user.details)) {
    const userDetails = state.user.details;
    if (userDetails.country) {
      return clm.getLocaleByAlpha2(userDetails.country).replace('_', '-');
    }
  }

  return '';
};

export const getLanguageOptions = () => {
  return languageOptions.sort((a, b) => {
    if (a.text > b.text) return 1;
    if (a.text < b.text) return -1;
    return 0;
  });
};

export const getCountry = state => {
  return (
    getTeamCountry(state) ||
    state.user.details.country ||
    state.countryByIp ||
    'NL'
  );
};

export const getLanguage = state => {
  const { language } = state.user.details;
  if (language) {
    return languageOptions.find(el => el.value === language) ? language : 'en';
  }
  return 'en';
};

export const getDateFormatPreference = state => {
  return state.datePreference.preferredFormat;
};
export const getDateFormats = state => {
  return state.datePreference.options;
};

export const getTimeFormatter = createSelector([getUserLocale], userLocale => {
  return (time, is12Hour) => {
    return getLocaleTimeString(time, userLocale || undefined, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: is12Hour || false
    })
      .split('.')
      .join('');
  };
});

const parseDropdownData = (data, tObjectName, upperCase, toInteger) => {
  return data.map((element, index) => ({
    key: toInteger ? index + 1 : element,
    text: tObjectName
      ? i18n.t(
          `${tObjectName}.${element[
            upperCase ? 'toUpperCase' : 'toLowerCase'
          ]()}`
        )
      : element,
    value: toInteger ? index + 1 : element
  }));
};
export const getTravelModes = () => {
  return parseDropdownData(travelModes, 'travelModes', true).map(el =>
    el.key === 'FLYING'
      ? Object.assign(el, { text: i18n.t('list.flight') })
      : el
  );
};

export const getLocal = key => {
  try {
    let result = window.localStorage.getItem(key);
    if (result) {
      result = JSON.parse(result);
    }
    return result;
  } catch (e) {
    console.error(e);
  }
  return undefined;
};

export const setLocal = (key, value) => {
  try {
    if (value === null) {
      return window.localStorage.removeItem(key);
    }
    return window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
  return false;
};

export const removeLocal = key => {
  try {
    if (window.localStorage) {
      return window.localStorage.removeItem(key);
    }
  } catch (e) {
    console.error(e);
  }
  return false;
};

export const setCookie = ({ name, value, days }) => {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `expires=${date.toGMTString()}`;
  } else {
    expires = '';
  }
  document.cookie = `${name}=${value};${expires};path=/;`;
};
export const getCookie = name => {
  if (document.cookie.length > 0) {
    let startIndex = document.cookie.indexOf(`${name}=`);
    if (startIndex !== -1) {
      startIndex = startIndex + name.length + 1;
      let endIndex = document.cookie.indexOf(';', startIndex);
      if (endIndex === -1) {
        endIndex = document.cookie.length;
      }
      return unescape(document.cookie.substring(startIndex, endIndex));
    }
  }
  return '';
};
export const deleteCookie = name => {
  document.cookie = `${name}=;expires=${new Date(1)};path=/;`;
};
export const getColor = (className, dark) => {
  switch (className) {
    case 'appTheme':
      return '#24b626';
    case 'red':
      return 'red';
    case 'TRANSIT':
      return dark ? '#005482' : '#0079ba';
    case 'DRIVING':
      return dark ? '#a93919' : '#f25124';
    case 'BICYCLING':
      return dark ? '#ac8200' : '#f7ba00';
    case 'WALKING':
      return dark ? '#697680' : '#a7bccc';
    case 'FLYING':
      return dark ? '#67366b' : '#944d99';
    case 'UNFOCUSED':
      return dark ? '#a1a7ab' : '#bec8cf';
    case 'UNKNOWN':
    default:
      return dark ? '#697680' : '#9bb0be';
  }
};

export const getDateFormatter = createSelector(
  [getDateFormatPreference],
  preference => {
    return time => {
      return getDateStringFromMoment(time, preference);
    };
  }
);

export const getCompleteDateFormatter = createSelector(
  [getDateFormatPreference],
  preference => {
    return time => {
      const day = weekDays()[new Date(time).getDay()].slice(0, 3);
      return `${day} ${getDateStringFromMoment(time, preference)}`;
    };
  }
);

export const getDateRangeFormatter = createSelector(
  [getDateFormatPreference],
  preference => {
    return (startTime, endTime) => {
      return {
        start: moment(startTime).format(preference),
        end: moment(endTime).format(preference)
      };
    };
  }
);
export const getDistanceUnit = createSelector([getCountry], country => {
  // Miles for US, Great Britain, Liberia and Myanmar
  return ['us', 'gb', 'lr', 'mm'].includes(country.toLowerCase()) ? `mi` : `km`;
});
export const getDistanceCalculator = createSelector(
  [getDistanceUnit],
  distanceUnit => {
    const metersMultiplier = distanceUnit === 'mi' ? 0.0621371192 : 0.1;
    const unit = distanceUnit === 'mi' ? `mile` : `kilometer`;
    return (meters = 0, showDecimal) => {
      const value = Math.round(meters * metersMultiplier) / 100;
      return {
        unit: i18n.t(`units.${unit}`, { count: value }),
        unitShort: distanceUnit,
        value: showDecimal ? Number(value).toFixed(2) : Number(value).toFixed()
      };
    };
  }
);

export const getWeightCalculator = createSelector([getCountry], country => {
  return (kg = 0) => {
    let unit;
    let unitShort;
    let value;
    // Pounds for US, Great Britain, Liberia and Myanmar
    if (['us', 'gb', 'lr', 'mm'].indexOf(country.toLowerCase()) > -1) {
      value = Math.round(kg * 220.462262) / 100;
      unit = 'units.pound';
      unitShort = 'lb';
    } else {
      unit = 'units.kilogram';
      unitShort = 'kg';
      value = Math.round(kg * 100) / 100;
    }
    return {
      unit,
      unitShort,
      value
    };
  };
});

export const getCurrencyFromCountry = country => {
  const euroCountries = [
    'AL',
    'AT',
    'BA',
    'BE',
    'BG',
    'CH',
    'CY',
    'CZ',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MD',
    'MK',
    'MT',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'RS',
    'SK',
    'SI',
    'SE',
    'TR',
    'UA'
  ];

  if (country === 'GB') {
    return 'GBP';
  } else if (euroCountries.includes(country)) {
    return 'EUR';
  }
  return 'USD';
};

export const currencySymbol = iso => {
  if (
    [
      'USD',
      'AUD',
      'CAD',
      'NZD',
      'SGD',
      'ARS',
      'BSD',
      'BBD',
      'BMD',
      'BND',
      'KYD',
      'CLP',
      'COP',
      'HKD',
      'LRD',
      'MXN',
      'NAD'
    ].indexOf(iso) > -1
  ) {
    return '$';
  } else if (iso === 'EUR') {
    return '€';
  } else if (['GBP', 'SYP', 'EGP', 'FKP', 'IMP'].indexOf(iso) > -1) {
    return '£';
  } else if (['CNY', 'JPY'].indexOf(iso) > -1) {
    return '¥';
  } else if (['DKK', 'SEK'].indexOf(iso) > -1) {
    return 'kr';
  } else if (iso === 'ILS') {
    return '₪';
  } else if (iso === 'BRL') {
    // Brazilian Real
    return 'R$';
  } else if (iso === 'UYU') {
    // Uruguay Peso
    return '$U';
  } else if (iso === 'ZAR') {
    // South African Rand
    return 'R';
  } else if (iso === 'KRW') {
    // Korean Won
    return '₩';
  } else if (iso === 'SAR') {
    // Saudi Arabia Riyal
    return '\ufdfc';
  } else if (iso === 'INR') {
    // Indian Rupee
    return '\u20B9';
  } else if (iso === 'HUF') {
    // Ungary Forint
    return 'Ft';
  } else if (iso === 'RUB') {
    // Russian Ruble
    return '\u20BD';
  } else if (iso === 'THB') {
    // Thailand Baht
    return '\u0E3F';
  } else if (iso === 'NPR') {
    // Nepal Rupee
    return '\u20a8';
  }
  return iso;
};

export const getShippingZoneFromCountry = country => {
  switch (country) {
    case 'AT':
    case 'BE':
    case 'DK':
    case 'FR':
    case 'DE':
    case 'IT':
    case 'LU':
    case 'ES':
    case 'SE':
    case 'NL':
      return 'eur1';
    case 'AL':
    case 'AD':
    case 'BY':
    case 'BA':
    case 'BG':
    case 'HR':
    case 'CY':
    case 'CZ':
    case 'EE':
    case 'FO':
    case 'FI':
    case 'GB':
    case 'GI':
    case 'GR':
    case 'GL':
    case 'HU':
    case 'IS':
    case 'IE':
    case 'XK':
    case 'LV':
    case 'LI':
    case 'LT':
    case 'MK':
    case 'MT':
    case 'MD':
    case 'ME':
    case 'NO':
    case 'PL':
    case 'RO':
    case 'PT':
    case 'SM':
    case 'RS':
    case 'SK':
    case 'SI':
    case 'CH':
    case 'TR':
    case 'UA':
    case 'VA':
      return 'eur2';
    default:
      return 'international';
  }
};

export const getCurrencyCalculator = createSelector([], () => {
  return (value, iso, round, forDollar = false) => ({
    unit: forDollar
      ? `US ${getSymbolFromCurrency(iso)}`
      : getSymbolFromCurrency(iso) || '',
    unitShort: forDollar
      ? `US ${getSymbolFromCurrency(iso)}`
      : getSymbolFromCurrency(iso) || '',
    value: Number(value).toFixed(round)
  });
});

export const getStringifyNumber = createSelector(
  [getCountry, getCountryLocale],
  (country, locale) => {
    let radix, delimiter, interval;
    if (['de', 'dk'].indexOf(country.toLowerCase()) > -1) {
      radix = ',';
      delimiter = '.';
      interval = 3;
    } else if (['nl', 'fr', 'it', 'es'].indexOf(country.toLowerCase()) > -1) {
      radix = ',';
      delimiter = '\u00a0';
      interval = 3;
    } else if (['ca'].indexOf(country.toLowerCase()) > -1) {
      radix = '.';
      delimiter = '\u00a0';
      interval = 3;
    } else {
      radix = '.';
      delimiter = ',';
      interval = 3;
    }
    return (unit, format, t) => {
      if (typeof unit === 'number') {
        unit = { value: unit };
        if (!format) format = '*v';
      } else if (!format) {
        format = '*v *s';
      }
      var valueString;
      const arr = unit.value.toString().split('.');
      if (locale) {
        let decimalDigits = Math.max(2, Number(unit.value).countDecimals());
        valueString = Number(unit.value).toLocaleString(locale, {
          minimumFractionDigits: arr.length > 1 ? decimalDigits : 0
        });
      } else {
        for (let i = arr[0].length - interval; i > 0; i -= interval) {
          const s = arr[0];
          arr[0] = s.slice(0, i) + delimiter + s.slice(i);
        }
        valueString = arr[0] + (arr[1] ? radix + arr[1] : '');
      }
      return format
        .replace('*v', valueString)
        .replace(
          '*s',
          t
            ? t(`units.${unit.unitShort}`, { count: valueString })
            : unit.unitShort
        )
        .replace(
          '*l',
          t ? t(`units.${unit.unit}`, { count: valueString }) : unit.unit
        );
    };
  }
);

export const getCountryOptions = createSelector([getLanguage], language => {
  const lang = Object.keys(countries[0].translations).includes(language)
    ? language
    : 'en';
  return countries
    .reduce((acc, country) => {
      acc.push({
        value: country.cca2,
        text:
          country.translations[lang].common ||
          country.translations[lang].official,
        key: country.cca2
      });
      return acc;
    }, [])
    .sort((a, b) => {
      if (a.text > b.text) return 1;
      if (a.text < b.text) return -1;
      return 0;
    });
});

export const getTaxCountry = createSelector(
  [getCustomer, getCountry],
  (customer, userCountry) => {
    if (customer.tax_country) return customer.tax_country;
    if (customer.billing_address)
      return customer.billing_address.country_code_alpha2;
    return userCountry;
  }
);

export const getCountryName = state => {
  const countryOptions = getCountryOptions(state);
  const taxCountry = getTaxCountry(state);
  let countryRes = countryOptions.find(country => country.value === taxCountry);
  countryRes = countryRes && countryRes.text;
  return countryRes;
};

export const getCommaSeparatedValues = array => {
  const { length } = array;
  return length
    ? length === 1
      ? array[0]
      : `${array.slice(0, length - 1).join(', ')} ${i18n.t('and')} ${
          array[length - 1]
        }`
    : '';
};

export const parseDisplayFields = fields => {
  return Object.entries(fields)
    .map(([key, value]) => value && key)
    .filter(field => field);
};

export const parseTripsDisplayFields = (fields, allHeaders) =>
  Object.keys(fields).reduce(
    (acc, el) =>
      (acc = fields[el] ? acc.concat(allHeaders[el].actualKeys) : acc),
    []
  );

export const buildCleanReportCriteria = criteria => {
  const cleanCriteria = {};
  if (criteria.dates) {
    if (criteria.dates.start) {
      cleanCriteria.dates = {};
      cleanCriteria.dates.start = criteria.dates.start;
    }
    if (criteria.dates.end) {
      cleanCriteria.dates.end = criteria.dates.end;
    }
  }

  cleanCriteria.display_fields = criteria.display_fields;
  if (criteria.places) {
    if (criteria.places.from && criteria.places.from.length) {
      cleanCriteria.places = {};
      cleanCriteria.places.from = criteria.places.from;
    }

    if (criteria.places.to && criteria.places.to.length) {
      cleanCriteria.places.to = criteria.places.to;
    }
  }

  if (criteria.tagged !== null) {
    cleanCriteria.tagged = criteria.tagged;
  }

  if (criteria.tags && criteria.tags.length) {
    cleanCriteria.tags = criteria.tags;
  }

  if (criteria.travel_modes && criteria.travel_modes.length) {
    cleanCriteria.travel_modes = criteria.travel_modes;
  }

  if (criteria.vehicles) {
    cleanCriteria.vehicles = {};
    if (criteria.vehicles.drivers && criteria.vehicles.drivers.length) {
      cleanCriteria.vehicles.drivers = criteria.vehicles.drivers;
    }

    if (criteria.vehicles.ids && criteria.vehicles.ids.length) {
      cleanCriteria.vehicles.ids = criteria.vehicles.ids;
    }
  }

  return cleanCriteria;
};

export const parseDateTime = (date, dateFormat = '') => {
  const dateTime = new Date(date * 1000 || 0);
  const day = dateTime.getDate();
  const month = months[dateTime.getMonth()];
  const monthCapitalize = `${month.substring(0, 1)}${month
    .substring(1, 3)
    .toLocaleLowerCase()}`;
  const year = dateTime.getFullYear();
  const res =
    dateFormat.indexOf('M') < dateFormat.indexOf('D') &&
    dateFormat.indexOf('D') < dateFormat.indexOf('Y')
      ? `${monthCapitalize} ${day}, ${year}`
      : `${day} ${monthCapitalize} ${year}`;
  return res;
};

export const parseLocation = str => {
  const index = str.substring(1, str.length).indexOf('/');
  return index === -1 ? str : str.substring(0, index + 1);
};
export const parseDashboardLocation = str => {
  return str.replace('/dashboard', '');
};

export const parseLocationEnd = str => {
  const index = str.lastIndexOf('/');
  return str.substring(0, index);
};

export const checkNewDashboard = () => {
  const newDashboard = getLocal('newDashboard');
  if (!newDashboard) {
    setLocal('user_country', null);
    setLocal('user_id', null);
    setLocal('user_mail', null);
    setLocal('user_token', null);
    setLocal('newDashboard', true);
  }
};
