import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';

import { availableLanguages } from '../../utils/landingConstants';

const LanguageSelector = ({ language, onLanguageChange, className }) => {
  const langObj = availableLanguages.find(lang => lang.value === language);
  let currentLanguageText = 'English';
  if (langObj) {
    currentLanguageText = langObj.text;
  }
  const filteredLanguages = availableLanguages.filter(
    lang => lang.value !== language
  );
  return (
    <Dropdown
      className={`langSelector ${className}`}
      icon="chevron down"
      selectOnBlur={false}
      value={language}
      text={currentLanguageText}
    >
      <Dropdown.Menu>
        {filteredLanguages.map(lang => (
          <Dropdown.Item
            key={lang.value}
            value={lang.value}
            content={lang.text}
            onClick={() => onLanguageChange(lang.value)}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

LanguageSelector.propTypes = {
  language: PropTypes.string,
  className: PropTypes.string,
  onLanguageChange: PropTypes.func
};
LanguageSelector.defaultProps = {
  language: 'en',
  className: ''
};

export default translate()(LanguageSelector);
