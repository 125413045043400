import { createSelector } from 'reselect';
import { sectionSelector } from '../../utils/selector';
import { getCompleteDateFormatter } from '../locale';

export const selectorTripsMergeContainer = createSelector(
  sectionSelector('ui.tripsMergeForm'),
  state => getCompleteDateFormatter(state),
  (tripsMergeForm, dateFormatter) => {
    return {
      tripsMergeForm,
      dateFormatter
    };
  }
);
