import qs from 'qs';
import _orderBy from 'lodash/orderBy';
import _isEmpty from 'lodash/isEmpty';

import { setFiltersFromSearchText } from './';
import {
  checkFilters,
  closeItem,
  restoreItems,
  deleteItems,
  updateOpenedItems,
  updateListItem,
  setFilterSearchText,
  setList,
  sortListByColumn,
  listDeselectAll,
  setOpenedItem,
  setOpenedItemId,
  toggleisUpdatingData,
  openListItem,
  getList,
  updateList,
  listDeselect,
  setUnclassifiedTrips
} from './list';
import { setError } from './errors';

import { getDateFormatter } from '../selectors/locale';
import {
  getActiveListGroup,
  getOpenedItemIndex,
  getOpenedItem,
  getHeaders,
  getActiveList
} from '../selectors/list';

import serverRequest from '../utils/Api';
import {
  getFiltersFromText,
  getDashboardLinkFor,
  getSameDayTripsOccurrences
} from '../utils';
import { createQueryObject } from '../utils/search';
import { LIST_LIMITS } from '../utils/constants';
import { createErrorObject } from '../utils/dataStore';
import history from '../utils/history';
import {
  getTripsExportColumns,
  getReportDisplayFields
} from '../utils/listUtils';
import { getHasTeams, getSelectedTeamId } from '../selectors/teams';
import { getTagOptions } from '../selectors/filters';
import { URLs } from '../utils/urls';
import { selectorTripsList } from '../selectors/list/trips';
import { ReduxActionType } from '../utils/reduxActionType';
import get from 'lodash/get';
import { selectorTripsMergeContainer } from '../selectors/trip/selectorTripsMergeContainer';
import { setFreeTripsLeft } from './user';

export const ACTION_TYPE_TRIP_SAVE = new ReduxActionType('trip-save');
export const ACTION_GET_USER_RECENT_PLACES = new ReduxActionType(
  'get-user-recent-places'
);
export const FILTER_TEAM_TRIPS_APPROVED = new Array(5, 3, 2, 1); // show trips with non-NEW approval status
export const FILTER_TEAM_TRIPS_ABORTED = new Array(5, 4, 3, 2, 1); // show all aborted team trips
export const FILTER_TEAM_TRIPS_NEW = [4];
export const TRIP_REFRESH_INTERVAL_MS = 90 * 1000;

export const ACTION_TRIPS_MERGE_MODAL_OPEN = new ReduxActionType(
  'trips-merge-modal-open'
);
export const ACTION_GET_TRIPS_TO_MERGE = new ReduxActionType(
  'trips-get-trips-to-merge'
);
export const ACTION_TYPE_TRIPS_MERGE = new ReduxActionType('trips-merge');
export const ACTION_TYPE_UPDATE_TRIPS_TO_MERGE = new ReduxActionType(
  'update-trips-to-merge'
);

export const getTrips = (listType, optionsForApi = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const hasTeams = getHasTeams(state);
  const { selectedTeam } = state.teamUserInfo;
  const { filters, sortBy } = state.listNew[listType];

  const queryObj = createQueryObject(filters, hasTeams);
  queryObj.limit = `${LIST_LIMITS.TWENTY}`;

  const { method, url } = URLs.trips.getTrips(
    listType === 'teamTrips',
    selectedTeam,
    qs.stringify(queryObj)
  );
  serverRequest(method, url, state, optionsForApi).then(resp => {
    const sortByColumn = sortBy || { departure_time: 'desc' };
    const orderColumn = Object.keys(sortByColumn)[0];
    const orderedList = _orderBy(
      resp.trips,
      [orderColumn],
      [sortByColumn[orderColumn]]
    );

    dispatch(sortListByColumn(sortByColumn, orderedList));
    dispatch(setList(orderedList, resp.pagination, listType));
    dispatch(toggleisUpdatingData(listType));
  });
};

export const getTripDetails = tripId => (dispatch, getState) => {
  const state = getState();
  const { method, url } = URLs.trips.getDetails(tripId);
  serverRequest(method, url, state)
    .then(resp => {
      dispatch(setOpenedItemId(tripId));
      dispatch(setOpenedItem(resp, tripId));
    })
    .catch(({ err, statusCode }) => {
      if (statusCode === 404) {
        dispatch(setError(createErrorObject(err.detail, 'trips_invalid_trip')));
      }
    });
};

export const deleteTrip = trip => {
  // Abort (delete) a single trip from "Trip Details" popup
  return (dispatch, getState) => {
    const { method, url } = URLs.trips.abortTrip(trip.id);
    serverRequest(method, url, getState()).then(json => {
      if (json.valid) {
        const { user } = json;
        if (user) {
          dispatch(setUnclassifiedTrips(user.untagged_trip_count));
          dispatch(setFreeTripsLeft(user.trips_left));
        }
      }
      const state = getState();
      const { list } = getActiveListGroup(state);
      const { activeList } = state.listNew;
      const tripIndex = list.findIndex(item => item.id === trip.id);
      if (list[tripIndex + 1]) {
        const { id } = list[tripIndex + 1];
        let dashUrl = getDashboardLinkFor('tripDetails', { id });
        if (activeList === 'reportTrips') {
          const { openedItem } = state.listNew.reports;
          dashUrl = getDashboardLinkFor('reportTripId', {
            reportId: openedItem,
            tripId: id
          });
        }
        history.push(dashUrl);
        dispatch(openListItem(id, activeList));
      } else if (list[tripIndex - 1]) {
        const { id } = list[tripIndex - 1];
        let dashUrl = getDashboardLinkFor('tripDetails', { id });
        if (activeList === 'reportTrips') {
          const { openedItem } = state.listNew.reports;
          dashUrl = getDashboardLinkFor('reportTripId', {
            reportId: openedItem,
            tripId: id
          });
        }
        history.push(dashUrl);
        dispatch(openListItem(id, activeList));
      } else {
        let dashUrl = getDashboardLinkFor('trips', { for: activeList });
        if (activeList === 'reportTrips') {
          dashUrl = getDashboardLinkFor('reportTrips');
        }
        dispatch(closeItem());
        history.push(dashUrl);
      }

      dispatch(deleteItems({ id: trip.id }));
      dispatch(
        updateOpenedItems(
          Object.assign({}, trip, { status: 'aborted' }),
          trip.id
        )
      );
    });
  };
};
export const deleteTrips = (state, trips) => {
  // Abort (delete) one or more trips from the "Trips" list
  var api = {};
  const hasTeams = getHasTeams(state);
  if (hasTeams) {
    const teamId = getSelectedTeamId(state);
    const { method, url } = URLs.trips.abortTeamTrips(teamId);
    return serverRequest(method, url, state, {
      body: { trip_ids: trips }
    });
  } else {
    const { method, url } = URLs.trips.abortTrips();
    return serverRequest(method, url, state, {
      body: { trips_ids: trips.join(',') }
    });
  }
};

export const restoreTrip = trip => (dispatch, getState) => {
  // Restore a single trip from "Trip Details" popup
  const { method, url } = URLs.trips.restoreTrip(trip.id);

  return serverRequest(method, url, getState()).then(json => {
    if (json.valid) {
      const { user } = json;
      if (user) {
        dispatch(setUnclassifiedTrips(user.untagged_trip_count));
        dispatch(setFreeTripsLeft(user.trips_left));
      }
    }
    const state = getState();
    const { list } = getActiveListGroup(state);
    const { activeList } = state.listNew;
    const tripIndex = list.findIndex(item => item.id === trip.id);
    if (list[tripIndex + 1]) {
      const { id } = list[tripIndex + 1];
      let dashUrl = getDashboardLinkFor('tripDetails', { id });
      if (activeList === 'reportTrips') {
        const { openedItem } = state.listNew.reports;
        dashUrl = getDashboardLinkFor('reportTripId', {
          reportId: openedItem,
          tripId: id
        });
      }
      history.push(dashUrl);
      dispatch(openListItem(id, activeList));
    } else if (list[tripIndex - 1]) {
      const { id } = list[tripIndex - 1];
      let dashUrl = getDashboardLinkFor('tripDetails', { id });
      if (activeList === 'reportTrips') {
        const { openedItem } = state.listNew.reports;
        dashUrl = getDashboardLinkFor('reportTripId', {
          reportId: openedItem,
          tripId: id
        });
      }
      history.push(dashUrl);
      dispatch(openListItem(id, activeList));
    } else {
      let dashUrl = getDashboardLinkFor('trips', { for: activeList });
      if (activeList === 'reportTrips') {
        dashUrl = getDashboardLinkFor('reportTrips');
      }
      dispatch(closeItem());
      history.push(dashUrl);
    }

    dispatch(restoreItems([trip.id]));
    dispatch(
      updateOpenedItems(
        Object.assign({}, trip, { status: 'completed' }),
        trip.id
      )
    );
  });
};
export const restoreTrips = (state, trips) => {
  // Restore one or more trips from the "Trips" list
  const hasTeams = getHasTeams(state);
  if (hasTeams) {
    const teamId = getSelectedTeamId(state);
    const { method, url } = URLs.trips.restoreTeamTrips(teamId);
    return serverRequest(method, url, state, {
      body: { trip_ids: trips }
    });
  } else {
    const { method, url } = URLs.trips.restoreTrips(trips.join(','));
    return serverRequest(method, url, state);
  }
};

export const exportTripsList = (state, format, listType) => {
  const { timeFormatPreference } = state;
  const hasTeams = getHasTeams(state);
  const { selectedTeam } = state.teamUserInfo;
  const { filters, displayFields } = state.listNew[listType];

  const queryObj = createQueryObject(filters, hasTeams);
  queryObj.format = format;
  queryObj['time-format'] = timeFormatPreference;
  queryObj.columns = getTripsExportColumns(
    displayFields,
    getHeaders(state),
    getReportDisplayFields(false, hasTeams),
    true
  ).join(',');

  const { method, url } = URLs.trips.exportList(
    listType === 'teamTrips',
    selectedTeam,
    qs.stringify(queryObj)
  );
  return new Promise(resolve => {
    serverRequest(method, url, state, { format: 'blob' }).then(blob => {
      const {
        listNew: {
          [listType]: {
            filters: { start_date: startDate, end_date: endDate },
            list
          }
        },
        user: {
          details: { created }
        }
      } = state;
      let fileName = 'trips_';
      const dateFormatter = getDateFormatter(state);
      if (startDate && endDate) {
        fileName += `${dateFormatter(
          new Date(startDate).getTime()
        )}_${dateFormatter(new Date(endDate).getTime())}`;
      } else {
        fileName += `${dateFormatter(created * 1000)}_${dateFormatter(
          list[0].departure_time * 1000
        )}`;
      }
      resolve({ blob, fileName: `${fileName}.${format}` });
    });
  });
};

export const updateStep = (dispatch, state, stepObj, body) => {
  const { method, url } = URLs.trips.updateStep(stepObj.trip_id, stepObj.id);
  return serverRequest(method, url, state, { body }).then(
    ({ trip, step = {} }) => {
      const index = getOpenedItemIndex(state);
      const openedItem = getOpenedItem(state);
      const { steps } = openedItem;
      const stepsNew = steps.map(stepN => {
        return stepN.id === step.id ? step : stepN;
      });
      const tripObj = Object.assign({}, trip, { steps: stepsNew });

      dispatch(updateOpenedItems(tripObj, index));
      dispatch(updateListItem(tripObj, index));
      dispatch(checkFilters());
    }
  );
};

export const searchTrips = (searchQuery, listType) => (dispatch, getState) => {
  const state = getState();
  const activeList = getActiveList(state);
  const hasTeams = getHasTeams(state);
  const {
    vehicles: { vehiclesMap = {} }
  } = getState();
  const tagOptions = getTagOptions(state);
  dispatch(listDeselectAll(activeList));
  dispatch(setFilterSearchText(searchQuery));

  const exactTerms = [];
  const restArray = [];
  const searchArray = searchQuery.split(`"`);
  for (let i = 0; i < searchArray.length; i++) {
    if (i % 2 !== 0) {
      exactTerms.push(`"${searchArray[i]}"`);
    } else {
      searchArray[i] && restArray.push(searchArray[i]);
    }
  }
  const searchFilters = getFiltersFromText(
    restArray.join(`,`),
    tagOptions,
    vehiclesMap
  );

  if (searchFilters.text && exactTerms.length) {
    searchFilters.text = `${searchFilters.text},${exactTerms.join(`,`)}`;
  } else if (exactTerms.length) {
    searchFilters.text = exactTerms.join(`,`);
  }

  dispatch(setFiltersFromSearchText(searchFilters, hasTeams, listType));
};

export const getTripsListToShowOnTeamChange = () => (dispatch, getState) => {
  const state = getState();
  const {
    teamUserInfo: { rolesInSelectedTeam }
  } = state;
  let listType = 'trips';
  if (rolesInSelectedTeam.length === 1) {
    if (
      rolesInSelectedTeam.includes('admin') ||
      rolesInSelectedTeam.includes('manager')
    ) {
      listType = 'teamTrips';
    }
  }

  history.push(getDashboardLinkFor('trips', { for: listType }));
  dispatch(getList(listType, true));
};

export const saveCompletedTrip = data => (dispatch, getState) => {
  let body = {};
  const listType = 'trips';
  const state = getState();
  const { method, url, requestPayloadTransformer } = URLs.trips.createNewTrip();

  if (requestPayloadTransformer) {
    body = requestPayloadTransformer(data);
  }

  dispatch({
    type: ACTION_TYPE_TRIP_SAVE.process,
    payload: true
  });

  return serverRequest(method, url, state, { body })
    .then(response => {
      const { user, trip } = response;
      if (trip) {
        dispatch({
          type: ACTION_TYPE_TRIP_SAVE.success
        });
        dispatch({
          type: ACTION_TYPE_TRIP_SAVE.error,
          payload: null
        });
        const { trip } = response;
        const { trips, sortBy } = selectorTripsList(state);
        const newTrips = [trip, ...trips];
        const sortByColumn = sortBy || { departure_time: 'desc' };
        const orderColumn = Object.keys(sortByColumn)[0];
        const orderedList = _orderBy(
          newTrips,
          [orderColumn],
          [sortByColumn[orderColumn]]
        );
        dispatch(updateList(orderedList, listType));
        history.push(getDashboardLinkFor('trips'));
      }
      if (user) {
        dispatch(setUnclassifiedTrips(user.untagged_trip_count));
        dispatch(setFreeTripsLeft(user.trips_left));
      }
    })
    .catch(err =>
      dispatch({
        type: ACTION_TYPE_TRIP_SAVE.error,
        payload: get(err, ['err', 'errors', 'validation'], '') || err
      })
    )
    .finally(() =>
      dispatch({
        type: ACTION_TYPE_TRIP_SAVE.process,
        payload: false
      })
    );
};

export const getUserRecentPlaces = ({ searchTerm }) => (dispatch, getState) => {
  const state = getState();
  const { method, url, responseTransformer } = URLs.trips.getRecentPlaces({
    userId: state.user.id,
    searchTerm
  });

  dispatch({
    type: ACTION_GET_USER_RECENT_PLACES.process,
    payload: true
  });

  return serverRequest(method, url, state)
    .then(response => {
      if (response && response.destinations) {
        const { destinations } = response;
        const payload = responseTransformer
          ? responseTransformer(destinations)
          : destinations;
        dispatch({
          type: ACTION_GET_USER_RECENT_PLACES.success,
          payload
        });
        dispatch({
          type: ACTION_GET_USER_RECENT_PLACES.error,
          payload: null
        });
      }
    })
    .catch(err =>
      dispatch({
        type: ACTION_GET_USER_RECENT_PLACES.error,
        payload: err
      })
    )
    .finally(() =>
      dispatch({
        type: ACTION_GET_USER_RECENT_PLACES.process,
        payload: false
      })
    );
};

export const openTripsMergeModal = () => (dispatch, getState) => {
  dispatch({
    type: ACTION_TRIPS_MERGE_MODAL_OPEN.success,
    payload: true
  });
  dispatch(getTripsToMerge());
};

export const closeTripsMergeModal = () => (dispatch, getState) => {
  dispatch({
    type: ACTION_TRIPS_MERGE_MODAL_OPEN.success,
    payload: false
  });
};

export const getTripsToMerge = (listType = 'trips') => (dispatch, getState) => {
  const state = getState();
  const listGroup = getActiveListGroup(state);
  const { selectedItems = {}, list = [] } = listGroup;
  const selectedDatesWithMultipleTrips = getSameDayTripsOccurrences(
    list,
    Object.values(selectedItems)
  );
  const [date, tripIds] = selectedDatesWithMultipleTrips[0];

  const { start_date, end_date } = list
    .filter(({ id }) => tripIds.includes(id))
    .reduce(
      (acc, { arrival_time, departure_time }) => {
        return {
          start_date: Math.min(departure_time, acc.start_date),
          end_date: Math.max(arrival_time, acc.end_date)
        };
      },
      { start_date: Number.MAX_SAFE_INTEGER, end_date: 0 }
    );

  const { selectedTeam } = state.teamUserInfo;

  const { method, url } = URLs.trips.getTrips(
    listType === 'teamTrips',
    selectedTeam,
    qs.stringify({ status: 'completed', start_date, end_date })
  );

  const {
    method: detailsMethod,
    url: detailsUrl
  } = URLs.trips.getMultipleTripsDetails(tripIds);

  dispatch({
    type: ACTION_GET_TRIPS_TO_MERGE.process,
    payload: true
  });

  return Promise.allSettled([
    serverRequest(method, url, state),
    serverRequest(detailsMethod, detailsUrl, state)
  ])
    .then(response => {
      if (response.every(promise => promise.status === 'fulfilled')) {
        const [satePromiseResult, detailsPromiseResult] = response;
        const {
          pagination: { stats }
        } = satePromiseResult.value;
        const { results: trips } = detailsPromiseResult.value;

        const orderedTrips = _orderBy(trips, ['departure_time'], 'asc');

        dispatch({
          type: ACTION_GET_TRIPS_TO_MERGE.success,
          payload: {
            date,
            trips: orderedTrips,
            total_distance: stats.total_distance
          }
        });

        dispatch({
          type: ACTION_GET_TRIPS_TO_MERGE.error,
          payload: null
        });
      } else {
        dispatch({
          type: ACTION_GET_TRIPS_TO_MERGE.error,
          payload: response
            .filter(promise => promise.status === 'rejected')
            .map(({ reason }) => reason)
            .join(', ')
        });
      }
    })
    .catch(err =>
      dispatch({
        type: ACTION_GET_TRIPS_TO_MERGE.error,
        payload: err
      })
    )
    .finally(() =>
      dispatch({
        type: ACTION_GET_TRIPS_TO_MERGE.process,
        payload: false
      })
    );
};

export const onTripsMerge = (mergeTo, mergeFromList, isComplete) => (
  dispatch,
  getState
) => {
  const listType = 'trips';
  const state = getState();
  const { method, url } = URLs.trips.mergeTrips();
  const body = {
    allow_merge: true,
    merge_to: mergeTo,
    merge_from_list: mergeFromList
  };

  dispatch({
    type: ACTION_TYPE_TRIPS_MERGE.process,
    payload: true
  });

  return serverRequest(method, url, state, { body })
    .then(response => {
      const { merged, aborted } = response || {};

      if (merged) {
        const abortedToArray =
          aborted && Array.isArray(aborted) ? aborted : [aborted];
        const abortedIds = abortedToArray.map(({ id }) => id);
        const { trips, sortBy } = selectorTripsList(state);
        const { tripsMergeForm } = selectorTripsMergeContainer(state);
        let tripsToMerge = tripsMergeForm.trips.slice();

        if (!isComplete) {
          abortedIds.forEach(removableId => {
            dispatch(
              listDeselect(trips.findIndex(({ id }) => id === removableId))
            );
            tripsToMerge = tripsToMerge.filter(({ id }) => id !== removableId);
          });

          tripsToMerge[
            tripsToMerge.findIndex(({ id }) => id === merged.id)
          ] = merged;

          dispatch({
            type: ACTION_TYPE_UPDATE_TRIPS_TO_MERGE.success,
            payload: tripsToMerge
          });
        }

        dispatch({
          type: ACTION_TYPE_TRIPS_MERGE.success
        });
        dispatch({
          type: ACTION_TYPE_TRIPS_MERGE.error,
          payload: null
        });

        trips[trips.findIndex(({ id }) => id === merged.id)] = merged;
        const tripsSanitized = trips.filter(
          ({ id }) => !abortedIds.includes(id)
        );
        const sortByColumn = sortBy || { departure_time: 'desc' };
        const orderColumn = Object.keys(sortByColumn)[0];
        const orderedList = _orderBy(
          tripsSanitized,
          [orderColumn],
          [sortByColumn[orderColumn]]
        );

        dispatch(updateList(orderedList, listType));

        dispatch(listDeselectAll('trips'));
        if (isComplete) {
          dispatch(closeTripsMergeModal());
        }
      }
    })
    .catch(err =>
      dispatch({
        type: ACTION_TYPE_TRIPS_MERGE.error,
        payload:
          err.message ||
          (get(err, ['err', 'non_field_errors'], []) || []).join('|')
      })
    )
    .finally(() =>
      dispatch({
        type: ACTION_TYPE_TRIPS_MERGE.process,
        payload: false
      })
    );
};
