import React from 'react';
import PropTypes from 'prop-types';

const CircleLoader = ({ loadingText, className }) => (
  <div className={`loadingMore ${className}`}>
    <div className="circleLoader">{loadingText}</div>
  </div>
);

CircleLoader.propTypes = {
  loadingText: PropTypes.string,
  className: PropTypes.string
};
CircleLoader.defaultProps = {
  loadingText: '',
  className: ''
};

export default CircleLoader;
