import serverRequest from '../utils/Api';
import { getCleanTeamMembers, createErrorObject } from '../utils/dataStore';

import {
  getSelectedItemIndex,
  getTeamMembersList,
  getTeamsList
} from '../selectors/teams';
import { wrongPassword, changePassword } from '../actions/login';
import { requestTeamsList } from '../actions/teams';
import { setError } from '../actions/errors';
import { URLs } from '../utils/urls';

export const SELECT_MEMBERS_LIST_ITEM = 'SELECT_MEMBERS_LIST_ITEM';
export const DESELECT_MEMBERS_LIST_ITEM = 'DESELECT_MEMBERS_LIST_ITEM';
export const SELECT_ALL_MEMBERS_LIST_ITEMS = 'SELECT_ALL_MEMBERS_LIST_ITEMS';
export const DESELECT_ALL_MEMBERS_LIST_ITEMS =
  'DESELECT_ALL_MEMBERS_LIST_ITEMS';
export const CLOSE_TEAM_MEMBERS_LIST = 'CLOSE_TEAM_MEMBERS_LIST';
export const SET_OPENED_TEAM_ID = 'SET_OPENED_TEAM_ID';
export const SET_TEAM_MEMBERS_LIST = 'SET_TEAM_MEMBERS_LIST';
export const FETCHING_TEAM_MEMBERS_LIST = 'FETCHING_TEAM_MEMBERS_LIST';
export const UPDATE_TEAM_MEMBERS_LIST = 'UPDATE_TEAM_MEMBERS_LIST';
export const UPDATE_TEAM_MEMBERS_MODAL = 'UPDATE_TEAM_MEMBERS_MODAL';

export const selectMembersListItem = itemIndex => ({
  type: SELECT_MEMBERS_LIST_ITEM,
  itemIndex
});
export const deselectMembersListItem = itemIndex => ({
  type: DESELECT_MEMBERS_LIST_ITEM,
  itemIndex
});
export const selectAllMembersListItems = () => ({
  type: SELECT_ALL_MEMBERS_LIST_ITEMS
});
export const deselectAllMembersListItems = () => ({
  type: DESELECT_ALL_MEMBERS_LIST_ITEMS
});
export const closeTeamMembersList = () => ({
  type: CLOSE_TEAM_MEMBERS_LIST
});
export const setOpenedTeamId = teamId => ({
  type: SET_OPENED_TEAM_ID,
  teamId
});
export const setTeamMembersList = (list, pagination, stats) => ({
  type: SET_TEAM_MEMBERS_LIST,
  list,
  pagination,
  stats
});
export const fetchingTeamMembersList = () => ({
  type: FETCHING_TEAM_MEMBERS_LIST
});
export const updateTeamMembersList = list => ({
  type: UPDATE_TEAM_MEMBERS_LIST,
  list
});
export const updateTeamMembersModal = modal => ({
  type: UPDATE_TEAM_MEMBERS_MODAL,
  modal
});

const mergeTeamMembersList = (list, pagination, stats, teamId) => (
  dispatch,
  getState
) => {
  const { list: oldList, teamId: oldTeamId } = getState().teamMembers;
  let newList = list;
  if (oldList && oldList.length && teamId === oldTeamId) {
    newList = list.map(el => {
      const oldElObj = oldList.find(oldEl => oldEl.id === el.id);
      if (oldElObj && oldElObj.isSelected) {
        return Object.assign({}, el, { isSelected: oldElObj.isSelected });
      }
      return el;
    });
  }
  dispatch(setTeamMembersList(newList, pagination, stats));
};

const updateTeamMemberListFromInviteResponse = (list, teamId) => (
  dispatch,
  getState
) => {
  const { list: oldList, teamId: oldTeamId } = getState().teamMembers;
  let newList = oldList;
  if (oldList && oldList.length && teamId === oldTeamId) {
    newList = list.concat(oldList);
  }
  dispatch(updateTeamMembersList(newList));
};

const requestTeamMembersList = (teamId, searchQuery) => (
  dispatch,
  getState
) => {
  var { method, url } = URLs.teamMembers.getList(teamId);
  if (searchQuery) {
    url += '&search=' + encodeURIComponent(searchQuery.split(' ').join(','));
  }
  return serverRequest(method, url, getState()).then(response => {
    dispatch(
      mergeTeamMembersList(
        getCleanTeamMembers(response.team_users),
        response.pagination,
        {
          count: response.pagination.stats.team_users_count
        },
        teamId
      )
    );
  });
};

export const fetchTeamMembersList = (teamId, searchQuery) => dispatch => {
  dispatch(setOpenedTeamId(teamId));
  dispatch(requestTeamMembersList(teamId, searchQuery));
};

export const getNextPageForTeamMembersList = () => (dispatch, getState) => {
  const state = getState();
  const {
    teamMembers: {
      pagination: { next },
      isFetching,
      list
    }
  } = state;
  if (isFetching || !next) {
    return;
  }

  dispatch(fetchingTeamMembersList());
  let url = next;
  if (url.indexOf(process.env.REACT_APP_api_url) === 0) {
    url = url.split(process.env.REACT_APP_api_url)[1];
  }
  serverRequest('get', url, state).then(resp => {
    if (resp.team_users && resp.team_users.length) {
      dispatch(
        setTeamMembersList(list.concat(resp.team_users), resp.pagination)
      );
    }
  });
};

export const selectMemberInList = itemId => (dispatch, getState) => {
  const {
    teamMembers: { list }
  } = getState();
  dispatch(selectMembersListItem(getSelectedItemIndex(list, itemId)));
};
export const deselectMemberInList = itemId => (dispatch, getState) => {
  const {
    teamMembers: { list }
  } = getState();
  dispatch(deselectMembersListItem(getSelectedItemIndex(list, itemId)));
};

export const deleteTeamMember = (
  companyId,
  teamId,
  memberId,
  change = false
) => (dispatch, getState) => {
  const { method, url } = URLs.teamMembers.delete(teamId, memberId);
  serverRequest(method, url, getState()).then(
    resp => {
      change && dispatch(changePassword());
      const { list: membersList } = getState().teamMembers;
      dispatch(requestTeamMembersList(teamId, null));
      dispatch(requestTeamsList(companyId));
    },
    err => {
      let message =
        err.details || (err.err && err.err.non_field_errors)
          ? err.err.non_field_errors[0]
          : '';
      dispatch(
        setError(createErrorObject(err.detail, 'member_invite_forbidden'))
      );
    }
  );
};

export const checkPasswordAndDeleteTeamMember = (
  password,
  teamId,
  memberId
) => (dispatch, getState) => {
  const {
    user: {
      details: { email }
    },
    company: { id: companyId }
  } = getState();
  const body = { email, password };
  const { method, url } = URLs.user.login();
  serverRequest(method, url, getState(), {
    body,
    doNotAuthorize: true
  }).then(response => {
    if (response.valid) {
      dispatch(deleteTeamMember(companyId, teamId, memberId, true));
    } else {
      dispatch(wrongPassword());
    }
  });
};

export const inviteTeamMember = (
  invitees,
  authGroupId,
  teamId,
  permissions
) => (dispatch, getState) => {
  const state = getState();
  const { id } = getTeamsList(state)[0];
  const teamID = teamId || id;
  const userRole = {
    role: authGroupId
  };
  if (permissions) {
    userRole.min_approval = permissions.minApproval || -1;
    userRole.max_approval = permissions.maxApproval || -1;
    userRole.manage_rates = permissions.manageRates;
    userRole.manage_roles = permissions.manageRoles;
    userRole.manage_vehicles = permissions.manageVehicles;
    userRole.approve_own_report = permissions.approveOwnReports;
  }

  const body = {
    invites: invitees,
    user_roles: [userRole]
  };
  const { method, url } = URLs.teamMembers.invite(teamID);
  return serverRequest(method, url, state, { body })
    .then(response => {
      var results = response.team_users;
      if (!results) {
        results = [response];
      }
      dispatch(updateTeamMemberListFromInviteResponse(results, teamID));
      dispatch(updateTeamMembersModal(''));
    })
    .catch(({ err, statusCode }) => {
      if (statusCode === 403) {
        dispatch(
          setError(createErrorObject(err.detail, 'member_invite_forbidden'))
        );
      }
      dispatch(updateTeamMembersModal(''));
    });
};

export const revokeTeamMemberInvite = (teamId, memberId) => (
  dispatch,
  getState
) => {
  const {
    teamMembers: { list },
    company: { id: companyId }
  } = getState();
  const newList = list.filter(el => el.id !== memberId);
  dispatch(updateTeamMembersList(newList));
  dispatch(deleteTeamMember(companyId, teamId, memberId));
};
export const resendTeamMemberInvite = (teamId, member) => (
  dispatch,
  getState
) => {
  const { method, url } = URLs.teamMembers.resendInvitation(teamId, member.id);
  serverRequest(method, url, getState()).then(resp => {
    if (resp && resp.invites && resp.invites.length) {
      const teamMembersList = [...getTeamMembersList(getState())];
      const index = teamMembersList.findIndex(
        teamMember => teamMember.id === resp.id
      );
      teamMembersList[index] = resp;
      dispatch(updateTeamMembersList(teamMembersList));
    }
  });
};

export const updateMemberRoles = (teamId, memberId, updatedRoles) => (
  dispatch,
  getState
) => {
  const body = updatedRoles.map(updatedRole => {
    const newRule = {};
    if (updatedRole.role === 'admin') {
      newRule.role = 1;
      newRule.approve_own_report =
        updatedRole.managerCapabilities.approveOwnReports;
    } else if (updatedRole.role === 'manager') {
      newRule.role = 3;
      newRule.min_approval = updatedRole.managerCapabilities.fromAmount;
      newRule.max_approval = updatedRole.managerCapabilities.toAmount;
      newRule.manage_rates = updatedRole.managerCapabilities.manageMileageRates;
      newRule.manage_roles = updatedRole.managerCapabilities.manageInvites;
      newRule.manage_vehicles =
        updatedRole.managerCapabilities.manageOrderBeacons;
      newRule.approve_own_report =
        updatedRole.managerCapabilities.approveOwnReports;
    } else {
      // employee
      newRule.role = 2;
      newRule.email_out = updatedRole.employeeCapabilities.emailOut;
      newRule.email_out_format =
        updatedRole.employeeCapabilities.emailOutFormat;
    }

    return newRule;
  });

  const { method, url } = URLs.teamMembers.updateRoles(teamId, memberId);
  return serverRequest(method, url, getState(), { body })
    .then(
      () => {
        dispatch(requestTeamMembersList(teamId, null));
      },
      err => {
        let message =
          err.details || (err.err && err.err.non_field_errors)
            ? err.err.non_field_errors[0]
            : '';
        dispatch(
          setError(createErrorObject(message, 'member_invite_forbidden'))
        );
        console.error('addPaymentMethod: ', err);
      }
    )
    .catch(({ err, statusCode }) => {
      if (statusCode === 403) {
        dispatch(
          setError(createErrorObject(err.detail, 'member_invite_forbidden'))
        );
      }
    });
};
