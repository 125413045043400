import FileSaver from 'file-saver';
import _orderBy from 'lodash/orderBy';
import _isEmpty from 'lodash/isEmpty';

import serverRequest from '../utils/Api';
import {
  getActiveListGroup,
  getSelectedItemsIds,
  getAvailableColumns,
  getSelectedItems,
  getOpenedItem,
  getItemIndex,
  getHeaders,
  getActiveList
} from '../selectors/list';
import { parseDisplayFields, setLocal } from '../selectors/locale';
import { deleteTrips, restoreTrips, getTrips, exportTripsList } from './trips';
import {
  deleteReports,
  requestSelectedReportTrips,
  changeDisplayFields,
  getReports,
  exportReportsList,
  exportReport,
  getReportTrips
} from './reports';
import { setError } from './errors';
import { requestAutomaticReport } from './automaticreports';
import { deleteVehicles } from './vehicles';

import { isEmpty } from '../utils';
import { createErrorObject } from '../utils/dataStore';
import { parseTags } from '../selectors/filters';
import { getReportRules } from './reportRules';
import { changeFilters, resetListFilters } from '.';
import {
  getReportDisplayFields,
  getTripsExportColumns
} from '../utils/listUtils';
import { getHasTeams } from '../selectors/teams';
import { URLs } from '../utils/urls';
import { unsetApiAbortController, setApiAbortController } from './apiMeta';
import { updateTeamReportStats } from './teams';
import { removeListDuplicatesById } from '../utils/trip';
import { isUndefined } from 'lodash';
import { getUserId } from '../selectors/user';
import { setFreeTripsLeft } from './user';

export const SET_ACTIVE_LIST = 'SET_ACTIVE_LIST';
export const SET_LIST = 'SET_LIST';
export const UPDATE_LIST = 'UPDATE_LIST';
export const TOGGLE_LIST_INVALID = 'TOGGLE_LIST_INVALID';
export const UPDATE_TRIPS_LIST = 'UPDATE_TRIPS_LIST';
export const SET_OPENED_ITEM_ID = 'SET_OPENED_ITEM_ID';
export const SET_OPENED_ITEM = 'SET_OPENED_ITEM';
export const CLOSE_ITEM = 'CLOSE_ITEM';
export const SET_DISPLAY_FIELDS = 'SET_DISPLAY_FIELDS';
export const ADD_DISPLAY_FIELD = 'ADD_DISPLAY_FIELD';
export const DELETE_DISPLAY_FIELD = 'DELETE_DISPLAY_FIELD';
export const FETCHING_LIST = 'FETCHING_LIST';
export const NO_FETCHING_LIST = 'NO_FETCHING_LIST';
export const SET_UNCLASSIFIED_TRIPS = 'SET_UNCLASSIFIED_TRIPS';
export const DELETE_LIST_GROUP = 'DELETE_LIST_GROUP';
export const LIST_SELECT = 'LIST_SELECT';
export const LIST_DESELECT = 'LIST_DESELECT';
export const LIST_SELECT_ALL = 'LIST_SELECT_ALL';
export const LIST_DESELECT_ALL = 'LIST_DESELECT_ALL';
export const LIST_SELECT_ALL_ITEMS = 'LIST_SELECT_ALL_ITEMS';
export const UPDATE_OPENED_ITEMS = 'UPDATE_OPENED_ITEMS';
export const UPDATE_LIST_ITEM = 'UPDATE_LIST_ITEM';
export const DELETE_ITEMS = 'DELETE_ITEMS';
export const RESTORE_ITEMS = 'RESTORE_ITEMS';
export const RECEIVE_FORMAT = 'RECEIVE_FORMAT';
export const LIST_SELECT_DETAIL = 'LIST_SELECT_DETAIL';
export const DELETE_OPENED_ITEMS = 'DELETE_OPENED_ITEMS';
export const SET_UNCLASSIFIED_CLICK_TRUE = 'SET_UNCLASSIFIED_CLICK_TRUE';
export const SET_UNCLASSIFIED_CLICK_FALSE = 'SET_UNCLASSIFIED_CLICK_FALSE';
export const SORT_LIST_BY_COLUMN = 'SORT_LIST_BY_COLUMN';
export const SET_LIST_ITEM = 'SET_LIST_ITEM';
export const ADD_LIST_ITEM = 'ADD_LIST_ITEM';
export const UPDATE_SELECTED_ITEMS = 'UPDATE_SELECTED_ITEMS';
export const SET_FILTER_SEARCH_TEXT = 'SET_FILTER_SEARCH_TEXT';
export const UPDATE_LIST_STATS = 'UPDATE_LIST_STATS';
export const REMOVE_OPENED_ITEM_ID = 'REMOVE_OPENED_ITEM_ID';
export const SET_REPORTS_BADGE_COUNT = 'SET_REPORTS_BADGE_COUNT';
export const INCREMENT_REPORTS_BADGE_COUNT = 'INCREMENT_REPORTS_BADGE_COUNT';
export const DECREMENT_REPORTS_BADGE_COUNT = 'DECREMENT_REPORTS_BADGE_COUNT';
export const TOGGLE_IS_UPDATING_DATA = 'TOGGLE_IS_UPDATING_DATA';
export const TOGGLE_EXPORT_LIST_IN_PROGRESS = 'TOGGLE_EXPORT_LIST_IN_PROGRESS';
export const SET_OPENED_REPORT_ID = 'SET_OPENED_REPORT_ID';

export const setActiveList = listType => ({
  type: SET_ACTIVE_LIST,
  listType
});
export const setList = (list, pagination, listType, openedItem) => ({
  type: SET_LIST,
  items: list || [],
  pagination: pagination || {},
  listType,
  openedItem
});
export const updateList = (list, listType) => ({
  type: UPDATE_LIST,
  items: list || [],
  listType
});
export const toggleListInvalid = (listType, isInvalid = true) => ({
  type: TOGGLE_LIST_INVALID,
  isInvalid,
  listType
});
export const updateTripsList = list => ({
  type: UPDATE_TRIPS_LIST,
  list
});
export const setListItem = (item, index, listType) => ({
  type: SET_LIST_ITEM,
  index,
  listType,
  item
});
export const addListItem = (item, listType) => ({
  type: ADD_LIST_ITEM,
  listType,
  item
});
export const setOpenedItemId = (itemId, listType) => ({
  type: SET_OPENED_ITEM_ID,
  itemId,
  listType
});
export const removeOpenedItemId = () => ({
  type: REMOVE_OPENED_ITEM_ID
});
export const setOpenedItem = (item, itemId, listType) => ({
  type: SET_OPENED_ITEM,
  item,
  itemId,
  listType
});
export const closeItem = (listType = '') => ({
  type: CLOSE_ITEM,
  listType
});
export const setFetchingList = listType => ({
  type: FETCHING_LIST,
  listType
});
export const setUnclassifiedTrips = tripsCount => ({
  type: SET_UNCLASSIFIED_TRIPS,
  tripsCount
});
export const setReportsBadgeCount = (reportsCount, listType) => ({
  type: SET_REPORTS_BADGE_COUNT,
  reportsCount,
  listType
});
export const incrementReportsBadgeCount = () => ({
  type: INCREMENT_REPORTS_BADGE_COUNT
});
export const decrementReportsBadgeCount = () => ({
  type: DECREMENT_REPORTS_BADGE_COUNT
});
export const deleteListGroup = listType => ({
  type: DELETE_LIST_GROUP,
  listType
});
export const listSelect = index => ({
  type: LIST_SELECT,
  index
});
export const listDeselect = index => ({
  type: LIST_DESELECT,
  index
});
const listSelectAll = () => ({
  type: LIST_SELECT_ALL
});
export const listDeselectAll = listType => ({
  type: LIST_DESELECT_ALL,
  listType
});
const listSelectAllItems = items => ({
  type: LIST_SELECT_ALL_ITEMS,
  items
});

const noFetchingList = list => ({
  type: NO_FETCHING_LIST,
  list
});

const setDisplayFields = (displayFields, listType) => ({
  type: SET_DISPLAY_FIELDS,
  displayFields,
  listType
});
export const addDisplayField = name => ({
  type: ADD_DISPLAY_FIELD,
  name
});
export const deleteDisplayField = name => ({
  type: DELETE_DISPLAY_FIELD,
  name
});

export const updateOpenedItems = (json, index, listType) => ({
  type: UPDATE_OPENED_ITEMS,
  json,
  index,
  listType
});
export const updateListItem = (json, index, listType) => ({
  type: UPDATE_LIST_ITEM,
  json,
  index,
  listType
});
export const deleteItems = items => ({
  type: DELETE_ITEMS,
  items
});
export const restoreItems = items => ({
  type: RESTORE_ITEMS,
  items
});
export const deleteOpenedItems = (listType = '') => ({
  type: DELETE_OPENED_ITEMS,
  listType
});

export const receiveFormat = (blob, format) => ({
  type: RECEIVE_FORMAT,
  formatType: format,
  blob
});
const listSelectDetail = (index, stats) => ({
  type: LIST_SELECT_DETAIL,
  index,
  stats
});
export const setUnclassifiedClickTrue = () => ({
  type: SET_UNCLASSIFIED_CLICK_TRUE
});
export const setUnclassifiedClickFalse = () => ({
  type: SET_UNCLASSIFIED_CLICK_FALSE
});
export const updateSelectedItems = (selectedItems, listType) => ({
  type: UPDATE_SELECTED_ITEMS,
  selectedItems,
  listType
});
export const setFilterSearchText = (searchText, listType) => ({
  type: SET_FILTER_SEARCH_TEXT,
  searchText,
  listType
});
export const sortListByColumn = (sortBy, orderedList) => ({
  type: SORT_LIST_BY_COLUMN,
  sortBy,
  list: orderedList
});
export const updateListStats = (stats, listType) => ({
  type: UPDATE_LIST_STATS,
  stats,
  listType
});
export const toggleisUpdatingData = (listType, isUpdating = false) => ({
  type: TOGGLE_IS_UPDATING_DATA,
  listType,
  isUpdating
});
export const toggleExportListInProgress = (inProgress = false) => ({
  type: TOGGLE_EXPORT_LIST_IN_PROGRESS,
  inProgress
});
export const setOpenedReportId = (reportId, listType) => ({
  type: SET_OPENED_REPORT_ID,
  reportId,
  listType
});

const updateIndicesOfSelectedItems = (
  selectedItems,
  orderedList
) => dispatch => {
  const newSelectedItemsList = Object.values(selectedItems).reduce(
    (acc, itemId) => {
      const newIndex = orderedList.findIndex(
        listItem => listItem.id === itemId
      );
      acc[newIndex] = itemId;
      return acc;
    },
    {}
  );

  dispatch({
    type: UPDATE_SELECTED_ITEMS,
    selectedItems: newSelectedItemsList
  });
};

export const sortByColumn = sortKey => (dispatch, getState) => {
  const activeListGroup = getActiveListGroup(getState());
  const currentSortKey =
    activeListGroup.sortBy && Object.keys(activeListGroup.sortBy)[0];
  const sortBy = {};

  let sortOrder = 'asc';
  if (currentSortKey === sortKey) {
    sortOrder =
      activeListGroup.sortBy[currentSortKey] === 'asc' ? 'desc' : 'asc';
  }

  sortBy[sortKey] = sortOrder;

  const newOrderedList = _orderBy(activeListGroup.list, [sortKey], [sortOrder]);

  dispatch(sortListByColumn(sortBy, newOrderedList));

  if (!isEmpty(activeListGroup.selectedItems)) {
    // update indices of the items in the selectedItems list
    dispatch(
      updateIndicesOfSelectedItems(
        activeListGroup.selectedItems,
        newOrderedList
      )
    );
  }
};

export const setDisplayAvailableFields = () => (dispatch, getState) => {
  const state = getState();
  const { activeList } = state.listNew;
  const columns = getAvailableColumns(state);
  dispatch(setDisplayFields(columns, activeList));
  activeList === 'trips' &&
    setLocal('visibleColumns', parseDisplayFields(columns));
};

export const getList = (listType, isUpdatingData = true) => (
  dispatch,
  getState
) => {
  const { apiMeta } = getState();
  const keyForAbortController = 'getList';
  if (apiMeta[keyForAbortController]) {
    apiMeta[keyForAbortController].abort &&
      apiMeta[keyForAbortController].abort();
    dispatch(unsetApiAbortController(keyForAbortController));
  }
  dispatch(setActiveList(listType));
  isUpdatingData && dispatch(toggleisUpdatingData(listType, true));
  dispatch(noFetchingList(listType));
  dispatch(setFetchingList(listType));
  dispatch(setDisplayAvailableFields());

  const optionsForApi = { isCancellable: true };
  try {
    const abortController = new window.AbortController();
    dispatch(setApiAbortController(keyForAbortController, abortController));
    optionsForApi.abortController = abortController;
  } catch (err) {
    optionsForApi.isCancellable = false;
  }

  switch (listType) {
    case 'trips':
    case 'teamTrips':
      dispatch(getTrips(listType, optionsForApi));
      break;

    case 'reports':
    case 'teamReports':
      dispatch(getReports(listType, optionsForApi));
      break;

    case 'reportTrips':
      dispatch(getReportTrips());
      break;

    case 'automaticReports':
      dispatch(getReportRules());
      break;

    default:
      break;
  }
};

export const getNextPage = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { activeList } = state.listNew;
    const listGroup = getActiveListGroup(state);
    if (listGroup.isFetching || !listGroup.next) {
      return;
    }
    dispatch(setFetchingList(activeList));

    let url = listGroup.next;
    if (url.indexOf(process.env.REACT_APP_api_url) === 0) {
      url = url.split(process.env.REACT_APP_api_url)[1];
    }

    serverRequest('get', url, state, {
      cancelType: 'list'
    }).then(json => {
      let key = activeList;
      if (activeList === 'reportTrips' || activeList === 'teamTrips') {
        key = 'trips';
      } else if (
        activeList === 'automaticReports' ||
        activeList === 'teamReports'
      ) {
        key = 'reports';
      }
      let finalList = listGroup.list
        ? removeListDuplicatesById(listGroup.list.concat(json[key]))
        : json[key];
      if (listGroup.sortBy) {
        const orderColumn = Object.keys(listGroup.sortBy)[0];
        finalList = _orderBy(
          finalList,
          [orderColumn],
          [listGroup.sortBy[orderColumn]]
        );
      }

      dispatch(setList(finalList, json.pagination));

      if (!isEmpty(listGroup.selectedItems)) {
        // update indices of the items in the selectedItems list
        dispatch(
          updateIndicesOfSelectedItems(listGroup.selectedItems, finalList)
        );
      }
    });
  };
};

export const listSelectAllAction = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { list } = getActiveListGroup(state);

    dispatch(listSelectAll());

    const items = Object.entries(list).reduce((acc, [key, value]) => {
      acc[key] = value.id;
      return acc;
    }, {});

    dispatch(listSelectAllItems(items));
  };
};

export const openListItem = itemId => (dispatch, getState) => {
  dispatch(setOpenedItemId(itemId));
  const state = getState();
  const listGroup = getActiveListGroup(state);

  switch (state.listNew.activeList) {
    case 'reports':
    case 'teamReports':
      dispatch(
        setOpenedItem(
          listGroup.list[getItemIndex(itemId, listGroup.list)],
          itemId
        )
      );
      break;

    case 'automaticReports':
      if (
        !listGroup.openedItems ||
        (listGroup.openedItems && !listGroup.openedItems[itemId])
      ) {
        dispatch(
          setOpenedItem(
            listGroup.list[getItemIndex(itemId, listGroup.list)],
            itemId
          )
        );
      } else {
        requestAutomaticReport(state, itemId).then(json =>
          dispatch(setOpenedItem(json.report, itemId))
        );
      }
      break;

    default:
      break;
  }
};

export const deleteSelection = () => (dispatch, getState) => {
  const state = getState();
  const { activeList, trips } = state.listNew;
  const selectedItemsIds = getSelectedItemsIds(state);
  if (state.changingList) return null;
  let func = null;

  switch (activeList) {
    case 'trips':
    case 'teamTrips':
    case 'reportTrips':
      func = deleteTrips;
      break;
    case 'reports':
    case 'teamReports':
      func = deleteReports;
      break;
    case 'vehicles':
      func = deleteVehicles;
      break;
    default:
  }
  if (!func) {
    return;
  }

  func(state, selectedItemsIds).then(json => {
    if (json.valid) {
      const { user } = json;
      if (user) {
        dispatch(setUnclassifiedTrips(user.untagged_trip_count));
        dispatch(setFreeTripsLeft(user.trips_left));
      }
      dispatch(listDeselectAll());
      dispatch(getList(activeList));
      if (activeList === 'trips' || activeList === 'teamTrips') {
        trips.openedItems &&
          selectedItemsIds.forEach(el => {
            if (trips.openedItems[el]) {
              dispatch(
                updateOpenedItems(
                  Object.assign({}, trips.openedItems[el], {
                    status: 'aborted'
                  }),
                  el
                )
              );
            }
          });
      } else if (['reports', 'teamReports'].includes(activeList) && json.team) {
        dispatch(updateTeamReportStats(json.team.id, json.team.report_stats));
      }
    }
  });
};

// un-abort trips
export const restoreSelection = () => (dispatch, getState) => {
  const state = getState();
  const { activeList } = state.listNew;
  const trips = state.listNew[activeList];
  const selectedItemsIds = getSelectedItemsIds(state);
  if (state.changingList) return null;
  let func = null;
  let untaggedCount = 0;
  selectedItemsIds.forEach(itemId => {
    const item = trips && trips.list.find(el => el.id === itemId);
    _isEmpty(item.tags) && untaggedCount++;
  });

  switch (activeList) {
    case 'trips':
    case 'teamTrips':
      func = restoreTrips;
      break;
    default:
  }
  if (!func) {
    return;
  }

  func(state, selectedItemsIds).then(json => {
    if (json.valid) {
      const { user } = json;
      if (user) {
        dispatch(setUnclassifiedTrips(user.untagged_trip_count));
        dispatch(setFreeTripsLeft(user.trips_left));
      }
      dispatch(listDeselectAll());
      dispatch(getList(activeList));
      trips.openedItems &&
        selectedItemsIds.forEach(el => {
          if (trips.openedItems[el]) {
            dispatch(
              updateOpenedItems(
                Object.assign({}, trips.openedItems[el], {
                  status: 'completed'
                }),
                el
              )
            );
          }
        });
    }
  });
};

export const getFormat = (format, listType) => (dispatch, getState) => {
  const state = getState();

  dispatch(toggleExportListInProgress(true));
  let getData = null;
  switch (state.listNew.activeList) {
    case 'trips':
    case 'teamTrips':
      getData = exportTripsList;
      break;
    case 'reports':
    case 'teamReports':
      getData = exportReportsList;
      break;
    case 'reportTrips':
      getData = exportReport;
      break;
    default:
  }

  let promises = getData(state, format, listType);
  promises = promises.length ? promises : [promises];
  promises.map(promise =>
    promise.then(response => {
      dispatch(receiveFormat(response.blob, response.format));
      FileSaver.saveAs(response.blob, response.fileName);
      dispatch(toggleExportListInProgress());
    })
  );
};

export const checkFilters = () => (dispatch, getState) => {
  const state = getState();
  const { filters } = getActiveListGroup(state);
  if (filters === undefined) {
    return;
  }
  const activeFilters = Object.entries(filters).reduce((acc, [_, value]) => {
    !isEmpty(value) && acc++;
    return acc;
  }, 0);
  const selectedItems = getSelectedItems(state);
  const openedItem = getOpenedItem(state);
  const list = openedItem ? { 0: openedItem } : selectedItems;

  activeFilters > 1 &&
    Object.values(list).forEach(({ tags, user_vehicles: vehicles, id }) => {
      Object.entries(filters).forEach(([key, value]) => {
        switch (key) {
          case 'tags':
            const tagsArr = parseTags(tags);
            !isEmpty(value) && value[0] === ''
              ? !isEmpty(tags) && dispatch(deleteItems({ id }))
              : !value.some(tag => tagsArr.includes(tag)) &&
                dispatch(deleteItems({ id }));
            break;
          case 'vehicles':
            !isEmpty(value) &&
              !value.some(vehicle => vehicles.includes(vehicle)) &&
              dispatch(deleteItems({ id }));
            break;
          default:
            break;
        }
      });
    });
};

export const getListSelectDetail = (index, item) => (dispatch, getState) => {
  const state = getState();
  const { activeList } = state.listNew;
  let func = null;
  switch (activeList) {
    case 'reports':
      func = requestSelectedReportTrips(state, item);
      break;
    default:
  }
  func.then(json => {
    const {
      pagination: { stats }
    } = json;
    json.pagination && dispatch(listSelectDetail(index, stats));
  });
};

export const getReportDetailsBeforeOpening = reportId => (
  dispatch,
  getState
) => {
  const state = getState();
  const { url, method } = URLs.reports.getDetails(reportId);
  serverRequest(method, url, state)
    .then(json => {
      var activeList = getActiveList(state);
      if (isUndefined(activeList)) {
        const hasTeams = getHasTeams(state);
        activeList =
          hasTeams && json.report.owner.id === getUserId(state)
            ? 'reports'
            : 'teamReports';
      }
      dispatch(setOpenedItemId(reportId, activeList));
      dispatch(setOpenedItem(json.report, reportId, activeList));
    })
    .catch(({ err, statusCode }) => {
      if (statusCode === 404) {
        dispatch(
          setError(createErrorObject(err.detail, 'reportTrips_invalid_report'))
        );
      }
    });
};

export const addDisplayFieldAction = key => (dispatch, getState) => {
  const state = getState();
  const hasTeams = getHasTeams(state);
  const activeList = getActiveList(state);
  const activeListGroup = getActiveListGroup(state);
  const { displayFields } = activeListGroup;
  const fields = { ...displayFields, [key]: true, id: true };

  switch (activeList) {
    case 'trips':
    case 'teamTrips':
      setLocal('visibleColumns', parseDisplayFields(fields));
      break;
    case 'reportTrips':
      const displayFieldsToBe = getTripsExportColumns(
        fields,
        getHeaders(getState()),
        getReportDisplayFields(false, hasTeams)
      );
      dispatch(changeDisplayFields(displayFieldsToBe));
      dispatch(toggleListInvalid('reports', true));
      break;
    default:
      break;
  }
  dispatch(addDisplayField(key));
};
export const deleteDisplayFieldAction = key => (dispatch, getState) => {
  const state = getState();
  const hasTeams = getHasTeams(state);
  const activeList = getActiveList(state);
  const activeListGroup = getActiveListGroup(state);
  const { displayFields } = activeListGroup;
  displayFields.id = true;
  const { [key]: deleteField, ...fields } = displayFields;

  switch (activeList) {
    case 'trips':
    case 'teamTrips':
      setLocal('visibleColumns', parseDisplayFields(fields));
      break;
    case 'reportTrips':
      const displayFieldsToBe = getTripsExportColumns(
        fields,
        getHeaders(getState()),
        getReportDisplayFields(false, hasTeams)
      );
      dispatch(changeDisplayFields(displayFieldsToBe));
      break;
    default:
      break;
  }
  dispatch(deleteDisplayField(key));
};

export const findIndexAndSelect = (tripId, listType) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { list } = state.listNew[listType];
  let tripIndex = null;
  list.forEach((el, index) => {
    if (el.id === tripId) {
      tripIndex = index;
    }
  });
  dispatch(listSelect(tripIndex.toString()));
};
export const findIndexAndDeselect = (tripId, listType) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { list } = state.listNew[listType];
  let tripIndex = null;
  list.forEach((el, index) => {
    if (el.id === tripId) {
      tripIndex = index;
    }
  });
  dispatch(listDeselect(tripIndex.toString()));
};

export const removeItemFromList = (itemId, listType) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { list } = state.listNew[listType];
  const newList = list.filter(el => el.id !== itemId);
  dispatch(updateList(newList));
};

export const toggleListItemSelection = (toSelect, listItemId) => (
  dispatch,
  getState
) => {
  const { list } = getActiveListGroup(getState());
  const itemIndex = getItemIndex(listItemId, list);
  if (toSelect) {
    dispatch(listSelect(itemIndex.toString()));
  } else {
    dispatch(listDeselect(itemIndex.toString()));
  }
};

export const applyStatusFilterForTrips = status => dispatch => {
  dispatch(changeFilters(status));
};
