import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react';

import LanguageSelector from '../components/Landing/LanguageSelector';

import { setAppLanguageAction } from '../actions';

import { getAppLanguage } from '../selectors/app';
import { getIsMobile, getIsTablet, isUserLoggedIn } from '../selectors/user';

import { getPublicLinkFor } from '../utils';
import { trackLoginFromGoToDashboard } from '../utils/analytics';
import { MAIL_TO_LINKS } from '../utils/constants';

const ProductDetails = () => (
  <div className="productDetails">
    <div className="product">
      <Image src="/images/landing/p-logo-footer.png" size="tiny" />
      <span className="name">Psngr</span>
    </div>
    <div className="warmGrey">
      Copyright © 2015 - {new Date().getFullYear()} Psngr BV
    </div>
    <div className="warmGrey">Made in Amsterdam, Netherlands</div>
  </div>
);
const SocialLinks = () => (
  <div className="socialLinks">
    <a
      href="https://www.facebook.com/psngrapp"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Image src="/images/landing/social_facebook.png" size="tiny" />
    </a>
    <a
      href="https://twitter.com/psngrapp"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Image src="/images/landing/social_twitter.png" size="tiny" />
    </a>
    <a href={MAIL_TO_LINKS.GENERAL_CONTACT}>
      <Image src="/images/landing/social_email.png" size="tiny" />
    </a>
  </div>
);

const MobileFooter = ({ language, onLanguageChange, trackEvent, t }) => (
  <div className="section">
    <div className="firstPart">
      <ProductDetails />
      <SocialLinks />
    </div>

    <div className="links">
      <div className="container">
        <a href="/dashboard" className="link" onClick={trackEvent}>
          {t(`home.footer.dashboard`)}
        </a>
        <a href="/help" className="link">
          {t(`home.footer.faq`)}
        </a>
        {/* <a href="#pricing" className="link">
          {t(`home.footer.pricing`)}
        </a> */}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://blog.psngr.co"
          className="link"
        >
          {t(`home.footer.blog`)}
        </a>
      </div>
      <div className="container">
        <LanguageSelector
          language={language}
          onLanguageChange={onLanguageChange}
          className="link"
        />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/terms"
          className="link"
        >
          {t(`home.footer.termsOfUse`)}
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/privacy?lang=${language}`}
          className="link"
        >
          {t(`home.footer.privacyPolicy`)}
        </a>
      </div>
    </div>
  </div>
);
MobileFooter.propTypes = {
  language: PropTypes.string,
  onLanguageChange: PropTypes.func,
  trackEvent: PropTypes.func,
  t: PropTypes.func.isRequired
};

const DesktopFooter = ({ language, onLanguageChange, trackEvent, t }) => (
  <div className="section">
    <ProductDetails />

    <div className="links">
      <div className="container">
        <LanguageSelector
          language={language}
          className="link"
          onLanguageChange={onLanguageChange}
        />
        <a href="/dashboard" className="link" onClick={trackEvent}>
          {t(`home.footer.dashboard`)}
        </a>
        <a href={MAIL_TO_LINKS.GENERAL_CONTACT} className="link">
          {t('home.enterprise.plans.contactUs')}
        </a>
      </div>
      <div className="container">
        <a href="/help" className="link">
          {t(`home.footer.faq`)}
        </a>
        {/* <a href="#pricing" className="link">
          {t(`home.footer.pricing`)}
        </a> */}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://blog.psngr.co"
          className="link"
        >
          {t(`home.footer.blog`)}
        </a>
      </div>
      <div className="container">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/terms"
          className="link"
        >
          {t(`home.footer.termsOfUse`)}
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/privacy?lang=${language}`}
          className="link"
        >
          {t(`home.footer.privacyPolicy`)}
        </a>
      </div>
    </div>

    <SocialLinks />
  </div>
);
DesktopFooter.propTypes = {
  language: PropTypes.string,
  onLanguageChange: PropTypes.func,
  trackEvent: PropTypes.func,
  t: PropTypes.func.isRequired
};

class PublicRouteFooter extends Component {
  onLanguageChange = language => {
    const { dispatch } = this.props;
    dispatch(setAppLanguageAction(language));
  };

  trackEventForLogin = () => {
    const {
      userLoggedIn,
      location: { pathname }
    } = this.props;
    if (!userLoggedIn) {
      let eventCategory = 'main';
      if (pathname === getPublicLinkFor('solutionsPro')) {
        eventCategory = 'pro';
      } else if (pathname === getPublicLinkFor('solutionsEnterprise')) {
        eventCategory = 'enterprise';
      }
      trackLoginFromGoToDashboard(eventCategory);
    }
  };

  render() {
    const { language, isMobile, isTablet, t } = this.props;
    return (
      <div id="landingFooter">
        {isMobile || isTablet ? (
          <MobileFooter
            language={language}
            onLanguageChange={this.onLanguageChange}
            trackEvent={this.trackEventForLogin}
            t={t}
          />
        ) : (
          <DesktopFooter
            language={language}
            onLanguageChange={this.onLanguageChange}
            trackEvent={this.trackEventForLogin}
            t={t}
          />
        )}
      </div>
    );
  }
}

PublicRouteFooter.propTypes = {
  language: PropTypes.string,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  location: PropTypes.object,
  userLoggedIn: PropTypes.bool,
  dispatch: PropTypes.func,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    language: getAppLanguage(state),
    isMobile: getIsMobile(state),
    isTablet: getIsTablet(state),
    userLoggedIn: isUserLoggedIn(state)
  };
};
const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default translate()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicRouteFooter))
);
