import serverRequest from '../utils/Api';
import { deleteItems, setList, updateList } from './list';

import { isEmpty } from '../utils';
import { getCleanTeamReportRuleDetails } from '../utils/dataStore';
import { URLs } from '../utils/urls';

const updateReportInList = report => (dispatch, getState) => {
  const { list } = getState().listNew.automaticReports;
  const updatedList = list.reduce((acc, item) => {
    if (item.id === report.id) {
      acc.push(report);
    } else {
      acc.push(item);
    }
    return acc;
  }, []);
  dispatch(updateList(updatedList, 'automaticReports'));
};

export const getReportRules = () => (dispatch, getState) => {
  const state = getState();
  const { selectedTeam, hasTeams } = state.teamUserInfo;

  const { method, url } = URLs.reportRules.getReportRules(
    hasTeams,
    selectedTeam
  );
  serverRequest(method, url, state).then(resp => {
    const cleanList = resp.reports.map(reportRule =>
      getCleanTeamReportRuleDetails(reportRule)
    );
    dispatch(setList(cleanList, resp.pagination, 'automaticReports'));
  });
};

export const createReportRule = values => (dispatch, getState) => {
  const state = getState();
  const { selectedTeam, hasTeams } = state.teamUserInfo;

  const body = {
    name: values.name,
    enabled: true,
    frequency: values.frequency,
    auto_submit: {
      value: values.autoSubmit,
      unit: values.autoSubmitUnit
    },
    bop: values.bop
  };

  const criteria = {};
  if (values.tags.length) {
    if (values.tags.includes('untagged')) {
      criteria.tags = [];
      criteria.tagged = false;
    } else {
      criteria.tags = values.tags;
      criteria.tagged = null;
    }
  } else {
    criteria.tags = [];
    criteria.tagged = null;
  }
  if (values.vehicles.length) {
    criteria.vehicles = {};
    criteria.vehicles.ids = values.vehicles;
  }
  if (values.travelModes.length) {
    criteria.travel_modes = values.travelModes;
  }
  criteria.display_fields = values.dataFields;
  if (!isEmpty(criteria)) {
    body.criteria = criteria;
  }
  if (values.owner) {
    body.owner = values.owner;
  }
  if (hasTeams) {
    if (values.aggregate !== undefined) {
      body.aggregate = values.aggregate;
    }
  }

  const { method, url } = URLs.reportRules.create(hasTeams, selectedTeam);
  serverRequest(method, url, state, { body }).then(response => {
    if (response.report) {
      const { list } = state.listNew.automaticReports;
      dispatch(updateList(list.concat([response.report]), 'automaticReports'));
    }
  });
};

export const updateReportRule = (id, values) => (dispatch, getState) => {
  const state = getState();
  const { hasTeams, selectedTeam } = state.teamUserInfo;

  const criteria = {
    vehicles: {
      ids: values.vehicles
    },
    travel_modes: values.travelModes,
    display_fields: values.dataFields
  };
  if (values.tags.length) {
    if (values.tags.includes('untagged')) {
      criteria.tags = [];
      criteria.tagged = false;
    } else {
      criteria.tags = values.tags;
      criteria.tagged = null;
    }
  } else {
    criteria.tags = [];
    criteria.tagged = null;
  }
  const body = {
    name: values.name,
    criteria,
    frequency: values.frequency,
    auto_submit: {
      value: values.autoSubmit,
      unit: values.autoSubmitUnit
    },
    bop: values.bop
  };
  if (hasTeams) {
    if (values.aggregate !== undefined) {
      body.aggregate = values.aggregate;
    }
  }

  const { method, url } = URLs.reportRules.update(id, hasTeams, selectedTeam);
  serverRequest(method, url, state, { body }).then(response => {
    if (response.report) {
      dispatch(updateReportInList(response.report));
    }
  });
};

const deleteReportRule = id => (dispatch, getState) => {
  const state = getState();
  const { hasTeams, selectedTeam } = state.teamUserInfo;
  const { method, url } = URLs.reportRules.delete(id, hasTeams, selectedTeam);
  serverRequest(method, url, state).then(() => {
    dispatch(deleteItems({ id }));
  });
};

export const deleteSelectedReportRules = () => (dispatch, getState) => {
  const state = getState();
  const { selectedItems } = state.listNew.automaticReports;

  Object.values(selectedItems).forEach(reportRuleId =>
    dispatch(deleteReportRule(reportRuleId))
  );
};

export const toggleReportRuleEnabled = (value, id) => (dispatch, getState) => {
  const state = getState();
  const { hasTeams, selectedTeam } = state.teamUserInfo;
  const body = {
    enabled: value
  };
  const { method, url } = URLs.reportRules.update(id, hasTeams, selectedTeam);
  serverRequest(method, url, state, { body }).then(response => {
    if (response.report) {
      dispatch(updateReportInList(response.report));
    }
  });
};
