import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ReactPlayer from 'react-player/lazy';

import SectionTitle from './SectionTitle';

class BeaconIntroSection extends Component {
  state = {
    showVideo: false
  };

  playVideo = () => {
    this.setState({ showVideo: true });
  };

  render() {
    const { showVideo } = this.state;
    const { t } = this.props;

    return (
      <div className="beaconIntroSection" id="beacon-intro">
        <div className="section">
          <div className="titleContainer">
            <SectionTitle secondLine="Psngr Beacon" />
            <div className="sectionSubTitle">
              {t('home.beacon.sectionSubTitle')}
            </div>
          </div>
          <div className="video">
            <ReactPlayer
              className="videoPlayer"
              url="https://www.youtube.com/watch?v=q8kAk32tx9A"
              playing={showVideo}
              // controls
            />
            {!showVideo && (
              <div className="beaconVideoPreview" onClick={this.playVideo} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

BeaconIntroSection.propTypes = {
  t: PropTypes.func
};

export default translate()(BeaconIntroSection);
