export const getTeamCountry = state => {
  const selectedTeamId = state.teamUserInfo && state.teamUserInfo.selectedTeam;
  if (selectedTeamId) {
    const team = state.teams.list.find(team => team.id === selectedTeamId);
    if (team) {
      return team.country;
    }
  }
  return undefined;
};
