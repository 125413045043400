import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { Animated } from 'react-animated-css';
import { removeNotification } from '../actions/notifications';

class NotificationCenter extends Component {
  componentWillReceiveProps(nextProps) {
    const { notifs, dispatch } = nextProps;
    if (notifs.length !== this.props.notifs.length) {
      const lastNotif = notifs[notifs.length - 1];
      if (lastNotif) {
        setTimeout(() => {
          dispatch(removeNotification(lastNotif.id));
        }, 1500);
      }
    }
  }

  render() {
    const { notifs } = this.props;
    return (
      <div id="notifCenter">
        {notifs.map(notif => (
          <Animated
            animationIn="slideInUp"
            animationOut="fadeOut"
            key={notif.id}
            isVisible
          >
            <Message floating compact content={notif.text} />
          </Animated>
        ))}
      </div>
    );
  }
}

NotificationCenter.propTypes = {
  notifs: PropTypes.array,
  dispatch: PropTypes.func
};

const mapStateToProps = state => {
  return {
    notifs: state.notifications
  };
};

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationCenter);
