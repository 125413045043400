import qs from 'qs';

import serverRequest from '../utils/Api';
import { setAppLanguageAction, setNextPageToBe, setPromotions } from './index';
import { signOut, signOutCurrentUser, setUserCurrency } from './login';
import { setLocal, getLocal } from '../selectors/locale';

import { setTeamsList } from './teams';
import {
  setHasTeamSubscription,
  setCurrentTeamInfo,
  setHasTeams,
  setHasNoTeams
} from './teamUserInfo';
import { setCompanyDetails } from './company';
import { updateUserVehicles } from './vehicles';
import { setUnclassifiedTrips } from './list';
import {
  addNotificationForBar,
  removeNotificationFromBar
} from './notifications';

import {
  getUserInfo,
  shouldAskToMigrateIAPSubscription,
  getUserCountry
} from '../selectors/user';

import { isEmpty, getDashboardLinkFor, getDateFormatOptions } from '../utils';
import history from '../utils/history';
import { TIME_FORMATS } from '../utils/constants';
import {
  getCleanUserDetails,
  getCleanTeamFromDetails,
  createNotificationObject,
  createNotificationObjectWithUrl
} from '../utils/dataStore';
import { setPreferredDateFormat, setDateFormatOptions } from './datePreference';
import { getHasTeams } from '../selectors/teams';
import { getTeamCountry } from '../selectors/locale_utils';
import { requestTagslist } from './tags';
import { requestUserMileageRates } from './mileageRates';
import { URLs } from '../utils/urls';
import { getUserVehicles } from '../selectors/vehicles';
import { updateHasTransferRequest } from './userSettings';
import { isUndefined } from 'lodash';
import { isApiCalled, startApiCall, endApiCall } from './apiMeta';

export const RECEIVE_USER_DETAILS = 'RECEIVE_USER_DETAILS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const SET_TRIPS_LEFT = 'SET_TRIPS_LEFT';
export const CHANGE_FORMAT = 'CHANGE_FORMAT';
export const CHANGE_TIME_FORMAT = 'CHANGE_TIME_FORMAT';
export const UPDATE_USER_DETAILS_REMOVE_PENDING =
  'UPDATE_USER_DETAILS_REMOVE_PENDING';
export const UPDATE_USER_SUBSCRIPTION = 'UPDATE_USER_SUBSCRIPTION';

export const receiveDetails = json => ({
  type: RECEIVE_USER_DETAILS,
  details: json || {}
});
export const updateUserDetails = json => ({
  type: UPDATE_USER_DETAILS,
  details: json || {}
});
export const setFreeTripsLeft = count => ({
  type: SET_TRIPS_LEFT,
  count
});
export const updateUserDetailsRemovePending = json => ({
  type: UPDATE_USER_DETAILS_REMOVE_PENDING,
  details: json || {}
});
export const changeTimeFormat = format => ({
  type: CHANGE_TIME_FORMAT,
  format
});
export const updateUserSubscription = subscription => ({
  type: UPDATE_USER_SUBSCRIPTION,
  subscription
});

export const checkToShowNotifBar = () => (dispatch, getState) => {
  const state = getState();
  const {
    confirmed: isEmailConfirmed,
    first_name: firstName,
    last_name: lastName,
    subscription: { status: subscriptionStatus = '' } = {}
  } = getUserInfo(state);
  const { promotions } = state;

  if (subscriptionStatus && subscriptionStatus.toLowerCase() === 'pastdue') {
    dispatch(
      addNotificationForBar(createNotificationObject('', 'subscriptionPastDue'))
    );
  } else if (
    subscriptionStatus &&
    subscriptionStatus.toLowerCase() === 'expired'
  ) {
    dispatch(
      addNotificationForBar(createNotificationObject('', 'subscriptionExpired'))
    );
  } else {
    dispatch(removeNotificationFromBar('subscriptionPastDue'));
  }

  if (!isEmpty(promotions)) {
    const promo = promotions[0];
    dispatch(
      addNotificationForBar(
        createNotificationObjectWithUrl(
          promo.subject,
          'promotion',
          '',
          promo.action_url,
          promo.action
        )
      )
    );
  } else {
    dispatch(removeNotificationFromBar('promotion'));
  }
  if (shouldAskToMigrateIAPSubscription(state)) {
    dispatch(addNotificationForBar(createNotificationObject('', 'migrateIAP')));
  } else {
    dispatch(removeNotificationFromBar('migrateIAP'));
  }

  if (!(firstName && lastName && isEmailConfirmed)) {
    dispatch(
      addNotificationForBar(createNotificationObject('', 'incompleteInfo'))
    );
  } else {
    dispatch(removeNotificationFromBar('incompleteInfo'));
  }
};

const updateUser = changes => (dispatch, getState) => {
  const { url, method } = URLs.user.updateUser();
  serverRequest(method, url, getState(), { body: changes }).then(json => {
    if (json.valid) {
      dispatch(setAppLanguageAction(json.user.language));
      dispatch(updateUserDetails(json.user));
      dispatch(checkToShowNotifBar());
      if (changes.country) {
        dispatch(requestUserMileageRates());
      }
      if (changes.language) {
        dispatch(updateUserVehicles(getUserVehicles(getState())));
      }
    }
  });
};
export const deleteUser = () => (dispatch, getState) => {
  const { url, method } = URLs.user.deleteUser();
  serverRequest(method, url, getState()).then(() => {
    dispatch(signOutCurrentUser());
  });
};
export const transferUserData = (target_email, delete_source) => (
  dispatch,
  getState
) => {
  const { url, method } = URLs.user.transferUserData();
  const body = { target_email, delete_source };
  serverRequest(method, url, getState(), { body }).then(() => {
    dispatch(updateHasTransferRequest(target_email));
  });
};
export const changeName = (firstName, lastName) => {
  return updateUser({
    first_name: firstName,
    last_name: lastName
  });
};
export const changeMail = email => {
  return updateUser({ email });
};
export const changeCountry = country => {
  return updateUser({ country });
};
export const changeLanguage = language => {
  return updateUser({ language });
};
export const resetPassword = email => (dispatch, getState) => {
  const { url, method } = URLs.user.resetPassword();
  const body = { email };
  serverRequest(method, url, getState(), { body });
};
export const changeProfileImage = imageAsB64String => {
  return (dispatch, getState) => {
    const { url, method } = URLs.user.updateProfileImage();
    const body = { data: imageAsB64String, type: 'profile' };
    serverRequest(method, url, getState(), { body })
      .then(json => {
        dispatch(updateUserDetails(json.user));
      })
      .catch(err => {
        console.error('failed to upload profile image: ', err);
      });
  };
};
export const removeProfileImage = () => {
  return (dispatch, getState) => {
    const { url, method } = URLs.user.deleteProfileImage();
    const body = { type: 'profile' };
    serverRequest(method, url, getState(), { body })
      .then(json => {
        dispatch(updateUserDetails(json.user));
      })
      .catch(err => {
        console.error('failed to remove profile image : ', err);
      });
  };
};
export const setPreferredTimeFormat = format => dispatch => {
  setLocal('timePreference', format);
  dispatch(changeTimeFormat(format));
};
const checkLocalAndSetDatePreference = (lang, country) => dispatch => {
  const dateFormatOptions = getDateFormatOptions(country, lang);
  const formatValues = dateFormatOptions.map(format => format.value);
  let datePreference = getLocal('datePreference');
  if (!datePreference || !formatValues.includes(datePreference)) {
    [datePreference] = formatValues;
  }
  dispatch(setDateFormatOptions(dateFormatOptions));
  dispatch(setPreferredDateFormat(datePreference));
};
export const getPromotions = () => {
  return (dispatch, getState) => {
    const { url, method } = URLs.promotions.getPromotions();
    serverRequest(method, url, getState())
      .then(json => {
        const { results, count } = json;
        dispatch(setPromotions(results));
        if (count > 0) {
          dispatch(checkToShowNotifBar());
        }
      })
      .catch(error => {
        console.error('getPromotions ERROR: ', error);
      });
  };
};
export const getUserDetails = () => {
  return (dispatch, getState) => {
    const { url, method } = URLs.user.getUserDetails();
    if (isApiCalled(getState(), url)) {
      console.warn('Skipping API call (already busy): ' + url);
      return;
    }
    dispatch(startApiCall(url));
    serverRequest(method, url, getState())
      .then(json => {
        const {
          teams,
          company,
          subscription,
          vehicles,
          language,
          country
        } = json;
        dispatch(endApiCall(url));
        const languageToSet = language || 'en';
        dispatch(setAppLanguageAction(languageToSet));
        if (teams && teams.length) {
          dispatch(setHasTeams());
          let selectedTeamFromLastSession = getLocal('selectedTeam');
          if (
            !selectedTeamFromLastSession ||
            isUndefined(
              teams.find(t => {
                return t.team.id === selectedTeamFromLastSession;
              })
            )
          ) {
            setLocal('selectedTeam', null);
            selectedTeamFromLastSession = teams[0].team.id;
          }
          dispatch(setTeamsList(teams.map(getCleanTeamFromDetails)));
          dispatch(setCurrentTeamInfo(selectedTeamFromLastSession));
          dispatch(setCompanyDetails(company));

          if (subscription && subscription.type === 'team') {
            // this is a enterprise account
            dispatch(setHasTeamSubscription());
          }
        } else {
          dispatch(setHasNoTeams());
          dispatch(checkLocalAndSetDatePreference(languageToSet, country));
        }

        if (vehicles && vehicles.length) {
          dispatch(updateUserVehicles(vehicles));
        }
        dispatch(setUnclassifiedTrips(json.untagged_trip_count));

        dispatch(receiveDetails(getCleanUserDetails(json)));

        dispatch(checkToShowNotifBar());
        dispatch(requestTagslist());
      })
      .catch(error => {
        dispatch(endApiCall(url));
        console.error('getUserDetails ERROR: ', error);
        dispatch(signOut(history));
      });
  };
};
export const loadPreferencesFromLocal = () => dispatch => {
  let timePreference = getLocal('timePreference');
  if (!timePreference) {
    timePreference = TIME_FORMATS['24'];
  }

  dispatch(changeTimeFormat(timePreference));
};

export const getUserCurrencyFromCountry = () => (dispatch, getState) => {
  const state = getState();
  const country = getTeamCountry(state) || getUserCountry(state);
  const { method, url } = URLs.common.getCurrencyForCountry(country);
  serverRequest(method, url, state).then(resp => {
    dispatch(setUserCurrency(resp.length ? resp[0].currency_code : 'EUR'));
  });
};

export const getUserToRightPage = () => (dispatch, getState) => {
  const state = getState();
  const {
    nextPageToBe,
    teamUserInfo: { rolesInSelectedTeam },
    user: { isMobile, isTablet }
  } = state;
  const hasTeams = getHasTeams(state);
  let urlToBe = `${history.location.pathname}${history.location.search}`;

  if (!isMobile && !isTablet) {
    if (
      nextPageToBe.includes('/login') ||
      nextPageToBe.includes('/landing') ||
      nextPageToBe.includes('/pro') ||
      nextPageToBe.includes('/enterprise') ||
      nextPageToBe === '/' ||
      nextPageToBe === '/dashboard' ||
      nextPageToBe.startsWith('/help')
    ) {
      if (hasTeams) {
        if (
          rolesInSelectedTeam.includes('manager') ||
          rolesInSelectedTeam.includes('admin')
        ) {
          urlToBe = getDashboardLinkFor('reports', { for: 'teamReports' });
        } else {
          urlToBe = getDashboardLinkFor('trips');
        }
      } else {
        urlToBe = getDashboardLinkFor('trips');
      }
    } else {
      const regex = new RegExp(/\?.*/gi);
      let queryString = nextPageToBe.match(regex);
      if (queryString && queryString.length) {
        queryString = queryString[0];
        const queryStringObj = qs.parse(queryString.slice(1));
        if (queryStringObj && !isEmpty(queryStringObj)) {
          const { action } = queryStringObj;
          if (action) {
            switch (action) {
              case 'dashboard':
              case 'classify':
                urlToBe = getDashboardLinkFor('trips');
                break;
              case 'reports':
                urlToBe = getDashboardLinkFor('reports');
                break;
              case 'report':
                urlToBe = getDashboardLinkFor('reportTrips', {
                  id: queryStringObj.id
                });
                break;
              case 'periodicreport':
              case 'report_rules':
                urlToBe = getDashboardLinkFor('reportRules');
                break;
              case 'teams':
                urlToBe = getDashboardLinkFor('teams');
                break;
              case 'team':
                urlToBe = getDashboardLinkFor('teamMembers', {
                  id: queryStringObj.id
                });
                break;
              case 'pro':
              case 'enterprise':
              case 'upgrade':
                urlToBe = getDashboardLinkFor('managePlan');
                break;
              case 'unsubscribe':
              case 'account':
                urlToBe = getDashboardLinkFor('account');
                break;
              case 'faq':
                urlToBe = getDashboardLinkFor('help', queryStringObj);
                break;
              default:
                break;
            }
          } else {
            urlToBe = `${nextPageToBe}`;
          }
        } else {
          urlToBe = `${nextPageToBe}`;
        }
      } else if (nextPageToBe.includes('/teams')) {
        if (
          hasTeams &&
          rolesInSelectedTeam.length === 1 &&
          rolesInSelectedTeam[0] === 'employee'
        ) {
          urlToBe = getDashboardLinkFor('account');
        } else {
          urlToBe = `${nextPageToBe}`;
        }
      } else {
        urlToBe = `${nextPageToBe}`;
      }
    }
  } else if (
    // ['/billing', '/manage', '/account', '/help', '/beacons'].some(el =>
    //   nextPageToBe.includes(el)
    // )
    nextPageToBe.includes('/billing') ||
    nextPageToBe.includes('/manage') ||
    nextPageToBe.includes('/account') ||
    nextPageToBe.includes('/help') ||
    nextPageToBe.includes('/beacons') ||
    nextPageToBe.includes('/pay')
  ) {
    urlToBe = `${nextPageToBe}`;
  } else {
    urlToBe = getDashboardLinkFor('account');
  }

  history.push(urlToBe);
  dispatch(setNextPageToBe(urlToBe));
};
