import { isUndefined } from 'lodash';

export const SET_API_ABORT_CONTROLLER = 'SET_API_ABORT_CONTROLLER';
export const UNSET_API_ABPRT_CONTROLLER = 'UNSET_API_ABPRT_CONTROLLER';
export const API_CALL_STARTED = 'API_CALL_STARTED';
export const API_CALL_ENDED = 'API_CALL_ENDED';

export const setApiAbortController = (key, controller) => ({
  type: SET_API_ABORT_CONTROLLER,
  controller,
  key
});

export const unsetApiAbortController = key => ({
  type: UNSET_API_ABPRT_CONTROLLER,
  key
});

export const startApiCall = url => ({
  type: API_CALL_STARTED,
  url
});

export const endApiCall = url => ({
  type: API_CALL_ENDED,
  url
});

export const isApiCalled = (state, url) => {
  if (isUndefined(state.apiMeta)) {
    return false;
  }
  let ts = state.apiMeta[url];
  if (isUndefined(ts)) {
    return false;
  }
  let now = new Date().getTime();
  return now - ts < 30 * 1000; // 30 secs
};
