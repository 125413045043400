export const makeAddressObject = ({
  firstName,
  lastName,
  address,
  country,
  postalCode,
  locality,
  region,
  company
}) => {
  return {
    first_name: firstName,
    last_name: lastName,
    street_address: address,
    country_code_alpha2: country,
    postal_code: postalCode,
    locality,
    region,
    company
  };
};
