import {
  SET_ACTIVE_LIST,
  SET_LIST,
  UPDATE_LIST,
  TOGGLE_LIST_INVALID,
  UPDATE_TRIPS_LIST,
  SET_LIST_ITEM,
  ADD_LIST_ITEM,
  SET_OPENED_ITEM_ID,
  REMOVE_OPENED_ITEM_ID,
  SET_OPENED_ITEM,
  CLOSE_ITEM,
  SET_DISPLAY_FIELDS,
  ADD_DISPLAY_FIELD,
  DELETE_DISPLAY_FIELD,
  FETCHING_LIST,
  NO_FETCHING_LIST,
  SET_UNCLASSIFIED_TRIPS,
  DELETE_LIST_GROUP,
  LIST_SELECT,
  LIST_DESELECT,
  LIST_SELECT_ALL,
  LIST_DESELECT_ALL,
  LIST_SELECT_ALL_ITEMS,
  UPDATE_SELECTED_ITEMS,
  UPDATE_OPENED_ITEMS,
  UPDATE_LIST_ITEM,
  DELETE_ITEMS,
  RESTORE_ITEMS,
  LIST_SELECT_DETAIL,
  DELETE_OPENED_ITEMS,
  SET_UNCLASSIFIED_CLICK_TRUE,
  SET_UNCLASSIFIED_CLICK_FALSE,
  SORT_LIST_BY_COLUMN,
  SET_FILTER_SEARCH_TEXT,
  UPDATE_LIST_STATS,
  SET_REPORTS_BADGE_COUNT,
  INCREMENT_REPORTS_BADGE_COUNT,
  DECREMENT_REPORTS_BADGE_COUNT,
  TOGGLE_IS_UPDATING_DATA,
  TOGGLE_EXPORT_LIST_IN_PROGRESS,
  SET_OPENED_REPORT_ID
} from '../actions/list';
import {
  SET_FILTER,
  CHANGE_FILTERS,
  DELETE_FILTERS,
  RESET_DATE_FILTER,
  RESET_TAGGED_FILTER,
  RESET_TRIPS_FILTERS,
  RESET_LIST_FILTERS,
  SHOW_UNCLASSIFIED_TRIPS
} from '../actions';

import { SET_REPORT_STATS } from '../actions/reports';

import {
  ACTIVATE_FLAG_MODE,
  DEACTIVATE_FLAG_MODE,
  UPDATE_REPORT_TRIPS_REMOVE_LIST,
  CLEAR_REMOVED_LIST
} from '../actions/team/reports';

import { isEmpty } from '../utils';
import {
  ACTION_GET_USER_RECENT_PLACES,
  FILTER_TEAM_TRIPS_APPROVED,
  FILTER_TEAM_TRIPS_NEW
} from '../actions/trips';

const initialState = {};

export const listNew = (state = initialState, action) => {
  const getObjectToMerge = () => {
    let activeList = action.listType || state.activeList;
    let activeListGroup = state[activeList];
    let filters = activeListGroup ? activeListGroup.filters : {};
    let selectedItems = {};
    const displayFields = activeListGroup ? activeListGroup.displayFields : {};
    let openedItems = {};
    let list = [];

    switch (action.type) {
      case SET_ACTIVE_LIST:
        if (activeList === 'trips') {
          if (isEmpty(filters)) {
            filters = { status: 'completed' };
          }
        } else if (activeList === 'teamTrips') {
          if (isEmpty(filters)) {
            filters = {
              status: 'completed',
              approvalStatus: FILTER_TEAM_TRIPS_NEW
            };
          } else {
            filters = Object.assign({}, filters, {
              status: filters.status || 'completed',
              approvalStatus: isEmpty(filters.approvalStatus)
                ? FILTER_TEAM_TRIPS_NEW
                : filters.approvalStatus
            });
          }
        }
        const listObj = { filters };
        return {
          activeList: action.listType,
          [activeList]: Object.assign({}, activeListGroup, listObj)
        };

      case FETCHING_LIST:
        return {
          [activeList]: Object.assign({}, activeListGroup, { isFetching: true })
        };

      case NO_FETCHING_LIST:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            isFetching: false
          })
        };

      case TOGGLE_IS_UPDATING_DATA:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            isUpdating: action.isUpdating
          })
        };
      case SET_OPENED_REPORT_ID:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            openedReportId: action.reportId
          })
        };
      case SET_REPORT_STATS:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            stats: action.stats
          })
        };

      case TOGGLE_EXPORT_LIST_IN_PROGRESS:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            isExportListInProgress: action.inProgress
          })
        };

      case SET_DISPLAY_FIELDS:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            displayFields: action.displayFields
          })
        };

      case ADD_DISPLAY_FIELD:
        displayFields[action.name] = true;
        return {
          [activeList]: Object.assign({}, activeListGroup, { displayFields })
        };

      case DELETE_DISPLAY_FIELD:
        displayFields[action.name] = false;
        return {
          [activeList]: Object.assign({}, activeListGroup, { displayFields })
        };

      case SET_LIST:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            isFetching: false,
            list: action.items,
            next: action.pagination.next,
            stats: action.pagination.stats,
            isInvalid: false
          })
        };

      case UPDATE_LIST:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            list: action.items
          })
        };

      case TOGGLE_LIST_INVALID:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            isInvalid: action.isInvalid
          })
        };

      case UPDATE_TRIPS_LIST:
        return {
          trips: Object.assign({}, state.trips, {
            list: action.list
          })
        };

      case SET_LIST_ITEM:
        list = [].concat(activeListGroup.list);
        list[action.index] = action.item;
        return {
          [activeList]: Object.assign({}, activeListGroup, { list })
        };

      case ADD_LIST_ITEM:
        list = [].concat(activeListGroup.list);
        list.push(action.item);
        return {
          [activeList]: Object.assign({}, activeListGroup, { list })
        };

      case SET_UNCLASSIFIED_TRIPS:
        activeList = 'trips';
        activeListGroup = state[activeList];
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            unclassifiedTripsCount: action.tripsCount
          })
        };

      case DELETE_LIST_GROUP:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            list: [],
            isFetching: false,
            next: null,
            stats: {},
            openedItem: null,
            openedItems: {},
            selectedItems: {}
          })
        };
      case SET_UNCLASSIFIED_CLICK_TRUE:
        return {
          trips: Object.assign({}, state.trips, { unclassifiedClick: true })
        };

      case SET_UNCLASSIFIED_CLICK_FALSE:
        return {
          trips: Object.assign({}, state.trips, { unclassifiedClick: false })
        };

      case SET_FILTER:
        filters = Object.assign({}, activeListGroup.filters, {
          [action.key]: action.value
        });
        switch (action.key) {
          case 'tags':
            action.value[0] === ''
              ? (filters.tagged = false)
              : delete filters.tagged;
            break;
          default:
            if (action.value === '') {
              delete filters[action.key];
            }
        }
        return {
          [activeList]: Object.assign({}, activeListGroup, { filters })
        };

      case CHANGE_FILTERS:
        filters = Object.assign({}, activeListGroup.filters, action.filters);
        return {
          [activeList]: Object.assign({}, activeListGroup, { filters })
        };

      case DELETE_FILTERS:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            filters: {
              status: filters.status,
              approvalStatus: filters.approvalStatus
            }
          })
        };

      case RESET_TRIPS_FILTERS:
        activeList = 'trips';
        return {
          [activeList]: Object.assign({}, state.trips, {
            filters: { status: 'completed' }
          })
        };
      case RESET_LIST_FILTERS:
        filters =
          activeList === 'trips' || activeList === 'teamTrips'
            ? { status: 'completed' }
            : {};
        return {
          [activeList]: Object.assign({}, state.trips, {
            filters
          })
        };

      case SHOW_UNCLASSIFIED_TRIPS:
        activeList = 'trips';
        return {
          [activeList]: Object.assign({}, state.trips, {
            filters: {
              status: 'completed',
              tags: [''],
              tagged: false
            }
          })
        };

      case RESET_DATE_FILTER:
        const { start_date, end_date, ...newFilter } = state[
          activeList
        ].filters;
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            filters: newFilter
          })
        };

      case RESET_TAGGED_FILTER:
        const { tagged, tags, ...newTripsFilter } = state.trips.filters;
        tags.indexOf('') > -1 && tags.splice(tags.indexOf(''), 1);
        tags !== [] && Object.assign(newTripsFilter, { tags });
        !newTripsFilter.status &&
          Object.assign(newTripsFilter, { status: 'completed' });
        return {
          trips: Object.assign({}, state.trips, {
            filters: newTripsFilter
          })
        };
      case LIST_SELECT:
        selectedItems = Object.assign({}, activeListGroup.selectedItems, {
          [action.index]: activeListGroup.list[action.index].id
        });
        return {
          [activeList]: Object.assign({}, activeListGroup, { selectedItems })
        };

      case LIST_DESELECT:
        const { [action.index]: deletedItem, ...newSelectedItems } = state[
          activeList
        ].selectedItems;
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            selectedItems: newSelectedItems,
            selectedAllItems: false
          })
        };

      case LIST_SELECT_ALL:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            selectedAllItems: true
          })
        };

      case LIST_DESELECT_ALL:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            selectedAllItems: false,
            selectedItems: {}
          })
        };

      case LIST_SELECT_ALL_ITEMS:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            selectedItems: action.items
          })
        };

      case UPDATE_SELECTED_ITEMS:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            selectedItems: action.selectedItems
          })
        };

      case SET_OPENED_ITEM_ID:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            openedItem: action.itemId
          })
        };
      case REMOVE_OPENED_ITEM_ID:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            openedItem: null
          })
        };

      case SET_OPENED_ITEM:
        openedItems = activeListGroup.openedItems
          ? Object.assign({}, activeListGroup.openedItems, {
              [action.itemId]: action.item
            })
          : {
              [action.itemId]: action.item
            };

        return {
          [activeList]: Object.assign({}, activeListGroup, { openedItems })
        };

      case UPDATE_OPENED_ITEMS:
        if (activeListGroup.openedItems) {
          const updatedItem = Object.assign(
            {},
            activeListGroup.openedItems[action.json.id],
            action.json
          );
          const {
            [action.json.id]: deleteOpenedItem,
            ...restOpenedItems
          } = state[activeList].openedItems;

          openedItems = Object.assign({}, restOpenedItems, {
            [action.json.id]: updatedItem
          });
        } else {
          openedItems = Object.assign(
            {},
            {
              [action.json.id]: action.json
            }
          );
        }

        return {
          [activeList]: Object.assign({}, activeListGroup, { openedItems })
        };

      case UPDATE_LIST_ITEM:
        const updatedListItem = Object.assign(
          {},
          state[activeList].list[action.index],
          action.json
        );
        const updatedList = [
          ...activeListGroup.list.slice(0, Number(action.index)),
          updatedListItem,
          ...activeListGroup.list.slice(Number(action.index) + 1)
        ];
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            list: updatedList
          })
        };

      case CLOSE_ITEM:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            openedItem: null
          })
        };

      case DELETE_ITEMS:
      case RESTORE_ITEMS:
        const ids = Object.values(action.items).map(value => value);
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            list: activeListGroup.list.filter(({ id }) => !ids.includes(id)),
            selectedItems: {},
            selectedAllItems: false
          })
        };

      case DELETE_OPENED_ITEMS:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            openedItems: {}
          })
        };

      case LIST_SELECT_DETAIL:
        const { mileage_expenses, total_distance: distance } = action.stats;
        const newList = [
          ...activeListGroup.list.slice(0, Number(action.index)),
          Object.assign({}, activeListGroup.list[action.index], {
            mileage_expenses,
            stats: { distance }
          }),
          ...activeListGroup.list.slice(Number(action.index) + 1)
        ];
        return {
          [activeList]: Object.assign({}, activeListGroup, { list: newList })
        };

      case SORT_LIST_BY_COLUMN:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            sortBy: action.sortBy,
            list: action.list
          })
        };

      case SET_FILTER_SEARCH_TEXT:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            filterSearchText: action.searchText
          })
        };
      case ACTIVATE_FLAG_MODE:
        return {
          reportTrips: Object.assign({}, state.reportTrips, {
            flagMode: true
          })
        };

      case DEACTIVATE_FLAG_MODE:
        return {
          reportTrips: Object.assign({}, state.reportTrips, {
            flagMode: false
          })
        };

      case UPDATE_LIST_STATS:
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            stats: action.stats
          })
        };
      case UPDATE_REPORT_TRIPS_REMOVE_LIST:
        return {
          reportTrips: Object.assign({}, state.reportTrips, {
            removed: action.removedList
          })
        };
      case CLEAR_REMOVED_LIST:
        return {
          reportTrips: Object.assign({}, state.reportTrips, {
            removed: []
          })
        };
      case SET_REPORTS_BADGE_COUNT:
        activeListGroup = state[activeList];
        return {
          [activeList]: Object.assign({}, activeListGroup, {
            reportsBadgeCount: action.reportsCount
          })
        };

      case INCREMENT_REPORTS_BADGE_COUNT:
        activeList = 'reports';
        activeListGroup = state[activeList];

        return {
          [activeList]: Object.assign({}, activeListGroup, {
            reportsBadgeCount: activeListGroup.reportsBadgeCount + 1
          })
        };

      case DECREMENT_REPORTS_BADGE_COUNT:
        activeList = 'reports';
        activeListGroup = state[activeList];

        return {
          [activeList]: Object.assign({}, state[activeList], {
            reportsBadgeCount: activeListGroup.reportsBadgeCount - 1
          })
        };
      case ACTION_GET_USER_RECENT_PLACES.process:
        return {
          trips: Object.assign({}, state.trips, {
            isRecentPlacesLoading: action.payload
          })
        };
      case ACTION_GET_USER_RECENT_PLACES.success:
        return {
          trips: Object.assign({}, state.trips, {
            recentPlaces: action.payload
          })
        };
      default:
        return state;
    }
  };

  return Object.assign({}, state, getObjectToMerge());
};
