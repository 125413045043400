import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Input, Icon } from 'semantic-ui-react';
import _isEqual from 'lodash/isEqual';
import _debounce from 'lodash/debounce';
import _uniqBy from 'lodash/uniqBy';

import { escapeRegExp } from '../../utils';

class SearchableAnswers extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);
  }

  getInitialState = props => {
    return {
      searchText: props.searchText || '',
      searchResults: props.items
    };
  };

  componentWillMount() {
    const { searchQuestionId, searchText, items } = this.props;
    let searchResults = items;
    if (items.length) {
      if (searchQuestionId) {
        const { results, text } = this.findAndSetSearchQuestionById(
          searchQuestionId,
          items
        );
        searchResults = results;

        this.setState({
          searchText: text,
          searchResults
        });
      } else if (searchText) {
        this.filterResultsBySearchTerm(searchText);
      }
    }
  }

  componentWillReceiveProps(newProps) {
    var searchText = newProps.searchText;
    if (!_isEqual(this.props.items, newProps.items)) {
      let searchResults = newProps.items;
      if (newProps.searchQuestionId) {
        const { results, text } = this.findAndSetSearchQuestionById(
          newProps.searchQuestionId,
          newProps.items
        );
        this.setState({
          searchResults: results,
          searchText: text
        });
      } else if (searchText && newProps.items) {
        this.filterResultsBySearchTerm(searchText, newProps.items);
      } else {
        this.setState({
          searchText,
          searchResults
        });
      }
    } else if (this.props.searchQuestionId !== newProps.searchQuestionId) {
      const { results, text } = this.findAndSetSearchQuestionById(
        newProps.searchQuestionId,
        newProps.items
      );
      this.setState({
        searchResults: results,
        searchText: text
      });
    } else if (this.props.searchText != searchText) {
      this.filterResultsBySearchTerm(searchText);
    }
  }

  onInputChange = (_, { value }) => {
    this.filterResultsBySearchTerm(value);
  };

  filterResultsBySearchTerm = (value, itemsToSearch) => {
    const items = itemsToSearch || this.props.items;
    let results = []; // "New Report", approved, Ben "Anuraag's New Report"
    if (value.trim()) {
      const splittedByQuotes = value.split(/("[^"]+")/gi); // ['"New Report", ', 'approved, Ben ', '"Anuraag's New Report"']
      splittedByQuotes.forEach(splittedPart => {
        if (
          splittedPart.charAt(0) === '"' &&
          splittedPart.charAt(splittedPart.length - 1) === '"'
        ) {
          // the term is inside double quotes; this needs to be matched exact
          const exactTerm = splittedPart.substring(1, splittedPart.length - 1);
          if (exactTerm.trim()) {
            const re = new RegExp(escapeRegExp(exactTerm), 'i');
            const matches = items.filter(item => {
              return (
                re.test(item.question.replace(/<[^>]+>/g, '')) ||
                re.test(item.answer.replace(/<[^>]+>/g, ''))
              );
            });
            results = results.concat(matches);
          }
        } else if (splittedPart.trim()) {
          const words = splittedPart.split(/[,\s]+/gi);
          results = results.concat(
            words.reduce((acc, word) => {
              if (word.trim()) {
                const re = new RegExp('\\b' + escapeRegExp(word), 'i');
                const matches = items.filter(item => {
                  return (
                    re.test(item.question.replace(/<[^>]+>/g, '')) ||
                    re.test(item.answer.replace(/<[^>]+>/g, ''))
                  );
                });
                return acc.concat(matches);
              }
              return acc;
            }, [])
          );
        }
      });
    } else {
      results = items;
    }

    this.setState({
      searchText: value,
      searchResults: _uniqBy(results, 'id')
    });
  };

  getItemContainer = item => {
    return (
      <div key={item.id} className="itemContainer">
        <div className="question">
          {item.question}
          <Icon
            name="linkify"
            size="small"
            onClick={() => this.props.onCopyLinkToClipboard(item.id)}
          />
        </div>
        <div
          className="answer"
          dangerouslySetInnerHTML={{ __html: item.answer }}
        />
      </div>
    );
  };

  findAndSetSearchQuestionById = (questionId, items) => {
    let search = {
      results: items,
      text: ''
    };
    const item = items.find(el => el.id === questionId);
    if (item) {
      search = {
        results: [item],
        text: `"${item.question}"`
      };
    }

    return search;
  };

  render() {
    const { t } = this.props;
    const { searchText, searchResults } = this.state;

    return (
      <div className="searchListContainer">
        <Input
          fluid
          value={searchText || ''}
          icon="search"
          iconPosition="left"
          placeholder={t('filters.quickSearch')}
          className="searchInput"
          onChange={_debounce(this.onInputChange, 500, { leading: true })}
        />

        <div className="itemsListContainer">
          {searchResults.map(this.getItemContainer)}
        </div>
      </div>
    );
  }
}

SearchableAnswers.propTypes = {
  items: PropTypes.array,
  searchQuestionId: PropTypes.number,
  searchText: PropTypes.string,
  onCopyLinkToClipboard: PropTypes.func,
  t: PropTypes.func
};

export default translate()(SearchableAnswers);
