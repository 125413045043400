import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ReactPlayer from 'react-player/lazy';
import { Icon } from 'semantic-ui-react';
import _debounce from 'lodash/debounce';

import SectionTitle from './SectionTitle';

import { appDemoVideos } from '../../utils/landingConstants';

class MeetAppSection extends Component {
  state = {
    activeVideo: 1,
    playerReady: false,
    playVideo: false
  };

  componentDidMount() {
    if (this.props.isMobile) {
      this.onPlayerReady();
      this.startPlayingVideo();
      this.startTimer();
    } else {
      document
        .getElementById('landingPage')
        .addEventListener('scroll', this.startPlayingVideo);
    }
  }

  componentWillUnmount() {
    if (!this.props.isMobile) {
      document
        .getElementById('landingPage')
        .removeEventListener('scroll', this.startPlayingVideo);
    }
  }

  startPlayingVideo = () => this.setState({ playVideo: true });
  onPrev = () => {
    const { activeVideo } = this.state;
    let prevVideo = activeVideo - 1;
    if (prevVideo < 1) {
      prevVideo = appDemoVideos.length;
    }
    this.setActiveSlide(prevVideo);
  };
  onNext = () => {
    const { activeVideo } = this.state;
    let nextVideo = activeVideo + 1;
    if (nextVideo > appDemoVideos.length) {
      nextVideo = 1;
    }
    this.setActiveSlide(nextVideo);
  };
  onPlayerReady = () => this.setState({ playerReady: true });
  onVideoEnd = () => this.onNext();

  getActiveSlide = () => {
    const { activeVideo } = this.state;
    const { t } = this.props;
    switch (activeVideo) {
      case 1:
        return {
          videoURL: appDemoVideos[0].url,
          gifURL: 'images/landing/appDemo_1.gif',
          activeTitle: t(`home.meetApp.demos.demo1.title`),
          activeSubTitle: t(`home.meetApp.demos.demo1.subtitle`)
        };
      case 2:
        return {
          videoURL: appDemoVideos[1].url,
          gifURL: 'images/landing/appDemo_2.gif',
          activeTitle: t(`home.meetApp.demos.demo2.title`),
          activeSubTitle: t(`home.meetApp.demos.demo2.subtitle`)
        };
      case 3:
        return {
          videoURL: appDemoVideos[2].url,
          gifURL: 'images/landing/appDemo_3.gif',
          activeTitle: t(`home.meetApp.demos.demo3.title`),
          activeSubTitle: t(`home.meetApp.demos.demo3.subtitle`)
        };
      case 4:
        return {
          videoURL: appDemoVideos[3].url,
          gifURL: 'images/landing/appDemo_4.gif',
          activeTitle: t(`home.meetApp.demos.demo4.title`),
          activeSubTitle: t(`home.meetApp.demos.demo4.subtitle`)
        };

      default:
        return {};
    }
  };
  setActiveSlide = activeVideo => {
    this.setState({ playerReady: false, activeVideo }, () => {
      if (this.props.isMobile) {
        this.startTimer();
      }
    });
  };

  startTimer = () => {
    const { activeVideo } = this.state;
    this.progressTimeout = setTimeout(() => {
      this.onVideoEnd();
    }, appDemoVideos[activeVideo - 1].duration);
  };

  render() {
    const { activeVideo, playerReady, playVideo } = this.state;
    const {
      videoURL,
      gifURL,
      activeTitle,
      activeSubTitle
    } = this.getActiveSlide();
    const { isMobile, trackEvent, t } = this.props;
    return (
      <div className="meetAppSection" id="meet-app">
        <div className="section">
          <div className="intro">
            <SectionTitle secondLine="Psngr App" />
            <div className="sectionSubTitle">{t(`home.meetApp.subtitle`)}</div>
            <a className="action" href="/login" onClick={trackEvent}>
              {t(`home.meetApp.getStarted`)}
            </a>
          </div>

          <div className="demoAndCarouselWrapper">
            <div className="iphoneWrapper">
              <div className="appDemo">
                <div className="appImg" />
                {isMobile && playVideo ? (
                  <div
                    className="videoAsGif"
                    style={{
                      backgroundImage: `url('${gifURL}')`
                    }}
                  />
                ) : (
                  <ReactPlayer
                    className="video"
                    url={videoURL}
                    playing={playVideo}
                    controls={false}
                    onReady={this.onPlayerReady}
                    onEnded={_debounce(this.onVideoEnd, 100)}
                  />
                )}
              </div>
            </div>

            <div className="carousel">
              <div className="progress">
                <div
                  className={`bar ${activeVideo > 1 ? 'full' : ''} ${
                    activeVideo === 1 ? 'active' : ''
                  }`}
                  onClick={() => this.setActiveSlide(1)}
                >
                  {activeVideo === 1 && playVideo && (
                    <div
                      className="progressing"
                      style={{
                        animationDuration: `${appDemoVideos[0].duration}ms`,
                        animationPlayState: 'running'
                      }}
                    />
                  )}
                </div>
                <div
                  className={`bar ${activeVideo > 2 ? 'full' : ''} ${
                    activeVideo === 2 ? 'active' : ''
                  }`}
                  onClick={() => this.setActiveSlide(2)}
                >
                  {activeVideo === 2 && playVideo && (
                    <div
                      className="progressing"
                      style={{
                        animationDuration: `${appDemoVideos[1].duration}ms`,
                        animationPlayState: 'running'
                      }}
                    />
                  )}
                </div>
                <div
                  className={`bar ${activeVideo > 3 ? 'full' : ''} ${
                    activeVideo === 3 ? 'active' : ''
                  }`}
                  onClick={() => this.setActiveSlide(3)}
                >
                  {activeVideo === 3 && playVideo && (
                    <div
                      className="progressing"
                      style={{
                        animationDuration: `${appDemoVideos[2].duration}ms`,
                        animationPlayState: 'running'
                      }}
                    />
                  )}
                </div>
                <div
                  className={`bar ${activeVideo === 4 ? 'active' : ''}`}
                  onClick={() => this.setActiveSlide(4)}
                >
                  {activeVideo === 4 && playVideo && (
                    <div
                      className="progressing"
                      style={{
                        animationDuration: `${appDemoVideos[3].duration}ms`,
                        animationPlayState: 'running'
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="slide">
                <div className="title">{activeTitle}</div>
                <div className="subTitle">{activeSubTitle}</div>
              </div>

              <div className="actions nav">
                <div
                  className={`navBtn prev ${playerReady ? '' : 'disabled'}`}
                  onClick={this.onPrev}
                >
                  <Icon name="chevron left" />
                </div>
                <div
                  className={`navBtn next ${playerReady ? '' : 'disabled'}`}
                  onClick={this.onNext}
                >
                  <Icon name="chevron right" />
                </div>
                <a className="action" href="/login">
                  {t(`home.meetApp.getStarted`)}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MeetAppSection.propTypes = {
  isMobile: PropTypes.bool,
  trackEvent: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default translate()(MeetAppSection);
