import _intersection from 'lodash/intersection';
import _findIndex from 'lodash/findIndex';

import {
  updateOpenedItems,
  updateListItem,
  checkFilters,
  toggleListInvalid,
  updateListStats,
  setUnclassifiedTrips,
  listDeselectAll,
  getList
} from './list';

import {
  getSelectedItemsIds,
  getOpenedItem,
  getOpenedItemIndex,
  getActiveListGroup
} from '../selectors/list';

import { isEmpty } from '../utils';
import serverRequest from '../utils/Api';
import { URLs } from '../utils/urls';
import { isUndefined } from 'lodash';
import { setFreeTripsLeft } from './user';
import { updateOpenedReportStats } from './reports';

export const RECEIVE_TAGS = 'RECEIVE_TAGS';

const receiveTags = (tags = []) => {
  return {
    type: RECEIVE_TAGS,
    tags
  };
};

export const requestTagslist = () => (dispatch, getState) => {
  const { url, method } = URLs.tags.getTagsList();
  serverRequest(method, url, getState()).then(json => {
    dispatch(receiveTags(json.tags.sort((a, b) => b.num_trips - a.num_trips)));
  });
};

const doAdjustmentsAfterTagEdit = (
  newTrips,
  selectedOldTrips,
  tagAdded = true
) => (dispatch, getState) => {
  const state = getState();
  const { list, stats, openedItems } = getActiveListGroup(state);

  const { activeList } = state.listNew;

  dispatch(toggleListInvalid('reports'));
  if (activeList === 'reportTrips') {
    dispatch(toggleListInvalid('trips'));
  }

  const adjustments = selectedOldTrips.reduce(
    (acc, staleTripObj) => {
      const tripObjFromResponse = newTrips.find(
        trip => trip.id === staleTripObj.id
      );

      if (isUndefined(tripObjFromResponse)) {
        return acc;
      }

      // if (
      //   (tagAdded && !staleTripObj.tags.length) ||
      //   (!tagAdded && !tripObjFromResponse.tags.length)
      // ) {
      //   acc.untaggedCount += 1;
      // }

      acc.expensesToAdjust.toAdd += tripObjFromResponse.mileage_expenses.value;
      acc.expensesToAdjust.toRemove += staleTripObj.mileage_expenses.value;

      const updatedTripObj = Object.assign(
        {},
        staleTripObj,
        tripObjFromResponse
      );
      const listItemIndex = _findIndex(
        list,
        item => item.id === staleTripObj.id
      );
      // update listItem
      dispatch(updateListItem(updatedTripObj, listItemIndex));
      if (!isEmpty(openedItems)) {
        // update openedItem
        dispatch(updateOpenedItems(updatedTripObj, listItemIndex));
      }

      return acc;
    },
    {
      // untaggedCount: 0,
      expensesToAdjust: {
        toAdd: 0,
        toRemove: 0
      }
    }
  );

  const updatedListStats = Object.assign({}, stats);
  updatedListStats.mileage_expenses = {
    currency: stats.mileage_expenses.currency,
    value:
      stats.mileage_expenses.value -
      adjustments.expensesToAdjust.toRemove +
      adjustments.expensesToAdjust.toAdd
  };

  // if (adjustments.untaggedCount) {
  //   let totalUnclassifiedTripsCount =
  //     unclassifiedTripsCount - adjustments.untaggedCount;
  //   if (!tagAdded) {
  //     totalUnclassifiedTripsCount =
  //       unclassifiedTripsCount + adjustments.untaggedCount;
  //   }
  //   dispatch(setUnclassifiedTrips(totalUnclassifiedTripsCount));

  //   if (activeList === 'reportTrips') {
  //     if (tagAdded) {
  //       updatedListStats.untagged_trips -= adjustments.untaggedCount;
  //     } else {
  //       updatedListStats.untagged_trips += adjustments.untaggedCount;
  //     }
  //   }
  // }

  dispatch(updateListStats(updatedListStats));
};

const getTripsNotSatisfyingReportCriteria = (trips, openedReport) => {
  return trips.reduce((acc, tripFromResponse) => {
    // check if these trips are still satifying the report criteria
    if (openedReport.criteria && openedReport.criteria.tags) {
      const criteriaTags = openedReport.criteria.tags.map(
        criteriaTag => criteriaTag.name
      );
      const tripTags = tripFromResponse.tags.map(tripTag => tripTag.name);
      if (
        criteriaTags.length &&
        !_intersection(criteriaTags, tripTags).length
      ) {
        // remove this trip from the reportTrips list
        acc.push(tripFromResponse.id);
      }
    }

    return acc;
  }, []);
};

const getOpenedReport = state => {
  const { reports, teamReports } = state.listNew;
  var openedItems, openedItem;
  if (reports) {
    openedItems = reports.openedItems;
    openedItem = reports.openedItem;
  } else if (teamReports) {
    openedItems = teamReports.openedItems;
    openedItem = teamReports.openedItem;
  }
  if (!isUndefined(openedItems) && !isUndefined(openedItem)) {
    return openedItems[openedItem];
  }
  return null;
};

export const addTagToSelectedTrips = tagName => (dispatch, getState) => {
  const state = getState();
  const selectedTripIds = getSelectedItemsIds(state);
  const { url, method } = URLs.tags.addTagsToTrip();
  serverRequest(method, url, state, {
    body: {
      trips: selectedTripIds,
      tags: [tagName]
    }
  }).then(
    json => {
      if (json.valid) {
        const { list } = getActiveListGroup(state);
        const { trips, user } = json;
        if (user) {
          dispatch(setUnclassifiedTrips(user.untagged_trip_count));
          dispatch(setFreeTripsLeft(user.trips_left));
        }
        const selectedTrips = selectedTripIds.map(tripId =>
          list.find(item => item.id === tripId)
        );
        dispatch(doAdjustmentsAfterTagEdit(trips, selectedTrips));

        const { activeList } = state.listNew;
        if (activeList === 'reportTrips') {
          var openedReport = getOpenedReport(state);
          if (openedReport) {
            dispatch(updateOpenedReportStats(openedReport.id));
          }
        }

        // adding the tag(s) might have added it to the user's tags list; so, refetch the tagsList
        dispatch(requestTagslist());
      } else {
        // TODO: handle error
      }
    },
    (err, statusCode) => {
      dispatch(toggleListInvalid('trips'));
    }
  );
};

export const removeTagFromSelectedTrips = tagName => (dispatch, getState) => {
  const state = getState();
  const selectedTripIds = getSelectedItemsIds(state);
  const { url, method } = URLs.tags.removeTagsFromTrip();
  serverRequest(method, url, state, {
    body: {
      trips: selectedTripIds,
      tags: [tagName]
    }
  }).then(json => {
    if (json.valid) {
      const { activeList } = state.listNew;
      const { list } = getActiveListGroup(state);
      const selectedTrips = selectedTripIds.map(tripId =>
        list.find(item => item.id === tripId)
      );
      const { trips, user } = json;
      if (user) {
        dispatch(setUnclassifiedTrips(user.untagged_trip_count));
        dispatch(setFreeTripsLeft(user.trips_left));
      }
      dispatch(doAdjustmentsAfterTagEdit(trips, selectedTrips, false));

      if (activeList === 'reportTrips') {
        var openedReport = getOpenedReport(state);
        if (!openedReport) {
          return;
        }
        const reportTripsToBeDeleted = getTripsNotSatisfyingReportCriteria(
          json.trips,
          openedReport
        );

        if (reportTripsToBeDeleted.length) {
          // if trips are getting removed from the list, then refresh the list altogether
          dispatch(listDeselectAll());
          dispatch(getList(activeList));
        }
        dispatch(updateOpenedReportStats(openedReport.id));
      }

      // remove the tag might have removed it from the user's tags list; so, refetch the tagsList
      dispatch(requestTagslist());
    } else {
      // TODO: handle error
    }
  });
};

export const setNotesAndTagsTrip = notesAndTags => {
  return (dispatch, getState) => {
    const state = getState();
    const { activeList } = state;
    const oldTripObj = getOpenedItem(state);
    const { id, tags: initialTags } = oldTripObj;
    const index = getOpenedItemIndex(state);
    const { url, method } = URLs.tags.setNotesAndTagsOfTrip(id);
    serverRequest(method, url, state, {
      body: { trip: { notes: notesAndTags } }
    }).then(({ valid, trip, user }) => {
      if (valid) {
        // update unclassified trips counter
        if (user) {
          dispatch(setUnclassifiedTrips(user.untagged_trip_count));
          dispatch(setFreeTripsLeft(user.trips_left));
        }
        if (trip) {
          dispatch(updateOpenedItems(trip, index));
          dispatch(updateListItem(trip, index));
        }
        const { tags: currentTags } = trip;
        if (initialTags.length && !currentTags.length) {
          if (activeList === 'reportTrips') {
            const { openedItems, openedItem } = state.listNew.reports;
            const openedReport = openedItems[openedItem];
            const reportTripsToBeDeleted = getTripsNotSatisfyingReportCriteria(
              [trip],
              openedReport
            );

            if (reportTripsToBeDeleted.length) {
              // if trips are getting removed from the list, then refresh the list altogether
              dispatch(listDeselectAll());
              dispatch(getList(activeList));
            }
          }
        } else {
          dispatch(updateOpenedItems(trip, index));
          dispatch(updateListItem(trip, index));
          dispatch(checkFilters()); // ??????
        }

        dispatch(requestTagslist());
      } else {
        // TODO: handle error
      }
    });
  };
};
