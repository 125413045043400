import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION
} from '../actions/notifications';

export const notifications = (state = [], action) => {
  const currentNotifications = [...state];
  switch (action.type) {
    case ADD_NOTIFICATION:
      return [...currentNotifications, action.notification];

    case REMOVE_NOTIFICATION:
      return currentNotifications.filter(
        notification => notification.id !== action.notificationId
      );

    default:
      return state;
  }
};
