import {
  RECEIVE_USER_BEACONS,
  RECEIVE_BEACON_ORDER_DETAILS,
  RECEIVE_BEACON_ORDER_LIST,
  UPDATE_BEACON_ORDER_LIST,
  UPDATE_BEACON_ORDER,
  SET_FETCHING_LISTS,
  SET_FETCHING_ORDER_BEACON_LIST
} from '../actions/beacons';

const initialState = {
  beacons: {},
  orders: {},
  isFetching: false
};

export const beacons = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_USER_BEACONS:
      return {
        ...state,
        beacons: action.info
      };
    case RECEIVE_BEACON_ORDER_LIST:
      return {
        ...state,
        orders: action.info
      };
    case UPDATE_BEACON_ORDER_LIST:
      return {
        ...state,
        orders: {
          ...state.orders,
          next: action.info.next,
          previous: action.info.previous,
          list: state.orders.list.concat(action.info.results)
        }
      };
    case RECEIVE_BEACON_ORDER_DETAILS:
      return {
        ...state,
        orders: {
          ...state.orders,
          list: state.orders.list.concat(action.details)
        }
      };
    case UPDATE_BEACON_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          list: action.list,
          count: action.count
        }
      };
    case SET_FETCHING_LISTS:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case SET_FETCHING_ORDER_BEACON_LIST:
      return {
        ...state,
        orders: {
          ...state.orders,
          isFetching: action.isFetching
        }
      };
    default:
      return state;
  }
};
