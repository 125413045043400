import _isEmpty from 'lodash/isEmpty';

export const createQueryObject = (filters, hasTeams, type) => {
  const queryObj = {};
  if (!_isEmpty(filters)) {
    if (filters.start_date && filters.end_date) {
      // the comma is appended or prepended to the end_date and start_date because those fields are expecting a range
      if (type === 'reports') {
        queryObj.end_date = `${filters.start_date / 1000},${filters.end_date /
          1000}`;
      } else {
        queryObj.start_date = `${filters.start_date / 1000},`;
        queryObj.end_date = `,${filters.end_date / 1000}`;
      }
    }
    if (filters.status) {
      queryObj.status = filters.status;
    }
    if (hasTeams && filters.approvalStatus) {
      if (filters.approvalStatus.length === 1) {
        queryObj.approval_status = filters.approvalStatus[0];
      } else if (filters.approvalStatus.length > 1) {
        queryObj.approval_status__in = filters.approvalStatus.join(',');
      }
    }
    if (filters.text) {
      queryObj.search = filters.text;
    }
    if (filters.tagged === false) {
      queryObj.tagged = 'false';
    }
  }
  return queryObj;
};
