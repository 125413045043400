import fetch from 'isomorphic-fetch';
import { API_VERSION } from './constants';
import { containStr } from '.';

const lastCall = {};

export default (method, url, state, options = {}) => {
  const identifier = {};
  if (url.indexOf('<user_id>') !== -1) {
    !state.user.token && console.error('Missing user_token');
    url = url.replace('<user_id>', state.user.id);
  }
  if (options.cancelType) {
    lastCall[options.cancelType] = identifier;
  }
  return new Promise((resolve, reject) => {
    const endpoint = containStr(url, '/api/v3/')
      ? url.replace('/api/v3/', '')
      : url;

    const headers = {
      Accept: 'application/json, text/plain, */*'
    };
    if (!options.form) {
      headers['Content-Type'] = 'application/json';
    }
    if (!options.doNotAuthorize && state.user.token) {
      headers.Authorization = `Basic ${state.user.token}`;
    }
    options.headers && Object.assign(headers, options.headers);

    let completeURL = `${process.env.REACT_APP_api_url}/api/v${API_VERSION}/${endpoint}`;
    if (options.withoutApiVersion) {
      completeURL = `${process.env.REACT_APP_api_url}/api/${endpoint}`;
    }

    const optionsForFetch = {
      method: method.toUpperCase(),
      headers,
      body: options.form || JSON.stringify(options.body)
    };
    if (options.isCancellable && options.abortController) {
      optionsForFetch.signal = options.abortController.signal;
    }

    fetch(completeURL, optionsForFetch)
      .then(response => {
        if (options.cancelType && lastCall[options.cancelType] !== identifier) {
          return;
        }
        if (!(response.ok || response.valid) || response.status === 401) {
          if (
            (response.status === 400 ||
              response.status === 403 ||
              response.status === 404 ||
              response.status === 405) &&
            response.body
          ) {
            response.json().then(err => {
              reject({ err, statusCode: response.status });
            });
            return;
          } else if (response.status === 500) {
            let err = { message: response.statusText };
            reject({ err, statusCode: response.status });
            return;
          }
          reject(response);
          return;
        }
        if (options.format === 'blob') {
          resolve(response.blob());
        } else if (response.status === 204) {
          // no content
          resolve(response);
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};
