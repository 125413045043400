import {
  SET_BILLING_HISTORY,
  SET_ADD_METHOD,
  RECEIVE_PLANS,
  RECEIVE_PLAN,
  SELECT_PLAN,
  SET_BILLING_INFO,
  SET_BILLING_INFO_VALIDATIONS,
  CLEAR_BILLING_INFO,
  CLEAR_BILLING_INFO_VALIDATIONS,
  SET_BILLING_UPDATE_SUBSCRIPTION,
  CLEAR_BILLING_UPDATE_SUBSCRIPTION,
  SET_SHIPPING_INFO,
  SET_SHIPPING_INFO_VALIDATIONS,
  CLEAR_SHIPPING_INFO,
  CLEAR_SHIPPING_INFO_VALIDATIONS,
  SET_INFO,
  SET_INFO_VALIDATIONS,
  CLEAR_INFO_VALIDATIONS,
  CLEAR_INFO,
  SET_PROMO,
  CLEAR_PROMO,
  TAXAMO_READY,
  SET_UPGRADE_ANNUALLY,
  CLEAR_UPGRADE_ANNUALLY,
  SET_UPDATE_PLAN,
  SET_CANCELLED_SUBSCRIPTION,
  CLEAR_CANCELLED_SUBSCRIPTION,
  TOGGLE_SAME_ADDRESS_BILLING_SHIPPING,
  SET_UPDATE_VAT,
  CLEAR_UPDATE_VAT,
  UPDATE_SELECTED_PLAN
} from '../actions/billing';

import {
  SET_TRANSACTION,
  SET_TRANSACTION_DEDUCTED,
  SET_TRANSACTION_NONCE,
  SET_TRANSACTION_KEY,
  CLEAR_TRANSACTION,
  SET_PAYMENT_COMPLETED,
  CLEAR_PAYMENT_COMPLETED,
  SET_PAYMENT_FAILED
} from '../actions/payments';

const initialState = {
  payment: {
    billingInfo: {},
    shippingInfo: {},
    sameAddressBillingShipping: true,
    info: { ready: false, loading: false, verifying: false },
    promo: {}
  },
  premium: {
    history: [],
    addMethod: false,
    paymentCompleted: null,
    cancelled: null,
    upgradeAnnually: null
  },
  plansData: { plans: [], selectedPlan: '' },
  services: { taxamo: false },
  transaction: { ready: false, isDeducted: null }
};

export const billing = (state = initialState, action) => {
  const getObjectToMerge = () => {
    let billingInfo = {};
    let shippingInfo = {};
    let info = {};
    let validations = {};
    const { billingInfo: currentBillingInfo } = state.payment;
    switch (action.type) {
      // payment
      case SET_BILLING_INFO:
        billingInfo = action.billingInfo;
        return {
          payment: Object.assign({}, state.payment, {
            billingInfo: Object.assign({}, currentBillingInfo, billingInfo)
          })
        };
      case SET_SHIPPING_INFO:
        shippingInfo = action.shippingInfo;
        return {
          payment: Object.assign({}, state.payment, { shippingInfo })
        };
      case SET_UPDATE_VAT:
        return {
          payment: Object.assign({}, state.payment, { updateVAT: action.taxId })
        };
      case CLEAR_UPDATE_VAT:
        return {
          payment: Object.assign({}, state.payment, { updateVAT: null })
        };
      case SET_BILLING_INFO_VALIDATIONS:
        validations = Object.assign(
          {},
          state.payment.billingInfo.validations,
          action.validations
        );
        billingInfo = Object.assign({}, state.payment.billingInfo, {
          validations
        });
        return {
          payment: Object.assign({}, state.payment, { billingInfo })
        };
      case SET_BILLING_UPDATE_SUBSCRIPTION:
        return {
          payment: Object.assign({}, state.payment, {
            update_subscription_id: action.subscription_id
          })
        };
      case CLEAR_BILLING_UPDATE_SUBSCRIPTION:
        return {
          payment: Object.assign({}, state.payment, {
            update_subscription_id: null
          })
        };
      case SET_SHIPPING_INFO_VALIDATIONS:
        validations = Object.assign(
          {},
          state.payment.shippingInfo.validations,
          action.validations
        );
        shippingInfo = Object.assign({}, state.payment.shippingInfo, {
          validations
        });
        return {
          payment: Object.assign({}, state.payment, { shippingInfo })
        };
      case CLEAR_BILLING_INFO:
        return {
          payment: Object.assign({}, state.payment, { billingInfo: {} })
        };
      case CLEAR_SHIPPING_INFO:
        return {
          payment: Object.assign({}, state.payment, { shippingInfo: {} })
        };
      case CLEAR_BILLING_INFO_VALIDATIONS:
        billingInfo = Object.assign({}, state.payment.billingInfo, {
          validations: {}
        });
        return {
          payment: Object.assign({}, state.payment, { billingInfo })
        };
      case CLEAR_SHIPPING_INFO_VALIDATIONS:
        shippingInfo = Object.assign({}, state.payment.shippingInfo, {
          validations: {}
        });
        return {
          payment: Object.assign({}, state.payment, { shippingInfo })
        };
      case TOGGLE_SAME_ADDRESS_BILLING_SHIPPING:
        return {
          payment: Object.assign({}, state.payment, {
            sameAddressBillingShipping: !state.payment
              .sameAddressBillingShipping
          })
        };
      case SET_INFO:
        info = Object.assign({}, state.payment.info, action.info);
        return {
          payment: Object.assign({}, state.payment, { info })
        };
      case SET_INFO_VALIDATIONS:
        validations = Object.assign(
          {},
          state.payment.info.validations,
          action.validations
        );
        info = Object.assign({}, state.payment.info, {
          validations
        });
        return {
          payment: Object.assign({}, state.payment, { info })
        };
      case CLEAR_INFO:
        return {
          payment: Object.assign({}, state.payment, { info: {} })
        };
      case CLEAR_INFO_VALIDATIONS:
        info = Object.assign({}, state.payment.info, {
          validations: {}
        });
        return {
          payment: Object.assign({}, state.payment, { info })
        };
      case SET_PROMO:
        let promo = {};
        if (action.promo) {
          promo = Object.assign({}, state.payment.promo, action.promo);
        }
        return {
          payment: Object.assign({}, state.payment, { promo })
        };
      case CLEAR_PROMO:
        return {
          payment: Object.assign({}, state.payment, { promo: {} })
        };
      // premium
      case SET_BILLING_HISTORY:
        return {
          premium: Object.assign({}, state.premium, {
            history: action.historyList
          })
        };
      case SET_ADD_METHOD:
        return {
          premium: Object.assign({}, state.premium, {
            addMethod: action.addMethod
          })
        };
      case SET_PAYMENT_COMPLETED:
        return {
          premium: Object.assign({}, state.premium, {
            paymentCompleted: true
          })
        };
      case SET_PAYMENT_FAILED:
        info = Object.assign({}, state.payment.info, {
          ready: true,
          loading: false,
          verifying: false,
          pid: null
        });
        return {
          payment: Object.assign({}, state.payment, { info })
        };
      case CLEAR_PAYMENT_COMPLETED:
        return {
          premium: Object.assign({}, state.premium, {
            paymentCompleted: null
          })
        };
      case SET_CANCELLED_SUBSCRIPTION:
        return {
          premium: Object.assign({}, state.premium, {
            cancelled: true
          })
        };
      case CLEAR_CANCELLED_SUBSCRIPTION:
        return {
          premium: Object.assign({}, state.premium, {
            cancelled: false
          })
        };
      case SET_UPGRADE_ANNUALLY:
        return {
          premium: Object.assign({}, state.premium, {
            upgradeAnnually: true
          })
        };
      case CLEAR_UPGRADE_ANNUALLY:
        return {
          premium: Object.assign({}, state.premium, {
            upgradeAnnually: null
          })
        };
      case RECEIVE_PLANS:
        return {
          plansData: Object.assign({}, state.plansData, {
            plans: action.plans
          })
        };
      case RECEIVE_PLAN:
        const existingPlans = state.plansData.plans.filter(
          plan => plan.id !== action.plan.id
        );
        return {
          plansData: Object.assign({}, state.plansData, {
            plans: existingPlans.concat(action.plan)
          })
        };
      case SELECT_PLAN:
        return {
          plansData: Object.assign({}, state.plansData, {
            selectedPlan: action.plan
          })
        };
      case UPDATE_SELECTED_PLAN:
        return {
          plansData: Object.assign({}, state.plansData, {
            plans: state.plansData.plans.map((plan, index) => {
              if (plan.id !== action.plan.id) {
                return plan;
              }
              return action.plan;
            })
          })
        };
      case SET_UPDATE_PLAN:
        return {
          plansData: Object.assign({}, state.plansData, {
            selectedPlan: action.plan && action.plan.id
          })
        };
      case TAXAMO_READY:
        return {
          services: Object.assign({}, state.services, {
            taxamo: true
          })
        };
      case SET_TRANSACTION:
        return {
          transaction: Object.assign({}, state.transaction, {
            transaction: action.transaction,
            ready: action.transaction.transaction_lines !== undefined
          })
        };
      case SET_TRANSACTION_DEDUCTED:
        return {
          transaction: Object.assign({}, state.transaction, {
            isDeducted: action.isDeducted
          })
        };
      case SET_TRANSACTION_NONCE:
        return {
          transaction: Object.assign({}, state.transaction, {
            nonce: action.nonce
          })
        };
      case SET_TRANSACTION_KEY:
        return {
          transaction: Object.assign({}, state.transaction, {
            key: action.key
          })
        };
      case CLEAR_TRANSACTION:
        return {
          transaction: Object.assign({}, state.transaction, {
            transaction: {},
            ready: false
          })
        };
      default:
        return state;
    }
  };
  return Object.assign({}, state, getObjectToMerge());
};
