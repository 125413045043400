export const USER_TESTIMONIALS = {
  numberOfQuotes: {
    en: 4,
    nl: 4,
    de: 3,
    fr: 3,
    it: 4
  }
};
export const STATS_USER_TESTIMONIALS = {
  numberOfQuotes: {
    en: 16,
    nl: 9,
    de: 12,
    fr: 16,
    it: 4
  }
};
export const availableLanguages = [
  { value: 'en', text: 'English', smallText: 'EN' },
  { value: 'fr', text: 'Français', smallText: 'FR' },
  { value: 'de', text: 'Deutsch', smallText: 'DE' },
  { value: 'nl', text: 'Nederlands', smallText: 'NL' },
  { value: 'it', text: 'Italiano', smallText: 'IT' }
];
export const appDemoVideos = [
  {
    url:
      'https://player.vimeo.com/video/163821487?api=1&autopause=0&autoplay=1&badge=0&byline=0&loop=1&player_id=vimeo163821487&portrait=0&muted=1&title=0',
    duration: 15000
  },
  {
    url:
      'https://player.vimeo.com/video/163834575?api=1&autopause=0&autoplay=1&badge=0&byline=0&loop=1&player_id=vimeo163834575&portrait=0&muted=1&title=0',
    duration: 23000
  },
  {
    url:
      'https://player.vimeo.com/video/163835513?api=1&autopause=0&autoplay=1&badge=0&byline=0&loop=1&player_id=vimeo163835513&portrait=0&muted=1&title=0',
    duration: 20000
  },
  {
    url:
      'https://player.vimeo.com/video/163836316?api=1&autopause=0&autoplay=1&badge=0&byline=0&loop=1&player_id=vimeo163836316&portrait=0&muted=1&title=0',
    duration: 20000
  }
];
