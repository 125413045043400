import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import SectionTitle from '../SectionTitle';
import { enterpriseHowItWorks } from '../../../utils/landingPro';
import Carousel from '../../Carousel';

const StepWrapper = ({ number, title, desc, isActive, onClick }) => (
  <div className={`stepWrapper ${isActive && 'active'}`} onClick={onClick}>
    <div className="number">{number}</div>
    <div className="step">
      <div className="title">{title}</div>
      <div className="desc">{desc}</div>
    </div>
  </div>
);
StepWrapper.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
  desc: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

class HowItWorksSection extends Component {
  state = {
    activeSlide: 0
  };

  componentWillMount() {
    const { isMobile, isTablet } = this.props;
    if (!(isMobile || isTablet)) {
      this.timeInterval = setInterval(this.showNextSlide, 6000);
    }
  }

  componentWillUnmount() {
    this.stopInterval();
  }

  getStepWrappers = () => {
    const { activeSlide } = this.state;
    return enterpriseHowItWorks().map((step, index) => (
      <StepWrapper
        number={index}
        key={step.stepKey}
        title={step.title}
        desc={step.description}
        isActive={activeSlide === index}
        onClick={() => this.showSlide(index)}
      />
    ));
  };
  stopInterval = () => {
    this.timeInterval && clearInterval(this.timeInterval);
  };
  showNextSlide = () => {
    const { activeSlide } = this.state;
    let nextSlide = activeSlide + 1;
    if (nextSlide > 3) {
      nextSlide = 0;
    }

    this.setState({ activeSlide: nextSlide });
  };
  showSlide = slideNumber => {
    this.stopInterval();
    this.setState({ activeSlide: slideNumber });
  };

  render() {
    const { activeSlide } = this.state;
    const { isMobile, isTablet, t } = this.props;

    return (
      <div className="howItWorksSection">
        <div className="section">
          <div className="titleContainer">
            <SectionTitle
              secondLine={`${t(`home.enterprise.howItWorks.titlePartOne`)} ${t(
                `home.enterprise.howItWorks.titlePartTwo`
              )}`}
            />
            <div className="sectionSubTitle">
              {t(`home.enterprise.howItWorks.subtitle`)}
            </div>
          </div>

          <div className="progressListContainer">
            <div
              className={`progressBar ${activeSlide === 0 ? 'active' : ''} ${
                activeSlide > 0 ? 'complete' : ''
              }`}
            >
              <div className="progress" />
            </div>
            <div
              className={`progressBar ${activeSlide === 1 ? 'active' : ''} ${
                activeSlide > 1 ? 'complete' : ''
              }`}
            >
              <div className="progress" />
            </div>
            <div
              className={`progressBar ${activeSlide === 2 ? 'active' : ''} ${
                activeSlide > 2 ? 'complete' : ''
              }`}
            >
              <div className="progress" />
            </div>
            <div className={`progressBar ${activeSlide === 3 ? 'active' : ''}`}>
              <div className="progress" />
            </div>
          </div>

          <div className="worksContainer">
            <div className="stepsList">
              {isMobile || isTablet ? (
                <Carousel paddingRight={16} onSlideChange={this.showSlide}>
                  {this.getStepWrappers()}
                </Carousel>
              ) : (
                <React.Fragment>{this.getStepWrappers()}</React.Fragment>
              )}
            </div>
            <div className="imageContainer">
              <div
                className={`slide${activeSlide + 1}`}
                style={{
                  backgroundImage: `url('../images/landing/ent_hiw_${activeSlide +
                    1}.png')`
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HowItWorksSection.propTypes = {
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  t: PropTypes.func.isRequired
};

export default translate()(HowItWorksSection);
