import {
  SET_VEHICLE_CLASSES,
  SET_VEHICLE_MAKE_OPTIONS,
  SET_VEHICLE_YEAR_OPTIONS,
  SET_VEHICLE_MODEL_OPTIONS,
  SET_VEHICLE_OPTIONS,
  SET_VEHICLE,
  SET_USER_VEHICLES,
  SET_VEHICLES_MAP,
  UPDATE_VEHICLE_ODOMETER
} from '../actions/vehicles';

const initialState = {
  yearOptions: [],
  makeOptions: [],
  modelOptions: [],
  vehicleOptions: [],
  classes: []
};

export const vehicles = (state = initialState, action) => {
  const { vehicleId, odometer } = action;
  switch (action.type) {
    case SET_VEHICLE_CLASSES:
      return {
        ...state,
        classes: (action.json && action.json.classes) || []
      };
    case SET_VEHICLE_YEAR_OPTIONS:
      return {
        ...state,
        yearOptions: (action.json && action.json.years.reverse()) || []
      };
    case SET_VEHICLE_MAKE_OPTIONS:
      return {
        ...state,
        makeOptions: (action.json && action.json.makers) || []
      };
    case SET_VEHICLE_MODEL_OPTIONS:
      return {
        ...state,
        modelOptions: (action.json && action.json.models) || []
      };
    case SET_VEHICLE_OPTIONS:
      return {
        ...state,
        vehicleOptions: (action.json && action.json) || []
      };
    case SET_VEHICLE:
      return {
        ...state,
        vehicle: (action.json && action.json.results[0]) || {}
      };
    case SET_USER_VEHICLES:
      return {
        ...state,
        userVehicles: action.vehiclesList || []
      };
    case SET_VEHICLES_MAP:
      return {
        ...state,
        vehiclesMap: action.vehiclesMap
      };
    case UPDATE_VEHICLE_ODOMETER:
      return {
        ...state,
        userVehicles: state.userVehicles.map(
          el => (el.id === vehicleId ? { ...el, odometer } : el)
        )
      };
    default:
      return state;
  }
};
